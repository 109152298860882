import React, { useState } from "react";
import { FaEyeSlash, FaEye } from "react-icons/fa";
import { Dispatch } from "redux";
import { useSelector, useDispatch } from "react-redux";
import { AxiosResponse, AxiosError } from "axios";
import axios from "axios";
import { History } from "history";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link, NavLink, useNavigate, useSearchParams } from "react-router-dom";
import { Oval } from "react-loader-spinner";
import { PaymentApis } from "../../../apis/paymentApis";
import { jsPDF } from 'jspdf';
import { Document, Page, Text, View, StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
  page: {
    fontFamily: 'Helvetica',
    fontSize: 12,
    padding: 20,
  },
  section: {
    margin: 10,
    padding: 10,
  },
  title: {
    fontSize: 18,
    marginBottom: 10,
  },
  text: {
    marginBottom: 10,
  },
});

const VerifyCreditPayment = () => {

  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [subValue, setSubValue] = React.useState(1);

  React.useEffect(() => {
    if (searchParams.get("status") == "cancelled") {
      navigate('/new-dashboard');
    }
  });



  if (searchParams.get("tx_ref")) {
    let data;
    if (searchParams.get("status") == "cancelled") {
      navigate('/new-dashboard');
    } else {
      PaymentApis.verifyCreditHistoryPayment(searchParams.get("tx_ref"))
        .then((response: any) => {
          if (response?.data) {
            // navigate('/wallet');
            if (response?.data?.success === true) {
              // console?.log(response?.data)
              navigate("/loans-gold-success");
            }
          } else {
            // toast.warn('Invalid Login Credentials');
          }
        })
        .catch(function (error: any) {
          // handle error
          console.log(error.response.data);
        })
        .finally(() => {
          navigate("/loans-gold-success");
          // toast.error("No Internet Connection");
        });
    }
  }

  return (
    <>
      <div className="flex justify-center mt-[40vh]">
        <div className="flex flex-col">
          <span className="flex justify-center">
            <Oval
              visible={true}
              height="80"
              width="80"
              color="#14B266"
              secondaryColor="#E6F1FC"
              ariaLabel="oval-loading"
              wrapperStyle={{}}
              wrapperClass=""
            />
          </span>

          <span className=" text-center">Please Wait !!!</span>
          <div className=" text-center">We are generating your credit score.</div>
        </div>




      </div>
    </>
  );
};

export default VerifyCreditPayment;
