import React from 'react'
import { NavLink } from "react-router-dom";

function ResetPassword() {
  return (
    <>
    {/* <!-- Start block --> */}
    <section className="bg-white  body-font font-poppins ">
        <div className="flex justify-center pt-20">
          {/* grid max-w-screen-xl mx-auto lg:gap-8 xl:gap-0 lg:grid-cols-12 */}

          

          <div className="px-24  mt-30">
            <div className="flex justify-center">
              <img src="/images/afripro.svg" alt="sign-in" />
            </div>
            <h1 className="max-w-2xl mt-6 text-3xl text-[#000000] font-semibold leading-10 md:text-3xl xl:text-4xl text-center ">
           Password Reset
            </h1>
            <p className="text-xs my-5 text-center text-gray-500">
              Must be at least 8 charaters
            </p>
           

            <div className="mt-10">
              <form>
                <div className="mb-5">
                  <label className="block mb-2 text-sm font-semibold text-[#0A2E04]">
                    Password
                  </label>
                  <input
                    type="password"
                    className=" border border-[#D9D9D9] text-[#333333] text-sm rounded-lg block w-full p-3"
                    placeholder="*****"
                    name="email"
                    required
                  />
                </div>
                <div className="mb-6">
                  <label className="block mb-2 text-sm font-semibold text-[#0A2E04]">
                    Comfirm Password
                  </label>
                  <input
                    type="password"
                    className=" border border-[#D9D9D9] text-[#333333] text-sm rounded-lg block w-full p-3"
                    placeholder="*****"
                    name="email"
                    required
                  />
                </div>
               
            
               

               <NavLink to={"/"} className="mt-3 ">
                  <button
                    type="button"
                    className="w-full text-white bg-[#48B774] hover:bg-[#05401C] focus:ring-4 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2"
                  >
                    Reset Password
                  </button>
                </NavLink>
               
              </form>

              <NavLink to={"/sign-in"} className="flex justify-center mt-4">
                <img src="/images/arrow-back-icon.svg" className=" mr-2" alt="sign-in" />
                <p className="text-center text-xs font-semibold text-gray-500 mt-1">
                
                  Back to login
                  
                </p>
                </NavLink>
            </div>
          </div>
        </div>
      </section>

      {/* <!-- End block --> */}
    </>
  )
}

export default ResetPassword