import React from 'react'
import Navbar from '../../Navbar'
import Footer from '../../Footer';
import BlogBg from "../../../img/blog.png"


function STEMCourses() {
    return (
        <>
            <Navbar />
            <div className='pt-16'>
                <div
                    style={{
                        backgroundImage: `url(${BlogBg})`,
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "cover",
                        backgroundPosition: "center center",
                    }}
                    className="md:bg-cover bg-contain lg:px-14 px-6 md:pb-24 pb-10 mx-auto  flex flex-col lg:justify-center lg:space-x-14 relative"
                >
                    <div className="place-self-center text-center md:pt-32 md:pb-10 pt-8">
                        <h1 className="md:text-5xl text-2xl text-[#191A15] font-bold">
                        Boost Your Education with AfriPay: Why STEM Courses Are Your Best Choice

                        </h1>

                        <p className="md:mt-5 mt-2 md:text-base text-[#999A93] text-sm">
                            Get News with AfriPay
                        </p>
                    </div>
                </div>
            </div>

            <section className="bg-[#FFFFFF] body-font font-poppins py-10">
                <div className='lg:px-16 mx-auto px-8 pt-3'>
                    <div className='grid grid-cols-1 gap-8 md:grid-cols-3 lg:grid-cols-12'>
                        <div className='col-span-8'>
                            <div>
                                <img src="/images/blog3.jpg" alt="blog" className='rounded-[10px]' />
                                
                                <p className='mt-3'>Many dream of higher education, but finances often pose a hurdle. That's where AfriPay steps in, revolutionizing educational loans for international students. AfriPay makes credit scores less daunting, and focusing on STEM courses can boost your loan approval chances even more.</p>
                            </div>

                        </div>
                    </div>

                    <div className='mt-5'>
                        <h3 className='mt-3 text-[20px] font-semibold'>Why Choose STEM?</h3>
                        <p className='mt-3'>STEM—Science, Technology, Engineering, and Mathematics—courses are highly valued for several reasons. They are the backbone of innovation and development, driving the future of technology, healthcare, infrastructure, and more. Here are some key benefits of pursuing STEM education:</p>
                        <div className='mt-3'>
                            <ul className='space-y-2 list-decimal list-inside'>
                                <li><span className='font-semibold'>High Demand and Job Security</span> </li>
                               <h3>STEM fields are in high demand worldwide, ensuring robust job opportunities and career growth. Industries such as technology, engineering, and healthcare consistently seek skilled professionals, offering higher job security compared to other fields.</h3>
                                <li><span className='font-semibold'>Competitive Salaries</span> </li>
                                <h3>Careers in STEM are among the highest-paying jobs. Engineers, IT specialists, and healthcare professionals often enjoy lucrative salaries, making the investment in education worthwhile.</h3>
                                <li><span className='font-semibold'>Critical Thinking and Problem-Solving Skills</span> </li>
                                <h3>STEM education emphasizes analytical thinking, problem-solving, and innovation. These skills are not only vital for STEM careers but are also highly transferable to other industries, enhancing overall employability.</h3>
                                
                            </ul>
                        </div>
                        
                       
                        <div className='mt-5'>
                            <h3 className='mt-3 text-[20px] font-semibold'>AfriPay: Your Educational Partner AfriPay supports your educational dreams with:</h3>
                            <p className='mt-3'>AfriPay understands the importance of supporting aspiring students in achieving their educational dreams. Here’s how AfriPay stands out:</p> 
                        </div>
                        
                        <div className='mt-5'>
                        <h3 className='text-[20px] font-semibold'>Tailored Loan Packages</h3>
                        <p className='mt-3'>AfriPay offers customized loan packages that cater to various educational needs, including our premium EduPremium package. This package provides access to over 2000 schools across the US, UK, and Canada, along with guaranteed loans for eligible applicants.</p>
                        </div>

                        <div className='mt-5'>
                        <h3 className='text-[20px] font-semibold'>Flexible Payback Periods</h3>
                        <p className='mt-3'>With up to 10 years to repay your loan, AfriPay ensures that you can focus on your studies without immediate financial stress. This extended payback period allows for a smoother transition from education to employment.</p>
                        </div>

                        <div className='mt-5'>
                        <h3 className='text-[20px] font-semibold'>Seamless Application Process</h3>
                        <p className='mt-3'>AfriPay’s application process is straightforward and user-friendly. Register on our web app goafripay.com/sign-up, check your credit score, and apply for your loan—all in a few simple steps.</p>
                        </div>

                        <div className='mt-5'>
                        <h3 className='text-[20px] font-semibold'>Support for STEM Students</h3>
                        <p className='mt-3'>Understanding the value of STEM education, AfriPay provides enhanced support for students pursuing these critical fields. This focus not only helps students secure loans more easily but also aligns with global trends in education and employment.</p>
                        </div>

                        <div className='mt-5'>
                            <h3 className=' text-[24px] font-semibold'>Get Started with AfriPay Today!</h3>
                            <p className='mt-3'>Don’t let financial constraints hold you back from achieving your dreams. Whether you’re aiming to study engineering, computer science, or any other STEM course, AfriPay is here to help you every step of the way. Secure your future with a reliable partner dedicated to your success.</p>
                        </div>

                        <div className='mt-5'>
                        <h3 className='text-[20px] font-semibold'>Ready to take the next step? Register now on AfriPay and start your journey towards a brighter future!</h3>
                        <p className='mt-3'>Make the smart choice today. Empower your education with AfriPay and pave the way for a successful future in STEM!</p>
                        </div>
                        <hr className='my-4'/>
 {/* <-- Tags --> */}
                        <div className='mt-3'>
                            <h3>#Visa
#tuitionfee
#STEMeducation
#StudyAbroad
#AfriPayLoans
#HigherEducation
#StudentLoans
#EduPremium
#Innovation
#CareerGrowth</h3>
                        </div>
                    </div>
                </div>
            </section>



            {/* <-- Footer --> */}
            <Footer />
        </>
    )
}

export default STEMCourses;