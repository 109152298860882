import React from 'react'
import Navbar from '../Navbar'
import { NavLink } from "react-router-dom";
import Footer from '../Footer';

function Product() {
    return (
        <>
            
            <section className=' bg-gradient-to-r from-[#FFFCFC] to-[#DBEEE6]'>
            <Navbar />
            <div className='pt-[120px] pb-20'>
            <div className='flex flex-col justify-center items-center px-8'>
                    <h1 className='text-[40px] text-[#000000] font-bold md:leading-[90px] tracking-normal  md:text-[80px] xl:text-[80px]'>Simplifying Global</h1>
                    <h1 className='text-[40px] text-[#209D5A] font-bold md:leading-[90px] tracking-normal  md:text-[80px] xl:text-[80px]'>Tuition Payments</h1>
                    <div className='mt-2'>
                        <img src="/images/arc.svg" alt="hero" />
                    </div>
                    <p className=' max-w-[1000px] mt-4 text-center font-medium text-[16px] md:text-[18px]'>AfriPay aims to revolutionize the EdTech payment landscape by providing an integrated payment solution for students and their families, simplifying international tuition fee payments.</p>
                    <div className='mt-5'>
                        <NavLink to="/sign-in">
                        <button
                            type="submit"
                            className="py-[10px] px-[120px]  text-[16px] font-normal text-white bg-[#209D5A] rounded-[45px] border border-[#209D5A] "
                        >
                            Get Started
                        </button>
                        </NavLink>
                        
                    </div>
                </div>
                <section className=" pt-16 px-8" >
                    <div className=" mx-auto text-center md:py-4 ">
                        <h1 className="text-[#000000] md:text-[36px] text-[30px] px-6 font-[700]">
                            The most seamless Tuition payment system
                        </h1>
                        <p className=" font-medium text-[14px] md:text-[16px] mt-1">We have heard your plea and we have decided to make things easy with AfriPay</p>
                    </div>
                    <div className="lg:px-14 md:grid md:grid-cols-3 gap-4 md:py-10 py-6 p-6">
                        <div className="flex space-x-3 md:mt-0 mt-5">
                            <div className="h-[60px] w-[60px] rounded-full bg-[#CDECD7]"></div>
                            <div>
                                <h3 className=" font-semibold text-[20px] md:text-[24px]">Convenient</h3>
                                <p className=" font-normal text-[13px] md:text-[15px] max-w-[300px]">AfriPay ensures easy transactions for a seamless academic journey</p>
                            </div>
                        </div>
                        <div className="flex space-x-3 md:mt-0 mt-5">
                            <div className="h-[60px] w-[60px] rounded-full bg-[#CDECD7]"></div>
                            <div>
                                <h3 className=" font-semibold text-[20px] md:text-[24px]">Faster </h3>
                                <p className=" font-normal text-[13px] md:text-[15px] max-w-[300px]">AfriPay ensures faster transactions, making education-related payments swift and convenient. </p>
                            </div>
                        </div>
                        <div className="flex space-x-3 md:mt-0 mt-5">
                            <div className="h-[60px] w-[60px] rounded-full bg-[#CDECD7]"></div>
                            <div>
                                <h3 className=" font-semibold text-[20px] md:text-[24px]">Simpler</h3>
                                <p className=" font-normal text-[13px] md:text-[15px] max-w-[300px]">AfriPay makes education transactions simpler, ensuring an easy and straightforward financial experience </p>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
                
            </section>

            {/* <!-- Proof of funds --> */}
            {/* <section className="bg-gradient-to-r from-[#FFFCFC] to-[#DBEEE6] md:pt-[20px] pt-[20px] sm:px-14 px-8 flex justify-center items-start" >
                <div className='xl:max-w-[1280px] w-full'>
                    <div className='flex md:flex-row flex-col sm:py-14 py-6'>
                        <div className='flex-1 flex justify-center items-start flex-col'>
                            <div className="md:flex flex-col w-full">

                                <h1 className="flex-1 font-poppins font-semibold md:text-[40px] text-[25px] md:mt-[20px] mt-[10px] text-[#1B212F] md:leading-[48px] leading-[38px]">
                                    Proof of Funds
                                </h1>
                                <p className='md:mt-[20px] mt-[10px] text-[#707070] md:text-[16px] text-sm max-w-[500px]'>Fast-track your international education journey with AfriPay's swift and secure proof of funds process</p>
                            </div>
                            <div className='mt-[24px]'>
                                <div className="flex space-x-2">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 24 24"><g fill="#1db459"><path d="M3.464 20.535C4.93 22 7.286 22 12 22c4.714 0 7.071 0 8.535-1.465C22 19.072 22 16.714 22 12s0-7.071-1.465-8.536C19.072 2 16.714 2 12 2S4.929 2 3.464 3.464C2 4.93 2 7.286 2 12c0 4.714 0 7.071 1.464 8.535Z" opacity=".5" /><path d="M18.581 9.474a.75.75 0 1 0-1.162-.948l-5.168 6.33a.749.749 0 0 0-.879 1.116l.286.438a.75.75 0 0 0 1.209.064l5.714-7Zm-4 0a.75.75 0 1 0-1.162-.948l-5.133 6.288l-1.705-2.088a.75.75 0 0 0-1.162.948l2.286 2.8a.75.75 0 0 0 1.162 0l5.714-7Z" /></g></svg>
                                    <h3 className="mt-1 font-normal text-[#838383] text-[14px]">Quick Verification</h3>
                                </div>
                                <div className="flex space-x-2 mt-[20px]">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 24 24"><g fill="#1db459"><path d="M3.464 20.535C4.93 22 7.286 22 12 22c4.714 0 7.071 0 8.535-1.465C22 19.072 22 16.714 22 12s0-7.071-1.465-8.536C19.072 2 16.714 2 12 2S4.929 2 3.464 3.464C2 4.93 2 7.286 2 12c0 4.714 0 7.071 1.464 8.535Z" opacity=".5" /><path d="M18.581 9.474a.75.75 0 1 0-1.162-.948l-5.168 6.33a.749.749 0 0 0-.879 1.116l.286.438a.75.75 0 0 0 1.209.064l5.714-7Zm-4 0a.75.75 0 1 0-1.162-.948l-5.133 6.288l-1.705-2.088a.75.75 0 0 0-1.162.948l2.286 2.8a.75.75 0 0 0 1.162 0l5.714-7Z" /></g></svg>
                                    <h3 className="mt-1 font-normal text-[#838383] text-[14px]">Secure Financial Proof</h3>
                                </div>
                                <div className="flex space-x-2 mt-[20px]">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 24 24"><g fill="#1db459"><path d="M3.464 20.535C4.93 22 7.286 22 12 22c4.714 0 7.071 0 8.535-1.465C22 19.072 22 16.714 22 12s0-7.071-1.465-8.536C19.072 2 16.714 2 12 2S4.929 2 3.464 3.464C2 4.93 2 7.286 2 12c0 4.714 0 7.071 1.464 8.535Z" opacity=".5" /><path d="M18.581 9.474a.75.75 0 1 0-1.162-.948l-5.168 6.33a.749.749 0 0 0-.879 1.116l.286.438a.75.75 0 0 0 1.209.064l5.714-7Zm-4 0a.75.75 0 1 0-1.162-.948l-5.133 6.288l-1.705-2.088a.75.75 0 0 0-1.162.948l2.286 2.8a.75.75 0 0 0 1.162 0l5.714-7Z" /></g></svg>
                                    <h3 className="mt-1 font-normal text-[#838383] text-[14px]">Streamlined Application</h3>
                                </div>
                                <div className="flex space-x-2 mt-[20px]">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 24 24"><g fill="#1db459"><path d="M3.464 20.535C4.93 22 7.286 22 12 22c4.714 0 7.071 0 8.535-1.465C22 19.072 22 16.714 22 12s0-7.071-1.465-8.536C19.072 2 16.714 2 12 2S4.929 2 3.464 3.464C2 4.93 2 7.286 2 12c0 4.714 0 7.071 1.464 8.535Z" opacity=".5" /><path d="M18.581 9.474a.75.75 0 1 0-1.162-.948l-5.168 6.33a.749.749 0 0 0-.879 1.116l.286.438a.75.75 0 0 0 1.209.064l5.714-7Zm-4 0a.75.75 0 1 0-1.162-.948l-5.133 6.288l-1.705-2.088a.75.75 0 0 0-1.162.948l2.286 2.8a.75.75 0 0 0 1.162 0l5.714-7Z" /></g></svg>
                                    <h3 className="mt-1 font-normal text-[#838383] text-[14px]">Transparent Process</h3>
                                </div>




                            </div>
                            <NavLink to={"/sign-in"} className='mb-6 mt-[25px]'>
                                <button type="button" className="text-[#1DB459] border border-[#1DB459]  font-medium rounded-full text-sm px-6 py-3 text-center items-center">
                                    Apply Now
                                </button>
                            </NavLink>

                        </div>
                        <div className='flex-1 flex justify-center items-center'>
                            <img src="/images/proof-of-fund.png" alt="hero" />
                        </div>
                    </div>
                </div>
            </section> */}

            {/* <!-- Loans --> */}

            <section className="bg-[#FFFFFF] md:pt-[50px] pt-[40px] pb-16 sm:px-14 px-8 flex justify-center items-start" >
                <div className='xl:max-w-[1280px] w-full'>
                    <div className='flex md:flex-row flex-col'>

                        <div className='flex-1 flex justify-center items-center'>
                            <img src="/images/loan.png" alt="hero" />
                        </div>

                        <div className='flex-1 flex justify-center items-start flex-col md:ml-[120px] ml-0'>
                            <div className="md:flex flex-col w-full">

                                <h1 className="flex-1 font-poppins font-semibold md:text-[40px] text-[25px] md:mt-[20px] mt-[10px] text-[#1B212F] md:leading-[48px] leading-[38px]">
                                    Loans
                                </h1>
                                <p className='md:mt-[20px] mt-[10px] text-[#838383] md:text-[18px] text-sm max-w-[500px]'>Afripay provides student loans through our partners as a financial solution to support students in pursuing their education. </p>
                            </div>
                            <div className='mt-[24px]'>
                                <div className="flex space-x-2">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 24 24"><g fill="#1db459"><path d="M3.464 20.535C4.93 22 7.286 22 12 22c4.714 0 7.071 0 8.535-1.465C22 19.072 22 16.714 22 12s0-7.071-1.465-8.536C19.072 2 16.714 2 12 2S4.929 2 3.464 3.464C2 4.93 2 7.286 2 12c0 4.714 0 7.071 1.464 8.535Z" opacity=".5" /><path d="M18.581 9.474a.75.75 0 1 0-1.162-.948l-5.168 6.33a.749.749 0 0 0-.879 1.116l.286.438a.75.75 0 0 0 1.209.064l5.714-7Zm-4 0a.75.75 0 1 0-1.162-.948l-5.133 6.288l-1.705-2.088a.75.75 0 0 0-1.162.948l2.286 2.8a.75.75 0 0 0 1.162 0l5.714-7Z" /></g></svg>
                                    <h3 className="mt-1 font-normal text-[#777777] text-[16px] md:text-[18px]">Low-Interest Rates</h3>
                                </div>
                                <div className="flex space-x-2 mt-[20px]">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 24 24"><g fill="#1db459"><path d="M3.464 20.535C4.93 22 7.286 22 12 22c4.714 0 7.071 0 8.535-1.465C22 19.072 22 16.714 22 12s0-7.071-1.465-8.536C19.072 2 16.714 2 12 2S4.929 2 3.464 3.464C2 4.93 2 7.286 2 12c0 4.714 0 7.071 1.464 8.535Z" opacity=".5" /><path d="M18.581 9.474a.75.75 0 1 0-1.162-.948l-5.168 6.33a.749.749 0 0 0-.879 1.116l.286.438a.75.75 0 0 0 1.209.064l5.714-7Zm-4 0a.75.75 0 1 0-1.162-.948l-5.133 6.288l-1.705-2.088a.75.75 0 0 0-1.162.948l2.286 2.8a.75.75 0 0 0 1.162 0l5.714-7Z" /></g></svg>
                                    <h3 className="mt-1 font-normal text-[#777777] text-[16px] md:text-[18px]">Flexible Repayment Plans</h3>
                                </div>
                                <div className="flex space-x-2 mt-[20px]">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 24 24"><g fill="#1db459"><path d="M3.464 20.535C4.93 22 7.286 22 12 22c4.714 0 7.071 0 8.535-1.465C22 19.072 22 16.714 22 12s0-7.071-1.465-8.536C19.072 2 16.714 2 12 2S4.929 2 3.464 3.464C2 4.93 2 7.286 2 12c0 4.714 0 7.071 1.464 8.535Z" opacity=".5" /><path d="M18.581 9.474a.75.75 0 1 0-1.162-.948l-5.168 6.33a.749.749 0 0 0-.879 1.116l.286.438a.75.75 0 0 0 1.209.064l5.714-7Zm-4 0a.75.75 0 1 0-1.162-.948l-5.133 6.288l-1.705-2.088a.75.75 0 0 0-1.162.948l2.286 2.8a.75.75 0 0 0 1.162 0l5.714-7Z" /></g></svg>
                                    <h3 className="mt-1 font-normal text-[#777777] text-[16px] md:text-[18px]">Quick Approval</h3>
                                </div>

                                <div className="flex space-x-2 mt-[20px]">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 24 24"><g fill="#1db459"><path d="M3.464 20.535C4.93 22 7.286 22 12 22c4.714 0 7.071 0 8.535-1.465C22 19.072 22 16.714 22 12s0-7.071-1.465-8.536C19.072 2 16.714 2 12 2S4.929 2 3.464 3.464C2 4.93 2 7.286 2 12c0 4.714 0 7.071 1.464 8.535Z" opacity=".5" /><path d="M18.581 9.474a.75.75 0 1 0-1.162-.948l-5.168 6.33a.749.749 0 0 0-.879 1.116l.286.438a.75.75 0 0 0 1.209.064l5.714-7Zm-4 0a.75.75 0 1 0-1.162-.948l-5.133 6.288l-1.705-2.088a.75.75 0 0 0-1.162.948l2.286 2.8a.75.75 0 0 0 1.162 0l5.714-7Z" /></g></svg>
                                    <h3 className="mt-1 font-normal text-[#777777] text-[16px] md:text-[18px]">Access to Education</h3>
                                </div>




                            </div>
                            <NavLink to={"/sign-in"} className='mb-6 mt-[25px]'>
                                <button type="button" className="text-[#1DB459] border border-[#1DB459]  font-medium rounded-full text-sm px-6 py-3 text-center items-center">
                                    Apply Now
                                </button>
                            </NavLink>

                        </div>

                    </div>
                </div>

            </section>

            {/* <-- Footer --> */}
            <Footer />
        </>
    )
}

export default Product