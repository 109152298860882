import React, { useState } from 'react'
import Sidebar from "../../Sidebar/AdminSidebar";
import UserNavbar from "../../Navbars/AdminNavbar";
import { useParams } from 'react-router-dom';
import { NavLink, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { AdminApis } from '../../../apis/adminApi';
import { AxiosResponse } from 'axios';
import { ToastContainer, toast } from 'react-toastify';


function SchoolFormDetails() {
    const [openTestTab, setOpenTestTab] = useState(1);

    const params: any = useParams();
    let user_id: string = params?.user_id;
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [userSchData, setUserSchData] = React.useState<any>([]);
    const [status, setStatus] = React.useState<any>('');
    const userLoginData = useSelector((state: any) => state.data.login.value);

    React.useEffect(() => {
        AdminApis.getSingleSchoolForm(user_id).then(
            (response: AxiosResponse) => {
                if (response?.data) {
                    setUserSchData(response?.data?.data)
                    console.log(response?.data)
                }


            }
        ).catch(function (error) {
            // handle error
            console.log(error.response.data);

        })

    }, []);


    const changeStatus = React.useCallback(
        (id: any) => {
            //   setLoader(true);
            const query: any = {
                status: status,
            };

            AdminApis.updateStatus(id, query).then(
                (response: AxiosResponse<any>) => {
                    if (response?.data) {

                        toast.success("Status Updated")

                        AdminApis.getSingleSchoolForm(id).then(
                            (response: AxiosResponse) => {
                                if (response?.data) {
                                    setUserSchData(response?.data?.data)
                                }

                                // toast.success(response?.data?.message);
                            }
                        )
                        // setUserList(response?.data?.data)
                    }
                }
            ).catch(function (error) {
                console.log(error.response.data);
            })

        }, [status, userSchData]);



    console.log(userSchData)


    return (
        <>
            <Sidebar />
            <div className="relative md:ml-64">
                <UserNavbar title="School Form Details" />

                <div className='pt-5 pb-6 lg:px-10 px-6 bg-[#F8F8F8] min-h-screen'>

                    <div className='p-4'>

                        <div>
                            <NavLink to={"/list-of-school-form"}>
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M20.7501 11.9998C20.7501 12.4138 20.4141 12.7498 20.0001 12.7498H5.81115L8.53112 15.4698C8.82412 15.7628 8.82412 16.2378 8.53112 16.5308C8.38512 16.6768 8.19309 16.7508 8.00109 16.7508C7.80909 16.7508 7.61706 16.6778 7.47106 16.5308L3.47106 12.5308C3.40206 12.4618 3.3472 12.3789 3.3092 12.2869C3.2332 12.1039 3.2332 11.8969 3.3092 11.7139C3.3472 11.6219 3.40206 11.5387 3.47106 11.4697L7.47106 7.46975C7.76406 7.17675 8.2391 7.17675 8.5321 7.46975C8.8251 7.76275 8.8251 8.23779 8.5321 8.53079L5.81213 11.2508H20.0001C20.4141 11.2498 20.7501 11.5858 20.7501 11.9998Z" fill="#2A2A2A" />
                                </svg>

                            </NavLink>
                        </div>

                        <div className='bg-white rounded-[15px] pt-5 pb-3 px-6 flex lg:flex-row flex-col lg:justify-between'>
                            <div>
                                <h3 className='text-[18px] font-semibold'>{userSchData?.full_name}</h3>

                                <h5 className='text-[14px] text-[#A9A9A9] mt-4'>Registration Date : {(userSchData?.created_at)?.slice(0, 10)} </h5>

                            </div>
                            <div>
                                <h3 className='font-semibold text-[14px]'>Payment Status</h3>
                            {userSchData?.payment_status === "pending" ?  <span className='bg-[#FFDDBD] text-[#FF922B] text-[12px] px-[8px] py-[2px] rounded-sm'>{userSchData?.payment_status}</span> : "" }
                                                            {userSchData?.payment_status === "successful" ?<span className='bg-[#C9F0D0] text-[#51CF66] text-[12px] px-[8px] py-[2px] rounded-sm'>{userSchData?.payment_status}</span>: "" }
                                                            {userSchData?.payment_status === "failed" ? <span className='bg-[#F5BFBF] text-[#E03130] text-[12px] px-[8px] py-[2px] rounded-sm'>{userSchData?.payment_status}</span> : ""}
                                                           
                                                            <h3 className='font-semibold text-[14px] mt-3'>Amount Payment</h3> 
                                                            {userSchData?.amount ? userSchData?.amount : "0"}                    
                            </div>


                        </div>
                    </div>

                    <div className='p-4'>
                        {/* -- Student details -- */}
                        <div>
                            <div className='pt-5'>
                                <div className='bg-white px-6 pt-5 pb-10 rounded-[20px]'>
                                    <h3 className='text-[16px] font-semibold'>Student Application Info </h3>
                                    <div className='grid grid-cols-1 lg:grid-cols-4'>
                                        <div className='mt-4'>
                                            <h3 className='text-[#A9A9A9] text-[14px]'>Student Name</h3>
                                            <h2 className='mt-1 text-[14px] font-semibold capitalize'>{userSchData?.full_name}</h2>
                                        </div>
                                        <div className='mt-4'>
                                            <h3 className='text-[#A9A9A9] text-[14px]'>Email</h3>
                                            <h2 className='mt-1 text-[14px] font-semibold'>{userSchData?.email}</h2>
                                        </div>
                                        <div className='mt-4'>
                                            <h3 className='text-[#A9A9A9] text-[14px]'>Age</h3>
                                            <h2 className='mt-1 text-[14px] font-semibold'>{userSchData?.age}</h2>
                                        </div>

                                        <div className='mt-4'>
                                            <h3 className='text-[#A9A9A9] text-[14px]'>Gender</h3>
                                            <h2 className='mt-1 text-[14px] font-semibold capitalize'>{userSchData?.gender}</h2>
                                        </div>
                                        <div className='mt-4'>
                                            <h3 className='text-[#A9A9A9] text-[14px]'>Marital Status</h3>
                                            <h2 className='mt-1 text-[14px] font-semibold capitalize'>{userSchData?.marital_status}</h2>
                                        </div>
                                        <div className='mt-4'>
                                            <h3 className='text-[#A9A9A9] text-[14px]'>Employment Status</h3>
                                            <h2 className='mt-1 text-[14px] font-semibold capitalize'>{userSchData?.employment_status}</h2>
                                        </div>

                                        <div className='mt-4'>
                                            <h3 className='text-[#A9A9A9] text-[14px]'>Job Title</h3>
                                            <h2 className='mt-1 text-[14px] font-semibold capitalize'>{userSchData.job_title}</h2>
                                        </div>


                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* -- Credit Check details -- */}
                        <div>
                            <div className='pt-5'>
                                <div className='bg-white px-6 pt-5 pb-10 rounded-[20px]'>
                                    <h3 className='text-[16px] font-semibold'>Certificate Info </h3>
                                    <div className='grid grid-cols-1 lg:grid-cols-2'>
                                        <div className='mt-4'>
                                            <h3 className='text-[#A9A9A9] text-[14px]'>CGPA</h3>
                                            <h2 className='mt-1 text-[14px] font-semibold capitalize'>{userSchData?.cgpa_level}</h2>
                                        </div>
                                        <div className='mt-4'>
                                            <h3 className='text-[#A9A9A9] text-[14px]'> Institution you obtained your degree</h3>
                                            <h2 className='mt-1 text-[14px] font-semibold capitalize'>{userSchData?.your_institution}</h2>
                                        </div>
                                        <div className='mt-4'>
                                            <h3 className='text-[#A9A9A9] text-[14px]'> Type of Certificate</h3>
                                            <h2 className='mt-1 text-[14px] font-semibold'>{userSchData?.type_of_certificate}</h2>
                                        </div>
                                        <div className='mt-4'>
                                            <h3 className='text-[#A9A9A9] text-[14px]'>Certificate Upload</h3>
                                            <div className='mt-1 w-[100px] h-[100px]'>
                                                <img src={userSchData?.certificate ? userSchData?.certificate : ""} />
                                            </div>
                                        </div>
                                        <div className='mt-8'>
                                            <h3 className='text-[#A9A9A9] text-[14px]'>Do you have Language Certificate</h3>
                                            <h2 className='mt-1 text-[14px] font-semibold'>{userSchData?.do_you_have_lang_certificate}</h2>
                                        </div>
                                        <div className='mt-8'>
                                            <h3 className='text-[#A9A9A9] text-[14px]'>Language Certificate Upload</h3>
                                            <div className='mt-1 w-[100px] h-[100px]'>
                                                <img src={userSchData?.language_certificate ? userSchData?.language_certificate : ""} />
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* -- Student Loan details -- */}
                        <div>
                            <div className='pt-5'>
                                <div className='bg-white px-6 pt-5 pb-10 rounded-[20px]'>
                                    <h3 className='text-[16px] font-semibold'>School Info </h3>
                                    <div className='grid grid-cols-1 lg:grid-cols-3'>
                                        <div className='mt-4'>
                                            <h3 className='text-[#A9A9A9] text-[14px]'>Region One</h3>
                                            <h2 className='mt-1 text-[14px] font-semibold capitalize'>{userSchData?.region1}</h2>
                                        </div>
                                        <div className='mt-4'>
                                            <h3 className='text-[#A9A9A9] text-[14px]'>School 1 in Region 1</h3>
                                            <h2 className='mt-1 text-[14px] font-semibold capitalize'>{userSchData?.school1_in_region1}</h2>
                                        </div>
                                        <div className='mt-4'>
                                            <h3 className='text-[#A9A9A9] text-[14px]'>School 2 in Region 1</h3>
                                            <h2 className='mt-1 text-[14px] font-semibold capitalize'>{userSchData?.school12_in_region1}</h2>
                                        </div>
                                        <div className='mt-4'>
                                            <h3 className='text-[#A9A9A9] text-[14px]'>Region Two</h3>
                                            <h2 className='mt-1 text-[14px] font-semibold capitalize'>{userSchData?.region2}</h2>
                                        </div>
                                        <div className='mt-4'>
                                            <h3 className='text-[#A9A9A9] text-[14px]'>School 1 in Region 2</h3>
                                            <h2 className='mt-1 text-[14px] font-semibold capitalize'>{userSchData?.school1_in_region2}</h2>
                                        </div>
                                        <div className='mt-4'>
                                            <h3 className='text-[#A9A9A9] text-[14px]'>School 2 in Region 2</h3>
                                            <h2 className='mt-1 text-[14px] font-semibold capitalize'>{userSchData?.school2_in_region2}</h2>
                                        </div>
                                        <div className='mt-4'>
                                            <h3 className='text-[#A9A9A9] text-[14px]'>Region Three</h3>
                                            <h2 className='mt-1 text-[14px] font-semibold capitalize'>{userSchData?.region3}</h2>
                                        </div>
                                        <div className='mt-4'>
                                            <h3 className='text-[#A9A9A9] text-[14px]'>School 1 in Region 3</h3>
                                            <h2 className='mt-1 text-[14px] font-semibold capitalize'>{userSchData?.school1_in_region3}</h2>
                                        </div>
                                        <div className='mt-4'>
                                            <h3 className='text-[#A9A9A9] text-[14px]'>School 2 in Region 3</h3>
                                            <h2 className='mt-1 text-[14px] font-semibold capitalize'>{userSchData?.school2_in_region3}</h2>
                                        </div>


                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

                    {/* <div className='p-4'>
                        <div className='bg-white rounded-[15px] pt-5 pb-3 px-6 flex lg:flex-row flex-col lg:justify-between'>
                            <div>
                                <h3 className='text-[18px] font-semibold'>{userData?.f_name + ' ' + userData?.l_name}</h3>
                               
                                <h5 className='text-[14px] text-[#A9A9A9] mt-4'>Registration Date : {(userData?.created_at)?.slice(0,10)} </h5>
                            </div>
                            <div className='mt-3'>
                                <span className='bg-[#EDF6F5] text-[#3A974C] text-[12px] rounded-[22px] items-center text-center py-2 capitalize px-6'>{userData?.status}</span>

                                <div className='flex mt-5 space-x-3'>
                                    <form>
                                        <div className="relative w-full">
                                            <select id="status" name="status" onChange={(e)=>setStatus(e.target.value)}
                                                className=" bg-white border border-[#DADADA] text-[#000] text-sm rounded-[5px] block w-full px-3 py-2.5 placeholder-[#000]  ease-linear transition-all duration-150">
                                                <option selected>Choose a Status</option>
                                                <option value="approved">Approved</option>
                                                <option value="inReview">In Review</option>
                                                <option value="pending">Pending</option>
                                                <option value="cancelled">Cancelled</option>
                                            </select>
                                        </div>
                                    </form>
                                    <div>

                                        <button type='button' onClick={()=>changeStatus(userData?.id)} className='bg-[#00B07B] text-white rounded-[5px] py-[8px] px-[16px]'>Save</button>
                                       
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div> */}

                    <div className='p-4'>
                        {/* -- Loan application details -- */}
                        {/* <div>
                            <div className='pt-5'>
                                <div className='bg-white px-6 pt-5 pb-10 rounded-[20px]'>
                                    <h3 className='text-[16px] font-semibold'>Student Application Info </h3>
                                    <div className='grid grid-cols-1 lg:grid-cols-4'>
                                    <div className='mt-4'>
                                                <h3 className='text-[#A9A9A9] text-[14px]'>Student Name</h3>
                                                <h2 className='mt-1 text-[14px] font-semibold capitalize'>{userData?.f_name + ' ' + userData?.l_name}</h2>
                                            </div>
                                            <div className='mt-4'>
                                                <h3 className='text-[#A9A9A9] text-[14px]'>Email</h3>
                                                <h2 className='mt-1 text-[14px] font-semibold'>{userData?.email}</h2>
                                            </div>
                                            <div className='mt-4'>
                                                <h3 className='text-[#A9A9A9] text-[14px]'>Phone Number</h3>
                                                <h2 className='mt-1 text-[14px] font-semibold'>{userData?.phone_number}</h2>
                                            </div>
                                            <div className='mt-4'>
                                                <h3 className='text-[#A9A9A9] text-[14px]'>Program Type</h3>
                                                <h2 className='mt-1 text-[14px] font-semibold capitalize'>{userData?.program_of_application}</h2>
                                            </div>
                                            <div className='mt-4'>
                                                <h3 className='text-[#A9A9A9] text-[14px]'>Country Of Study</h3>
                                                <h2 className='mt-1 text-[14px] font-semibold capitalize'>{userData?.where_are_you_studing}</h2>
                                            </div>
                                            <div className='mt-4'>
                                                <h3 className='text-[#A9A9A9] text-[14px]'>School</h3>
                                                <h2 className='mt-1 text-[14px] font-semibold capitalize'>{userData?.choose_your_sch}</h2>
                                            </div>
                                            <div className='mt-4'>
                                                <h3 className='text-[#A9A9A9] text-[14px]'>Field of Study </h3>
                                                <h2 className='mt-1 text-[14px] font-semibold capitalize'>{userData?.field_of_study}</h2>
                                            </div>
                                            <div className='mt-4'>
                                                <h3 className='text-[#A9A9A9] text-[14px]'>Stage of Admission </h3>
                                                <h2 className='mt-1 text-[14px] font-semibold capitalize'>{userData?.stage_of_admission}</h2>
                                            </div>
                                            <div className='mt-4'>
                                                <h3 className='text-[#A9A9A9] text-[14px]'>Program Start Date </h3>
                                                <h2 className='mt-1 text-[14px] font-semibold'>{userData?.program_start_date}</h2>
                                            </div>
                                            <div className='mt-4'>
                                                <h3 className='text-[#A9A9A9] text-[14px]'>Program End Date </h3>
                                                <h2 className='mt-1 text-[14px] font-semibold'>{userData?.program_end_date}</h2>
                                            </div>
                                            <div className='mt-4'>
                                                <h3 className='text-[#A9A9A9] text-[14px]'>Have a Sponsor </h3>
                                                <h2 className='mt-1 text-[14px] font-semibold capitalize'>{userData?.do_you_have_a_sponsor}</h2>
                                            </div>
                                            <div className='mt-4'>
                                                <h3 className='text-[#A9A9A9] text-[14px]'>Sponsor Amount</h3>
                                                <h2 className='mt-1 text-[14px] font-semibold'>{userData?.sponsor_amount}</h2>
                                            </div>
                                            <div className='mt-4'>
                                                <h3 className='text-[#A9A9A9] text-[14px]'>Loan Amount </h3>
                                                <h2 className='mt-1 text-[14px] font-semibold'>{userData?.loan_amount}</h2>
                                            </div>
                                        
                                    </div>
                                </div>
                            </div>
                        </div> */}

                    </div>
                </div>
            </div>

            <ToastContainer
                position="bottom-left"
                autoClose={2000}
                hideProgressBar={true}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover />
        </>
    )
}

export default SchoolFormDetails;