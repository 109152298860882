/*eslint-disable*/
import { AxiosResponse } from "axios";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { AuthApis } from "../../apis/authApis";
import { useDispatch } from "react-redux";
import { login } from "../../reducer/loginSlice";
import { NavLink, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { MdDashboard } from "react-icons/md";
import { TbReceipt } from "react-icons/tb";
import { AiFillDollarCircle } from "react-icons/ai";
import { IoSchoolOutline } from "react-icons/io5";
import { RiSwapLine } from "react-icons/ri";
//  import { RiTokenSwapLine } from "react-icons/ri";
import { GrMoney } from "react-icons/gr";
import { TbMoneybag } from "react-icons/tb";
import { MdOutlineAccountBalanceWallet } from "react-icons/md";
import { CiShop } from "react-icons/ci";
import ProgressBar from "@ramonak/react-progress-bar";
import "../Screens/User/index.css"
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { CiWallet } from "react-icons/ci";

// import NotificationDropdown from "components/Dropdowns/NotificationDropdown.js";
// import UserDropdown from "components/Dropdowns/UserDropdown.js";

export default function Sidebar() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userLoginData = useSelector((state: any) => state.data.login.value);
  // && userLoginData.data?.user_data?.isVerified == 'true'

  React.useEffect(() => {
    userLoginData.token ?
      ''
      :
      removeAuth()
  }, []);

  // React.useEffect(() => {
  //   if(userLoginData.data?.user_data?.status == 'pending'){
  //     navigate("/kyc")
  //   }else if(userLoginData.data?.user_data?.status == 'inReview'){
  //     navigate("/kyc")
  //   }
  // }, []);

  const removeAuth = () => {
    dispatch(login([]))
    navigate("/sign-in");
  };

  // React.useEffect(() => {
  //   (userLoginData.token && userLoginData?.data?.role == 'user') ?
  //     ''
  //     :
  //     navigate('/sign-in');
  // }, []);

  const logOut = () => {
    AuthApis.logout('').then(
      (response: AxiosResponse<any>) => {
        if (response?.data) {
          dispatch(login([]))
          navigate('/sign-in');

        }
      }
    ).catch(function (error) {
      // handle error
      dispatch(login([]))
      navigate('/sign-in');
      console.log(error.response.data);
      // console.log("new error");
    }).finally(function () {
      // navigate('/sign-in');
      dispatch(login([]))
      navigate('/sign-in');
    }

    )

  };

  const [collapseShow, setCollapseShow] = React.useState("hidden");
  const [eduSilver, setEduSilver] = useState(false);
  const [eduGold, setEduGold] = useState(false);
  const [eduPremium, setEduPremium] = useState(false);
  
  // DrawerToggle
     const drawerToggle = (section: string) => {
      // props.open();
  
      section === "silver" ? setEduSilver(true) : setEduSilver(false);
      section === "gold" ? setEduGold((prev:any) => !prev) : setEduGold(false);
      section === "premium" ? setEduPremium((prev:any) => !prev) : setEduPremium(false);
  
    };
 
  return (
    <>
      <nav className="bg-[white] md:left-0 md:block md:fixed md:top-0 md:bottom-0 md:overflow-y-auto md:flex-row md:flex-nowrap md:overflow-hidden shadow-xl   flex flex-wrap items-center justify-between relative md:w-64 z-10 py-4 pl-6 pr-2">
        <div className="md:flex-col md:items-stretch md:min-h-full md:flex-nowrap px-0 flex flex-wrap items-center justify-between w-full mx-auto">
          {/* Toggler */}
          <button
            className="cursor-pointer text-black opacity-50 md:hidden px-3 py-1 text-xl leading-none bg-transparent rounded border border-solid border-transparent"
            type="button"
            onClick={() => setCollapseShow("bg-[#05401C] m-2 py-3 px-6")}
          >
            <b className="fas fa-bars"> ≡ </b>
          </button>
          {/* Brand */}
          {/* <Link
            className=" md:block text-center md:pb-2 text-blueGray-600 mr-0 inline-block whitespace-nowrap text-sm uppercase font-bold p-4 px-0"
            to="/"
          >
            <img src="/images/Dashboard/logo2.svg" alt="hero" />
          </Link> */}
          {/* User */}
          <ul className="md:hidden items-center flex flex-wrap list-none">
            <li className="inline-block relative">
              {/* <NotificationDropdown /> */}
            </li>
            <li className="inline-block relative">
              {/* <UserDropdown /> */}
            </li>
          </ul>
          {/* Collapse */}
          <div
            className={
              "bg-[white] md:flex md:flex-col md:items-stretch md:opacity-100 md:relative md:mt-4 md:shadow-none shadow absolute top-0 left-0 right-0 z-40 overflow-y-auto overflow-x-hidden h-auto items-center flex-1 rounded " +
              collapseShow
            }
          >
            {/* Collapse header w-[78px] */}
            <div className=" md:min-w-full md:hidden block   mb-4 border-b border-solid border-blueGray-200">
              <div className="flex flex-wrap">
                <div className="w-6/12">
                  <Link
                    className="md:block text-left md:pb-2 text-blueGray-600 mr-0 inline-block whitespace-nowrap text-sm uppercase font-bold p-4 px-0"
                    to="/"
                  >
                    <img src="/images/Dashbord/logo.png" alt="logo" />
                  </Link>
                </div>
                <div className="w-6/12 flex justify-end">
                  <button
                    type="button"
                    className="cursor-pointer text-black opacity-50 md:hidden px-3 py-1 text-xl leading-none bg-transparent rounded border border-solid border-transparent"
                    onClick={() => setCollapseShow("hidden")}
                  >
                    <b className="fas fa-times"> X </b>
                  </button>
                </div>
              </div>
            </div>


            <div className="lg:flex hidden justify-center">
              <NavLink to="/" >
                <img src="/images/Dashbord/logo.png" alt="logo" className="max-w-[150px]" />
              </NavLink>


            </div>






            
              <ul className="md:flex-col md:min-w-full flex flex-col list-none md:mt-8">
                <li className="items-center mb-2">
                  <NavLink to="/new-dashboard" className="flex mx-2 py-[10px] pl-[15px] gap-2 rounded-[37px] text-[12px] text-[#6E7093] hover:bg-[#00B07B] hover:text-[#ffffff]"
                    style={({ isActive }) =>
                      isActive ? { color: '#ffffff', background: "#00B07B" } : { color: '' }
                    }
                  >
                    <MdDashboard className="" size={24} />
                    <span className="mt-0.5"> Dashbord</span>
                  </NavLink>
                </li>

                <li className="items-center mb-2">
                  <NavLink to="/payments" className="flex mx-2 py-[10px] pl-[15px] gap-2 rounded-[37px] text-[12px] text-[#6E7093] hover:bg-[#00B07B] hover:text-[#ffffff]"
                    style={({ isActive }) =>
                      isActive ? { color: '#ffffff', background: "#00B07B" } : { color: '' }
                    }
                  >
                    <IoSchoolOutline className="" size={24} />
                    <span className="mt-0.5"> Pay Tuition Fees</span>

                  </NavLink>
                </li>

                <li className="items-center mb-2">
                  <div
                   onClick={() => drawerToggle("silver")}
                    // style={({ isActive }) =>
                    //   isActive ? { color: '#ffffff', background: "#00B07B" } : { color: '' }
                    // }
                    className={`${
                      eduSilver ? "text-[#ffffff] bg-[#00B07B]" : ""
                    } flex items-center mx-2 py-[10px] px-[15px] justify-between rounded-[37px] text-[12px] text-[#6E7093] hover:bg-[#00B07B] hover:text-[#ffffff]`}
                    // className="flex items-center mx-2 py-[10px] px-[15px] justify-between rounded-[37px] text-[12px] text-[#6E7093] hover:bg-[#00B07B] hover:text-[#ffffff]"
                    >
                  <h2
             className="flex gap-2 "
                 >
   <TbMoneybag className="" size={24} />
                    <span className="mt-0.5"> Loans</span>
                  </h2>
                  <div>{!eduSilver ? <IoIosArrowDown /> : <IoIosArrowUp className="w-5 h-5" />}</div>

                  </div>

                  <div className={`${!eduSilver && "hidden"} grid ml-8`} >
                  <NavLink to="/loans" className=" flex mx-2 pt-[10px] pb-[10px] pl-[15px] gap-2 rounded-[37px] text-[12px] text-[#6E7093] "
                    style={({ isActive }) =>
                      isActive ? { color: '#00B07B', background: "" } : { color: '#6E7093' }
                    }
                  >
                    {/* <TbMoneybag className="" size={24} /> */}
                    <span className="mt-0.5 text-center">EduSilver Plan</span>

                </NavLink>
                {/* <NavLink to="/loans-gold-plan" className=" flex mx-2 pb-[10px] pl-[15px] gap-2 rounded-[37px] text-[12px] text-[#6E7093]"
                    style={({ isActive }) =>
                      isActive ? { color: '#00B07B', background: "" } : { color: '#6E7093' }
                    }
                  >
                    <span className="mt-0.5 text-center">EduGold Plan</span>

                </NavLink> */}
                   <NavLink to="/loans-gold-plan" className=" flex mx-2 pb-[10px] pl-[15px] gap-2 rounded-[37px] text-[12px] text-[#6E7093] "
                    style={({ isActive }) =>
                      isActive ? { color: '#00B07B', background: "" } : { color: '#6E7093' }
                    }
                  >
                    {/* <TbMoneybag className="" size={24} /> */}
                    <span className="mt-0.5 text-center">EduGold Plan</span>

                </NavLink>
                <NavLink to="/loans-premium-plan" className=" flex mx-2 pb-[10px] pl-[15px] gap-2 rounded-[37px] text-[12px] text-[#6E7093] "
                    style={({ isActive }) =>
                      isActive ? { color: '#00B07B', background: "" } : { color: '#6E7093' }
                    }
                  >
                    {/* <TbMoneybag className="" size={24} /> */}
                    <span className="mt-0.5 text-center">EduPremium Plan</span>

                </NavLink>

             
                  </div>
                  {/* <NavLink to="/loans" className="flex mx-2 py-[10px] pl-[15px] gap-2 rounded-[37px] text-[12px] text-[#6E7093] hover:bg-[#00B07B] hover:text-[#ffffff]"
                    style={({ isActive }) =>
                      isActive ? { color: '#ffffff', background: "#00B07B" } : { color: '' }
                    }
                  >
                    <TbMoneybag className="" size={24} />
                    <span className="mt-0.5"> Loans</span>

                </NavLink> */}
              </li>
              <li className="items-center mb-2">
                <NavLink to="/topup" className="flex mx-2 py-[10px] pl-[15px] gap-2 rounded-[37px] text-[12px] text-[#6E7093] hover:bg-[#00B07B] hover:text-[#ffffff]"
                  style={({ isActive }) =>
                    isActive ? { color: '#ffffff', background: "#00B07B" } : { color: '' }
                  }
                >
                  <RiSwapLine className="" size={24} />
                  <span className="mt-0.5">Top Up</span>

                </NavLink>
              </li>
              <li className="items-center mb-2">
                <NavLink to="/savings" className="flex mx-2 py-[10px] pl-[15px] gap-2 rounded-[37px] text-[12px] text-[#6E7093] hover:bg-[#00B07B] hover:text-[#ffffff]"
                  style={({ isActive }) =>
                    isActive ? { color: '#ffffff', background: "#00B07B" } : { color: '' }
                  }
                >
                  <CiWallet className="" size={24} />
                  <span className="mt-0.5">Savings</span>

                </NavLink>
              </li>
              {/* <li className="items-center ">
                <NavLink to="/proof-of-funds" className="flex mx-2 py-[10px] pl-[15px] gap-2 rounded-[37px] text-[12px] text-[#6E7093] hover:bg-[#00B07B] hover:text-[#ffffff]"
                  style={({ isActive }) =>
                    isActive ? { color: '#ffffff', background: "#00B07B" } : { color: '' }
                  }
                >
                  <AiFillDollarCircle className="" size={24} />
                  <span className="mt-0.5"> Proof of Funds</span>

                  </NavLink>
                </li>
                {/* <li className="items-center ">
                   <NavLink to="/proof-of-funds" className="flex mx-2 py-[10px] pl-[15px] gap-2 rounded-[37px] text-[12px] text-[#6E7093] hover:bg-[#00B07B] hover:text-[#ffffff]"
                     style={({ isActive }) =>
                       isActive ? { color: '#ffffff', background: "#00B07B" } : { color: '' }
                     }
                   >
                     <AiFillDollarCircle className="" size={24} />
                     <span className="mt-0.5"> Proof of Funds</span>
   
                   </NavLink>
                 </li> */}
                <hr className=" w-full text-[#D9D9D9]" />



                <hr className="mt-10 w-full text-[#D9D9D9]" />
                <li className="items-center mb-2 mt-2">
                  <NavLink to="/afripay-shop" className="flex mx-2 py-[10px] pl-[15px] gap-2 rounded-[37px] text-[12px] text-[#6E7093] hover:bg-[#00B07B] hover:text-[#ffffff]"
                    style={({ isActive }) =>
                      isActive ? { color: '#ffffff', background: "#00B07B" } : { color: '' }
                    }
                  >
                    <CiShop className="" size={24} />
                    <span className="mt-0.5"> Afripay Shop</span>

                  </NavLink>
                </li>

                <li className="items-center">
                  <NavLink to="/transactions" className="flex mx-2 py-[10px] pl-[15px] gap-2 rounded-[37px] text-[12px] text-[#6E7093] hover:bg-[#00B07B] hover:text-[#ffffff]"
                    style={({ isActive }) =>
                      isActive ? { color: '#ffffff', background: "#00B07B" } : { color: '' }
                    }
                  >
                    <TbReceipt className="" size={24} />
                    <span className="mt-0.5"> Transactions</span>

                  </NavLink>
                </li>
                <hr className=" w-full text-[#D9D9D9]" />
                <hr className="mt-20 w-full text-[#D9D9D9]" />

                <div
                  onClick={() => navigate("/contact-us")}
                  className="mt-3 hidden lg:block">

                  <img src="/images/Dashbord/contact-card.svg" alt="logo" className="" />
                </div>


                <li className="flex justify-start gap-2 pt-4 px-5 pb-3" style={{ cursor: 'pointer' }} onClick={logOut}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24"><path fill="#db1e36" d="M12 4a4 4 0 0 1 4 4a4 4 0 0 1-4 4a4 4 0 0 1-4-4a4 4 0 0 1 4-4m0 10c4.42 0 8 1.79 8 4v2H4v-2c0-2.21 3.58-4 8-4" /></svg>
                  <span className=" text-[#DB1E36] text-[14px] font-medium mt-2">Log out</span>
                </li>
              </ul>
             

          </div>
        </div>
      </nav>
    </>
  );
}
