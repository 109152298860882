import React from 'react'
import Sidebar from "../../Sidebar/AdminSidebar";
import UserNavbar from "../../Navbars/AdminNavbar";
import { DashboardApi } from '../../../apis/DashboardApi';
import { AxiosResponse } from 'axios';
function SchoolList() {


    const [userLists, setUserList] = React.useState<any>([]);
    const [searchText, setSearchText] = React.useState('');
    const [csvData, setCsvData] = React.useState<any>([]);

    React.useEffect(() => {
        const query: any = {
            search: searchText,
        };
        DashboardApi.getAllSchool('', query).then(
            (response: AxiosResponse<any>) => {
                if (response?.data) {
                    setUserList(response?.data?.data)
                    console?.log(response?.data?.data)
                }
            }
        ).catch(function (error) {
            // handle error

        })

    }, []);

   


    const paginator = React.useCallback(
        (value: any) => {
            //   setLoader(true);
            let value2 = '';
            if (value !== null) {
                value2 = value;
            } else {
                value2 = ''
            }
            const query: any = {
                search: searchText,
            };

            DashboardApi.getAllSchool(value2, query).then(
                (response: AxiosResponse<any>) => {
                    if (response?.data) {
                        setUserList(response?.data?.data)
                    }
                }
            ).catch(function (error) {
                console.log(error.response.data);
            })

        }, [userLists, searchText]);

    return (
        <>
            <Sidebar />
            <div className="relative md:ml-64">
                <UserNavbar title="Schools" />
                <div className='pt-5 lg:px-10 px-6 bg-[#F8F8F8] min-h-screen'>
                    <div className='p-4 flex justify-end'>
                        <div>
                            <button type='button' className='bg-[#00B07B] text-white rounded-[5px] py-[12.5px] px-[30px]'>+ Add School</button>
                        </div>
                    </div>
                    
                    <div className='p-4'>
                        <div className="relative overflow-x-auto sm:rounded-lg">
                            <table className="w-full text-sm text-left rtl:text-right text-gray-500 ">
                                <thead className="text-xs text-gray-700  border-none">
                                    <tr>
                                        <th scope="col" className="px-6 py-3">
                                            Name of School
                                        </th>
                                        <th scope="col" className="px-6 py-3">
                                            Email
                                        </th>
                                        <th scope="col" className="px-6 py-3">
                                            Status
                                        </th>
                                        <th scope="col" className="px-6 py-3">
                                            Date Added
                                        </th>


                                    </tr>
                                </thead>
                                <tbody>
                                    <tr className="bg-white hover:bg-gray-50 hover:shadow-md rounded-lg mb-3 border-none">
                                        <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap ">
                                            University of Vassa
                                        </th>
                                        <td className="px-6 py-4">
                                            Schoolwithme@vassa.com
                                        </td>
                                        <td className="px-6 py-4">
                                            <div className='bg-[#EDF6F5] text-[#3A974C] text-[12px] rounded-[22px] items-center text-center py-2 px-3'>Active</div>
                                        </td>
                                        <td className="px-6 py-4">
                                            12, Dec 2023
                                        </td>


                                    </tr>
                                    <tr className="bg-white hover:bg-gray-50 hover:shadow-md rounded-lg mb-3 border-none">
                                        <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap ">
                                            University of Vassa
                                        </th>
                                        <td className="px-6 py-4">
                                            Schoolwithme@vassa.com
                                        </td>
                                        <td className="px-6 py-4">
                                            <div className='bg-[#EDF6F5] text-[#3A974C] text-[12px] rounded-[22px] items-center text-center py-2 px-3'>Active</div>
                                        </td>
                                        <td className="px-6 py-4">
                                            12, Dec 2023
                                        </td>

                                    </tr>

                                </tbody>
                            </table>
                        </div>

                    </div>
                </div>
            </div>
        </>
    )
}

export default SchoolList