import React, { useState } from "react";
import Sidebar from "../../Sidebar/Sidebar";
import UserNavbar from "../../Navbars/UserNavbar";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import ComingSoon from "./ComingSoon";
import { useSelector } from "react-redux";
import { DashboardApi } from "../../../apis/DashboardApi";
import { ToastContainer, toast } from "react-toastify";
import { AxiosResponse } from "axios";
import { NavLink } from "react-router-dom";

function UserLoans() {
  const userLoginData = useSelector((state: any) => state.data.login.value);
  const userDetails = userLoginData?.data?.user_data;
  const [loanStatus, setLoanStatus] = React.useState<any>("");

  React.useEffect(() => {
    DashboardApi.verifyLoan()
      .then((response: AxiosResponse<any>) => {
        if (response?.data) {
          // setTransactionList(response?.data?.data)
          setLoanStatus(response?.data?.data);
          //  console?.log(response?.data?.data)
        }
      })
      .catch(function (error) {
        // handle error
      });
  }, []);

  // console?.log(loanStatus)

  let [showScreen, setShowScreen] = React.useState<any>(1);
  const [open, setOpen] = useState(false);
  const onOpenModal = () => setOpen(true);
  const onCloseModal = () => setOpen(false);

  const [successOpen, setSuccessOpen] = useState(false);
  const onOpenSuccessModal = () => setSuccessOpen(true);
  const onCloseSuccessModal = () => setSuccessOpen(false);

  const [loanData, setLoandata] = React.useState({
    first_name: "",
    last_name: "",
    phone_number: "",
    email: "",
    program_of_application: "",
    where_are_you_studing: "",
    choose_your_sch: "",
    field_of_study: "",
    stage_of_admission: "",
    program_start_date: "",
    program_end_date: "",
    do_you_have_a_sponsor: "",
    sponsor_amount: "",
    loan_amount: "",
  });
  // console.log(loanData)
  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    setLoandata({ ...loanData, [name]: value });
  };

  const handleSubmit = (e: React.ChangeEvent<HTMLFormElement>) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("email", userDetails?.email);
    formData.append("f_name", userDetails?.first_name);
    formData.append("l_name", userDetails?.last_name);
    formData.append("phone_number", userDetails?.phone_number);
    formData.append("program_of_application", loanData?.program_of_application);
    formData.append("where_are_you_studing", loanData?.where_are_you_studing);
    formData.append("choose_your_sch", loanData?.choose_your_sch);
    formData.append("field_of_study", loanData?.field_of_study);
    formData.append("stage_of_admission", loanData?.stage_of_admission);
    formData.append("program_start_date", loanData?.program_start_date);
    formData.append("program_end_date", loanData?.program_end_date);
    formData.append("do_you_have_a_sponsor", loanData?.do_you_have_a_sponsor);
    formData.append("sponsor_amount", loanData?.sponsor_amount);
    formData.append("loan_amount", loanData?.loan_amount);

    DashboardApi.applyStudentLoan(formData)
      .then((response: any) => {
        if (response?.data) {
          onCloseModal();
          onOpenSuccessModal();
          // toast.success('Information under review, we will let you know once we approve you.');
          // console?.log(response?.data?.data)
          // onClick={onOpenSuccessModal}
        }
      })
      .catch((err) => {
        if (err?.response?.status == 422) {
          toast.error("Form not rightly filled. Kindly Cross check.");
        } else {
          toast.error("Some error occured.Please try again");
        }
      })
      .finally(() => {});
  };

  return (
    <>
      <Sidebar />
      <div className="relative md:ml-64">
        <UserNavbar />

        <div className="lg:mt-3 w-full lg:px-10 pb-6 pt-2 px-6 bg-[#F8F8F8] min-h-screen">
          <div className="lg:mt-3 pt-2 bg-[#E1F9EC] rounded-[10px]">
            <div className="flex justify-between">
              <img src="/images/bro.png" className="lg:block hidden" alt="hero" />

              <div className="flex flex-col text-center">
                <h4 className="text-[#00B07B] text-[25px] md:text-[32px] font-[700] max-w-[561px] ">
               
                Check if your Institution Qualifies for our Loan Programs at no Cost.
                </h4>
                <h6 className="text-[16px] font-[500] text-center text-[#5A5A5A]">
                 
                Please ensure that you verify your school, country qualification for admission.

                </h6>
              </div>
              <img src="/images/bro1.png" className="lg:block hidden" alt="hero" />
            </div>
          </div>

          <div className="md:flex justify-center w-full pt-10">
            <div className="flex justify-center bg-white py-10 px-7">
              <div className=" text-center ">
                <h2 className="text-[#00B07B] text-[24px] font-[700] ">
                  EDUSILVER
                </h2>
                <h5 className="text-[#65656C] text-[14px] font-[400]">
                  For student with admission letter
                </h5>
                <div>
                  <h5 className="text-[#6F6C90] pt-5 text-[20px] font-[400] max-w-[394px]">
                    In order to be eligible for a loan, students must possess an
                    admission letter from the school we have a partnership with.
                  </h5>
                </div>
                <h4 className="text-[#170F49] text-[32px] font-bold pt-1">
                  Free
                </h4>
                <div className="mt-5">
                  <NavLink to="/loans-silver-eligibility" className="">
                    <button
                      // type="submit"
                      className="py-[10px] px-[70px] bg-[#00B07B] text-[16px] font-normal text-white rounded-[30px] border border-[#ff] "
                    >
                      Get Started
                    </button>
                  </NavLink>
                </div>
              </div>
            </div>

            <div className="bg-[#00B07B]/[5%] px-4 py-[70px] flex justify-center text-center items-center">
              <div className="">
                <div className="flex justify-center text-center">
                  <h5 className="text-[#170F49] text-[18px] font-semibold">
                   Status
                  </h5>
                </div>
                <div className="flex items-center pt-3 gap-2">
                  {/* <img src="/images/check.png" alt="hero" /> */}
                  <h5 className="text-[#170F49] text-[18px]">
                  ● Check if your school qualifies
                  </h5>
                </div>
                <div className="flex items-center pt-3 gap-2">
                  {/* <img src="/images/check.png" alt="hero" /> */}
                  <h5 className="text-[#170F49] text-[18px]">
                  ● Check if your country qualifies
                  </h5>
                </div>
               
              </div>
            </div>
          </div>
          {/* <ComingSoon/> */}

          {/* {
            loanStatus
            ?
            <div className='bg-white rounded-[10px] lg:px-[50px] lg:py-[30px] px-[20px] pt-[20px] pb-[40px] mt-[10px]'>
            <div className=''>
              <h1 className='lg:text-[30px] text-[20px] font-bold text-center'> Application <span className='text-[#1DB459]'>Submitted</span></h1>
              <p className='text-center mt-2 text-[#5F5D5D] lg:text-[16px] text-[13px]'>Sorry, you've already applied for a loan. You can't apply again.</p>
            </div>
            
            </div>


            :


            <div className='bg-white rounded-[10px] lg:px-[50px] lg:py-[30px] px-[20px] pt-[20px] pb-[40px] mt-[16px]'>
            <div className=''>
              <h1 className='lg:text-[30px] text-[20px] font-bold text-center'> Application <span className='text-[#1DB459]'>Form</span></h1>
              <p className='text-center mt-2 text-[#5F5D5D] lg:text-[16px] text-[13px]'>If you have admission to study in US, Canada or any of the eligible countries, please fill out the International Student Loan Qualification Form.</p>
            </div>
            <div className=' pt-8'>
              <p className='text-center mt-2 text-[#5F5D5D] lg:text-[16px] text-[13px]'>Before proceeding, Please note:</p>
              <p className='text-center mt-2 text-[#5F5D5D] lg:text-[16px] text-[13px]'>The next page is for you to check your eligibility for the loan. Please carefully review the criteria ans ensure you meet the requirements before proceeding.</p>
              <div className='flex justify-center mt-5'>
                <button
                  onClick={onOpenModal}
                  className='bg-[#209D5A] text-white rounded-[5px] px-6 py-3'>Apply for International Student Loan</button>
              </div>
            </div>
            </div>
          } */}

          <Modal open={open} onClose={onCloseModal} center>
            <div className="md:max-w-[900px]  body-font font-poppins">
              <div className="flex flex-wrap mt-4">
                <form onSubmit={handleSubmit}>
                  <div className={showScreen === 1 ? "block " : "hidden"}>
                    <h4 className="text-[18px] text-[#209D5A]">Step 1 of 3</h4>
                    <h3 className="text-[20px] font-semibold">
                      Student Loan Application
                    </h3>
                    <p className="mt-1 text-[#5F5D5D] lg:text-[15px] text-[13px]">
                      Only apply if you have a valid university admission offer
                    </p>

                    <div>
                      <div className="relative w-full mt-4">
                        <label className="block mb-2 text-[12px] font-semibold text-[#414143]">
                          First Name*
                        </label>
                        <input
                          type="text"
                          className="bg-white border border-[#D9D9D9] text-[#333333] text-sm rounded-[8px] block w-full p-3 placeholder:text-[#414143] placeholder:text-[12px]  ease-linear transition-all duration-150"
                          placeholder="Enter First Name"
                          name="first_name"
                          defaultValue={userDetails?.first_name}
                          onChange={handleChange}
                          disabled
                        />
                      </div>
                      <div className="relative w-full mt-4">
                        <label className="block mb-2 text-[12px] font-semibold text-[#414143]">
                          Last Name*
                        </label>
                        <input
                          type="text"
                          className="bg-white border border-[#D9D9D9] text-[#333333] text-sm rounded-[8px] block w-full p-3 placeholder:text-[#414143] placeholder:text-[12px]  ease-linear transition-all duration-150"
                          placeholder="Enter Last Name"
                          name="last_name"
                          defaultValue={userDetails?.last_name}
                          onChange={handleChange}
                          disabled
                        />
                      </div>
                      <div className="relative w-full mt-4">
                        <label className="block mb-2 text-[12px] font-semibold text-[#414143]">
                          Phone Number*
                        </label>
                        <input
                          type="text"
                          className="bg-white border border-[#D9D9D9] text-[#333333] text-sm rounded-[8px] block w-full p-3 placeholder:text-[#414143] placeholder:text-[12px]  ease-linear transition-all duration-150"
                          placeholder="Phone Number"
                          name="phone_number"
                          defaultValue={userDetails?.phone_number}
                          onChange={handleChange}
                          disabled
                        />
                      </div>
                      <div className="relative w-full mb-5 mt-4">
                        <label className="block mb-2 text-[12px] font-semibold text-[#414143]">
                          Email*
                        </label>
                        <input
                          type="email"
                          className="bg-white border border-[#D9D9D9] text-[#333333] text-sm rounded-[8px] block w-full p-3 placeholder:text-[#414143] placeholder:text-[12px]  ease-linear transition-all duration-150"
                          placeholder=""
                          name="email"
                          defaultValue={userDetails?.email}
                          onChange={handleChange}
                          disabled
                        />
                      </div>
                    </div>
                    <div className="flex justify-center mx-auto w-full">
                      <button
                        type="button"
                        className=" text-white bg-[#209D5A] rounded-[5px]  font-medium text-sm md:px-12 px-8 md:py-3 py-2.5 mr-2 mb-2"
                        onClick={() => setShowScreen(2)}
                      >
                        Continue
                      </button>
                    </div>
                  </div>

                  <div className={showScreen === 2 ? "block " : "hidden"}>
                    <h4 className="text-[18px] text-[#209D5A]">Step 2 of 3</h4>
                    <h3 className="text-[20px] font-semibold">
                      School Details
                    </h3>
                    <p className="mt-1 text-[#5F5D5D] lg:text-[15px] text-[13px]">
                      Enter information about your program/education
                    </p>

                    <div>
                      <div className="relative w-full mt-4">
                        <label className="block mb-2 text-[12px] font-semibold text-[#414143]">
                          Program of Application?*
                        </label>
                        <select
                          id="program_of_application"
                          name="program_of_application"
                          onChange={handleChange}
                          className=" border border-[#D9D9D9] text-[#333333] text-sm rounded-[8px]  block w-full py-3 px-2.5"
                        >
                          <option selected>Program of Application</option>
                          <option value="masters">
                            Postgraduate(Masters, MBA)
                          </option>
                          <option value="undergraduate">UnderGraduate</option>
                        </select>
                      </div>
                      <div className="relative w-full mt-4">
                        <label className="block mb-2 text-[12px] font-semibold text-[#414143]">
                          Where are you studying or going to study*
                        </label>
                        <select
                          id="where_are_you_studing"
                          name="where_are_you_studing"
                          value={loanData?.where_are_you_studing}
                          onChange={handleChange}
                          className=" border border-[#D9D9D9] text-[#333333] text-sm rounded-[8px]  block w-full py-3 px-2.5"
                        >
                          <option selected>Country of study</option>
                          <option value="usa">USA</option>
                          <option value="canada">Canada</option>
                        </select>
                      </div>
                      <div
                        className={
                          loanData?.where_are_you_studing === "usa"
                            ? " block"
                            : " hidden"
                        }
                      >
                        <div className="relative w-full mt-4">
                          <label className="block mb-2 text-[12px] font-semibold text-[#414143]">
                            Choose your school in USA*
                          </label>
                          <select
                            id="choose_your_sch"
                            name="choose_your_sch"
                            value={loanData?.choose_your_sch}
                            onChange={handleChange}
                            className="border border-[#D9D9D9] text-[#333333] text-sm rounded-[8px]  block w-full py-3 px-2.5"
                          >
                            <option selected>Supported schools in US</option>
                            <option value="University of California - Merced">
                              University of California - Merced
                            </option>
                            <option value="Touro University California">
                              Touro University California
                            </option>
                            <option value="Denver College of Nursing">
                              Denver College of Nursing
                            </option>
                            <option value="The University of Texas MD Anderson Cancer Center">
                              The University of Texas MD Anderson Cancer Center
                            </option>
                            <option value="Thomas Aquinas College">
                              Thomas Aquinas College
                            </option>
                            <option value="Hofstra University">
                              Hofstra University
                            </option>
                            <option value="SUNY at Albany">
                              SUNY at Albany
                            </option>
                            <option value="Thunderbird School of Global Management">
                              Thunderbird School of Global Management
                            </option>
                            <option value="University of Arizona">
                              University of Arizona
                            </option>
                            <option value="Northeastern University">
                              Northeastern University
                            </option>
                            <option value="Columbia University">
                              Columbia University
                            </option>
                            <option value="Florida International University">
                              Florida International University
                            </option>
                            <option value="Florida State University - FSU">
                              Florida State University - FSU
                            </option>
                            <option value="George Mason University">
                              George Mason University
                            </option>
                            <option value="SUNY at Binghamton">
                              SUNY at Binghamton
                            </option>
                            <option value="Ohio State University - OSU">
                              Ohio State University - OSU
                            </option>
                            <option value="Hult International Business School">
                              Hult International Business School
                            </option>
                            <option value="George Washington University - Columbian School of Arts and Sciences">
                              George Washington University - Columbian School of
                              Arts and Sciences
                            </option>
                            <option value="George Washington University - Law School">
                              George Washington University - Law School
                            </option>
                            <option value="Arizona State University- Polytechnic">
                              Arizona State University- Polytechnic
                            </option>
                            <option value="Arizona State University- Downtown Phoenix">
                              Arizona State University- Downtown Phoenix
                            </option>
                            <option value="Arizona State University- Skysong">
                              Arizona State University- Skysong
                            </option>
                            <option value="Harvard University">
                              Harvard University
                            </option>
                            <option value="Johns Hopkins University">
                              Johns Hopkins University
                            </option>
                            <option value="Georgetown University">
                              Georgetown University
                            </option>
                            <option value="Arizona State University">
                              Arizona State University
                            </option>
                            <option value="Eastern Virginia Medical School">
                              Eastern Virginia Medical School
                            </option>
                            <option value="Tufts University">
                              Tufts University
                            </option>
                            <option value="Columbia University Engineering">
                              Columbia University - Engineering(Graduate)
                            </option>
                            <option value="Brown University - Graduate School">
                              Brown University - Graduate School
                            </option>
                            <option value="Brown University - School of Medicine">
                              Brown University - School of Medicine
                            </option>
                            <option value="Arizona State University- West">
                              Arizona State University- West
                            </option>
                            <option value="Robert Morris University">
                              Robert Morris University
                            </option>
                            <option value="Lafayette College">
                              Lafayette College
                            </option>
                            <option value="La Salle University">
                              La Salle University
                            </option>
                            <option value="Lehigh University">
                              Lehigh University
                            </option>
                            <option value="Swarthmore College">
                              Swarthmore College
                            </option>
                            <option value="Saint Francis University">
                              Saint Francis University
                            </option>
                            <option value="Dickinson College">
                              Dickinson College
                            </option>
                            <option value="Temple University">
                              Temple University
                            </option>
                            <option value="University of Pennsylvania">
                              University of Pennsylvania
                            </option>
                            <option value="University of Pittsburgh - Pittsburgh">
                              University of Pittsburgh - Pittsburgh
                            </option>
                            <option value="University of Pittsburgh - Titusville">
                              University of Pittsburgh - Titusville
                            </option>
                            <option value="University of Pittsburgh - Johnstown">
                              University of Pittsburgh - Johnstown
                            </option>
                            <option value="University of Oklahoma">
                              University of Oklahoma
                            </option>
                            <option value="University of Tulsa">
                              University of Tulsa
                            </option>
                            <option value="Franklin and Marshall College">
                              Franklin and Marshall College
                            </option>
                            <option value="University of Scranton">
                              University of Scranton
                            </option>
                            <option value="Villanova University">
                              Villanova University
                            </option>
                            <option value="Gettysburg College">
                              Gettysburg College
                            </option>
                            <option value="Drexel University">
                              Drexel University
                            </option>
                            <option value="Duquesne University">
                              Duquesne University
                            </option>
                            <option value="Brown University">
                              Brown University
                            </option>
                            <option value="Arcadia University">
                              Arcadia University
                            </option>
                            <option value="Oregon State University">
                              Oregon State University
                            </option>
                            <option value="Widener University - Main Campus">
                              Widener University - Main Campus
                            </option>
                            <option value="Bryn Mawr College">
                              Bryn Mawr College
                            </option>
                            <option value="Bucknell University">
                              Bucknell University
                            </option>
                            <option value="Pacific University">
                              Pacific University
                            </option>
                            {/* <option value=""></option>
                            <option value=""></option>
                            <option value=""></option>
                            <option value=""></option>
                            <option value=""></option>
                            <option value=""></option>
                            <option value=""></option>
                            <option value=""></option>
                            <option value=""></option>
                            <option value=""></option>
                            <option value=""></option>
                            <option value=""></option>
                            <option value=""></option> */}
                          </select>
                        </div>
                      </div>
                      <div
                        className={
                          loanData?.where_are_you_studing === "canada"
                            ? " block"
                            : " hidden"
                        }
                      >
                        <div className="relative w-full mt-4">
                          <label className="block mb-2 text-[12px] font-semibold text-[#414143]">
                            Choose your school in Canada*
                          </label>
                          <select
                            id="choose_your_sch"
                            name="choose_your_sch"
                            value={loanData?.choose_your_sch}
                            onChange={handleChange}
                            className="border border-[#D9D9D9] text-[#333333] text-sm rounded-[8px]  block w-full py-3 px-2.5"
                          >
                            <option selected>
                              Supported schools in Canada
                            </option>
                            <option value="New York Institute of Technology(NYIT)">
                              New York Institute of Technology(NYIT)
                            </option>
                            <option value="New York Institute of Technology - Vancouver campus">
                              New York Institute of Technology - Vancouver
                              campus
                            </option>
                            <option value="Queens University at Kingston">
                              Queens University at Kingston
                            </option>
                            <option value="University of Toronto">
                              University of Toronto
                            </option>
                            <option value="York University">
                              York University
                            </option>
                            <option value="Western University - University of Western Ontario">
                              Western University - University of Western Ontario
                            </option>
                            <option value="Royal Roads University">
                              Royal Roads University
                            </option>
                            <option value="Northeastern University - Canada">
                              Northeastern University - Canada
                            </option>
                            <option value="McMaster University">
                              McMaster University
                            </option>
                            <option value="Toronto Metropolitan University">
                              Toronto Metropolitan University
                            </option>
                            <option value="University of Toronto">
                              University of Toronto
                            </option>
                            <option value="Wilfrid Laurier University">
                              Wilfrid Laurier University
                            </option>
                            <option value="University of Calgary">
                              University of Calgary
                            </option>
                            <option value="University of Lethbridge">
                              University of Lethbridge
                            </option>
                            <option value="University of Northern British Columbia">
                              University of Northern British Columbia
                            </option>
                            <option value="Carleton University">
                              Carleton University
                            </option>
                            <option value="University of Victoria">
                              University of Victoria
                            </option>
                            <option value="University of Alberta">
                              University of Alberta
                            </option>
                            <option value="University of Guelph">
                              University of Guelph
                            </option>
                            <option value="Brock University">
                              Brock University
                            </option>
                            <option value="University of Windsor">
                              University of Windsor
                            </option>
                            <option value="University of Ottawa">
                              University of Ottawa
                            </option>
                            <option value="Trent University">
                              Trent University
                            </option>
                            <option value="University of Manitoba">
                              University of Manitoba
                            </option>
                            <option value="Lakehead University">
                              Lakehead University
                            </option>
                            <option value="University of Waterloo">
                              University of Waterloo
                            </option>
                            <option value="Ontario College of Management and Technology (OCMT)">
                              Ontario College of Management and Technology
                              (OCMT)
                            </option>
                            <option value="Vancouver Island University">
                              Vancouver Island University
                            </option>
                            <option value="Western University - University of Western Ontario">
                              Western University - University of Western Ontario
                            </option>
                            <option value="Osgoode Hall Law School">
                              Osgoode Hall Law School
                            </option>
                            <option value="Brescia University College">
                              Brescia University College
                            </option>
                            <option value="Ted Rogers School of Management">
                              Ted Rogers School of Management
                            </option>
                            <option value="Alberta School of Business">
                              Alberta School of Business
                            </option>
                            <option value="University of Manitoba">
                              University of Manitoba
                            </option>
                            <option value="Smith School of Business">
                              Smith School of Business
                            </option>
                            <option value="Rotman School of Management">
                              Rotman School of Management
                            </option>
                            <option value="Schulich School of Business">
                              Schulich School of Business
                            </option>
                            <option value="Ivey Business School">
                              Ivey Business School
                            </option>
                            <option value="DeGroote School of Business">
                              DeGroote School of Business
                            </option>
                          </select>
                        </div>
                      </div>
                      <div className="relative w-full mt-4">
                        <label className="block mb-2 text-[12px] font-semibold text-[#414143]">
                          Field of Study*
                        </label>
                        <select
                          id="field_of_study"
                          name="field_of_study"
                          onChange={handleChange}
                          className=" border border-[#D9D9D9] text-[#333333] text-sm rounded-[8px]  block w-full py-3 px-2.5"
                        >
                          <option selected>Choose your study field</option>
                          <option value="engineering">Engineering</option>
                          <option value="health professions">
                            Health Professions
                          </option>
                          <option value="mathematics/computer sci">
                            Mathematics/Computer Science
                          </option>
                          <option value="technology">Technology</option>
                        </select>
                      </div>
                      <div className="relative w-full mb-5 mt-4">
                        <label className="block mb-2 text-[12px] font-semibold text-[#414143]">
                          What stage of Admission are you?*
                        </label>
                        <select
                          id="stage_of_admission"
                          name="stage_of_admission"
                          onChange={handleChange}
                          required
                          className=" border border-[#D9D9D9] text-[#333333] text-sm rounded-[8px]  block w-full py-3 px-2.5"
                        >
                          <option selected>
                            How far has your admission gone?
                          </option>
                          <option value="thinking about studying">
                            I'm thinking about studying
                          </option>
                          <option value="applied to a school but waiting for acceptance">
                            I've Applied to a school but waiting acceptance
                          </option>
                          <option value="been accepted to a school but havent enrolled">
                            I've been accepted to a school but haven't enrolled
                          </option>
                          <option value="currently enrolled at a school">
                            I've currently enrolled at a school
                          </option>
                        </select>
                      </div>
                      <div className="flex">
                        <div className="w-full lg:w-6/12 md:pr-4">
                          <div className="relative w-full mb-5 mt-4">
                            <label className="block mb-2 text-[12px] font-semibold text-[#414143]">
                              Program start date*
                            </label>
                            <input
                              type="date"
                              className="bg-white border border-[#D9D9D9] text-[#333333] text-sm rounded-[8px] block w-full p-3 placeholder:text-[#414143] placeholder:text-[12px]  ease-linear transition-all duration-150"
                              placeholder=""
                              name="program_start_date"
                              onChange={handleChange}
                              required
                            />
                          </div>
                        </div>
                        <div className="w-full lg:w-6/12">
                          <div className="relative w-full mb-5 mt-4">
                            <label className="block mb-2 text-[12px] font-semibold text-[#414143]">
                              Program end date*
                            </label>
                            <input
                              type="date"
                              className="bg-white border border-[#D9D9D9] text-[#333333] text-sm rounded-[8px] block w-full p-3 placeholder:text-[#414143] placeholder:text-[12px]  ease-linear transition-all duration-150"
                              placeholder=""
                              name="program_end_date"
                              onChange={handleChange}
                              required
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="flex justify-center mx-auto w-full">
                      <button
                        type="button"
                        className=" text-white bg-[#6E7093] rounded-[5px]  font-medium text-sm md:px-12 px-8 md:py-3 py-2.5 mr-2 mb-2"
                        onClick={() => setShowScreen(1)}
                      >
                        Back
                      </button>
                      <button
                        type="button"
                        className=" text-white bg-[#209D5A] rounded-[5px]  font-medium text-sm md:px-12 px-8 md:py-3 py-2.5 mr-2 mb-2"
                        onClick={() => setShowScreen(3)}
                      >
                        Continue
                      </button>
                    </div>
                  </div>

                  <div className={showScreen === 3 ? "block " : "hidden"}>
                    <h4 className="text-[18px] text-[#209D5A]">Step 3 of 3</h4>
                    <h3 className="text-[20px] font-semibold">
                      Sponsorship / Amount
                    </h3>
                    <p className="mt-1 text-[#5F5D5D] lg:text-[15px] text-[13px]">
                      Enter information about your program/education
                    </p>

                    <div>
                      <div className="relative w-full mt-4">
                        <label className="block mb-2 text-[12px] font-semibold text-[#414143]">
                          Do you have a sponsor?
                        </label>

                        <select
                          id="do_you_have_a_sponsor"
                          name="do_you_have_a_sponsor"
                          onChange={handleChange}
                          className=" border border-[#D9D9D9] text-[#333333] text-sm rounded-[8px]  block w-full py-3 px-2.5"
                        >
                          <option selected></option>
                          <option value="yes">Yes</option>
                          <option value="no">No</option>
                        </select>
                      </div>
                      <div className="relative w-full mt-4">
                        <label className="block mb-2 text-[12px] font-semibold text-[#414143]">
                          Sponsor amount($)*
                        </label>
                        <input
                          type="number"
                          className="bg-white border border-[#D9D9D9] text-[#333333] text-sm rounded-[8px] block w-full p-3 placeholder:text-[#414143] placeholder:text-[12px]  ease-linear transition-all duration-150"
                          placeholder="In $ (600)"
                          name="sponsor_amount"
                          onChange={handleChange}
                          required
                        />
                      </div>

                      <div className="relative w-full mt-4">
                        <label className="block mb-2 text-[12px] font-semibold text-[#414143]">
                          How much do you need ($)?*
                        </label>
                        <input
                          type="number"
                          className="bg-white border border-[#D9D9D9] text-[#333333] text-sm rounded-[8px] block w-full p-3 placeholder:text-[#414143] placeholder:text-[12px]  ease-linear transition-all duration-150"
                          placeholder="In $ (600)"
                          name="loan_amount"
                          onChange={handleChange}
                          required
                        />
                      </div>
                    </div>
                    <div className="flex justify-center mx-auto w-full mt-5">
                      <button
                        type="button"
                        className=" text-white bg-[#6E7093] rounded-[5px]  font-medium text-sm md:px-12 px-8 md:py-3 py-2.5 mr-2 mb-2"
                        onClick={() => setShowScreen(2)}
                      >
                        Back
                      </button>
                      <button
                        type="submit"
                        className=" text-white bg-[#209D5A] rounded-[5px]  font-medium text-sm md:px-12 px-8 md:py-3 py-2.5 mr-2 mb-2"
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </form>

                <hr className="h-px mb-8 mt-[20px] bg-[#F0F4F9] border-0" />
                {/* <p className='md:text-[12px] text-[10px] text-center mt-3 text-[#838383]'>By proceeding, you confirm that you have read and agree to the Terms and Conditions outlined above. If you do not agree, please click <span className='font-bold'>"Cancel"</span> to abort the payment process</p> */}
              </div>
            </div>
          </Modal>

          <Modal open={successOpen} onClose={onCloseSuccessModal} center>
            <div className="md:max-w-md  body-font font-poppins">
              <div className="flex flex-wrap mt-6">
                <h3 className="lg:text-[20px] text-[18px] text-[#209D5A] text-center font-semibold">
                  Congratulations on submitting your loan application!
                </h3>
                <div className="mt-4">
                  <p className=" text-[#5F5D5D] lg:text-[16px] text-[13px]">
                    Your application has been successfully received. Our team
                    will now review your application and assess your eligibility
                    for the loan.
                  </p>
                  <p className="mt-2 text-[#5F5D5D] lg:text-[16px] text-[13px]">
                    Please keep an eye on your email inbox for further updates.
                    If you qualify for the loan, we will contact you via email
                    with the next steps to complete the process.
                  </p>
                  <p className="mt-2 text-[#5F5D5D] lg:text-[16px] text-[13px]">
                    If you have any questions or need assistance, feel free to
                    reach out to our customer support team.
                  </p>
                  <p className="mt-2 text-[#5F5D5D] lg:text-[16px] text-[13px]">
                    Thank you for choosing us for your financial needs. We look
                    forward to assisting you further.{" "}
                  </p>
                </div>
              </div>
            </div>
          </Modal>
        </div>
      </div>

      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  );
}

export default UserLoans;
