import React, { useState } from "react";
import Navbar from "../Navbar";
import { NavLink } from "react-router-dom";
import Footer from "../Footer";
import { FaEyeSlash, FaEye } from "react-icons/fa";
import { useNavigate, useSearchParams } from 'react-router-dom';
import { AuthApis } from "../../apis/authApis";
import { Dispatch } from "redux";
import { ToastContainer, toast } from 'react-toastify';
import { useSelector, useDispatch } from 'react-redux';
import { login } from '../../reducer/loginSlice';
import 'react-toastify/dist/ReactToastify.css';

// @ts-ignore
import { PhoneInput } from "react-contact-number-input";
import LoadingSpinner from "../UI/LoadingSpinner";

function SurveyForm() {
  const [show, setShow] = useState(false);


  const [phone, setPhone] = useState<any>('');
  const [searchParams, setSearchParams] = useSearchParams();
  // console?.log(searchParams.get('ref'));
  const [refId, setRefId] = useState<any>(searchParams.get('ref'));
  const [loading, setLoading] = useState(false);
  const [emailError, setEmailError] = useState('');
  const [passwordError, setPassordError] = useState('');

  // Show password
  const [showPassword, setShowPassword] = useState(false);
  const [showOldPassword, setShowOldPassword] = useState(false);

  const userLoginData = useSelector((state: any) => state.data.login.value);


  const dispatch = useDispatch();
  const [userData, setUserdata] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    location: '',
    profession: '',
    academicQualification: '',
    studyAbroadFirstTime: '',
    studyLoanFirstTime: '',
    preferredContactMode: '',
    whatsappNumber: '',
    serviceFeeAgreement: '',
    reasonNoPayment: '',
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    setUserdata({ ...userData, [name]: value });
  };

  const handleInput = (event: any) => {
    const value = event.target.value;
    const regex = /^[a-zA-Z]*$/; // Regular expression to match alphabetic characters only

    if (!regex.test(value)) {
      event.target.value = value.replace(/[^a-zA-Z]/g, ''); // Remove any non-alphabetic characters
    }
  };

  const navigate = useNavigate();


  const handleSubmit = (e: React.ChangeEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);

    const formData = new FormData()
    formData.append('firstName', userData?.firstName)
    formData.append('lastName', userData?.lastName)
    formData.append('email', userData?.email)
    formData.append('phoneNumber', phone?.validData?.phoneNumber)
    formData.append('location', userData?.location)
    formData.append('profession', userData?.profession)
    formData.append('academicQualification', userData?.academicQualification)
    formData.append('studyAbroadFirstTime', userData?.studyAbroadFirstTime)
    formData.append('studyLoanFirstTime', userData?.studyLoanFirstTime)
    formData.append('preferredContactMode', userData?.preferredContactMode)
    formData.append('whatsappNumber', userData?.whatsappNumber)
    formData.append('serviceFeeAgreement', userData?.serviceFeeAgreement)
    formData.append('reasonNoPayment', userData?.reasonNoPayment)

    AuthApis.createSurvey(formData).then(
      (response) => {
        if (response?.data?.success) {
          console.log(response.data)
          toast.success(response?.data?.message);
         
        } else {
          setLoading(false);

        }

        // toast.success(response?.data?.message);
      }
    ).catch(function (error) {
      // handle error
      console.log(error.response.data);
      toast.error(error.response.data?.message);
      setLoading(false);

    }).finally(() => {
      // toast.error("No Internet Connection");
      setLoading(false);

    });
  }
  return (
    <>
      {/* <Navbar /> */}
      <div className="bg-gradient-to-b from-[#ffffff] to-[#F2FCFD]">
        <section className=" mt-6 mx-4 flex justify-center  lg:pb-20 pb-10">

          <form onSubmit={handleSubmit} className="lg:w-6/12 border p-4 rounded-lg">
            <div className="text-black flex flex-col">
              <div className="flex justify-center">
                <NavLink to="/" >
                  <img src="/images/afripay-logo.png" alt="logo" className="pt-4" />

                </NavLink>
              </div>

              <div className="flex justify-center text-[#27AF6D] md:text-[20px] text-[16px] font-[500]">
                 EDUPREMIUM PACKAGE
                FORM
              </div>


            </div>
            <div className="flex flex-col lg:flex-row lg:justify-between max-w-screen-xl mx-auto  mt-5">
              <div className="relative flex flex-col min-w-0 break-words w-full  ">
                <div className="flex-auto   py-10 pt-0">
                  <div className="flex flex-wrap ">

                    <div className="w-full lg:w-6/12 lg:pr-4">
                      <div className="relative w-full mb-6 mt-4">
                        <label className="block mb-2 text-sm font-semibold text-[#414143]">
                          First Name*
                        </label>
                        <input
                          type="text"
                          className="bg-white border border-[#cdcaca] text-[#333333] text-sm rounded-[8px] block w-full p-3 placeholder:text-[#414143] placeholder:text-[12px]  ease-linear transition-all duration-150"
                          placeholder="Enter First Name"
                          name="firstName"
                          onChange={handleChange}
                          // onInput={handleInput}

                          required
                        />
                      </div>
                    </div>

                    <div className="w-full lg:w-6/12 ">
                      <div className="relative w-full mb-6 md:mt-4 mt-0">
                        <label className="block mb-2 text-sm font-semibold text-[#414143]">
                          Last Name*
                        </label>
                        <input
                          type="text"
                          className="bg-white border border-[#cdcaca] text-[#333333] text-sm rounded-[8px] block w-full p-3 placeholder:text-[#414143] placeholder:text-[12px]  ease-linear transition-all duration-150"
                          placeholder="Enter Last Name"
                          name="lastName"
                          required
                          onChange={handleChange}
                        // onInput={handleInput}

                        />
                      </div>
                    </div>

                    <div className="w-full lg:w-6/12 lg:pr-4">
                      <div className="relative w-full mb-6">
                        <label className="block mb-2 text-sm font-semibold text-[#414143]">
                          Email Address*
                        </label>
                        <input
                          type="email"
                          className="bg-white border border-[#cdcaca] text-[#333333] text-sm rounded-[8px] block w-full p-3 placeholder:text-[#414143] placeholder:text-[12px]  ease-linear transition-all duration-150"
                          placeholder="Enter email"
                          name="email"
                          required
                          onChange={handleChange}
                          value={userData.email}

                        />
                        {emailError && <div className="text-red-500 text-sm mt-1">{emailError}</div>}

                      </div>
                    </div>

                    <div className="w-full lg:w-6/12 ">
                      <div className="relative mb-6">
                        <label className="block mb-2 text-sm font-semibold text-[#414143]">
                          Phone No*
                        </label>
                        <PhoneInput
                          style={{ backgroundColor: '#F5F5F5', width: "80%" }}
                          disabled={false}
                          className={"p-3 mr-4"}
                          containerClass={"bg-white  text-[#333333] text-sm rounded-[8px] block w-full p-1 "}
                          countryCode={'ng'}
                          onChange={setPhone}
                        />
                        <div className="pl-2 mt-1 text-xs text-red-500">{phone?.validData?.phoneNumber === null ? 'Enter valid phone number *' : ''}</div>

                      </div>
                    </div>


                    <div className="w-full lg:w-6/12 lg:pr-4">
                      <div className="relative w-full mb-6 mt-4">
                        <label className="block mb-2 text-sm font-semibold text-[#414143]">
                          Location
                        </label>
                        <input
                          type="text"
                          className="bg-white border border-[#cdcaca] text-[#333333] text-sm rounded-[8px] block w-full p-3 placeholder:text-[#414143] placeholder:text-[12px]  ease-linear transition-all duration-150"
                          // placeholder="Enter First Name"
                          name="location"
                          onChange={handleChange}
                          // onInput={handleInput}
                          required
                        />
                      </div>
                    </div>

                    <div className="w-full lg:w-6/12 ">
                      <div className="relative w-full mb-6 md:mt-4 mt-0">
                        <label className="block mb-2 text-sm font-semibold text-[#414143]">
                          Profession
                        </label>
                        <input
                          type="text"
                          className="bg-white border border-[#cdcaca] text-[#333333] text-sm rounded-[8px] block w-full p-3 placeholder:text-[#414143] placeholder:text-[12px]  ease-linear transition-all duration-150"
                          // placeholder="Enter Last Name"
                          name="profession"
                          required
                          onChange={handleChange}
                          // onInput={handleInput}

                        />
                      </div>
                    </div>

                    <div className="w-full lg:w-6/12 lg:pr-4 mb-3 md:mt-4 mt-0">
                      <label htmlFor="academicQualification" className="block text-sm font-medium text-gray-700">
                        What’s your Highest Academic Qualification
                      </label>
                      <select
                        id="academicQualification"
                        name="academicQualification"
                        // value={formData.academicQualification}
                        onChange={handleChange}
                        className="bg-white border border-[#cdcaca] text-[#333333] text-sm rounded-[8px] block w-full p-2 placeholder:text-[#414143] placeholder:text-[12px]  ease-linear transition-all duration-150"
                        required
                      >
                        <option value="">Select option</option>
                        <option value="ND">ND</option>
                        <option value="HND">HND</option>
                        <option value="BSc/BA">BSc/BA</option>
                        <option value="Masters/MBA">Masters/MBA</option>
                        <option value="PHD">PHD</option>
                        {/* Add more options here */}
                      </select>
                    </div>


                    <div className="w-full lg:w-6/12 mb-3 md:mt-4 mt-0">
                      <label htmlFor="academicQualification" className="block text-sm font-medium text-gray-700">
                        Is this your first time applying to study abroad?
                      </label>
                      <select
                        id="studyAbroadFirstTime"
                        name="studyAbroadFirstTime"
                        // value={formData.academicQualification}
                        onChange={handleChange}
                        className="bg-white border border-[#cdcaca] text-[#333333] text-sm rounded-[8px] block w-full p-2 placeholder:text-[#414143] placeholder:text-[12px]  ease-linear transition-all duration-150"
                        required
                      >
                        <option value="">Select option</option>
                        <option value="yes">Yes</option>
                        <option value="no">No</option>
                       
                        {/* Add more options here */}
                      </select>
                    </div>

                    <div className="w-full lg:w-12/12 mb-3 md:mt-4 mt-0">
                      <label htmlFor="academicQualification" className="block text-sm font-medium text-gray-700">
                        Is this your first time applying for study loan?
                      </label>
                      <select
                        id="studyLoanFirstTime"
                        name="studyLoanFirstTime"
                        // value={formData.academicQualification}
                        onChange={handleChange}
                        className="bg-white border border-[#cdcaca] text-[#333333] text-sm rounded-[8px] block w-full p-2 placeholder:text-[#414143] placeholder:text-[12px]  ease-linear transition-all duration-150"
                        required
                      >
                        <option value="">Select option</option>
                        <option value="yes">Yes</option>
                        <option value="no">No</option>
                        <option value="I am not sure">I am not sure</option>
                      </select>
                    </div>


                    <div className="w-full lg:w-6/12 lg:pr-4  mb-3 md:mt-4 mt-0">
                      <label htmlFor="academicQualification" className="block text-sm font-medium text-gray-700">
                        Preferred mode to be contacted
                      </label>
                      <select
                        id="preferredContactMode"
                        name="preferredContactMode"
                        // value={formData.academicQualification}
                        onChange={handleChange}
                        className="bg-white border border-[#cdcaca] text-[#333333] text-sm rounded-[8px] block w-full p-2 placeholder:text-[#414143] placeholder:text-[12px]  ease-linear transition-all duration-150"
                        required
                      >
                        <option value="">Select option</option>
                        <option value="Whatsapp">Whatsapp</option>
                        <option value="Email">Email</option>
                        <option value="Direct call">Direct call</option>
                      </select>
                    </div>

                    <div className="w-full lg:w-6/12 ">
                      <div className="relative w-full mb-6 md:mt-4 mt-0">
                        <label className="block text-sm font-semibold text-[#414143]">
                          If WhatsApp Provide your WhatsApp number
                        </label>
                        <input
                          type="text"
                          className="bg-white border border-[#cdcaca] text-[#333333] text-sm rounded-[8px] block w-full p-2 placeholder:text-[#414143] placeholder:text-[12px]  ease-linear transition-all duration-150"
                          // placeholder="Enter Last Name"
                          name="whatsappNumber"
                          
                          onChange={handleChange}
                        // onInput={handleInput}

                        />
                      </div>
                    </div>



                    <div className="w-full lg:w-12/12 lg:pr-4 mb-3 md:mt-4 mt-0">
                      <label htmlFor="serviceFeeAgreement" className="block text-sm pb-3 font-medium text-gray-700">
                        EDUPREMIUM offers admission assistance to over 2,000 schools across 40 countries, including the US, UK, Canada, and other European nations. They also provide assistance in obtaining student loans. The service charge for this assistance is $450. Would you like to know more about this opportunity?
                      </label>
                      <select
                        id="serviceFeeAgreement"
                        name="serviceFeeAgreement"
                        // value={formData.academicQualification}
                        onChange={handleChange}
                        className="bg-white border border-[#cdcaca] text-[#333333] text-sm rounded-[8px] block w-full p-2 placeholder:text-[#414143] placeholder:text-[12px]  ease-linear transition-all duration-150"
                        required
                      >
                        <option value="">Select option</option>
                        {/* Add more options here */}
                        <option value="yes">Yes</option>
                        <option value="no">No</option>
                      </select>
                    </div>


                    <div className="w-full lg:w-12/12 ">
                      <div className="relative w-full mb-6 md:mt-4 mt-0">
                        <label className="block text-sm font-semibold text-[#414143]">
                          If no, tell us the reason
                        </label>
                        <input
                          type="text"
                          className="bg-white border border-[#cdcaca] text-[#333333] text-sm rounded-[8px] block w-full p-2 placeholder:text-[#414143] placeholder:text-[12px]  ease-linear transition-all duration-150"
                          // placeholder="Enter Last Name"
                          name="reasonNoPayment"
                          onChange={handleChange}
                        // onInput={handleInput}

                        />
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>

            <button
              type="submit"
              disabled={loading ? true : false}
              className="w-full text-white bg-[#00B07B] hover:bg-[#05401C]  font-medium rounded-full text-sm px-5 py-2.5 mr-2 mb-2"
            >
              {loading ? <LoadingSpinner /> : "Submit"}


            </button>

          </form>
        </section>

      </div>

      {/* <-- Footer --> */}
      <Footer />

      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover />
    </>
  );
}

export default SurveyForm;
