import { AxiosGlobal } from "../shared/axios";
import{ AxiosPromise} from "axios";
import configs from "../../configs";
import { store } from "../../Components/store/store";



export class AdinLiveApis extends AxiosGlobal{  
    
    getPaymentHistory(pageNo:any,query:any): AxiosPromise<Array<any>> {
        return this.axios.get(`${configs.context}/admin/payment-history?page=${pageNo}&search=${query?.search}`,{
            headers: { "Content-Type": "aplication/json","Accept":"aplication/json","Authorization":`Bearer ${store.getState().data.login.value.token}`,"Access-Control-Allow-Origin":"*" },
          });
    } 

    getAllStudentLoan(pageNo:any,query:any): AxiosPromise<Array<any>> {
        return this.axios.get(`${configs.context}/dashboard/get-all-loan?page=${pageNo}&search=${query?.search}`,{
            headers: { "Content-Type": "aplication/json","Accept":"aplication/json","Authorization":`Bearer ${store.getState().data.login.value.token}`,"Access-Control-Allow-Origin":"*" },
          });
    } 

    getSingleLoan(id:any): AxiosPromise<Array<any>> {
        return this.axios.get(`${configs.context}/dashboard/get-single-loan/${id}`,{
            headers: { "Content-Type": "aplication/json","Accept":"aplication/json","Authorization":`Bearer ${store.getState().data.login.value.token}`,"Access-Control-Allow-Origin":"*" },
          });
    }

    getAllCreditEligibility(pageNo:any,query:any): AxiosPromise<Array<any>> {
        return this.axios.get(`${configs.context}/credit-eligibility/get-all-credit-form?page=${pageNo}&search=${query?.search}`,{
            headers: { "Content-Type": "aplication/json","Accept":"aplication/json","Authorization":`Bearer ${store.getState().data.login.value.token}`,"Access-Control-Allow-Origin":"*" },
          });
    } 

    getAllSchoolForm(pageNo:any,query:any): AxiosPromise<Array<any>> {
        return this.axios.get(`${configs.context}/credit-eligibility/get-all-school-form?page=${pageNo}&search=${query?.search}`,{
            headers: { "Content-Type": "aplication/json","Accept":"aplication/json","Authorization":`Bearer ${store.getState().data.login.value.token}`,"Access-Control-Allow-Origin":"*" },
          });
    }
    

    getSingleCreditEligibilityForm(user_id:any): AxiosPromise<Array<any>> {
        return this.axios.get(`${configs.context}/credit-eligibility/get-single-credit-form/${user_id}`,{
            headers: { "Content-Type": "aplication/json","Accept":"aplication/json","Authorization":`Bearer ${store.getState().data.login.value.token}`,"Access-Control-Allow-Origin":"*" },
          });
    }

    getSingleSchoolForm(user_id:any): AxiosPromise<Array<any>> {
        return this.axios.get(`${configs.context}/credit-eligibility/get-single-school-form/${user_id}`,{
            headers: { "Content-Type": "aplication/json","Accept":"aplication/json","Authorization":`Bearer ${store.getState().data.login.value.token}`,"Access-Control-Allow-Origin":"*" },
          });
    }

    getAllUsers(pageNo:any,query:any): AxiosPromise<Array<any>> {
        return this.axios.get(`${configs.context}/admin/get-all-users?page=${pageNo}&search=${query?.search}`,{
            headers: { "Content-Type": "aplication/json","Accept":"aplication/json","Authorization":`Bearer ${store.getState().data.login.value.token}`,"Access-Control-Allow-Origin":"*" },
          });
    } 

    getAllSurveys(pageNo:any,query:any): AxiosPromise<Array<any>> {
        return this.axios.get(`${configs.context}/admin/get-all-surveys?page=${pageNo}&search=${query?.search}`,{
            headers: { "Content-Type": "aplication/json","Accept":"aplication/json","Authorization":`Bearer ${store.getState().data.login.value.token}`,"Access-Control-Allow-Origin":"*" },
          });
    } 

    getSingleUser(id:any): AxiosPromise<Array<any>> {
        return this.axios.get(`${configs.context}/admin/get-single-user/${id}`,{
            headers: { "Content-Type": "aplication/json","Accept":"aplication/json","Authorization":`Bearer ${store.getState().data.login.value.token}`,"Access-Control-Allow-Origin":"*" },
          });
    }

    updateStatus(id:any,data:any): AxiosPromise<Array<any>> {
        return this.axios.put(`${configs.context}/admin/change-status/${id}`,data,{
            headers: { "Content-Type": "aplication/json","Accept":"aplication/json","Authorization":`Bearer ${store.getState().data.login.value.token}`,"Access-Control-Allow-Origin":"*" },
          });
    }

    getStatistics(): AxiosPromise<Array<any>> {
        return this.axios.get(`${configs.context}/admin/get-statistics`,{
            headers: { "Content-Type": "aplication/json","Accept":"aplication/json","Authorization":`Bearer ${store.getState().data.login.value.token}`,"Access-Control-Allow-Origin":"*" },
          });
    }


    

   

}