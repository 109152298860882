import React, { useState } from 'react'
import Sidebar from "../../Sidebar/AdminSidebar";
import UserNavbar from "../../Navbars/AdminNavbar";
import { SvgElement, icontypesEnum } from '../../assets/svgElement';
import { useNavigate, Link, useSearchParams } from 'react-router-dom';
import "react-responsive-modal/styles.css";
import { Modal } from 'react-responsive-modal'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { DashboardApi } from '../../../apis/DashboardApi';
import { MutatingDots } from 'react-loader-spinner'
import { AxiosResponse } from 'axios';
function AddSchool() {

    const navigate = useNavigate();
    const [open, setOpen] = useState(false);
    const [loader, setLoader] = useState<any>(false);
    const onOpenModal = () => setOpen(true);
    const onCloseModal = () => setOpen(false);


    let [userDatas, setUserDatas] = React.useState({
        school_name: "", location: "",
        email_address: "", phone_number: "",
        account_number: "", swift_code: "",
        sort_code: "", accepted_currency: "",
        other_information: "",
    });

    const handleChange = (e: any) => {
        setUserDatas({ ...userDatas, [e.target.name]: e.target.value });
    }



    const addSchool = React.useCallback(
        (e: any) => {
            e.preventDefault();
            setLoader(true);
            const userData = new FormData();
            userData.append('school_name', userDatas?.school_name);
            userData.append('location', userDatas?.location);
            userData.append('email_address', userDatas?.email_address);
            userData.append('phone_number', userDatas?.phone_number);
            userData.append('account_number', userDatas?.account_number);
            userData.append('swift_code', userDatas?.swift_code);
            userData.append('sort_code', userDatas?.sort_code);
            userData.append('accepted_currency', userDatas?.accepted_currency);
            userData.append('other_information', userDatas?.other_information);

            DashboardApi.addSchool(userData).then(
                (response: any) => {
                    if (response?.data) {
                        toast.success('School Added Successfully');
                        navigate('/schools');
                        setLoader(false)
                    }
                }
            ).catch((err) => {
                if (err?.response?.status == 422) {
                    toast.error('Form not rightly filled. Kindly Cross check.');
                } else {
                    toast.error('Some error occured.Please try again');
                }


            }).finally(() => {

            });
        },
        [userDatas, loader]
    );
    return (
        <>
            <Sidebar />
            <div className="relative md:ml-64">
                <UserNavbar title="School" />
                <div className='pt-5 pb-5 lg:px-10 px-6 bg-[#F8F8F8] min-h-screen'>
                    <div className=''>
                        <div className='flex justify-end space-x-3'>
                            <div>
                                <div className='bg-[#EDF6F5] text-[#3A974C] text-[14px] rounded-[22px] items-center text-center py-[11px] px-[44px]'>Activate</div>
                            </div>
                            <div>
                                <button type='button' className='bg-[#00B07B] text-white rounded-[5px] py-[11px] px-[30px]'>Edit School</button>
                            </div>
                        </div>
                        <div className='mt-5'>
                            {/* <div className='bg-white px-[30px] py-[29px] rounded-[10px]'>
                                
                            </div> */}
                            <form onSubmit={addSchool}>
                                <div className='bg-white px-[15px] py-[20px] rounded-[10px] flex flex-col items-center justify-center mx-auto mb-5'>
                                    <div className="relative flex flex-col min-w-0 break-words w-full  ">
                                        <div className="flex-auto  pt-0">
                                            <div className="flex flex-wrap">
                                                <div className='w-full lg:w-6/12 px-4'>
                                                    <div className="relative w-full mb-6">
                                                        <label
                                                            className="block text-[#000] text-sm font-medium mb-2"
                                                            htmlFor="name"
                                                        >
                                                            Name
                                                        </label>
                                                        <input
                                                            type="text"
                                                            name="school_name"
                                                            onChange={handleChange}
                                                            className="bg-white border border-[#DADADA] text-[#000] text-sm rounded-[5px] block w-full p-3 placeholder-[#000]  ease-linear transition-all duration-150"
                                                        />
                                                    </div>
                                                </div>

                                                <div className='w-full lg:w-6/12 px-4'>
                                                    <div className="relative w-full mb-6">
                                                        <label
                                                            className="block text-[#000] text-sm font-medium mb-2"
                                                            htmlFor="country"
                                                        >
                                                            Country/Location
                                                        </label>
                                                        <input
                                                            type="text"
                                                            name="location"
                                                            onChange={handleChange}
                                                            className="bg-white border border-[#DADADA] text-[#000] text-sm rounded-[5px] block w-full p-3 placeholder-[#000]  ease-linear transition-all duration-150"
                                                        />
                                                    </div>
                                                </div>
                                                <div className='w-full lg:w-6/12 px-4'>
                                                    <div className="relative w-full mb-6">
                                                        <label
                                                            className="block text-[#000] text-sm font-medium mb-2"
                                                            htmlFor="email"
                                                        >
                                                            Email Address
                                                        </label>
                                                        <input
                                                            type="email"
                                                            name="email_address"
                                                            onChange={handleChange}
                                                            className="bg-white border border-[#DADADA] text-[#000] text-sm rounded-[5px] block w-full p-3 placeholder-[#000]  ease-linear transition-all duration-150"
                                                        />
                                                    </div>
                                                </div>
                                                <div className='w-full lg:w-6/12 px-4'>
                                                    <div className="relative w-full mb-6">
                                                        <label
                                                            className="block text-[#000] text-sm font-medium mb-2"
                                                            htmlFor="phone_number"
                                                        >
                                                            Phone Number
                                                        </label>
                                                        <input
                                                            type="number"
                                                            onChange={handleChange}
                                                            name="phone_number"
                                                            className="bg-white border border-[#DADADA] text-[#000] text-sm rounded-[5px] block w-full p-3 placeholder-[#000]  ease-linear transition-all duration-150"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className='bg-white px-[15px] py-[20px] rounded-[10px] flex flex-col items-center justify-center mx-auto mb-5'>
                                    <div className="relative flex flex-col min-w-0 break-words w-full  ">
                                        <div className="flex-auto  pt-0">
                                            <div className="flex flex-wrap">
                                                <div className='w-full lg:w-6/12 px-4'>
                                                    <div className="relative w-full mb-6">
                                                        <label
                                                            className="block text-[#000] text-sm font-medium mb-2"
                                                            htmlFor="acct_number"
                                                        >
                                                            Account Number
                                                        </label>
                                                        <input
                                                            type="number"
                                                            name="account_number"
                                                            onChange={handleChange}
                                                            className="bg-white border border-[#DADADA] text-[#000] text-sm rounded-[5px] block w-full p-3 placeholder-[#000]  ease-linear transition-all duration-150"
                                                        />
                                                    </div>
                                                </div>

                                                <div className='w-full lg:w-6/12 px-4'>
                                                    <div className="relative w-full mb-6">
                                                        <label
                                                            className="block text-[#000] text-sm font-medium mb-2"
                                                            htmlFor="swift_code"
                                                        >
                                                            Swift/BIC Code
                                                        </label>
                                                        <input
                                                            type="text"
                                                            name="swift_code"
                                                            onChange={handleChange}
                                                            className="bg-white border border-[#DADADA] text-[#000] text-sm rounded-[5px] block w-full p-3 placeholder-[#000]  ease-linear transition-all duration-150"
                                                        />
                                                    </div>
                                                </div>
                                                <div className='w-full lg:w-6/12 px-4'>
                                                    <div className="relative w-full mb-6">
                                                        <label
                                                            className="block text-[#000] text-sm font-medium mb-2"
                                                            htmlFor="sort_code"
                                                        >
                                                            Sort Code
                                                        </label>
                                                        <input
                                                            type="text"
                                                            name="sort_code"
                                                            onChange={handleChange}
                                                            className="bg-white border border-[#DADADA] text-[#000] text-sm rounded-[5px] block w-full p-3 placeholder-[#000]  ease-linear transition-all duration-150"
                                                        />
                                                    </div>
                                                </div>
                                                <div className='w-full lg:w-6/12 px-4'>
                                                    <div className="relative w-full mb-6">
                                                        <label
                                                            className="block text-[#000] text-sm font-medium mb-2"
                                                            htmlFor="accepted_currency"
                                                        >
                                                            Accepted Currency
                                                        </label>
                                                        <input
                                                            type="text"
                                                            name="accepted_currency"
                                                            onChange={handleChange}
                                                            className="bg-white border border-[#DADADA] text-[#000] text-sm rounded-[5px] block w-full p-3 placeholder-[#000]  ease-linear transition-all duration-150"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className='bg-white px-[15px] py-[20px] rounded-[10px] flex flex-col mx-auto mb-5'>
                                    <label htmlFor="other_info" className="block text-[#000] text-sm font-medium mb-2">Other Information</label>
                                    <textarea id="message" name='other_information' onChange={handleChange} rows={4} className="block p-2.5 w-full text-sm text-gray-900 bg-white rounded-[5px] border border-[#DADADA]" placeholder=""></textarea>

                                </div>


                                <div className='flex justify-end'>
                                    <div>
                                        <button type='submit' className='bg-[#00B07B] text-white rounded-[5px] py-[11px] px-[30px]'>Save</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>

            <ToastContainer
                position="top-right"
                autoClose={2000}
                hideProgressBar={true}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover />
        </>
    )
}

export default AddSchool