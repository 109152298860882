import React from 'react'
import UserNavbar from '../../Navbars/UserNavbar'
import Sidebar from '../../Sidebar/Sidebar'
import { Link } from 'react-router-dom'

const LoanPremiumSuccess = () => {
  return (
    <div>
      <Sidebar />
      <div className="relative md:ml-64">
        <UserNavbar />
        <div className="lg:mt-3 lg:px-10 pb-6 pt-2 px-6 bg-[#F8F8F8] min-h-screen">
          {/* <HeaderTitle title="Loan" /> */}
          <div className="flex justify-center">

          </div>

          <div className=" gap-1">
            <div className="pt-2 px-2 rounded-[10px]">
              <div className='flex justify-center pt-8 pb-4'>
                <img src="/images/bro.svg" alt="hero" />

              </div>
              <h4 className="text-center text-[30px] font-bold">Your admission is in progress</h4>
              <div className='flex justify-center'>
                <h4 className="text-center text-[20px]  max-w-[480px]">You will receive an Email & SMS message when your admission is complete</h4>

              </div>
              <div className="flex justify-center mx-10 pb-10 pt-7">
                <Link to='/new-dashboard' className="text-white  bg-[#00B07B] flex justify-center items-center py-3 rounded-[10px] w-[500px]">
                  Go Home
                </Link>
              </div>

            </div>

            <div>

            </div>

          </div>

        </div>
      </div>

    </div>
  )
}

export default LoanPremiumSuccess
