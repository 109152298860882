import React, { useState } from "react";
import Sidebar from "../../Sidebar/Sidebar";
import UserNavbar from "../../Navbars/UserNavbar";
import HeaderTitle from '../../ReuseableComponent/HeaderTitle';
import { useNavigate, Link } from 'react-router-dom';
// import ExamplePdf from "../User/download-sample.pdf"

function DownloadForms() {
    let [screen, setScreen] = React.useState<any>(1);
    return (
        <>
            
                   <div className=" ">
                        <HeaderTitle title="Download Forms  " />
                        <div className='mt-[22px]'>
                            <h3 className='text-[#6E7093] text-[12px] mb-[60px] font-normal'>Please download the following forms</h3>
                        </div>
                        <div className='border-[0.5px] border-[#C4C4C4] bg-white rounded-[5px] mb-3 py-5 px-5'>
                            <a download="Example-PDF-document" target="_blank" rel="noreferrer">
                                <div className='flex flex-col md:flex-row md:justify-between'>

                                    <div>
                                        <h3 className=' text-[12px]  text-[#979797]'>1. Name of File to be downloaded </h3>

                                    </div>
                                    <div className=" cursor-pointer">

                                        <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M12 16.5L7 11.5L8.4 10.05L11 12.65V4.5H13V12.65L15.6 10.05L17 11.5L12 16.5ZM6 20.5C5.45 20.5 4.97933 20.3043 4.588 19.913C4.19667 19.5217 4.00067 19.0507 4 18.5V15.5H6V18.5H18V15.5H20V18.5C20 19.05 19.8043 19.521 19.413 19.913C19.0217 20.305 18.5507 20.5007 18 20.5H6Z" fill="#6E7093" />
                                        </svg>
                                    </div>

                                </div>
                            </a>


                        </div>
                        <div className='border-[0.5px] border-[#C4C4C4] bg-white rounded-[5px] mb-3 py-5 px-5'>
                            <a download="Example-PDF-document" target="_blank" rel="noreferrer">
                                <div className='flex flex-col md:flex-row md:justify-between'>

                                    <div>
                                        <h3 className=' text-[12px]  text-[#979797]'>2. Name of File to be downloaded </h3>

                                    </div>
                                    <div className=" cursor-pointer">

                                        <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M12 16.5L7 11.5L8.4 10.05L11 12.65V4.5H13V12.65L15.6 10.05L17 11.5L12 16.5ZM6 20.5C5.45 20.5 4.97933 20.3043 4.588 19.913C4.19667 19.5217 4.00067 19.0507 4 18.5V15.5H6V18.5H18V15.5H20V18.5C20 19.05 19.8043 19.521 19.413 19.913C19.0217 20.305 18.5507 20.5007 18 20.5H6Z" fill="#6E7093" />
                                        </svg>
                                    </div>

                                </div>
                            </a>


                        </div>
                        <div className='border-[0.5px] border-[#C4C4C4] bg-white rounded-[5px] mb-3 py-5 px-5'>
                            <a download="Example-PDF-document" target="_blank" rel="noreferrer">
                                <div className='flex flex-col md:flex-row md:justify-between'>

                                    <div>
                                        <h3 className=' text-[12px]  text-[#979797]'>3. Name of File to be downloaded </h3>

                                    </div>
                                    <div className=" cursor-pointer">

                                        <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M12 16.5L7 11.5L8.4 10.05L11 12.65V4.5H13V12.65L15.6 10.05L17 11.5L12 16.5ZM6 20.5C5.45 20.5 4.97933 20.3043 4.588 19.913C4.19667 19.5217 4.00067 19.0507 4 18.5V15.5H6V18.5H18V15.5H20V18.5C20 19.05 19.8043 19.521 19.413 19.913C19.0217 20.305 18.5507 20.5007 18 20.5H6Z" fill="#6E7093" />
                                        </svg>
                                    </div>

                                </div>
                            </a>


                        </div>
                        <div>
                            
                                <button
                                    type='button'
                                    onClick={() => setScreen(3)}
                                    className=

                                    "bg-[#00B07B] text-white mt-3 font-medium rounded-[5px] text-[12px] w-full py-2.5 text-center "


                                >Next</button>
                          
                        </div>







                        {/* <div className='w-full flex space-x-5 mt-5'>
<button
type='button'
onClick={() => navigate(-1)}
className=" bg-[#979797] text-white mt-3 font-medium rounded-[5px] text-[12px] w-full py-2.5 text-center "
>Back</button>
<button
type='submit'

className=

"bg-[#00B07B] text-white mt-3 font-medium rounded-[5px] text-[12px] w-full py-2.5 text-center "


>Continue</button>
</div> */}
                    </div>




        </>
    )
}

export default DownloadForms