import { AxiosPromise } from "axios";
import configs from "../configs";
import { AdinLiveApis } from "./live/adminLiveApi";



export class AdminApis {
    private static adminApi: AdinLiveApis = new AdinLiveApis();  
    
    
    
    static getPaymentHistory(pageNo:any,query:any): AxiosPromise<Array<any>> {
        if (configs.type === "LOCAL") {
            return {} as AxiosPromise;
        } else {
            return this.adminApi.getPaymentHistory(pageNo,query);
        }
    } 

    static getAllStudentLoan(pageNo:any,query:any): AxiosPromise<Array<any>> {
        if (configs.type === "LOCAL") {
            return {} as AxiosPromise;
        } else {
            return this.adminApi.getAllStudentLoan(pageNo,query);
        }
    } 
    
    
    static getAllCreditEligibility(pageNo:any,query:any): AxiosPromise<Array<any>> {
        if (configs.type === "LOCAL") {
            return {} as AxiosPromise;
        } else {
            return this.adminApi.getAllCreditEligibility(pageNo,query);
        }
    }

    static getAllSchoolForm(pageNo:any,query:any): AxiosPromise<Array<any>> {
        if (configs.type === "LOCAL") {
            return {} as AxiosPromise;
        } else {
            return this.adminApi.getAllSchoolForm(pageNo,query);
        }
    }

    static getSingleLoan(id:any): AxiosPromise<Array<any>> {
        if (configs.type === "LOCAL") {
            return {} as AxiosPromise;
        } else {
            return this.adminApi.getSingleLoan(id);
        }
    } 

    static getSingleCreditEligibilityForm(user_id:any): AxiosPromise<Array<any>> {
        if (configs.type === "LOCAL") {
            return {} as AxiosPromise;
        } else {
            return this.adminApi.getSingleCreditEligibilityForm(user_id);
        }
    } 

    static getSingleSchoolForm(user_id:any): AxiosPromise<Array<any>> {
        if (configs.type === "LOCAL") {
            return {} as AxiosPromise;
        } else {
            return this.adminApi.getSingleSchoolForm(user_id);
        }
    } 

    static getAllUsers(pageNo:any,query:any): AxiosPromise<Array<any>> {
        if (configs.type === "LOCAL") {
            return {} as AxiosPromise;
        } else {
            return this.adminApi.getAllUsers(pageNo,query);
        }
    } 


    static getAllSurveys(pageNo:any,query:any): AxiosPromise<Array<any>> {
        if (configs.type === "LOCAL") {
            return {} as AxiosPromise;
        } else {
            return this.adminApi.getAllSurveys(pageNo,query);
        }
    } 

    static getSingleUser(id:any): AxiosPromise<Array<any>> {
        if (configs.type === "LOCAL") {
            return {} as AxiosPromise;
        } else {
            return this.adminApi.getSingleUser(id);
        }
    } 

    static updateStatus(id:any,data:any): AxiosPromise<Array<any>> {
        if (configs.type === "LOCAL") {
            return {} as AxiosPromise;
        } else {
            return this.adminApi.updateStatus(id,data);
        }
    } 

    static getStatistics(): AxiosPromise<Array<any>> {
        if (configs.type === "LOCAL") {
            return {} as AxiosPromise;
        } else {
            return this.adminApi.getStatistics();
        }
    }  

    

}