import { AxiosPromise } from "axios";
import configs from "../configs";

import { AuthLiveApis } from "./live/authLiveApis";
import { PaymentLiveApis } from "./live/paymentLiveApis";



export class PaymentApis {
    private static paymentApis: PaymentLiveApis = new PaymentLiveApis();
    

    static verifyWalletTopup(reference:any): AxiosPromise<Array<any>> {
        if (configs.type === "LOCAL") {
            return {} as AxiosPromise;
        } else {
            return this.paymentApis.verifyWalletTopup(reference);
        }
    } 

    static verifyWalletTopupInNaira(reference:any): AxiosPromise<Array<any>> {
        if (configs.type === "LOCAL") {
            return {} as AxiosPromise;
        } else {
            return this.paymentApis.verifyWalletTopupInNaira(reference);
        }
    } 
    

    static creditUserWallet(data: any): AxiosPromise<Array<any>> {
        if (configs.type === "LOCAL") {
            return {} as AxiosPromise;
        } else {
            return this.paymentApis.creditUserWallet(data);
        }
    } 

    static creditUserInNaira(data: any): AxiosPromise<Array<any>> {
        if (configs.type === "LOCAL") {
            return {} as AxiosPromise;
        } else {
            return this.paymentApis.creditUserInNaira(data);
        }
    } 

    static getTransactionHistory(pageNo:any): AxiosPromise<Array<any>> {
        if (configs.type === "LOCAL") {
            return {} as AxiosPromise;
        } else {
            return this.paymentApis.getTransactionHistory(pageNo);
        }
    }

    static creditHistoryPayment(data: any): AxiosPromise<Array<any>> {
        if (configs.type === "LOCAL") {
            return {} as AxiosPromise;
        } else {
            return this.paymentApis.creditHistoryPayment(data);
        }
    } 

    static creditHistoryPremiumPayment(data: any): AxiosPromise<Array<any>> {
        if (configs.type === "LOCAL") {
            return {} as AxiosPromise;
        } else {
            return this.paymentApis.creditHistoryPremiumPayment(data);
        }
    } 

    static creditHistoryPremiumLoanPayment(data: any): AxiosPromise<Array<any>> {
        if (configs.type === "LOCAL") {
            return {} as AxiosPromise;
        } else {
            return this.paymentApis.creditHistoryPremiumLoanPayment(data);
        }
    } 

    static verifyCreditHistoryPayment(reference:any): AxiosPromise<Array<any>> {
        if (configs.type === "LOCAL") {
            return {} as AxiosPromise;
        } else {
            return this.paymentApis.verifyCreditHistoryPayment(reference);
        }
    } 

    static verifyEduPremiumPayment(reference:any): AxiosPromise<Array<any>> {
        if (configs.type === "LOCAL") {
            return {} as AxiosPromise;
        } else {
            return this.paymentApis.verifyEduPremiumPayment(reference);
        }
    } 

    

   
}