import React, { useState } from 'react'
import Sidebar from "../../Sidebar/Sidebar";
import UserNavbar from "../../Navbars/UserNavbar";
import HeaderTitle from '../../ReuseableComponent/HeaderTitle';
// import { DashboardApi } from "../../../apis/DashboardApi"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// @ts-ignore
import { PhoneInput } from "react-contact-number-input";

function Profile() {
    const [phone, setPhone] = useState<any>('');
    const [userDetails, setuserDetails] = React.useState<any>([]);

    // React.useEffect(() => {
    //     DashboardApi.getUserDetails().then(
    //         (response: any) => {
    //             if (response?.data) {
    //                 setuserDetails(response?.data?.data?.user_details)
    //             }
    //         }
    //     );

    // }, []);

    let [userDatas, setUserDatas] = React.useState({
        email: "", 
        f_name: "",
        l_name: "",
        address: "",
        postal_code: "",
    });

    const handleChange = (e: any) => {
        setUserDatas({ ...userDatas, [e.target.name]: e.target.value });
    }

    // const UpdateUser = React.useCallback(
    //     (e: any) => {
    //         e.preventDefault();
    //         const userData = new FormData();
    //         userData.append('address', (userDatas?.address !== '') ? userDatas?.address : userDetails?.address);
    //         userData.append('f_name', (userDatas?.f_name !== '') ? userDatas?.f_name : userDetails?.first_name);
    //         userData.append('l_name', (userDatas?.l_name !== '') ? userDatas?.l_name : userDetails?.last_name);
    //         userData.append('email', userDetails?.email);
    //         userData.append('id', userDetails?.id);
    //         userData.append('postal_code', (userDatas?.postal_code !== '') ? userDetails?.postal_code : userDetails?.postal_code);
           

    //         DashboardApi.updateUser(userData).then(
    //             (response: any) => {
    //                 if (response?.data) {
    //                     toast.success(response?.data?.message);
    //                     console?.log(response?.data?.data)
                        
    //                 }
    //             }
    //         ).catch((err) => {
    //             if (err?.response?.status == 422) {
    //                 toast.error('Form not rightly filled. Kindly Cross check.');
    //             } else {
    //                 toast.error('Some error occured.Please try again');
    //             }


    //         }).finally(() => {

    //         });
    //     },
    //     [userDatas, userDetails]
    // );

   
    return (
        <>
            <Sidebar />
            <div className="relative md:ml-64">

                <UserNavbar />
                <div className='lg:mt-5 lg:px-10 px-6 bg-[#Ffffff] min-h-screen'>
                    <HeaderTitle title="Profile" />

                    <form>
                        <div className='mt-[57px]'>
                            <div className="w-full lg:w-6/12 px-4">
                                <div className="relative flex flex-col min-w-0 break-words w-full  ">
                                    <div className="flex-auto px-4  py-10 pt-0">
                                        <div className="flex flex-wrap">
                                            <div className="relative w-full mb-6">
                                                <label
                                                    className="block text-[#000] text-sm font-medium mb-2"
                                                    htmlFor="grid-password"
                                                >
                                                    First Name
                                                </label>
                                                <input
                                                    type="text"
                                                    onChange={handleChange}
                                                    name="f_name"
                                                    disabled
                                                    className="bg-white border border-[#DADADA] text-[#000] text-sm rounded-[5px] block w-full p-3 placeholder-[#000]  ease-linear transition-all duration-150"
                                                    placeholder=""
                                                    defaultValue={userDetails?.first_name}

                                                />
                                            </div>
                                            <div className="relative w-full mb-6">
                                                <label
                                                    className="block text-[#000] text-sm font-medium mb-2"
                                                    htmlFor="grid-password"
                                                >
                                                    Last Name
                                                </label>
                                                <input
                                                    type="text"
                                                    onChange={handleChange}
                                                    name="l_name"
                                                    disabled
                                                    className="bg-white border border-[#DADADA] text-[#000] text-sm rounded-[5px] block w-full p-3 placeholder-[#000]  ease-linear transition-all duration-150"
                                                    placeholder=""
                                                    defaultValue={userDetails?.last_name}

                                                />
                                            </div>
                                            <div className="relative w-full mb-6">
                                                <label
                                                    className="block text-[#000] text-sm font-medium mb-2"
                                                    htmlFor="grid-password"
                                                >
                                                    Email address
                                                </label>
                                                <input
                                                    type="email"
                                                    onChange={handleChange}
                                                    name="email"
                                                    disabled
                                                    className="bg-white border border-[#DADADA] text-[#000] text-sm rounded-[5px] block w-full p-3 placeholder-[#000]  ease-linear transition-all duration-150"
                                                    placeholder=""
                                                    defaultValue={userDetails?.email}

                                                />
                                            </div>
                                            <div className="relative mb-6">
                                                <label
                                                    className="block text-[#000] text-sm font-medium mb-2"
                                                    htmlFor="grid-password"
                                                >
                                                    Phone Number
                                                </label>
                                                <PhoneInput
                                                    style={{ backgroundColor: '#F5F5F5', width: "100%" }}
                                                    disabled={false}
                                                    className={"p-3 mr-4"}
                                                    containerClass={"bg-white border border-[#DADADA] text-[#333333] text-sm rounded-[5px] block w-full p-1 "}
                                                    countryCode={'ng'}
                                                  onChange={setPhone}

                                                />
                                                <div className="pl-2 mt-1 text-xs text-red-500">{phone?.validData?.phoneNumber === null ? 'Enter valid phone number *' : ''}</div>

                                            </div>
                                            <div className="relative w-full mb-6">
                                                <label
                                                    className="block text-[#000] text-sm font-medium mb-2"
                                                    htmlFor="grid-password"
                                                >
                                                    Address
                                                </label>
                                                <input
                                                    type="text"
                                                    onChange={handleChange}
                                                    name="address"
                                                   
                                                    className="bg-white border border-[#DADADA] text-[#000] text-sm rounded-[5px] block w-full p-3 placeholder-[#000]  ease-linear transition-all duration-150"
                                                    placeholder=""

                                                />
                                            </div>
                                            <div className="relative w-full mb-6">
                                                <label
                                                    className="block text-[#000] text-sm font-medium mb-2"
                                                    htmlFor="grid-password"
                                                >
                                                    Postal Code
                                                </label>
                                                <input
                                                    type="text"
                                                    onChange={handleChange}
                                                    name="postal_code"
                                                  
                                                    className="bg-white border border-[#DADADA] text-[#000] text-sm rounded-[5px] block w-full p-3 placeholder-[#000]  ease-linear transition-all duration-150"
                                                    placeholder=""

                                                />
                                            </div>
                                            <div className='w-full'>
                                                <button type='submit' className='w-full bg-[#00B07B] text-white py-2'>Save Changes</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>

                </div>
            </div>
        </>

    )
}

export default Profile