import React, { useState } from "react";
import Sidebar from "../../Sidebar/Sidebar";
import UserNavbar from "../../Navbars/UserNavbar";
import HeaderTitle from '../../ReuseableComponent/HeaderTitle';
import { Link, useNavigate } from 'react-router-dom';

function VerifyInfo() {

    const navigate = useNavigate();
  const [userData, setUserdata] = useState({
    'payment_by': "",
    'payment_for': "",
    'country_from': "",
    'account_number': "",
    'sort_code': "",
    'swift_code': "",
    'sch_currency': "",

  });
  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    setUserdata({ ...userData, [name]: value });
  };
  const handleSubmit = (e: React.ChangeEvent<HTMLFormElement>) => {
    e.preventDefault();
    navigate('/student-information');
    // const formData = new FormData()
    // formData.append('payment_by', userData?.payment_by)
    // formData.append('institution_address', userData?.institution_address)
    // formData.append('institution', userData?.institution)



    // AuthApis.register(formData).then(
    //   (response) => {
    //     if (response?.data) {
    //       console.log(response.data)
    //       toast.error(response?.data?.message);
    //       if (response?.data?.success === true) {
    //         dispatch(login({ email: userData?.email, isVerified: response.data.data?.isVerified, token: response.data.data.token, id: response.data.data.account_id, name: response.data.data.name, data: response.data.data }))
    //         navigate('/email-verify');
    //       } else {
    //         toast.success(response?.data?.message);
    //       }
    //     } else {

    //     }

    //     // toast.success(response?.data?.message);
    //   }
    // ).catch(function (error) {
    //   // handle error
    //   console.log(error.response.data);
    //   toast.error(error.response.data?.message);
    // }).finally(() => {
    //   // toast.error("No Internet Connection");

    // });
  }
  return (
    <>
      <Sidebar />
      <div className="relative md:ml-64 pb-10">
        <UserNavbar />
        <div className='lg:mt-5 lg:px-10 px-6 bg-[#Ffffff] min-h-screen'>
          <HeaderTitle title="Verify Information " />
          <div className='mt-[22px]'>
            <h3 className='text-[#6E7093] text-[12px] mb-[83px] font-normal'>Enter the student details to be sent to the school  </h3>
          </div>

          <div className=' flex'>
            <div className="w-full lg:w-6/12 ">
                <div>
                    <div className="flex justify-between">
                        <h2 className=" font-medium md:text-[20px] text-[16px]">School  Information</h2>
                        <div>Edit</div>
                    </div>
                    <hr className="my-1"/>
                    <div className='flex justify-between pt-2 mt-1 mb-2 px-2 pb-1'>
                                        <h3 className='text-[12px] font-medium'>School Name : </h3>
                                        <h3 className='text-[12px]'>Takk Adult Center </h3>
                                    </div>
                                    <div className='flex justify-between pt-2 mt-1 mb-2 px-2 pb-1'>
                                        <h3 className='text-[12px] font-medium'>School Address : </h3>
                                        <h3 className='text-[12px]'> </h3>
                                    </div>
                                    <div className='flex justify-between pt-2 mt-1 mb-2 px-2 pb-1'>
                                        <h3 className='text-[12px] font-medium'>Country: </h3>
                                        <h3 className='text-[12px]'> </h3>
                                    </div>

                </div>
                <div className="mt-10">
                    <div className="flex justify-between">
                        <h2 className=" font-medium md:text-[20px] text-[16px]">Payment Information</h2>
                        <div>Edit</div>
                    </div>
                    <hr className="my-1"/>
                    <div className='flex justify-between pt-2 mt-1 mb-2 px-2 pb-1'>
                                        <h3 className='text-[12px] font-medium'>Payment for : </h3>
                                        <h3 className='text-[12px]'>Tuition </h3>
                                    </div>
                                    <div className='flex justify-between pt-2 mt-1 mb-2 px-2 pb-1'>
                                        <h3 className='text-[12px] font-medium'>Country you are paying from : </h3>
                                        <h3 className='text-[12px]'> </h3>
                                    </div>
                                    <div className='flex justify-between pt-2 mt-1 mb-2 px-2 pb-1'>
                                        <h3 className='text-[12px] font-medium'>Country: </h3>
                                        <h3 className='text-[12px]'></h3>
                                    </div>

                </div>
                <div className="mt-10">
                    <div className="flex justify-between">
                        <h2 className=" font-medium md:text-[20px] text-[16px]">Student Information</h2>
                        <div>Edit</div>
                    </div>
                    <hr className="my-1"/>
                    <div className='flex justify-between pt-2 mt-1 mb-2 px-2 pb-1'>
                                        <h3 className='text-[12px] font-medium'>Full Name : </h3>
                                        <h3 className='text-[12px]'>Omolade Samuel</h3>
                                    </div>
                                    <div className='flex justify-between pt-2 mt-1 mb-2 px-2 pb-1'>
                                        <h3 className='text-[12px] font-medium'>Student Email Address : </h3>
                                        <h3 className='text-[12px]'></h3>
                                    </div>
                                    <div className='flex justify-between pt-2 mt-1 mb-2 px-2 pb-1'>
                                        <h3 className='text-[12px] font-medium'>Extra Info : </h3>
                                        <h3 className='text-[12px]'></h3>
                                    </div>

                </div>
             
                <div className='w-full flex space-x-5'>
                        <Link to="/makepayment" className="w-full">
                        <button
                          type='submit'

                          className=
                            "bg-[#00B07B] text-white  mt-3 font-medium rounded-[5px] text-[12px] w-full py-2.5 text-center "
                          
                          
                        >Proceed to Payment</button>
                        </Link>
                        
                      </div>
            </div>
            <div className="w-full lg:w-2/12 hidden md:block"></div>
            <div className="w-full lg:w-4/12 hidden md:block">
              <div className='0 border border-[#979797] pr-5 pl-14 pt-5 pb-10 rounded-[15px]'>

                <ol className="relative border-l-2 border-[#D9D9D9]">
                  <li className="mb-5 ml-6">
                    <div className="absolute  bg-[#00B07B] text-white text-[10px] rounded-[30px]  -left-[31px] py-1 px-5 ">Step 1 </div>
                    <p className="mt-2 mb-4   ml-8 text-[12px]  text-[#6E7093] ">Enter your schools details to begin your payment Process</p>
                  </li>
                  <li className="mb-5 ml-6">
                    <div className="absolute  bg-[#00B07B] text-white text-[10px] rounded-[30px]  -left-[31px] py-1 px-5 ">Step 2</div>
                    <p className="mt-2 mb-4   ml-8 text-[12px]  text-[#6E7093] ">Confirm the amount you are sending before proceeding</p>
                  </li>
                  <li className="mb-5 ml-6">
                    <div className="absolute  bg-[#00B07B] text-white text-[10px] rounded-[30px]  -left-[31px] py-1 px-5 ">Step 3</div>
                    <p className="mt-2 mb-4   ml-8 text-[12px]  text-[#6E7093]">Enter Student details to be sent to the <br />school </p>
                  </li>
                  <li className="mb-5 ml-6">
                    <div className="absolute  bg-[#00B07B] text-white text-[10px] rounded-[30px]  -left-[31px] py-1 px-5 ">Step 4</div>
                    <p className="mt-2 mb-4   ml-8 text-[12px]  text-[#6E7093]">Verify all the information you have provided before proceeding </p>
                  </li>
                  <li className="mb-5 ml-6">
                    <div className="absolute  bg-[#979797] text-white text-[10px] rounded-[30px]  -left-[31px] py-1 px-5 ">Step 5</div>
                    <p className="mt-2 mb-4   ml-8 text-[12px]  text-[#6E7093] ">Make Payment </p>
                  </li>


                </ol>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default VerifyInfo