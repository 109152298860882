import React,{useState} from 'react'
// @ts-ignore
import Carousel from 'react-grid-carousel'
import Navbar from '../Navbar'
import { Link, NavLink } from "react-router-dom";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import Footer from '../Footer';



function Icon({ id, open }: { id: any; open: any }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={2}
      stroke="currentColor"
      className={`${id === open ? "rotate-180" : ""} h-5 w-5 transition-transform`}
    >
      <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
    </svg>
  );
}

function AfripayShopPage() {

const [open, setOpen] = useState(1);
    const [openTestTab, setOpenTestTab] = useState(1);
  
    const handleOpen = (value: any) => {
      setOpen(open === value ? 0 : value);
    };

    return (
        <>
         
          <section className=' bg-gradient-to-r from-[#FEFFFF] to-[#F6FDFE]'>
          <Navbar />
            <div className='pt-[120px] md:pt-[120px]'>
              <div className="grid  px-8 lg:px-16  pb-8  lg:gap-8 xl:gap-0 lg:grid-cols-12">
                <div className="mr-auto place-self-center lg:col-span-7">
                    <div>
                        <h1 className='lg:text-[17px] text-[14px] text-[#00B07B] font-extrabold mb-1'>AFRIPAY SHOP</h1>
                    </div>
                  <h1 className="max-w-2xl mb-4 text-[30px] text-[#191A15] font-bold md:leading-[60px] tracking-normal  md:text-[48px] xl:text-[48px]">
                    Empower Your Education with AfriPay's Shop
                  </h1>
    
                  <p className=" max-w-[520px] text-[12px]  mb-6 mt-3 font-normal text-[#6E7093] lg:mb-8 text-base">
                  Your Premier Destination for Seamless Living and Global Exploration, Offering an Extensive Array of Services Including Accommodation Booking, Bus Ticketing, Flight Reservations, Visa Processing, and Health Insurance Solutions.
                  </p>
                  <div className="flex ">
                    <NavLink to="/sign-in" className="">
                      <button
                        type="submit"
                        className="py-[14px] px-[30px]  text-[16px] font-normal text-white bg-[#00B07B] rounded-[40px] border border-[#00B07B] "
                      >
                        Get Started
                      </button>
                    </NavLink>
                  </div>
                </div>
                <div className=" my-6 lg:mt-0 lg:col-span-5 lg:flex w-full md:mt-0 mt-[50px]">
                  <img src="/images/afripay-hero.png" alt="hero" />
                </div>
              </div>
    
            </div>
          </section>
    
          <section className='bg-[#F6FDFE]'>
            <div className="grid  px-8 lg:px-16 pb-8  lg:gap-8 xl:gap-0 lg:pt-10">
              <div className=''>
                <h1 className='lg:text-[30px] text-[20px] font-bold text-center'>Incredible Features</h1>
              </div>
              <div className=' pt-8'>
              <Carousel cols={4} rows={1} gap={20} loop >
              <Carousel.Item>
              <img src="/images/book-flight.png" alt="hero" />
              </Carousel.Item>
              <Carousel.Item>
              <img src="/images/visa.png" alt="hero" />
              </Carousel.Item>
              <Carousel.Item>
              <img src="/images/bus.png" alt="hero" />
              </Carousel.Item>
              <Carousel.Item>
              <img src="/images/health.png" alt="hero" />
              </Carousel.Item>
              <Carousel.Item>
              <img src="/images/saveNow.png" alt="hero" />
              </Carousel.Item>
              <Carousel.Item>
              <img src="/images/orderVirtual.png" alt="hero" />
              </Carousel.Item>
              <Carousel.Item>
              <img src="/images/accomodation.png" alt="hero" />
              </Carousel.Item>
              </Carousel>
</div>
              {/* <div className='grid lg:grid-cols-4 grid-cols-2 gap-5 pt-8'>
               <div>
                 <img src="/images/book-flight.png" alt="hero" />
               </div>
                <div>
                 <img src="/images/visa.png" alt="hero" />
                </div>
               <div>
               <img src="/images/bus.png" alt="hero" />
               </div>
               <div>
               <img src="/images/health.png" alt="hero" />
               </div>
              </div> */}
            </div>
          </section>
    
          <section className="bg-[#F6FDFE] md:pt-[50px] pt-[40px] pb-16 sm:px-16 px-8 flex justify-center items-start" >
            <div className='xl:max-w-[1280px] w-full'>
              <div className='flex md:flex-row flex-col'>
    
                <div className='flex-1 flex justify-center items-center'>
                  <img src="/images/afripay1.png" alt="hero" />
                </div>
    
                <div className='flex-1 flex justify-center items-start flex-col md:ml-[120px] ml-0'>
                  <div className="flex space-x-5">
    
                    <h1 className="flex-1 font-poppins font-semibold md:text-[40px] text-[20px] md:mt-[20px] mt-[10px] text-[#1B212F] ">
                    Book Flights with AfriPay
                    </h1>
                   
                  </div>
                  <div className='mt-[18px]'>
                    <ul className=' list-disc list-outside space-y-2 text-[#979797] lg:text-[18px] text-[14px] pl-4'>
                      <li>Save Time: Skip the hassle of searching multiple websites for flights.</li>
                      <li>Best Deals: Get access to exclusive deals and discounts on flights.</li>
                      <li>Peace of Mind: Travel with confidence knowing your flight booking is secure and reliable.</li>
                      <li>24/7 Support: Assistance available round-the-clock for any flight-related queries or issues.</li>
                    </ul>
    
                  </div>
                </div>
    
              </div>
            </div>
    
          </section>
    
          <section className="bg-[#F6FDFE] md:pt-[20px] pt-[20px] sm:px-14 px-8 flex justify-center items-start" >
                <div className='xl:max-w-[1280px] w-full'>
                    <div className='flex md:flex-row flex-col sm:py-14 py-6'>
                        <div className='flex-1 flex justify-center items-start flex-col'>
                            <div className="md:flex flex-col w-full">

                            <h1 className="flex-1 font-poppins font-semibold md:text-[40px] text-[20px] md:mt-[20px] mt-[10px] text-[#1B212F] lg:leading-[48px]  ">
                            Simplify Your Travel with AfriPay's Resident Permit Processing Service
                    </h1>
                            </div>
                            <div className='mt-[18px]'>
                    <ul className=' list-disc list-outside space-y-2 text-[#979797] lg:text-[18px] text-[14px] pl-4'>
                      <li>Time Efficiency: Expedite the visa application process.</li>
                      <li>Expert Assistance: Rely on professional advice and support</li>
                      <li>Transparent Fees: Clear visibility into visa processing fees</li>
                      <li>Personalized Support: Tailored assistance to meet your<br/> specific visa needs.</li>
                    </ul>
    
                  </div>

                        </div>
                        <div className='flex-1 flex justify-center items-center lg:mt-0 mt-6'>
                            <img src="/images/afripay2.png" alt="hero" />
                        </div>
                    </div>
                </div>
            </section>

            <section className="bg-[#F6FDFE] md:pt-[50px] pt-[40px] pb-16 sm:px-16 px-8 flex justify-center items-start" >
            <div className='xl:max-w-[1280px] w-full'>
              <div className='flex md:flex-row flex-col'>
    
                <div className='flex-1 flex justify-center items-center'>
                  <img src="/images/afripay3.png" alt="hero" />
                </div>
    
                <div className='flex-1 flex justify-center items-start flex-col md:ml-[120px] ml-0'>
                  <div className="flex space-x-5">
    
                    <h1 className="flex-1 font-poppins font-semibold md:text-[40px] text-[20px] md:mt-[20px] mt-[10px] text-[#1B212F] lg:leading-[48px]">
                    Book Bus and Train Tickets with AfriPay
                    </h1>
                   
                  </div>
                  <div className='mt-[18px]'>
                    <ul className=' list-disc list-outside space-y-2 text-[#979797] lg:text-[18px] text-[14px] pl-4'>
                      <li>Save Time: Skip the queues and book online.</li>
                      <li>Transparent Pricing: No hidden fees, clear upfront prices.</li>
                      <li>User-Friendly Interface: Intuitive design for hassle-free navigation.</li>
                      <li>24/7 Customer Support: Assistance available round-the-clock.</li>
                    </ul>
    
                  </div>
                </div>
    
              </div>
            </div>
    
          </section>
    
          <section className="bg-[#F6FDFE] md:pt-[20px] pt-[20px] sm:px-14 px-8 flex justify-center items-start" >
                <div className='xl:max-w-[1280px] w-full'>
                    <div className='flex md:flex-row flex-col sm:py-14 py-6'>
                        <div className='flex-1 flex justify-center items-start flex-col'>
                            <div className="md:flex flex-col w-full">

                            <h1 className="flex-1 font-poppins font-semibold md:text-[40px] text-[20px] md:mt-[20px] mt-[10px] text-[#1B212F] lg:leading-[48px]  ">
                            Book Accommodations with AfriPay
                    </h1>
                            </div>
                            <div className='mt-[18px]'>
                    <ul className=' list-disc list-outside space-y-2 text-[#979797] lg:text-[18px] text-[14px] pl-4'>
                      <li>Save Time: Find the perfect accommodation quickly and easily.</li>
                      <li>Best Deals: Get access to exclusive deals and discounts on accommodations.</li>
                      <li>Peace of Mind: Travel with confidence knowing your accommodation booking is secure and reliable.</li>
                      <li>24/7 Support: Assistance available round-the-clock for any accommodation-related queries or issues.</li>
                    </ul>
    
                  </div>

                        </div>
                        <div className='flex-1 flex justify-center items-center lg:mt-0 mt-6'>
                            <img src="/images/afripay4.png" alt="hero" />
                        </div>
                    </div>
                </div>
            </section>
    
            <section className="bg-[#F6FDFE] md:pt-[50px] pt-[40px] pb-16 sm:px-16 px-8 flex justify-center items-start" >
            <div className='xl:max-w-[1280px] w-full'>
              <div className='flex md:flex-row flex-col'>
    
                <div className='flex-1 flex justify-center items-center'>
                  <img src="/images/afripay5.png" alt="hero" />
                </div>
    
                <div className='flex-1 flex justify-center items-start flex-col md:ml-[120px] ml-0'>
                  <div className="flex space-x-5">
    
                    <h1 className="flex-1 font-poppins font-semibold md:text-[40px] text-[20px] md:mt-[20px] mt-[10px] text-[#1B212F] lg:leading-[48px]">
                    Secure Your Health with AfriPay's Health Insurance Service
                    </h1>
                   
                  </div>
                  <div className='mt-[18px]'>
                    <ul className=' list-disc list-outside space-y-2 text-[#979797] lg:text-[18px] text-[14px] pl-4'>
                      <li>Financial Security: Protect yourself from unexpected medical expenses and high healthcare costs.</li>
                      <li>Access to Quality Care: Receive timely medical care and treatment from trusted healthcare providers.</li>
                      <li>Peace of Mind: Rest easy knowing that you and your family are covered in case of illness or injury.</li>
                      <li>Convenience: Manage your health insurance coverage conveniently through the AfriPay platform.</li>
                    </ul>
    
                  </div>
                </div>
    
              </div>
            </div>
    
          </section>


          <section className='bg-[#F6FDFE] pt-10 pb-10 md:pt-20'>
          <div className=' flex-col px-8 lg:px-16'>
            
          <div className='bg-gradient-to-br from-[#00B07B] from-90% to-[#004A34] to-10% rounded-[20px] lg:px-[50px] lg:pb-10 p-[20px] relative'>
            <div className='hidden lg:block'>
            <h3 className='lg:text-[36px]  text-white font-semibold'> Empower Your Education with <br/> AfriPay's Shop </h3>
            </div>
            <div className='block lg:hidden'>
            <h3 className=' text-[20px] text-white font-semibold'> Empower Your Education with AfriPay's Shop </h3>
            </div> 
            <div className='flex lg:flex-row lg:justify-between flex-col'>
              <div className='mt-5'>
             <p className='lg:w-[767px] text-[#DADADA] lg:text-[20px] text-[14px]'>Your Premier Destination for Seamless Living and Global Exploration, Offering an Extensive Array of Services Including Accommodation Booking, Bus Ticketing, Flight Reservations, Visa Processing, and Health Insurance Solutions.</p>
              </div>
              <div className='lg:mr-[50px]'>
                
                <div className='mt-5'>
                <NavLink to="/sign-in" className="">
                      <button
                        type="submit"
                        className="py-[14px] px-[35px]  text-[16px] font-normal text-white rounded-[30px] border border-[#ff] "
                      >
                        Get Started
                      </button>
                    </NavLink>
                </div>
              </div>
            </div>
            <div className=' absolute right-0 -top-24'>
          <img src="/images/umb.png" alt="hero" />
          </div>
          <div className=' absolute -left-10 -bottom-24'>
          <img src="/images/cube.png" alt="hero" />
          </div>
          </div>
          
          </div>
          </section>
    
          <section className='bg-[#FFF] pt-10 pb-10 md:pt-16'>
          <div className=' flex-col px-6 lg:px-16'>
          <div className='rounded-[20px] lg:px-[50px] p-[20px]'>
            <div className='flex justify-center mt-2'>
              <h1 className='lg:text-[38px] text-[20px] font-bold text-[#2C1762] text-center'>Want to know more about Afripay Shop?</h1>
               </div>
               <div className="flex flex-col md:flex-row md:justify-between  px-8 lg:px-20 pt-5 md:pt-16 pb-8 mx-auto lg:gap-20 ">
               <div className="my-6 lg:basis-4/12 basis-full lg:mt-0 lg:pl-24 flex flex-row lg:flex-col max-w-[90vw] overflow-auto">
            <div
              onClick={() => setOpenTestTab(1)}
              className={` ${openTestTab === 1 ? "bg-[#EEFEFC] text-[#2C1762]" : ""} pl-4 pr-4 lg:pr-16 lg:py-3.5 py-2 mb-4 text[#6F6C90] text-sm rounded-lg cursor-pointer`}
            >Bus Ticket</div>
            <div
              onClick={() => setOpenTestTab(2)}
              className={` ${openTestTab === 2 ? "bg-[#EEFEFC] text-[#2C1762]" : ""} pl-4 pr-4 lg:pr-16 lg:py-3.5 py-2 mb-4 text[#6F6C90] text-sm rounded-lg cursor-pointer`}
            >Visa Processing</div>
            <div
              onClick={() => setOpenTestTab(3)}
              className={` ${openTestTab === 3 ? "bg-[#EEFEFC] text-[#2C1762]" : ""} pl-4 pr-4 lg:pr-16 lg:py-3.5 py-2 mb-4 text[#6F6C90] text-sm rounded-lg cursor-pointer`}
            >Flight Booking</div>
            <div
              onClick={() => setOpenTestTab(4)}
            className={` ${openTestTab === 4 ? "bg-[#EEFEFC] text-[#2C1762]" : ""} pl-4 pr-4 lg:pr-16 lg:py-3.5 py-2 mb-4 text[#6F6C90] rounded-lg text-sm cursor-pointer`}>
              Accomodation Booking
            </div>
          
            <div
              onClick={() => setOpenTestTab(5)}
              className={` ${openTestTab === 5 ? "bg-[#EEFEFC] text-[#2C1762]" : ""} pl-4 pr-4 lg:pr-16 lg:py-3.5 py-2 mb-4 text[#6F6C90] rounded-lg text-sm cursor-pointer`}
            >Health Insurance</div>
            
          </div>
 
          <div className=" lg:mb-20 lg:basis-8/12 basis-full">
          {/* BUS TICKET FAQ */}
          <div className={openTestTab === 1 ? "block" : "hidden"}>
          <Accordion allowZeroExpanded allowMultipleExpanded>
                      <AccordionItem className='bg-white border border-[#209D5A] px-3 rounded-[10px] mb-3'>
                        <AccordionItemHeading>
                          <AccordionItemButton className=' py-[14px] mb-1 text-[15px] text-[#777777] flex justify-between'>
                            <span>How do I access AfriPay's Bus Ticket Service?</span>
                            <span><svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M13.5 10.2485H9.75V13.9985C9.75 14.1974 9.67098 14.3882 9.53033 14.5288C9.38968 14.6695 9.19891 14.7485 9 14.7485C8.80109 14.7485 8.61032 14.6695 8.46967 14.5288C8.32902 14.3882 8.25 14.1974 8.25 13.9985V10.2485H4.5C4.30109 10.2485 4.11032 10.1695 3.96967 10.0288C3.82902 9.88818 3.75 9.69742 3.75 9.49851C3.75 9.29959 3.82902 9.10883 3.96967 8.96817C4.11032 8.82752 4.30109 8.74851 4.5 8.74851H8.25V4.9985C8.25 4.79959 8.32902 4.60883 8.46967 4.46817C8.61032 4.32752 8.80109 4.2485 9 4.2485C9.19891 4.2485 9.38968 4.32752 9.53033 4.46817C9.67098 4.60883 9.75 4.79959 9.75 4.9985V8.74851H13.5C13.6989 8.74851 13.8897 8.82752 14.0303 8.96817C14.171 9.10883 14.25 9.29959 14.25 9.49851C14.25 9.69742 14.171 9.88818 14.0303 10.0288C13.8897 10.1695 13.6989 10.2485 13.5 10.2485Z" fill="black" />
                            </svg>
                            </span>
                          </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel className='pb-6 text-[13px] text-[#777777]'>
                          <p>
                          Simply log in to your AfriPay account securely and navigate to the "Bus Tickets" section to start booking your bus tickets effortlessly.
                          </p>
                        </AccordionItemPanel>
                      </AccordionItem>
                      <AccordionItem className='bg-white border border-[#209D5A] px-3 rounded-[10px] mb-3'>
                        <AccordionItemHeading>
                          <AccordionItemButton className=' py-[14px] mb-1 text-[15px] text-[#777777] flex justify-between'>
                            <span>Can I book bus tickets for any destination using AfriPay?</span>
                            <span><svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M13.5 10.2485H9.75V13.9985C9.75 14.1974 9.67098 14.3882 9.53033 14.5288C9.38968 14.6695 9.19891 14.7485 9 14.7485C8.80109 14.7485 8.61032 14.6695 8.46967 14.5288C8.32902 14.3882 8.25 14.1974 8.25 13.9985V10.2485H4.5C4.30109 10.2485 4.11032 10.1695 3.96967 10.0288C3.82902 9.88818 3.75 9.69742 3.75 9.49851C3.75 9.29959 3.82902 9.10883 3.96967 8.96817C4.11032 8.82752 4.30109 8.74851 4.5 8.74851H8.25V4.9985C8.25 4.79959 8.32902 4.60883 8.46967 4.46817C8.61032 4.32752 8.80109 4.2485 9 4.2485C9.19891 4.2485 9.38968 4.32752 9.53033 4.46817C9.67098 4.60883 9.75 4.79959 9.75 4.9985V8.74851H13.5C13.6989 8.74851 13.8897 8.82752 14.0303 8.96817C14.171 9.10883 14.25 9.29959 14.25 9.49851C14.25 9.69742 14.171 9.88818 14.0303 10.0288C13.8897 10.1695 13.6989 10.2485 13.5 10.2485Z" fill="black" />
                            </svg>
                            </span>
                          </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel className='pb-6 text-[13px] text-[#777777]'>
                          <p>
                          Yes, AfriPay's Bus Ticket Service provides access to a wide range of routes and operators, allowing you to book tickets for various destinations.
                          </p>
                        </AccordionItemPanel>
                      </AccordionItem>
                      <AccordionItem className='bg-white border border-[#209D5A] px-3 rounded-[10px] mb-3'>
                        <AccordionItemHeading>
                          <AccordionItemButton className=' py-[14px] mb-1 text-[15px] text-[#777777] flex justify-between'>
                            <span>How can I check seat availability for my desired bus route?</span>
                            <span><svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M13.5 10.2485H9.75V13.9985C9.75 14.1974 9.67098 14.3882 9.53033 14.5288C9.38968 14.6695 9.19891 14.7485 9 14.7485C8.80109 14.7485 8.61032 14.6695 8.46967 14.5288C8.32902 14.3882 8.25 14.1974 8.25 13.9985V10.2485H4.5C4.30109 10.2485 4.11032 10.1695 3.96967 10.0288C3.82902 9.88818 3.75 9.69742 3.75 9.49851C3.75 9.29959 3.82902 9.10883 3.96967 8.96817C4.11032 8.82752 4.30109 8.74851 4.5 8.74851H8.25V4.9985C8.25 4.79959 8.32902 4.60883 8.46967 4.46817C8.61032 4.32752 8.80109 4.2485 9 4.2485C9.19891 4.2485 9.38968 4.32752 9.53033 4.46817C9.67098 4.60883 9.75 4.79959 9.75 4.9985V8.74851H13.5C13.6989 8.74851 13.8897 8.82752 14.0303 8.96817C14.171 9.10883 14.25 9.29959 14.25 9.49851C14.25 9.69742 14.171 9.88818 14.0303 10.0288C13.8897 10.1695 13.6989 10.2485 13.5 10.2485Z" fill="black" />
                            </svg>
                            </span>
                          </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel className='pb-6 text-[13px] text-[#777777]'>
                          <p>
                          With AfriPay's Bus Ticket Service, you can check real-time seat availability instantly before making your booking.
                          </p>
                        </AccordionItemPanel>
                      </AccordionItem> 
                      <AccordionItem className='bg-white border border-[#209D5A] px-3 rounded-[10px] mb-3'>
                        <AccordionItemHeading>
                          <AccordionItemButton className=' py-[14px] mb-1 text-[15px] text-[#777777] flex justify-between'>
                            <span>Is it safe to make payments for bus tickets through AfriPay?</span>
                            <span><svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M13.5 10.2485H9.75V13.9985C9.75 14.1974 9.67098 14.3882 9.53033 14.5288C9.38968 14.6695 9.19891 14.7485 9 14.7485C8.80109 14.7485 8.61032 14.6695 8.46967 14.5288C8.32902 14.3882 8.25 14.1974 8.25 13.9985V10.2485H4.5C4.30109 10.2485 4.11032 10.1695 3.96967 10.0288C3.82902 9.88818 3.75 9.69742 3.75 9.49851C3.75 9.29959 3.82902 9.10883 3.96967 8.96817C4.11032 8.82752 4.30109 8.74851 4.5 8.74851H8.25V4.9985C8.25 4.79959 8.32902 4.60883 8.46967 4.46817C8.61032 4.32752 8.80109 4.2485 9 4.2485C9.19891 4.2485 9.38968 4.32752 9.53033 4.46817C9.67098 4.60883 9.75 4.79959 9.75 4.9985V8.74851H13.5C13.6989 8.74851 13.8897 8.82752 14.0303 8.96817C14.171 9.10883 14.25 9.29959 14.25 9.49851C14.25 9.69742 14.171 9.88818 14.0303 10.0288C13.8897 10.1695 13.6989 10.2485 13.5 10.2485Z" fill="black" />
                            </svg>
                            </span>
                          </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel className='pb-6 text-[13px] text-[#777777]'>
                          <p>
                          Yes, AfriPay ensures secure payments by encrypting your transactions, providing you with confidence and peace of mind during the booking process.
                          </p>
                        </AccordionItemPanel>
                      </AccordionItem> 
                      <AccordionItem className='bg-white border border-[#209D5A] px-3 rounded-[10px] mb-3'>
                        <AccordionItemHeading>
                          <AccordionItemButton className=' py-[14px] mb-1 text-[15px] text-[#777777] flex justify-between'>
                            <span>What are the benefits of using AfriPay's Bus Ticket Service?</span>
                            <span><svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M13.5 10.2485H9.75V13.9985C9.75 14.1974 9.67098 14.3882 9.53033 14.5288C9.38968 14.6695 9.19891 14.7485 9 14.7485C8.80109 14.7485 8.61032 14.6695 8.46967 14.5288C8.32902 14.3882 8.25 14.1974 8.25 13.9985V10.2485H4.5C4.30109 10.2485 4.11032 10.1695 3.96967 10.0288C3.82902 9.88818 3.75 9.69742 3.75 9.49851C3.75 9.29959 3.82902 9.10883 3.96967 8.96817C4.11032 8.82752 4.30109 8.74851 4.5 8.74851H8.25V4.9985C8.25 4.79959 8.32902 4.60883 8.46967 4.46817C8.61032 4.32752 8.80109 4.2485 9 4.2485C9.19891 4.2485 9.38968 4.32752 9.53033 4.46817C9.67098 4.60883 9.75 4.79959 9.75 4.9985V8.74851H13.5C13.6989 8.74851 13.8897 8.82752 14.0303 8.96817C14.171 9.10883 14.25 9.29959 14.25 9.49851C14.25 9.69742 14.171 9.88818 14.0303 10.0288C13.8897 10.1695 13.6989 10.2485 13.5 10.2485Z" fill="black" />
                            </svg>
                            </span>
                          </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel className='pb-6 text-[13px] text-[#777777]'>
                          <p>
                          Some benefits include saving time by skipping queues, transparent pricing with no hidden fees, a user-friendly interface for easy navigation, and 24/7 customer support for assistance.
                          </p>
                        </AccordionItemPanel>
                      </AccordionItem> 
                      <AccordionItem className='bg-white border border-[#209D5A] px-3 rounded-[10px] mb-3'>
                        <AccordionItemHeading>
                          <AccordionItemButton className=' py-[14px] mb-1 text-[15px] text-[#777777] flex justify-between'>
                            <span>How will I receive my bus tickets after booking?</span>
                            <span><svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M13.5 10.2485H9.75V13.9985C9.75 14.1974 9.67098 14.3882 9.53033 14.5288C9.38968 14.6695 9.19891 14.7485 9 14.7485C8.80109 14.7485 8.61032 14.6695 8.46967 14.5288C8.32902 14.3882 8.25 14.1974 8.25 13.9985V10.2485H4.5C4.30109 10.2485 4.11032 10.1695 3.96967 10.0288C3.82902 9.88818 3.75 9.69742 3.75 9.49851C3.75 9.29959 3.82902 9.10883 3.96967 8.96817C4.11032 8.82752 4.30109 8.74851 4.5 8.74851H8.25V4.9985C8.25 4.79959 8.32902 4.60883 8.46967 4.46817C8.61032 4.32752 8.80109 4.2485 9 4.2485C9.19891 4.2485 9.38968 4.32752 9.53033 4.46817C9.67098 4.60883 9.75 4.79959 9.75 4.9985V8.74851H13.5C13.6989 8.74851 13.8897 8.82752 14.0303 8.96817C14.171 9.10883 14.25 9.29959 14.25 9.49851C14.25 9.69742 14.171 9.88818 14.0303 10.0288C13.8897 10.1695 13.6989 10.2485 13.5 10.2485Z" fill="black" />
                            </svg>
                            </span>
                          </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel className='pb-6 text-[13px] text-[#777777]'>
                          <p>
                          AfriPay's Bus Ticket Service offers e-ticketing, allowing you to receive electronic tickets directly to your account for convenient access.
                          </p>
                        </AccordionItemPanel>
                      </AccordionItem>      
                      <AccordionItem className='bg-white border border-[#209D5A] px-3 rounded-[10px] mb-3'>
                        <AccordionItemHeading>
                          <AccordionItemButton className=' py-[14px] mb-1 text-[15px] text-[#777777] flex justify-between'>
                            <span>Can I cancel or modify my bus ticket booking through AfriPay?</span>
                            <span><svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M13.5 10.2485H9.75V13.9985C9.75 14.1974 9.67098 14.3882 9.53033 14.5288C9.38968 14.6695 9.19891 14.7485 9 14.7485C8.80109 14.7485 8.61032 14.6695 8.46967 14.5288C8.32902 14.3882 8.25 14.1974 8.25 13.9985V10.2485H4.5C4.30109 10.2485 4.11032 10.1695 3.96967 10.0288C3.82902 9.88818 3.75 9.69742 3.75 9.49851C3.75 9.29959 3.82902 9.10883 3.96967 8.96817C4.11032 8.82752 4.30109 8.74851 4.5 8.74851H8.25V4.9985C8.25 4.79959 8.32902 4.60883 8.46967 4.46817C8.61032 4.32752 8.80109 4.2485 9 4.2485C9.19891 4.2485 9.38968 4.32752 9.53033 4.46817C9.67098 4.60883 9.75 4.79959 9.75 4.9985V8.74851H13.5C13.6989 8.74851 13.8897 8.82752 14.0303 8.96817C14.171 9.10883 14.25 9.29959 14.25 9.49851C14.25 9.69742 14.171 9.88818 14.0303 10.0288C13.8897 10.1695 13.6989 10.2485 13.5 10.2485Z" fill="black" />
                            </svg>
                            </span>
                          </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel className='pb-6 text-[13px] text-[#777777]'>
                          <p>
                          Yes, you can easily manage your bus ticket bookings through AfriPay's platform. Simply log in to your account, navigate to your booking, and follow the prompts to cancel or modify your booking as needed.
                          </p>
                        </AccordionItemPanel>
                      </AccordionItem> 
                      <AccordionItem className='bg-white border border-[#209D5A] px-3 rounded-[10px] mb-3'>
                        <AccordionItemHeading>
                          <AccordionItemButton className=' py-[14px] mb-1 text-[15px] text-[#777777] flex justify-between'>
                            <span>Are there any additional fees associated with booking bus tickets through AfriPay?</span>
                            <span><svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M13.5 10.2485H9.75V13.9985C9.75 14.1974 9.67098 14.3882 9.53033 14.5288C9.38968 14.6695 9.19891 14.7485 9 14.7485C8.80109 14.7485 8.61032 14.6695 8.46967 14.5288C8.32902 14.3882 8.25 14.1974 8.25 13.9985V10.2485H4.5C4.30109 10.2485 4.11032 10.1695 3.96967 10.0288C3.82902 9.88818 3.75 9.69742 3.75 9.49851C3.75 9.29959 3.82902 9.10883 3.96967 8.96817C4.11032 8.82752 4.30109 8.74851 4.5 8.74851H8.25V4.9985C8.25 4.79959 8.32902 4.60883 8.46967 4.46817C8.61032 4.32752 8.80109 4.2485 9 4.2485C9.19891 4.2485 9.38968 4.32752 9.53033 4.46817C9.67098 4.60883 9.75 4.79959 9.75 4.9985V8.74851H13.5C13.6989 8.74851 13.8897 8.82752 14.0303 8.96817C14.171 9.10883 14.25 9.29959 14.25 9.49851C14.25 9.69742 14.171 9.88818 14.0303 10.0288C13.8897 10.1695 13.6989 10.2485 13.5 10.2485Z" fill="black" />
                            </svg>
                            </span>
                          </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel className='pb-6 text-[13px] text-[#777777]'>
                          <p>
                          AfriPay ensures transparent pricing with clear upfront prices, meaning there are no hidden fees associated with booking bus tickets through our platform.
                          </p>
                        </AccordionItemPanel>
                      </AccordionItem> 
                      <AccordionItem className='bg-white border border-[#209D5A] px-3 rounded-[10px] mb-3'>
                        <AccordionItemHeading>
                          <AccordionItemButton className=' py-[14px] mb-1 text-[15px] text-[#777777] flex justify-between'>
                            <span>How far in advance can I book bus tickets through AfriPay?</span>
                            <span><svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M13.5 10.2485H9.75V13.9985C9.75 14.1974 9.67098 14.3882 9.53033 14.5288C9.38968 14.6695 9.19891 14.7485 9 14.7485C8.80109 14.7485 8.61032 14.6695 8.46967 14.5288C8.32902 14.3882 8.25 14.1974 8.25 13.9985V10.2485H4.5C4.30109 10.2485 4.11032 10.1695 3.96967 10.0288C3.82902 9.88818 3.75 9.69742 3.75 9.49851C3.75 9.29959 3.82902 9.10883 3.96967 8.96817C4.11032 8.82752 4.30109 8.74851 4.5 8.74851H8.25V4.9985C8.25 4.79959 8.32902 4.60883 8.46967 4.46817C8.61032 4.32752 8.80109 4.2485 9 4.2485C9.19891 4.2485 9.38968 4.32752 9.53033 4.46817C9.67098 4.60883 9.75 4.79959 9.75 4.9985V8.74851H13.5C13.6989 8.74851 13.8897 8.82752 14.0303 8.96817C14.171 9.10883 14.25 9.29959 14.25 9.49851C14.25 9.69742 14.171 9.88818 14.0303 10.0288C13.8897 10.1695 13.6989 10.2485 13.5 10.2485Z" fill="black" />
                            </svg>
                            </span>
                          </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel className='pb-6 text-[13px] text-[#777777]'>
                          <p>
                          The booking window for bus tickets may vary depending on the route and operator. We recommend checking availability closer to your desired travel date for the best options.
                          </p>
                        </AccordionItemPanel>
                      </AccordionItem> 
                      <AccordionItem className='bg-white border border-[#209D5A] px-3 rounded-[10px] mb-3'>
                        <AccordionItemHeading>
                          <AccordionItemButton className=' py-[14px] mb-1 text-[15px] text-[#777777] flex justify-between'>
                            <span>What if I encounter any issues or have questions while booking bus tickets through AfriPay?</span>
                            <span><svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M13.5 10.2485H9.75V13.9985C9.75 14.1974 9.67098 14.3882 9.53033 14.5288C9.38968 14.6695 9.19891 14.7485 9 14.7485C8.80109 14.7485 8.61032 14.6695 8.46967 14.5288C8.32902 14.3882 8.25 14.1974 8.25 13.9985V10.2485H4.5C4.30109 10.2485 4.11032 10.1695 3.96967 10.0288C3.82902 9.88818 3.75 9.69742 3.75 9.49851C3.75 9.29959 3.82902 9.10883 3.96967 8.96817C4.11032 8.82752 4.30109 8.74851 4.5 8.74851H8.25V4.9985C8.25 4.79959 8.32902 4.60883 8.46967 4.46817C8.61032 4.32752 8.80109 4.2485 9 4.2485C9.19891 4.2485 9.38968 4.32752 9.53033 4.46817C9.67098 4.60883 9.75 4.79959 9.75 4.9985V8.74851H13.5C13.6989 8.74851 13.8897 8.82752 14.0303 8.96817C14.171 9.10883 14.25 9.29959 14.25 9.49851C14.25 9.69742 14.171 9.88818 14.0303 10.0288C13.8897 10.1695 13.6989 10.2485 13.5 10.2485Z" fill="black" />
                            </svg>
                            </span>
                          </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel className='pb-6 text-[13px] text-[#777777]'>
                          <p>
                          Our dedicated customer support team is available 24/7 to assist you with any inquiries or concerns you may have during the booking process. Simply reach out for prompt assistance.
                          </p>
                        </AccordionItemPanel>
                      </AccordionItem> 
                    </Accordion>
          </div>
          {/* VISA PROCESSING FAQ */}
          <div className={openTestTab === 2 ? "block" : "hidden"}>
          <Accordion allowZeroExpanded allowMultipleExpanded>
                      <AccordionItem className='bg-white border border-[#209D5A] px-3 rounded-[10px] mb-3'>
                        <AccordionItemHeading>
                          <AccordionItemButton className=' py-[14px] mb-1 text-[15px] text-[#777777] flex justify-between'>
                            <span>How do I access AfriPay's Visa Processing Service?</span>
                            <span><svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M13.5 10.2485H9.75V13.9985C9.75 14.1974 9.67098 14.3882 9.53033 14.5288C9.38968 14.6695 9.19891 14.7485 9 14.7485C8.80109 14.7485 8.61032 14.6695 8.46967 14.5288C8.32902 14.3882 8.25 14.1974 8.25 13.9985V10.2485H4.5C4.30109 10.2485 4.11032 10.1695 3.96967 10.0288C3.82902 9.88818 3.75 9.69742 3.75 9.49851C3.75 9.29959 3.82902 9.10883 3.96967 8.96817C4.11032 8.82752 4.30109 8.74851 4.5 8.74851H8.25V4.9985C8.25 4.79959 8.32902 4.60883 8.46967 4.46817C8.61032 4.32752 8.80109 4.2485 9 4.2485C9.19891 4.2485 9.38968 4.32752 9.53033 4.46817C9.67098 4.60883 9.75 4.79959 9.75 4.9985V8.74851H13.5C13.6989 8.74851 13.8897 8.82752 14.0303 8.96817C14.171 9.10883 14.25 9.29959 14.25 9.49851C14.25 9.69742 14.171 9.88818 14.0303 10.0288C13.8897 10.1695 13.6989 10.2485 13.5 10.2485Z" fill="black" />
                            </svg>
                            </span>
                          </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel className='pb-6 text-[13px] text-[#777777]'>
                          <p>
                          Simply log in to your AfriPay account securely and navigate to the "Visa Processing" section to begin your visa application process.
                          </p>
                        </AccordionItemPanel>
                      </AccordionItem>
                      <AccordionItem className='bg-white border border-[#209D5A] px-3 rounded-[10px] mb-3'>
                        <AccordionItemHeading>
                          <AccordionItemButton className=' py-[14px] mb-1 text-[15px] text-[#777777] flex justify-between'>
                            <span>What types of visas can I apply for through AfriPay's Visa Processing Service?</span>
                            <span><svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M13.5 10.2485H9.75V13.9985C9.75 14.1974 9.67098 14.3882 9.53033 14.5288C9.38968 14.6695 9.19891 14.7485 9 14.7485C8.80109 14.7485 8.61032 14.6695 8.46967 14.5288C8.32902 14.3882 8.25 14.1974 8.25 13.9985V10.2485H4.5C4.30109 10.2485 4.11032 10.1695 3.96967 10.0288C3.82902 9.88818 3.75 9.69742 3.75 9.49851C3.75 9.29959 3.82902 9.10883 3.96967 8.96817C4.11032 8.82752 4.30109 8.74851 4.5 8.74851H8.25V4.9985C8.25 4.79959 8.32902 4.60883 8.46967 4.46817C8.61032 4.32752 8.80109 4.2485 9 4.2485C9.19891 4.2485 9.38968 4.32752 9.53033 4.46817C9.67098 4.60883 9.75 4.79959 9.75 4.9985V8.74851H13.5C13.6989 8.74851 13.8897 8.82752 14.0303 8.96817C14.171 9.10883 14.25 9.29959 14.25 9.49851C14.25 9.69742 14.171 9.88818 14.0303 10.0288C13.8897 10.1695 13.6989 10.2485 13.5 10.2485Z" fill="black" />
                            </svg>
                            </span>
                          </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel className='pb-6 text-[13px] text-[#777777]'>
                          <p>
                          AfriPay offers support for various types of visas, including tourist visas, business visas, student visas, and more, depending on your destination and travel purposes.
                          </p>
                        </AccordionItemPanel>
                      </AccordionItem>
                      <AccordionItem className='bg-white border border-[#209D5A] px-3 rounded-[10px] mb-3'>
                        <AccordionItemHeading>
                          <AccordionItemButton className=' py-[14px] mb-1 text-[15px] text-[#777777] flex justify-between'>
                            <span>Can AfriPay assist me with visa applications for any country?</span>
                            <span><svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M13.5 10.2485H9.75V13.9985C9.75 14.1974 9.67098 14.3882 9.53033 14.5288C9.38968 14.6695 9.19891 14.7485 9 14.7485C8.80109 14.7485 8.61032 14.6695 8.46967 14.5288C8.32902 14.3882 8.25 14.1974 8.25 13.9985V10.2485H4.5C4.30109 10.2485 4.11032 10.1695 3.96967 10.0288C3.82902 9.88818 3.75 9.69742 3.75 9.49851C3.75 9.29959 3.82902 9.10883 3.96967 8.96817C4.11032 8.82752 4.30109 8.74851 4.5 8.74851H8.25V4.9985C8.25 4.79959 8.32902 4.60883 8.46967 4.46817C8.61032 4.32752 8.80109 4.2485 9 4.2485C9.19891 4.2485 9.38968 4.32752 9.53033 4.46817C9.67098 4.60883 9.75 4.79959 9.75 4.9985V8.74851H13.5C13.6989 8.74851 13.8897 8.82752 14.0303 8.96817C14.171 9.10883 14.25 9.29959 14.25 9.49851C14.25 9.69742 14.171 9.88818 14.0303 10.0288C13.8897 10.1695 13.6989 10.2485 13.5 10.2485Z" fill="black" />
                            </svg>
                            </span>
                          </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel className='pb-6 text-[13px] text-[#777777]'>
                          <p>
                          Yes, AfriPay's Visa Processing Service can assist you with visa applications for a wide range of countries worldwide.
                          </p>
                        </AccordionItemPanel>
                      </AccordionItem>
                      <AccordionItem className='bg-white border border-[#209D5A] px-3 rounded-[10px] mb-3'>
                        <AccordionItemHeading>
                          <AccordionItemButton className=' py-[14px] mb-1 text-[15px] text-[#777777] flex justify-between'>
                            <span>How long does the visa application process take through AfriPay?</span>
                            <span><svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M13.5 10.2485H9.75V13.9985C9.75 14.1974 9.67098 14.3882 9.53033 14.5288C9.38968 14.6695 9.19891 14.7485 9 14.7485C8.80109 14.7485 8.61032 14.6695 8.46967 14.5288C8.32902 14.3882 8.25 14.1974 8.25 13.9985V10.2485H4.5C4.30109 10.2485 4.11032 10.1695 3.96967 10.0288C3.82902 9.88818 3.75 9.69742 3.75 9.49851C3.75 9.29959 3.82902 9.10883 3.96967 8.96817C4.11032 8.82752 4.30109 8.74851 4.5 8.74851H8.25V4.9985C8.25 4.79959 8.32902 4.60883 8.46967 4.46817C8.61032 4.32752 8.80109 4.2485 9 4.2485C9.19891 4.2485 9.38968 4.32752 9.53033 4.46817C9.67098 4.60883 9.75 4.79959 9.75 4.9985V8.74851H13.5C13.6989 8.74851 13.8897 8.82752 14.0303 8.96817C14.171 9.10883 14.25 9.29959 14.25 9.49851C14.25 9.69742 14.171 9.88818 14.0303 10.0288C13.8897 10.1695 13.6989 10.2485 13.5 10.2485Z" fill="black" />
                            </svg>
                            </span>
                          </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel className='pb-6 text-[13px] text-[#777777]'>
                          <p>
                          The duration of the visa application process varies depending on the country and type of visa. Our team will provide you with an estimated timeline based on your specific application.
                          </p>
                        </AccordionItemPanel>
                      </AccordionItem>
                      <AccordionItem className='bg-white border border-[#209D5A] px-3 rounded-[10px] mb-3'>
                        <AccordionItemHeading>
                          <AccordionItemButton className=' py-[14px] mb-1 text-[15px] text-[#777777] flex justify-between'>
                            <span>What documents are required for the visa application process?</span>
                            <span><svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M13.5 10.2485H9.75V13.9985C9.75 14.1974 9.67098 14.3882 9.53033 14.5288C9.38968 14.6695 9.19891 14.7485 9 14.7485C8.80109 14.7485 8.61032 14.6695 8.46967 14.5288C8.32902 14.3882 8.25 14.1974 8.25 13.9985V10.2485H4.5C4.30109 10.2485 4.11032 10.1695 3.96967 10.0288C3.82902 9.88818 3.75 9.69742 3.75 9.49851C3.75 9.29959 3.82902 9.10883 3.96967 8.96817C4.11032 8.82752 4.30109 8.74851 4.5 8.74851H8.25V4.9985C8.25 4.79959 8.32902 4.60883 8.46967 4.46817C8.61032 4.32752 8.80109 4.2485 9 4.2485C9.19891 4.2485 9.38968 4.32752 9.53033 4.46817C9.67098 4.60883 9.75 4.79959 9.75 4.9985V8.74851H13.5C13.6989 8.74851 13.8897 8.82752 14.0303 8.96817C14.171 9.10883 14.25 9.29959 14.25 9.49851C14.25 9.69742 14.171 9.88818 14.0303 10.0288C13.8897 10.1695 13.6989 10.2485 13.5 10.2485Z" fill="black" />
                            </svg>
                            </span>
                          </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel className='pb-6 text-[13px] text-[#777777]'>
                          <p>
                          AfriPay provides a comprehensive document checklist to guide you through the visa application process. Required documents may include passport copies, photographs, financial statements, and invitation letters, among others.
                          </p>
                        </AccordionItemPanel>
                      </AccordionItem>
                      <AccordionItem className='bg-white border border-[#209D5A] px-3 rounded-[10px] mb-3'>
                        <AccordionItemHeading>
                          <AccordionItemButton className=' py-[14px] mb-1 text-[15px] text-[#777777] flex justify-between'>
                            <span>Can I track the status of my visa application in real-time?</span>
                            <span><svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M13.5 10.2485H9.75V13.9985C9.75 14.1974 9.67098 14.3882 9.53033 14.5288C9.38968 14.6695 9.19891 14.7485 9 14.7485C8.80109 14.7485 8.61032 14.6695 8.46967 14.5288C8.32902 14.3882 8.25 14.1974 8.25 13.9985V10.2485H4.5C4.30109 10.2485 4.11032 10.1695 3.96967 10.0288C3.82902 9.88818 3.75 9.69742 3.75 9.49851C3.75 9.29959 3.82902 9.10883 3.96967 8.96817C4.11032 8.82752 4.30109 8.74851 4.5 8.74851H8.25V4.9985C8.25 4.79959 8.32902 4.60883 8.46967 4.46817C8.61032 4.32752 8.80109 4.2485 9 4.2485C9.19891 4.2485 9.38968 4.32752 9.53033 4.46817C9.67098 4.60883 9.75 4.79959 9.75 4.9985V8.74851H13.5C13.6989 8.74851 13.8897 8.82752 14.0303 8.96817C14.171 9.10883 14.25 9.29959 14.25 9.49851C14.25 9.69742 14.171 9.88818 14.0303 10.0288C13.8897 10.1695 13.6989 10.2485 13.5 10.2485Z" fill="black" />
                            </svg>
                            </span>
                          </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel className='pb-6 text-[13px] text-[#777777]'>
                          <p>
                          Yes, AfriPay's Visa Processing Service offers real-time updates, allowing you to monitor the progress of your application conveniently through your AfriPay account.
                          </p>
                        </AccordionItemPanel>
                      </AccordionItem>
                      <AccordionItem className='bg-white border border-[#209D5A] px-3 rounded-[10px] mb-3'>
                        <AccordionItemHeading>
                          <AccordionItemButton className=' py-[14px] mb-1 text-[15px] text-[#777777] flex justify-between'>
                            <span>Is it safe to upload my documents through AfriPay's platform?</span>
                            <span><svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M13.5 10.2485H9.75V13.9985C9.75 14.1974 9.67098 14.3882 9.53033 14.5288C9.38968 14.6695 9.19891 14.7485 9 14.7485C8.80109 14.7485 8.61032 14.6695 8.46967 14.5288C8.32902 14.3882 8.25 14.1974 8.25 13.9985V10.2485H4.5C4.30109 10.2485 4.11032 10.1695 3.96967 10.0288C3.82902 9.88818 3.75 9.69742 3.75 9.49851C3.75 9.29959 3.82902 9.10883 3.96967 8.96817C4.11032 8.82752 4.30109 8.74851 4.5 8.74851H8.25V4.9985C8.25 4.79959 8.32902 4.60883 8.46967 4.46817C8.61032 4.32752 8.80109 4.2485 9 4.2485C9.19891 4.2485 9.38968 4.32752 9.53033 4.46817C9.67098 4.60883 9.75 4.79959 9.75 4.9985V8.74851H13.5C13.6989 8.74851 13.8897 8.82752 14.0303 8.96817C14.171 9.10883 14.25 9.29959 14.25 9.49851C14.25 9.69742 14.171 9.88818 14.0303 10.0288C13.8897 10.1695 13.6989 10.2485 13.5 10.2485Z" fill="black" />
                            </svg>
                            </span>
                          </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel className='pb-6 text-[13px] text-[#777777]'>
                          <p>
                          Yes, AfriPay ensures secure document upload by employing advanced encryption technology to protect your personal information throughout the application process.
                          </p>
                        </AccordionItemPanel>
                      </AccordionItem>
                      <AccordionItem className='bg-white border border-[#209D5A] px-3 rounded-[10px] mb-3'>
                        <AccordionItemHeading>
                          <AccordionItemButton className=' py-[14px] mb-1 text-[15px] text-[#777777] flex justify-between'>
                            <span>How much does AfriPay's Visa Processing Service cost?</span>
                            <span><svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M13.5 10.2485H9.75V13.9985C9.75 14.1974 9.67098 14.3882 9.53033 14.5288C9.38968 14.6695 9.19891 14.7485 9 14.7485C8.80109 14.7485 8.61032 14.6695 8.46967 14.5288C8.32902 14.3882 8.25 14.1974 8.25 13.9985V10.2485H4.5C4.30109 10.2485 4.11032 10.1695 3.96967 10.0288C3.82902 9.88818 3.75 9.69742 3.75 9.49851C3.75 9.29959 3.82902 9.10883 3.96967 8.96817C4.11032 8.82752 4.30109 8.74851 4.5 8.74851H8.25V4.9985C8.25 4.79959 8.32902 4.60883 8.46967 4.46817C8.61032 4.32752 8.80109 4.2485 9 4.2485C9.19891 4.2485 9.38968 4.32752 9.53033 4.46817C9.67098 4.60883 9.75 4.79959 9.75 4.9985V8.74851H13.5C13.6989 8.74851 13.8897 8.82752 14.0303 8.96817C14.171 9.10883 14.25 9.29959 14.25 9.49851C14.25 9.69742 14.171 9.88818 14.0303 10.0288C13.8897 10.1695 13.6989 10.2485 13.5 10.2485Z" fill="black" />
                            </svg>
                            </span>
                          </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel className='pb-6 text-[13px] text-[#777777]'>
                          <p>
                          AfriPay offers transparent pricing with clear visibility into visa processing fees. The cost varies depending on the destination and type of visa you're applying for, and it will be communicated to you before proceeding with the application.
                          </p>
                        </AccordionItemPanel>
                      </AccordionItem>
                      <AccordionItem className='bg-white border border-[#209D5A] px-3 rounded-[10px] mb-3'>
                        <AccordionItemHeading>
                          <AccordionItemButton className=' py-[14px] mb-1 text-[15px] text-[#777777] flex justify-between'>
                            <span>Can I receive expert guidance during the visa application process?</span>
                            <span><svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M13.5 10.2485H9.75V13.9985C9.75 14.1974 9.67098 14.3882 9.53033 14.5288C9.38968 14.6695 9.19891 14.7485 9 14.7485C8.80109 14.7485 8.61032 14.6695 8.46967 14.5288C8.32902 14.3882 8.25 14.1974 8.25 13.9985V10.2485H4.5C4.30109 10.2485 4.11032 10.1695 3.96967 10.0288C3.82902 9.88818 3.75 9.69742 3.75 9.49851C3.75 9.29959 3.82902 9.10883 3.96967 8.96817C4.11032 8.82752 4.30109 8.74851 4.5 8.74851H8.25V4.9985C8.25 4.79959 8.32902 4.60883 8.46967 4.46817C8.61032 4.32752 8.80109 4.2485 9 4.2485C9.19891 4.2485 9.38968 4.32752 9.53033 4.46817C9.67098 4.60883 9.75 4.79959 9.75 4.9985V8.74851H13.5C13.6989 8.74851 13.8897 8.82752 14.0303 8.96817C14.171 9.10883 14.25 9.29959 14.25 9.49851C14.25 9.69742 14.171 9.88818 14.0303 10.0288C13.8897 10.1695 13.6989 10.2485 13.5 10.2485Z" fill="black" />
                            </svg>
                            </span>
                          </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel className='pb-6 text-[13px] text-[#777777]'>
                          <p>
                          Yes, AfriPay's Visa Processing Service includes visa consultation, providing you with expert guidance and support to ensure a smooth and successful application process.
                          </p>
                        </AccordionItemPanel>
                      </AccordionItem>
                      <AccordionItem className='bg-white border border-[#209D5A] px-3 rounded-[10px] mb-3'>
                        <AccordionItemHeading>
                          <AccordionItemButton className=' py-[14px] mb-1 text-[15px] text-[#777777] flex justify-between'>
                            <span>What if I encounter difficulties or have questions during the visa application process?</span>
                            <span><svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M13.5 10.2485H9.75V13.9985C9.75 14.1974 9.67098 14.3882 9.53033 14.5288C9.38968 14.6695 9.19891 14.7485 9 14.7485C8.80109 14.7485 8.61032 14.6695 8.46967 14.5288C8.32902 14.3882 8.25 14.1974 8.25 13.9985V10.2485H4.5C4.30109 10.2485 4.11032 10.1695 3.96967 10.0288C3.82902 9.88818 3.75 9.69742 3.75 9.49851C3.75 9.29959 3.82902 9.10883 3.96967 8.96817C4.11032 8.82752 4.30109 8.74851 4.5 8.74851H8.25V4.9985C8.25 4.79959 8.32902 4.60883 8.46967 4.46817C8.61032 4.32752 8.80109 4.2485 9 4.2485C9.19891 4.2485 9.38968 4.32752 9.53033 4.46817C9.67098 4.60883 9.75 4.79959 9.75 4.9985V8.74851H13.5C13.6989 8.74851 13.8897 8.82752 14.0303 8.96817C14.171 9.10883 14.25 9.29959 14.25 9.49851C14.25 9.69742 14.171 9.88818 14.0303 10.0288C13.8897 10.1695 13.6989 10.2485 13.5 10.2485Z" fill="black" />
                            </svg>
                            </span>
                          </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel className='pb-6 text-[13px] text-[#777777]'>
                          <p>
                          Our team is dedicated to providing personalized support tailored to meet your specific visa needs. If you have any questions or encounter difficulties, feel free to reach out to our support team for assistance at any time.
                          </p>
                        </AccordionItemPanel>
                      </AccordionItem>      
                    </Accordion>
          </div>
          {/* FLIGHT BOOKING */}
          <div className={openTestTab === 3 ? "block" : "hidden"}>
          <Accordion allowZeroExpanded allowMultipleExpanded>
                      <AccordionItem className='bg-white border border-[#209D5A] px-3 rounded-[10px] mb-3'>
                        <AccordionItemHeading>
                          <AccordionItemButton className=' py-[14px] mb-1 text-[15px] text-[#777777] flex justify-between'>
                            <span>How do I access AfriPay's Flight Booking service?</span>
                            <span><svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M13.5 10.2485H9.75V13.9985C9.75 14.1974 9.67098 14.3882 9.53033 14.5288C9.38968 14.6695 9.19891 14.7485 9 14.7485C8.80109 14.7485 8.61032 14.6695 8.46967 14.5288C8.32902 14.3882 8.25 14.1974 8.25 13.9985V10.2485H4.5C4.30109 10.2485 4.11032 10.1695 3.96967 10.0288C3.82902 9.88818 3.75 9.69742 3.75 9.49851C3.75 9.29959 3.82902 9.10883 3.96967 8.96817C4.11032 8.82752 4.30109 8.74851 4.5 8.74851H8.25V4.9985C8.25 4.79959 8.32902 4.60883 8.46967 4.46817C8.61032 4.32752 8.80109 4.2485 9 4.2485C9.19891 4.2485 9.38968 4.32752 9.53033 4.46817C9.67098 4.60883 9.75 4.79959 9.75 4.9985V8.74851H13.5C13.6989 8.74851 13.8897 8.82752 14.0303 8.96817C14.171 9.10883 14.25 9.29959 14.25 9.49851C14.25 9.69742 14.171 9.88818 14.0303 10.0288C13.8897 10.1695 13.6989 10.2485 13.5 10.2485Z" fill="black" />
                            </svg>
                            </span>
                          </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel className='pb-6 text-[13px] text-[#777777]'>
                          <p>
                          You can access AfriPay's Flight Booking service by logging in to your account securely and selecting the "Flight Booking" option to begin your flight search.
                          </p>
                        </AccordionItemPanel>
                      </AccordionItem>
                      <AccordionItem className='bg-white border border-[#209D5A] px-3 rounded-[10px] mb-3'>
                        <AccordionItemHeading>
                          <AccordionItemButton className=' py-[14px] mb-1 text-[15px] text-[#777777] flex justify-between'>
                            <span>Can I book flights to any destination through AfriPay?</span>
                            <span><svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M13.5 10.2485H9.75V13.9985C9.75 14.1974 9.67098 14.3882 9.53033 14.5288C9.38968 14.6695 9.19891 14.7485 9 14.7485C8.80109 14.7485 8.61032 14.6695 8.46967 14.5288C8.32902 14.3882 8.25 14.1974 8.25 13.9985V10.2485H4.5C4.30109 10.2485 4.11032 10.1695 3.96967 10.0288C3.82902 9.88818 3.75 9.69742 3.75 9.49851C3.75 9.29959 3.82902 9.10883 3.96967 8.96817C4.11032 8.82752 4.30109 8.74851 4.5 8.74851H8.25V4.9985C8.25 4.79959 8.32902 4.60883 8.46967 4.46817C8.61032 4.32752 8.80109 4.2485 9 4.2485C9.19891 4.2485 9.38968 4.32752 9.53033 4.46817C9.67098 4.60883 9.75 4.79959 9.75 4.9985V8.74851H13.5C13.6989 8.74851 13.8897 8.82752 14.0303 8.96817C14.171 9.10883 14.25 9.29959 14.25 9.49851C14.25 9.69742 14.171 9.88818 14.0303 10.0288C13.8897 10.1695 13.6989 10.2485 13.5 10.2485Z" fill="black" />
                            </svg>
                            </span>
                          </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel className='pb-6 text-[13px] text-[#777777]'>
                          <p>
                          Yes, AfriPay's Flight Booking service provides access to a wide selection of flights to destinations worldwide, allowing you to choose from various airlines and routes.
                          </p>
                        </AccordionItemPanel>
                      </AccordionItem>
                      <AccordionItem className='bg-white border border-[#209D5A] px-3 rounded-[10px] mb-3'>
                        <AccordionItemHeading>
                          <AccordionItemButton className=' py-[14px] mb-1 text-[15px] text-[#777777] flex justify-between'>
                            <span>How can I search for flights using AfriPay?</span>
                            <span><svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M13.5 10.2485H9.75V13.9985C9.75 14.1974 9.67098 14.3882 9.53033 14.5288C9.38968 14.6695 9.19891 14.7485 9 14.7485C8.80109 14.7485 8.61032 14.6695 8.46967 14.5288C8.32902 14.3882 8.25 14.1974 8.25 13.9985V10.2485H4.5C4.30109 10.2485 4.11032 10.1695 3.96967 10.0288C3.82902 9.88818 3.75 9.69742 3.75 9.49851C3.75 9.29959 3.82902 9.10883 3.96967 8.96817C4.11032 8.82752 4.30109 8.74851 4.5 8.74851H8.25V4.9985C8.25 4.79959 8.32902 4.60883 8.46967 4.46817C8.61032 4.32752 8.80109 4.2485 9 4.2485C9.19891 4.2485 9.38968 4.32752 9.53033 4.46817C9.67098 4.60883 9.75 4.79959 9.75 4.9985V8.74851H13.5C13.6989 8.74851 13.8897 8.82752 14.0303 8.96817C14.171 9.10883 14.25 9.29959 14.25 9.49851C14.25 9.69742 14.171 9.88818 14.0303 10.0288C13.8897 10.1695 13.6989 10.2485 13.5 10.2485Z" fill="black" />
                            </svg>
                            </span>
                          </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel className='pb-6 text-[13px] text-[#777777]'>
                          <p>
                          Simply enter your travel details, including departure and destination cities, travel dates, and the number of passengers, to browse available flights from various airlines.
                          </p>
                        </AccordionItemPanel>
                      </AccordionItem>
                      <AccordionItem className='bg-white border border-[#209D5A] px-3 rounded-[10px] mb-3'>
                        <AccordionItemHeading>
                          <AccordionItemButton className=' py-[14px] mb-1 text-[15px] text-[#777777] flex justify-between'>
                            <span>Is it possible to check flight availability and book in real-time through AfriPay?</span>
                            <span><svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M13.5 10.2485H9.75V13.9985C9.75 14.1974 9.67098 14.3882 9.53033 14.5288C9.38968 14.6695 9.19891 14.7485 9 14.7485C8.80109 14.7485 8.61032 14.6695 8.46967 14.5288C8.32902 14.3882 8.25 14.1974 8.25 13.9985V10.2485H4.5C4.30109 10.2485 4.11032 10.1695 3.96967 10.0288C3.82902 9.88818 3.75 9.69742 3.75 9.49851C3.75 9.29959 3.82902 9.10883 3.96967 8.96817C4.11032 8.82752 4.30109 8.74851 4.5 8.74851H8.25V4.9985C8.25 4.79959 8.32902 4.60883 8.46967 4.46817C8.61032 4.32752 8.80109 4.2485 9 4.2485C9.19891 4.2485 9.38968 4.32752 9.53033 4.46817C9.67098 4.60883 9.75 4.79959 9.75 4.9985V8.74851H13.5C13.6989 8.74851 13.8897 8.82752 14.0303 8.96817C14.171 9.10883 14.25 9.29959 14.25 9.49851C14.25 9.69742 14.171 9.88818 14.0303 10.0288C13.8897 10.1695 13.6989 10.2485 13.5 10.2485Z" fill="black" />
                            </svg>
                            </span>
                          </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel className='pb-6 text-[13px] text-[#777777]'>
                          <p>
                          Yes, AfriPay's Flight Booking service offers real-time availability, allowing you to check flight availability instantly and book your preferred flights in real-time.
                          </p>
                        </AccordionItemPanel>
                      </AccordionItem>
                      <AccordionItem className='bg-white border border-[#209D5A] px-3 rounded-[10px] mb-3'>
                        <AccordionItemHeading>
                          <AccordionItemButton className=' py-[14px] mb-1 text-[15px] text-[#777777] flex justify-between'>
                            <span>What payment methods can I use to pay for my flight booking on AfriPay?</span>
                            <span><svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M13.5 10.2485H9.75V13.9985C9.75 14.1974 9.67098 14.3882 9.53033 14.5288C9.38968 14.6695 9.19891 14.7485 9 14.7485C8.80109 14.7485 8.61032 14.6695 8.46967 14.5288C8.32902 14.3882 8.25 14.1974 8.25 13.9985V10.2485H4.5C4.30109 10.2485 4.11032 10.1695 3.96967 10.0288C3.82902 9.88818 3.75 9.69742 3.75 9.49851C3.75 9.29959 3.82902 9.10883 3.96967 8.96817C4.11032 8.82752 4.30109 8.74851 4.5 8.74851H8.25V4.9985C8.25 4.79959 8.32902 4.60883 8.46967 4.46817C8.61032 4.32752 8.80109 4.2485 9 4.2485C9.19891 4.2485 9.38968 4.32752 9.53033 4.46817C9.67098 4.60883 9.75 4.79959 9.75 4.9985V8.74851H13.5C13.6989 8.74851 13.8897 8.82752 14.0303 8.96817C14.171 9.10883 14.25 9.29959 14.25 9.49851C14.25 9.69742 14.171 9.88818 14.0303 10.0288C13.8897 10.1695 13.6989 10.2485 13.5 10.2485Z" fill="black" />
                            </svg>
                            </span>
                          </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel className='pb-6 text-[13px] text-[#777777]'>
                          <p>
                          AfriPay offers secure payments, allowing you to pay for your flight booking securely using various payment methods, including credit/debit cards, mobile money, and bank transfers.
                          </p>
                        </AccordionItemPanel>
                      </AccordionItem>
                      <AccordionItem className='bg-white border border-[#209D5A] px-3 rounded-[10px] mb-3'>
                        <AccordionItemHeading>
                          <AccordionItemButton className=' py-[14px] mb-1 text-[15px] text-[#777777] flex justify-between'>
                            <span>Can I expect to find exclusive deals and discounts on flights through AfriPay?</span>
                            <span><svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M13.5 10.2485H9.75V13.9985C9.75 14.1974 9.67098 14.3882 9.53033 14.5288C9.38968 14.6695 9.19891 14.7485 9 14.7485C8.80109 14.7485 8.61032 14.6695 8.46967 14.5288C8.32902 14.3882 8.25 14.1974 8.25 13.9985V10.2485H4.5C4.30109 10.2485 4.11032 10.1695 3.96967 10.0288C3.82902 9.88818 3.75 9.69742 3.75 9.49851C3.75 9.29959 3.82902 9.10883 3.96967 8.96817C4.11032 8.82752 4.30109 8.74851 4.5 8.74851H8.25V4.9985C8.25 4.79959 8.32902 4.60883 8.46967 4.46817C8.61032 4.32752 8.80109 4.2485 9 4.2485C9.19891 4.2485 9.38968 4.32752 9.53033 4.46817C9.67098 4.60883 9.75 4.79959 9.75 4.9985V8.74851H13.5C13.6989 8.74851 13.8897 8.82752 14.0303 8.96817C14.171 9.10883 14.25 9.29959 14.25 9.49851C14.25 9.69742 14.171 9.88818 14.0303 10.0288C13.8897 10.1695 13.6989 10.2485 13.5 10.2485Z" fill="black" />
                            </svg>
                            </span>
                          </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel className='pb-6 text-[13px] text-[#777777]'>
                          <p>
                          Yes, AfriPay provides access to exclusive deals and discounts on flights, ensuring that you get the best deals for your air travel needs.
                          </p>
                        </AccordionItemPanel>
                      </AccordionItem>
                      <AccordionItem className='bg-white border border-[#209D5A] px-3 rounded-[10px] mb-3'>
                        <AccordionItemHeading>
                          <AccordionItemButton className=' py-[14px] mb-1 text-[15px] text-[#777777] flex justify-between'>
                            <span>How does AfriPay ensure the security and reliability of flight bookings?</span>
                            <span><svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M13.5 10.2485H9.75V13.9985C9.75 14.1974 9.67098 14.3882 9.53033 14.5288C9.38968 14.6695 9.19891 14.7485 9 14.7485C8.80109 14.7485 8.61032 14.6695 8.46967 14.5288C8.32902 14.3882 8.25 14.1974 8.25 13.9985V10.2485H4.5C4.30109 10.2485 4.11032 10.1695 3.96967 10.0288C3.82902 9.88818 3.75 9.69742 3.75 9.49851C3.75 9.29959 3.82902 9.10883 3.96967 8.96817C4.11032 8.82752 4.30109 8.74851 4.5 8.74851H8.25V4.9985C8.25 4.79959 8.32902 4.60883 8.46967 4.46817C8.61032 4.32752 8.80109 4.2485 9 4.2485C9.19891 4.2485 9.38968 4.32752 9.53033 4.46817C9.67098 4.60883 9.75 4.79959 9.75 4.9985V8.74851H13.5C13.6989 8.74851 13.8897 8.82752 14.0303 8.96817C14.171 9.10883 14.25 9.29959 14.25 9.49851C14.25 9.69742 14.171 9.88818 14.0303 10.0288C13.8897 10.1695 13.6989 10.2485 13.5 10.2485Z" fill="black" />
                            </svg>
                            </span>
                          </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel className='pb-6 text-[13px] text-[#777777]'>
                          <p>
                          AfriPay prioritizes the security and reliability of flight bookings by partnering with reputable airlines and employing secure payment processing methods, giving you peace of mind when booking your flights.
                          </p>
                        </AccordionItemPanel>
                      </AccordionItem>
                      <AccordionItem className='bg-white border border-[#209D5A] px-3 rounded-[10px] mb-3'>
                        <AccordionItemHeading>
                          <AccordionItemButton className=' py-[14px] mb-1 text-[15px] text-[#777777] flex justify-between'>
                            <span>Can I save time by using AfriPay's Flight Booking service?</span>
                            <span><svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M13.5 10.2485H9.75V13.9985C9.75 14.1974 9.67098 14.3882 9.53033 14.5288C9.38968 14.6695 9.19891 14.7485 9 14.7485C8.80109 14.7485 8.61032 14.6695 8.46967 14.5288C8.32902 14.3882 8.25 14.1974 8.25 13.9985V10.2485H4.5C4.30109 10.2485 4.11032 10.1695 3.96967 10.0288C3.82902 9.88818 3.75 9.69742 3.75 9.49851C3.75 9.29959 3.82902 9.10883 3.96967 8.96817C4.11032 8.82752 4.30109 8.74851 4.5 8.74851H8.25V4.9985C8.25 4.79959 8.32902 4.60883 8.46967 4.46817C8.61032 4.32752 8.80109 4.2485 9 4.2485C9.19891 4.2485 9.38968 4.32752 9.53033 4.46817C9.67098 4.60883 9.75 4.79959 9.75 4.9985V8.74851H13.5C13.6989 8.74851 13.8897 8.82752 14.0303 8.96817C14.171 9.10883 14.25 9.29959 14.25 9.49851C14.25 9.69742 14.171 9.88818 14.0303 10.0288C13.8897 10.1695 13.6989 10.2485 13.5 10.2485Z" fill="black" />
                            </svg>
                            </span>
                          </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel className='pb-6 text-[13px] text-[#777777]'>
                          <p>
                          Absolutely! AfriPay allows you to skip the hassle of searching multiple websites for flights by providing a convenient booking process all in one platform.
                          </p>
                        </AccordionItemPanel>
                      </AccordionItem>      
                      <AccordionItem className='bg-white border border-[#209D5A] px-3 rounded-[10px] mb-3'>
                        <AccordionItemHeading>
                          <AccordionItemButton className=' py-[14px] mb-1 text-[15px] text-[#777777] flex justify-between'>
                            <span>What if I encounter any issues or need assistance during the flight booking process?</span>
                            <span><svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M13.5 10.2485H9.75V13.9985C9.75 14.1974 9.67098 14.3882 9.53033 14.5288C9.38968 14.6695 9.19891 14.7485 9 14.7485C8.80109 14.7485 8.61032 14.6695 8.46967 14.5288C8.32902 14.3882 8.25 14.1974 8.25 13.9985V10.2485H4.5C4.30109 10.2485 4.11032 10.1695 3.96967 10.0288C3.82902 9.88818 3.75 9.69742 3.75 9.49851C3.75 9.29959 3.82902 9.10883 3.96967 8.96817C4.11032 8.82752 4.30109 8.74851 4.5 8.74851H8.25V4.9985C8.25 4.79959 8.32902 4.60883 8.46967 4.46817C8.61032 4.32752 8.80109 4.2485 9 4.2485C9.19891 4.2485 9.38968 4.32752 9.53033 4.46817C9.67098 4.60883 9.75 4.79959 9.75 4.9985V8.74851H13.5C13.6989 8.74851 13.8897 8.82752 14.0303 8.96817C14.171 9.10883 14.25 9.29959 14.25 9.49851C14.25 9.69742 14.171 9.88818 14.0303 10.0288C13.8897 10.1695 13.6989 10.2485 13.5 10.2485Z" fill="black" />
                            </svg>
                            </span>
                          </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel className='pb-6 text-[13px] text-[#777777]'>
                          <p>
                          Our dedicated customer support team is available to assist you 24/7 with any inquiries or issues you may encounter during the flight booking process. Simply reach out for prompt assistance.
                          </p>
                        </AccordionItemPanel>
                      </AccordionItem>
                      <AccordionItem className='bg-white border border-[#209D5A] px-3 rounded-[10px] mb-3'>
                        <AccordionItemHeading>
                          <AccordionItemButton className=' py-[14px] mb-1 text-[15px] text-[#777777] flex justify-between'>
                            <span>Can I receive my e-ticket instantly after completing my flight booking on AfriPay?</span>
                            <span><svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M13.5 10.2485H9.75V13.9985C9.75 14.1974 9.67098 14.3882 9.53033 14.5288C9.38968 14.6695 9.19891 14.7485 9 14.7485C8.80109 14.7485 8.61032 14.6695 8.46967 14.5288C8.32902 14.3882 8.25 14.1974 8.25 13.9985V10.2485H4.5C4.30109 10.2485 4.11032 10.1695 3.96967 10.0288C3.82902 9.88818 3.75 9.69742 3.75 9.49851C3.75 9.29959 3.82902 9.10883 3.96967 8.96817C4.11032 8.82752 4.30109 8.74851 4.5 8.74851H8.25V4.9985C8.25 4.79959 8.32902 4.60883 8.46967 4.46817C8.61032 4.32752 8.80109 4.2485 9 4.2485C9.19891 4.2485 9.38968 4.32752 9.53033 4.46817C9.67098 4.60883 9.75 4.79959 9.75 4.9985V8.74851H13.5C13.6989 8.74851 13.8897 8.82752 14.0303 8.96817C14.171 9.10883 14.25 9.29959 14.25 9.49851C14.25 9.69742 14.171 9.88818 14.0303 10.0288C13.8897 10.1695 13.6989 10.2485 13.5 10.2485Z" fill="black" />
                            </svg>
                            </span>
                          </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel className='pb-6 text-[13px] text-[#777777]'>
                          <p>
                          Yes, upon completing your flight booking and payment securely on AfriPay, you will receive your e-ticket instantly, allowing you to travel with ease and convenience.
                          </p>
                        </AccordionItemPanel>
                      </AccordionItem>
                    </Accordion>
          </div>
          {/* ACCOMODATION BOOKING */}
          <div className={openTestTab === 4 ? "block" : "hidden"}>
          <Accordion allowZeroExpanded allowMultipleExpanded>
                      <AccordionItem className='bg-white border border-[#209D5A] px-3 rounded-[10px] mb-3'>
                        <AccordionItemHeading>
                          <AccordionItemButton className=' py-[14px] mb-1 text-[15px] text-[#777777] flex justify-between'>
                            <span>How do I access AfriPay's Accommodation Booking service?</span>
                            <span><svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M13.5 10.2485H9.75V13.9985C9.75 14.1974 9.67098 14.3882 9.53033 14.5288C9.38968 14.6695 9.19891 14.7485 9 14.7485C8.80109 14.7485 8.61032 14.6695 8.46967 14.5288C8.32902 14.3882 8.25 14.1974 8.25 13.9985V10.2485H4.5C4.30109 10.2485 4.11032 10.1695 3.96967 10.0288C3.82902 9.88818 3.75 9.69742 3.75 9.49851C3.75 9.29959 3.82902 9.10883 3.96967 8.96817C4.11032 8.82752 4.30109 8.74851 4.5 8.74851H8.25V4.9985C8.25 4.79959 8.32902 4.60883 8.46967 4.46817C8.61032 4.32752 8.80109 4.2485 9 4.2485C9.19891 4.2485 9.38968 4.32752 9.53033 4.46817C9.67098 4.60883 9.75 4.79959 9.75 4.9985V8.74851H13.5C13.6989 8.74851 13.8897 8.82752 14.0303 8.96817C14.171 9.10883 14.25 9.29959 14.25 9.49851C14.25 9.69742 14.171 9.88818 14.0303 10.0288C13.8897 10.1695 13.6989 10.2485 13.5 10.2485Z" fill="black" />
                            </svg>
                            </span>
                          </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel className='pb-6 text-[13px] text-[#777777]'>
                          <p>
                          You can access AfriPay's Accommodation Booking service by logging in to your account securely and selecting the "Accommodation Booking" option to begin your search.
                          </p>
                        </AccordionItemPanel>
                      </AccordionItem>
                      <AccordionItem className='bg-white border border-[#209D5A] px-3 rounded-[10px] mb-3'>
                        <AccordionItemHeading>
                          <AccordionItemButton className=' py-[14px] mb-1 text-[15px] text-[#777777] flex justify-between'>
                            <span>What types of accommodations can I book through AfriPay?</span>
                            <span><svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M13.5 10.2485H9.75V13.9985C9.75 14.1974 9.67098 14.3882 9.53033 14.5288C9.38968 14.6695 9.19891 14.7485 9 14.7485C8.80109 14.7485 8.61032 14.6695 8.46967 14.5288C8.32902 14.3882 8.25 14.1974 8.25 13.9985V10.2485H4.5C4.30109 10.2485 4.11032 10.1695 3.96967 10.0288C3.82902 9.88818 3.75 9.69742 3.75 9.49851C3.75 9.29959 3.82902 9.10883 3.96967 8.96817C4.11032 8.82752 4.30109 8.74851 4.5 8.74851H8.25V4.9985C8.25 4.79959 8.32902 4.60883 8.46967 4.46817C8.61032 4.32752 8.80109 4.2485 9 4.2485C9.19891 4.2485 9.38968 4.32752 9.53033 4.46817C9.67098 4.60883 9.75 4.79959 9.75 4.9985V8.74851H13.5C13.6989 8.74851 13.8897 8.82752 14.0303 8.96817C14.171 9.10883 14.25 9.29959 14.25 9.49851C14.25 9.69742 14.171 9.88818 14.0303 10.0288C13.8897 10.1695 13.6989 10.2485 13.5 10.2485Z" fill="black" />
                            </svg>
                            </span>
                          </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel className='pb-6 text-[13px] text-[#777777]'>
                          <p>
                          AfriPay offers a variety of accommodation options, including hotels, apartments, vacation rentals, hostels, and more, ensuring that there's something suitable for every traveler's needs.
                          </p>
                        </AccordionItemPanel>
                      </AccordionItem>
                      <AccordionItem className='bg-white border border-[#209D5A] px-3 rounded-[10px] mb-3'>
                        <AccordionItemHeading>
                          <AccordionItemButton className=' py-[14px] mb-1 text-[15px] text-[#777777] flex justify-between'>
                            <span>Can I search for accommodations in any destination through AfriPay?</span>
                            <span><svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M13.5 10.2485H9.75V13.9985C9.75 14.1974 9.67098 14.3882 9.53033 14.5288C9.38968 14.6695 9.19891 14.7485 9 14.7485C8.80109 14.7485 8.61032 14.6695 8.46967 14.5288C8.32902 14.3882 8.25 14.1974 8.25 13.9985V10.2485H4.5C4.30109 10.2485 4.11032 10.1695 3.96967 10.0288C3.82902 9.88818 3.75 9.69742 3.75 9.49851C3.75 9.29959 3.82902 9.10883 3.96967 8.96817C4.11032 8.82752 4.30109 8.74851 4.5 8.74851H8.25V4.9985C8.25 4.79959 8.32902 4.60883 8.46967 4.46817C8.61032 4.32752 8.80109 4.2485 9 4.2485C9.19891 4.2485 9.38968 4.32752 9.53033 4.46817C9.67098 4.60883 9.75 4.79959 9.75 4.9985V8.74851H13.5C13.6989 8.74851 13.8897 8.82752 14.0303 8.96817C14.171 9.10883 14.25 9.29959 14.25 9.49851C14.25 9.69742 14.171 9.88818 14.0303 10.0288C13.8897 10.1695 13.6989 10.2485 13.5 10.2485Z" fill="black" />
                            </svg>
                            </span>
                          </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel className='pb-6 text-[13px] text-[#777777]'>
                          <p>
                          Yes, AfriPay's Accommodation Booking service allows you to search for accommodations in destinations worldwide, giving you the flexibility to find the perfect place to stay wherever you go.
                          </p>
                        </AccordionItemPanel>
                      </AccordionItem>
                      <AccordionItem className='bg-white border border-[#209D5A] px-3 rounded-[10px] mb-3'>
                        <AccordionItemHeading>
                          <AccordionItemButton className=' py-[14px] mb-1 text-[15px] text-[#777777] flex justify-between'>
                            <span>How can I check availability and book accommodations in real-time on AfriPay?</span>
                            <span><svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M13.5 10.2485H9.75V13.9985C9.75 14.1974 9.67098 14.3882 9.53033 14.5288C9.38968 14.6695 9.19891 14.7485 9 14.7485C8.80109 14.7485 8.61032 14.6695 8.46967 14.5288C8.32902 14.3882 8.25 14.1974 8.25 13.9985V10.2485H4.5C4.30109 10.2485 4.11032 10.1695 3.96967 10.0288C3.82902 9.88818 3.75 9.69742 3.75 9.49851C3.75 9.29959 3.82902 9.10883 3.96967 8.96817C4.11032 8.82752 4.30109 8.74851 4.5 8.74851H8.25V4.9985C8.25 4.79959 8.32902 4.60883 8.46967 4.46817C8.61032 4.32752 8.80109 4.2485 9 4.2485C9.19891 4.2485 9.38968 4.32752 9.53033 4.46817C9.67098 4.60883 9.75 4.79959 9.75 4.9985V8.74851H13.5C13.6989 8.74851 13.8897 8.82752 14.0303 8.96817C14.171 9.10883 14.25 9.29959 14.25 9.49851C14.25 9.69742 14.171 9.88818 14.0303 10.0288C13.8897 10.1695 13.6989 10.2485 13.5 10.2485Z" fill="black" />
                            </svg>
                            </span>
                          </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel className='pb-6 text-[13px] text-[#777777]'>
                          <p>
                          AfriPay provides real-time availability, allowing you to check accommodation availability instantly and book your desired accommodations in real-time for a seamless booking experience.
                          </p>
                        </AccordionItemPanel>
                      </AccordionItem>
                      <AccordionItem className='bg-white border border-[#209D5A] px-3 rounded-[10px] mb-3'>
                        <AccordionItemHeading>
                          <AccordionItemButton className=' py-[14px] mb-1 text-[15px] text-[#777777] flex justify-between'>
                            <span>What payment methods can I use to pay for my accommodation booking on AfriPay?</span>
                            <span><svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M13.5 10.2485H9.75V13.9985C9.75 14.1974 9.67098 14.3882 9.53033 14.5288C9.38968 14.6695 9.19891 14.7485 9 14.7485C8.80109 14.7485 8.61032 14.6695 8.46967 14.5288C8.32902 14.3882 8.25 14.1974 8.25 13.9985V10.2485H4.5C4.30109 10.2485 4.11032 10.1695 3.96967 10.0288C3.82902 9.88818 3.75 9.69742 3.75 9.49851C3.75 9.29959 3.82902 9.10883 3.96967 8.96817C4.11032 8.82752 4.30109 8.74851 4.5 8.74851H8.25V4.9985C8.25 4.79959 8.32902 4.60883 8.46967 4.46817C8.61032 4.32752 8.80109 4.2485 9 4.2485C9.19891 4.2485 9.38968 4.32752 9.53033 4.46817C9.67098 4.60883 9.75 4.79959 9.75 4.9985V8.74851H13.5C13.6989 8.74851 13.8897 8.82752 14.0303 8.96817C14.171 9.10883 14.25 9.29959 14.25 9.49851C14.25 9.69742 14.171 9.88818 14.0303 10.0288C13.8897 10.1695 13.6989 10.2485 13.5 10.2485Z" fill="black" />
                            </svg>
                            </span>
                          </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel className='pb-6 text-[13px] text-[#777777]'>
                          <p>
                          AfriPay offers secure payments, allowing you to pay for your accommodation booking securely using various payment methods, including credit/debit cards, mobile money, and bank transfers.
                          </p>
                        </AccordionItemPanel>
                      </AccordionItem>
                      <AccordionItem className='bg-white border border-[#209D5A] px-3 rounded-[10px] mb-3'>
                        <AccordionItemHeading>
                          <AccordionItemButton className=' py-[14px] mb-1 text-[15px] text-[#777777] flex justify-between'>
                            <span>Can I expect to find exclusive deals and discounts on accommodations through AfriPay?</span>
                            <span><svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M13.5 10.2485H9.75V13.9985C9.75 14.1974 9.67098 14.3882 9.53033 14.5288C9.38968 14.6695 9.19891 14.7485 9 14.7485C8.80109 14.7485 8.61032 14.6695 8.46967 14.5288C8.32902 14.3882 8.25 14.1974 8.25 13.9985V10.2485H4.5C4.30109 10.2485 4.11032 10.1695 3.96967 10.0288C3.82902 9.88818 3.75 9.69742 3.75 9.49851C3.75 9.29959 3.82902 9.10883 3.96967 8.96817C4.11032 8.82752 4.30109 8.74851 4.5 8.74851H8.25V4.9985C8.25 4.79959 8.32902 4.60883 8.46967 4.46817C8.61032 4.32752 8.80109 4.2485 9 4.2485C9.19891 4.2485 9.38968 4.32752 9.53033 4.46817C9.67098 4.60883 9.75 4.79959 9.75 4.9985V8.74851H13.5C13.6989 8.74851 13.8897 8.82752 14.0303 8.96817C14.171 9.10883 14.25 9.29959 14.25 9.49851C14.25 9.69742 14.171 9.88818 14.0303 10.0288C13.8897 10.1695 13.6989 10.2485 13.5 10.2485Z" fill="black" />
                            </svg>
                            </span>
                          </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel className='pb-6 text-[13px] text-[#777777]'>
                          <p>
                          Yes, AfriPay provides access to exclusive deals and discounts on accommodations, ensuring that you get the best deals for your stay.
                          </p>
                        </AccordionItemPanel>
                      </AccordionItem>
                      <AccordionItem className='bg-white border border-[#209D5A] px-3 rounded-[10px] mb-3'>
                        <AccordionItemHeading>
                          <AccordionItemButton className=' py-[14px] mb-1 text-[15px] text-[#777777] flex justify-between'>
                            <span>How does AfriPay ensure the security and reliability of accommodation bookings?</span>
                            <span><svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M13.5 10.2485H9.75V13.9985C9.75 14.1974 9.67098 14.3882 9.53033 14.5288C9.38968 14.6695 9.19891 14.7485 9 14.7485C8.80109 14.7485 8.61032 14.6695 8.46967 14.5288C8.32902 14.3882 8.25 14.1974 8.25 13.9985V10.2485H4.5C4.30109 10.2485 4.11032 10.1695 3.96967 10.0288C3.82902 9.88818 3.75 9.69742 3.75 9.49851C3.75 9.29959 3.82902 9.10883 3.96967 8.96817C4.11032 8.82752 4.30109 8.74851 4.5 8.74851H8.25V4.9985C8.25 4.79959 8.32902 4.60883 8.46967 4.46817C8.61032 4.32752 8.80109 4.2485 9 4.2485C9.19891 4.2485 9.38968 4.32752 9.53033 4.46817C9.67098 4.60883 9.75 4.79959 9.75 4.9985V8.74851H13.5C13.6989 8.74851 13.8897 8.82752 14.0303 8.96817C14.171 9.10883 14.25 9.29959 14.25 9.49851C14.25 9.69742 14.171 9.88818 14.0303 10.0288C13.8897 10.1695 13.6989 10.2485 13.5 10.2485Z" fill="black" />
                            </svg>
                            </span>
                          </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel className='pb-6 text-[13px] text-[#777777]'>
                          <p>
                          AfriPay prioritizes the security and reliability of accommodation bookings by partnering with reputable accommodation providers and employing secure payment processing methods, giving you peace of mind when booking your stay.
                          </p>
                        </AccordionItemPanel>
                      </AccordionItem>
                      <AccordionItem className='bg-white border border-[#209D5A] px-3 rounded-[10px] mb-3'>
                        <AccordionItemHeading>
                          <AccordionItemButton className=' py-[14px] mb-1 text-[15px] text-[#777777] flex justify-between'>
                            <span>Can I save time by using AfriPay's Accommodation Booking service?</span>
                            <span><svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M13.5 10.2485H9.75V13.9985C9.75 14.1974 9.67098 14.3882 9.53033 14.5288C9.38968 14.6695 9.19891 14.7485 9 14.7485C8.80109 14.7485 8.61032 14.6695 8.46967 14.5288C8.32902 14.3882 8.25 14.1974 8.25 13.9985V10.2485H4.5C4.30109 10.2485 4.11032 10.1695 3.96967 10.0288C3.82902 9.88818 3.75 9.69742 3.75 9.49851C3.75 9.29959 3.82902 9.10883 3.96967 8.96817C4.11032 8.82752 4.30109 8.74851 4.5 8.74851H8.25V4.9985C8.25 4.79959 8.32902 4.60883 8.46967 4.46817C8.61032 4.32752 8.80109 4.2485 9 4.2485C9.19891 4.2485 9.38968 4.32752 9.53033 4.46817C9.67098 4.60883 9.75 4.79959 9.75 4.9985V8.74851H13.5C13.6989 8.74851 13.8897 8.82752 14.0303 8.96817C14.171 9.10883 14.25 9.29959 14.25 9.49851C14.25 9.69742 14.171 9.88818 14.0303 10.0288C13.8897 10.1695 13.6989 10.2485 13.5 10.2485Z" fill="black" />
                            </svg>
                            </span>
                          </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel className='pb-6 text-[13px] text-[#777777]'>
                          <p>
                          Absolutely! AfriPay allows you to find the perfect accommodation quickly and easily, saving you time and hassle during the booking process.
                          </p>
                        </AccordionItemPanel>
                      </AccordionItem>
                      <AccordionItem className='bg-white border border-[#209D5A] px-3 rounded-[10px] mb-3'>
                        <AccordionItemHeading>
                          <AccordionItemButton className=' py-[14px] mb-1 text-[15px] text-[#777777] flex justify-between'>
                            <span>What if I encounter any issues or need assistance with my accommodation booking?</span>
                            <span><svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M13.5 10.2485H9.75V13.9985C9.75 14.1974 9.67098 14.3882 9.53033 14.5288C9.38968 14.6695 9.19891 14.7485 9 14.7485C8.80109 14.7485 8.61032 14.6695 8.46967 14.5288C8.32902 14.3882 8.25 14.1974 8.25 13.9985V10.2485H4.5C4.30109 10.2485 4.11032 10.1695 3.96967 10.0288C3.82902 9.88818 3.75 9.69742 3.75 9.49851C3.75 9.29959 3.82902 9.10883 3.96967 8.96817C4.11032 8.82752 4.30109 8.74851 4.5 8.74851H8.25V4.9985C8.25 4.79959 8.32902 4.60883 8.46967 4.46817C8.61032 4.32752 8.80109 4.2485 9 4.2485C9.19891 4.2485 9.38968 4.32752 9.53033 4.46817C9.67098 4.60883 9.75 4.79959 9.75 4.9985V8.74851H13.5C13.6989 8.74851 13.8897 8.82752 14.0303 8.96817C14.171 9.10883 14.25 9.29959 14.25 9.49851C14.25 9.69742 14.171 9.88818 14.0303 10.0288C13.8897 10.1695 13.6989 10.2485 13.5 10.2485Z" fill="black" />
                            </svg>
                            </span>
                          </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel className='pb-6 text-[13px] text-[#777777]'>
                          <p>
                          Our dedicated customer support team is available 24/7 to assist you with any accommodation-related queries or issues you may encounter. Simply reach out for prompt assistance.
                          </p>
                        </AccordionItemPanel>
                      </AccordionItem>
                      <AccordionItem className='bg-white border border-[#209D5A] px-3 rounded-[10px] mb-3'>
                        <AccordionItemHeading>
                          <AccordionItemButton className=' py-[14px] mb-1 text-[15px] text-[#777777] flex justify-between'>
                            <span>Will I receive instant confirmation of my accommodation booking on AfriPay?</span>
                            <span><svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M13.5 10.2485H9.75V13.9985C9.75 14.1974 9.67098 14.3882 9.53033 14.5288C9.38968 14.6695 9.19891 14.7485 9 14.7485C8.80109 14.7485 8.61032 14.6695 8.46967 14.5288C8.32902 14.3882 8.25 14.1974 8.25 13.9985V10.2485H4.5C4.30109 10.2485 4.11032 10.1695 3.96967 10.0288C3.82902 9.88818 3.75 9.69742 3.75 9.49851C3.75 9.29959 3.82902 9.10883 3.96967 8.96817C4.11032 8.82752 4.30109 8.74851 4.5 8.74851H8.25V4.9985C8.25 4.79959 8.32902 4.60883 8.46967 4.46817C8.61032 4.32752 8.80109 4.2485 9 4.2485C9.19891 4.2485 9.38968 4.32752 9.53033 4.46817C9.67098 4.60883 9.75 4.79959 9.75 4.9985V8.74851H13.5C13.6989 8.74851 13.8897 8.82752 14.0303 8.96817C14.171 9.10883 14.25 9.29959 14.25 9.49851C14.25 9.69742 14.171 9.88818 14.0303 10.0288C13.8897 10.1695 13.6989 10.2485 13.5 10.2485Z" fill="black" />
                            </svg>
                            </span>
                          </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel className='pb-6 text-[13px] text-[#777777]'>
                          <p>
                          Yes, upon completing your accommodation booking and payment securely on AfriPay, you will receive instant confirmation of your booking, allowing you to travel with confidence.
                          </p>
                        </AccordionItemPanel>
                      </AccordionItem>      
                    </Accordion>
          </div>
          {/* HEALTH INSURANCE */}
          <div className={openTestTab === 5 ? "block" : "hidden"}>
          <Accordion allowZeroExpanded allowMultipleExpanded>
                      <AccordionItem className='bg-white border border-[#209D5A] px-3 rounded-[10px] mb-3'>
                        <AccordionItemHeading>
                          <AccordionItemButton className=' py-[14px] mb-1 text-[15px] text-[#777777] flex justify-between'>
                            <span>How do I access AfriPay's Health Insurance Service?</span>
                            <span><svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M13.5 10.2485H9.75V13.9985C9.75 14.1974 9.67098 14.3882 9.53033 14.5288C9.38968 14.6695 9.19891 14.7485 9 14.7485C8.80109 14.7485 8.61032 14.6695 8.46967 14.5288C8.32902 14.3882 8.25 14.1974 8.25 13.9985V10.2485H4.5C4.30109 10.2485 4.11032 10.1695 3.96967 10.0288C3.82902 9.88818 3.75 9.69742 3.75 9.49851C3.75 9.29959 3.82902 9.10883 3.96967 8.96817C4.11032 8.82752 4.30109 8.74851 4.5 8.74851H8.25V4.9985C8.25 4.79959 8.32902 4.60883 8.46967 4.46817C8.61032 4.32752 8.80109 4.2485 9 4.2485C9.19891 4.2485 9.38968 4.32752 9.53033 4.46817C9.67098 4.60883 9.75 4.79959 9.75 4.9985V8.74851H13.5C13.6989 8.74851 13.8897 8.82752 14.0303 8.96817C14.171 9.10883 14.25 9.29959 14.25 9.49851C14.25 9.69742 14.171 9.88818 14.0303 10.0288C13.8897 10.1695 13.6989 10.2485 13.5 10.2485Z" fill="black" />
                            </svg>
                            </span>
                          </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel className='pb-6 text-[13px] text-[#777777]'>
                          <p>
                          You can access AfriPay's Health Insurance Service by securely logging in to your account and selecting the "Health Insurance" option to explore available plans tailored to your requirements.
                          </p>
                        </AccordionItemPanel>
                      </AccordionItem>
                      <AccordionItem className='bg-white border border-[#209D5A] px-3 rounded-[10px] mb-3'>
                        <AccordionItemHeading>
                          <AccordionItemButton className=' py-[14px] mb-1 text-[15px] text-[#777777] flex justify-between'>
                            <span>What types of coverage does AfriPay's Health Insurance offer?</span>
                            <span><svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M13.5 10.2485H9.75V13.9985C9.75 14.1974 9.67098 14.3882 9.53033 14.5288C9.38968 14.6695 9.19891 14.7485 9 14.7485C8.80109 14.7485 8.61032 14.6695 8.46967 14.5288C8.32902 14.3882 8.25 14.1974 8.25 13.9985V10.2485H4.5C4.30109 10.2485 4.11032 10.1695 3.96967 10.0288C3.82902 9.88818 3.75 9.69742 3.75 9.49851C3.75 9.29959 3.82902 9.10883 3.96967 8.96817C4.11032 8.82752 4.30109 8.74851 4.5 8.74851H8.25V4.9985C8.25 4.79959 8.32902 4.60883 8.46967 4.46817C8.61032 4.32752 8.80109 4.2485 9 4.2485C9.19891 4.2485 9.38968 4.32752 9.53033 4.46817C9.67098 4.60883 9.75 4.79959 9.75 4.9985V8.74851H13.5C13.6989 8.74851 13.8897 8.82752 14.0303 8.96817C14.171 9.10883 14.25 9.29959 14.25 9.49851C14.25 9.69742 14.171 9.88818 14.0303 10.0288C13.8897 10.1695 13.6989 10.2485 13.5 10.2485Z" fill="black" />
                            </svg>
                            </span>
                          </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel className='pb-6 text-[13px] text-[#777777]'>
                          <p>
                          AfriPay's Health Insurance provides comprehensive coverage, including hospitalization, outpatient care, prescription drugs, and more, ensuring you have access to a wide range of medical services.
                          </p>
                        </AccordionItemPanel>
                      </AccordionItem>
                      <AccordionItem className='bg-white border border-[#209D5A] px-3 rounded-[10px] mb-3'>
                        <AccordionItemHeading>
                          <AccordionItemButton className=' py-[14px] mb-1 text-[15px] text-[#777777] flex justify-between'>
                            <span>Can I customize my health insurance coverage with AfriPay?</span>
                            <span><svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M13.5 10.2485H9.75V13.9985C9.75 14.1974 9.67098 14.3882 9.53033 14.5288C9.38968 14.6695 9.19891 14.7485 9 14.7485C8.80109 14.7485 8.61032 14.6695 8.46967 14.5288C8.32902 14.3882 8.25 14.1974 8.25 13.9985V10.2485H4.5C4.30109 10.2485 4.11032 10.1695 3.96967 10.0288C3.82902 9.88818 3.75 9.69742 3.75 9.49851C3.75 9.29959 3.82902 9.10883 3.96967 8.96817C4.11032 8.82752 4.30109 8.74851 4.5 8.74851H8.25V4.9985C8.25 4.79959 8.32902 4.60883 8.46967 4.46817C8.61032 4.32752 8.80109 4.2485 9 4.2485C9.19891 4.2485 9.38968 4.32752 9.53033 4.46817C9.67098 4.60883 9.75 4.79959 9.75 4.9985V8.74851H13.5C13.6989 8.74851 13.8897 8.82752 14.0303 8.96817C14.171 9.10883 14.25 9.29959 14.25 9.49851C14.25 9.69742 14.171 9.88818 14.0303 10.0288C13.8897 10.1695 13.6989 10.2485 13.5 10.2485Z" fill="black" />
                            </svg>
                            </span>
                          </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel className='pb-6 text-[13px] text-[#777777]'>
                          <p>
                          Yes, AfriPay's Health Insurance service allows you to customize your coverage by choosing additional features and benefits according to your specific healthcare needs.
                          </p>
                        </AccordionItemPanel>
                      </AccordionItem>
                          
                      <AccordionItem className='bg-white border border-[#209D5A] px-3 rounded-[10px] mb-3'>
                        <AccordionItemHeading>
                          <AccordionItemButton className=' py-[14px] mb-1 text-[15px] text-[#777777] flex justify-between'>
                            <span>Are there options for family coverage with AfriPay's Health Insurance?</span>
                            <span><svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M13.5 10.2485H9.75V13.9985C9.75 14.1974 9.67098 14.3882 9.53033 14.5288C9.38968 14.6695 9.19891 14.7485 9 14.7485C8.80109 14.7485 8.61032 14.6695 8.46967 14.5288C8.32902 14.3882 8.25 14.1974 8.25 13.9985V10.2485H4.5C4.30109 10.2485 4.11032 10.1695 3.96967 10.0288C3.82902 9.88818 3.75 9.69742 3.75 9.49851C3.75 9.29959 3.82902 9.10883 3.96967 8.96817C4.11032 8.82752 4.30109 8.74851 4.5 8.74851H8.25V4.9985C8.25 4.79959 8.32902 4.60883 8.46967 4.46817C8.61032 4.32752 8.80109 4.2485 9 4.2485C9.19891 4.2485 9.38968 4.32752 9.53033 4.46817C9.67098 4.60883 9.75 4.79959 9.75 4.9985V8.74851H13.5C13.6989 8.74851 13.8897 8.82752 14.0303 8.96817C14.171 9.10883 14.25 9.29959 14.25 9.49851C14.25 9.69742 14.171 9.88818 14.0303 10.0288C13.8897 10.1695 13.6989 10.2485 13.5 10.2485Z" fill="black" />
                            </svg>
                            </span>
                          </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel className='pb-6 text-[13px] text-[#777777]'>
                          <p>
                          Yes, AfriPay offers family coverage options, allowing you to extend coverage to your family members for comprehensive protection and peace of mind.
                          </p>
                        </AccordionItemPanel>
                      </AccordionItem>
                      
                      <AccordionItem className='bg-white border border-[#209D5A] px-3 rounded-[10px] mb-3'>
                        <AccordionItemHeading>
                          <AccordionItemButton className=' py-[14px] mb-1 text-[15px] text-[#777777] flex justify-between'>
                            <span>How can I enroll in AfriPay's Health Insurance securely?</span>
                            <span><svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M13.5 10.2485H9.75V13.9985C9.75 14.1974 9.67098 14.3882 9.53033 14.5288C9.38968 14.6695 9.19891 14.7485 9 14.7485C8.80109 14.7485 8.61032 14.6695 8.46967 14.5288C8.32902 14.3882 8.25 14.1974 8.25 13.9985V10.2485H4.5C4.30109 10.2485 4.11032 10.1695 3.96967 10.0288C3.82902 9.88818 3.75 9.69742 3.75 9.49851C3.75 9.29959 3.82902 9.10883 3.96967 8.96817C4.11032 8.82752 4.30109 8.74851 4.5 8.74851H8.25V4.9985C8.25 4.79959 8.32902 4.60883 8.46967 4.46817C8.61032 4.32752 8.80109 4.2485 9 4.2485C9.19891 4.2485 9.38968 4.32752 9.53033 4.46817C9.67098 4.60883 9.75 4.79959 9.75 4.9985V8.74851H13.5C13.6989 8.74851 13.8897 8.82752 14.0303 8.96817C14.171 9.10883 14.25 9.29959 14.25 9.49851C14.25 9.69742 14.171 9.88818 14.0303 10.0288C13.8897 10.1695 13.6989 10.2485 13.5 10.2485Z" fill="black" />
                            </svg>
                            </span>
                          </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel className='pb-6 text-[13px] text-[#777777]'>
                          <p>
                          You can complete the enrollment process securely through the AfriPay platform, ensuring that your personal and medical information is protected throughout the process.
                          </p>
                        </AccordionItemPanel>
                      </AccordionItem>
                      <AccordionItem className='bg-white border border-[#209D5A] px-3 rounded-[10px] mb-3'>
                        <AccordionItemHeading>
                          <AccordionItemButton className=' py-[14px] mb-1 text-[15px] text-[#777777] flex justify-between'>
                            <span>Does AfriPay's Health Insurance provide access to a network of healthcare providers?</span>
                            <span><svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M13.5 10.2485H9.75V13.9985C9.75 14.1974 9.67098 14.3882 9.53033 14.5288C9.38968 14.6695 9.19891 14.7485 9 14.7485C8.80109 14.7485 8.61032 14.6695 8.46967 14.5288C8.32902 14.3882 8.25 14.1974 8.25 13.9985V10.2485H4.5C4.30109 10.2485 4.11032 10.1695 3.96967 10.0288C3.82902 9.88818 3.75 9.69742 3.75 9.49851C3.75 9.29959 3.82902 9.10883 3.96967 8.96817C4.11032 8.82752 4.30109 8.74851 4.5 8.74851H8.25V4.9985C8.25 4.79959 8.32902 4.60883 8.46967 4.46817C8.61032 4.32752 8.80109 4.2485 9 4.2485C9.19891 4.2485 9.38968 4.32752 9.53033 4.46817C9.67098 4.60883 9.75 4.79959 9.75 4.9985V8.74851H13.5C13.6989 8.74851 13.8897 8.82752 14.0303 8.96817C14.171 9.10883 14.25 9.29959 14.25 9.49851C14.25 9.69742 14.171 9.88818 14.0303 10.0288C13.8897 10.1695 13.6989 10.2485 13.5 10.2485Z" fill="black" />
                            </svg>
                            </span>
                          </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel className='pb-6 text-[13px] text-[#777777]'>
                          <p>
                          Yes, AfriPay's Health Insurance service provides access to a network of trusted healthcare providers and facilities, ensuring timely medical care and treatment when needed.
                          </p>
                        </AccordionItemPanel>
                      </AccordionItem>
                      <AccordionItem className='bg-white border border-[#209D5A] px-3 rounded-[10px] mb-3'>
                        <AccordionItemHeading>
                          <AccordionItemButton className=' py-[14px] mb-1 text-[15px] text-[#777777] flex justify-between'>
                            <span>What is the advantage of enrolling in wellness programs offered by AfriPay's Health Insurance?</span>
                            <span><svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M13.5 10.2485H9.75V13.9985C9.75 14.1974 9.67098 14.3882 9.53033 14.5288C9.38968 14.6695 9.19891 14.7485 9 14.7485C8.80109 14.7485 8.61032 14.6695 8.46967 14.5288C8.32902 14.3882 8.25 14.1974 8.25 13.9985V10.2485H4.5C4.30109 10.2485 4.11032 10.1695 3.96967 10.0288C3.82902 9.88818 3.75 9.69742 3.75 9.49851C3.75 9.29959 3.82902 9.10883 3.96967 8.96817C4.11032 8.82752 4.30109 8.74851 4.5 8.74851H8.25V4.9985C8.25 4.79959 8.32902 4.60883 8.46967 4.46817C8.61032 4.32752 8.80109 4.2485 9 4.2485C9.19891 4.2485 9.38968 4.32752 9.53033 4.46817C9.67098 4.60883 9.75 4.79959 9.75 4.9985V8.74851H13.5C13.6989 8.74851 13.8897 8.82752 14.0303 8.96817C14.171 9.10883 14.25 9.29959 14.25 9.49851C14.25 9.69742 14.171 9.88818 14.0303 10.0288C13.8897 10.1695 13.6989 10.2485 13.5 10.2485Z" fill="black" />
                            </svg>
                            </span>
                          </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel className='pb-6 text-[13px] text-[#777777]'>
                          <p>
                          Enrolling in wellness programs provided by AfriPay's Health Insurance allows you to benefit from preventive care services and maintain your health and well-being, ultimately reducing the risk of illness or injury.
                          </p>
                        </AccordionItemPanel>
                      </AccordionItem>
                      <AccordionItem className='bg-white border border-[#209D5A] px-3 rounded-[10px] mb-3'>
                        <AccordionItemHeading>
                          <AccordionItemButton className=' py-[14px] mb-1 text-[15px] text-[#777777] flex justify-between'>
                            <span>How does AfriPay's Health Insurance provide financial security during medical emergencies?</span>
                            <span><svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M13.5 10.2485H9.75V13.9985C9.75 14.1974 9.67098 14.3882 9.53033 14.5288C9.38968 14.6695 9.19891 14.7485 9 14.7485C8.80109 14.7485 8.61032 14.6695 8.46967 14.5288C8.32902 14.3882 8.25 14.1974 8.25 13.9985V10.2485H4.5C4.30109 10.2485 4.11032 10.1695 3.96967 10.0288C3.82902 9.88818 3.75 9.69742 3.75 9.49851C3.75 9.29959 3.82902 9.10883 3.96967 8.96817C4.11032 8.82752 4.30109 8.74851 4.5 8.74851H8.25V4.9985C8.25 4.79959 8.32902 4.60883 8.46967 4.46817C8.61032 4.32752 8.80109 4.2485 9 4.2485C9.19891 4.2485 9.38968 4.32752 9.53033 4.46817C9.67098 4.60883 9.75 4.79959 9.75 4.9985V8.74851H13.5C13.6989 8.74851 13.8897 8.82752 14.0303 8.96817C14.171 9.10883 14.25 9.29959 14.25 9.49851C14.25 9.69742 14.171 9.88818 14.0303 10.0288C13.8897 10.1695 13.6989 10.2485 13.5 10.2485Z" fill="black" />
                            </svg>
                            </span>
                          </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel className='pb-6 text-[13px] text-[#777777]'>
                          <p>
                          AfriPay's Health Insurance protects you from unexpected medical expenses and high healthcare costs, providing financial security and peace of mind during medical emergencies.
                          </p>
                        </AccordionItemPanel>
                      </AccordionItem>
                      <AccordionItem className='bg-white border border-[#209D5A] px-3 rounded-[10px] mb-3'>
                        <AccordionItemHeading>
                          <AccordionItemButton className=' py-[14px] mb-1 text-[15px] text-[#777777] flex justify-between'>
                            <span>Can I manage my health insurance coverage conveniently through the AfriPay platform?</span>
                            <span><svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M13.5 10.2485H9.75V13.9985C9.75 14.1974 9.67098 14.3882 9.53033 14.5288C9.38968 14.6695 9.19891 14.7485 9 14.7485C8.80109 14.7485 8.61032 14.6695 8.46967 14.5288C8.32902 14.3882 8.25 14.1974 8.25 13.9985V10.2485H4.5C4.30109 10.2485 4.11032 10.1695 3.96967 10.0288C3.82902 9.88818 3.75 9.69742 3.75 9.49851C3.75 9.29959 3.82902 9.10883 3.96967 8.96817C4.11032 8.82752 4.30109 8.74851 4.5 8.74851H8.25V4.9985C8.25 4.79959 8.32902 4.60883 8.46967 4.46817C8.61032 4.32752 8.80109 4.2485 9 4.2485C9.19891 4.2485 9.38968 4.32752 9.53033 4.46817C9.67098 4.60883 9.75 4.79959 9.75 4.9985V8.74851H13.5C13.6989 8.74851 13.8897 8.82752 14.0303 8.96817C14.171 9.10883 14.25 9.29959 14.25 9.49851C14.25 9.69742 14.171 9.88818 14.0303 10.0288C13.8897 10.1695 13.6989 10.2485 13.5 10.2485Z" fill="black" />
                            </svg>
                            </span>
                          </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel className='pb-6 text-[13px] text-[#777777]'>
                          <p>
                          Yes, AfriPay's Health Insurance service offers convenience by allowing you to manage your coverage, track claims, and access policy information easily through the AfriPay platform.
                          </p>
                        </AccordionItemPanel>
                      </AccordionItem>
                      <AccordionItem className='bg-white border border-[#209D5A] px-3 rounded-[10px] mb-3'>
                        <AccordionItemHeading>
                          <AccordionItemButton className=' py-[14px] mb-1 text-[15px] text-[#777777] flex justify-between'>
                            <span>Is there 24/7 support available for inquiries related to AfriPay's Health Insurance?</span>
                            <span><svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M13.5 10.2485H9.75V13.9985C9.75 14.1974 9.67098 14.3882 9.53033 14.5288C9.38968 14.6695 9.19891 14.7485 9 14.7485C8.80109 14.7485 8.61032 14.6695 8.46967 14.5288C8.32902 14.3882 8.25 14.1974 8.25 13.9985V10.2485H4.5C4.30109 10.2485 4.11032 10.1695 3.96967 10.0288C3.82902 9.88818 3.75 9.69742 3.75 9.49851C3.75 9.29959 3.82902 9.10883 3.96967 8.96817C4.11032 8.82752 4.30109 8.74851 4.5 8.74851H8.25V4.9985C8.25 4.79959 8.32902 4.60883 8.46967 4.46817C8.61032 4.32752 8.80109 4.2485 9 4.2485C9.19891 4.2485 9.38968 4.32752 9.53033 4.46817C9.67098 4.60883 9.75 4.79959 9.75 4.9985V8.74851H13.5C13.6989 8.74851 13.8897 8.82752 14.0303 8.96817C14.171 9.10883 14.25 9.29959 14.25 9.49851C14.25 9.69742 14.171 9.88818 14.0303 10.0288C13.8897 10.1695 13.6989 10.2485 13.5 10.2485Z" fill="black" />
                            </svg>
                            </span>
                          </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel className='pb-6 text-[13px] text-[#777777]'>
                          <p>Yes, AfriPay provides round-the-clock customer support to assist you with any inquiries or issues related to your health insurance coverage, ensuring that you receive timely assistance whenever needed.
                          </p>
                        </AccordionItemPanel>
                      </AccordionItem> 
                    </Accordion>
          </div>
               
               </div>
               </div>
               
          </div>
          </div>
          </section>
          <Footer/>
        </>
      )
}

export default AfripayShopPage