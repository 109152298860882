import React, { useState } from "react";
import Sidebar from "../../Sidebar/Sidebar";
import UserNavbar from "../../Navbars/UserNavbar";
import HeaderTitle from '../../ReuseableComponent/HeaderTitle';
import { useNavigate, Link } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { DashboardApi } from "../../../apis/DashboardApi";

function UploadForms() {

    const [selectedPics, setSelectedPics] = React.useState('No selected file');
    const changeHandler = (event: any) => {
        // console?.log(2)
        const target = event.target
        let size = (target.files[0].size / 1048576.0)
        let targetName = target.name
        if (target.files && target.files[0]) {
            if (size > 1) {
                if (targetName == 'doc_1') {
                    target.value = ''
                    toast.warn('File too large');
                }
            }
            if (size <= 1) {
                if (targetName == 'doc_1') {
                    setSelectedPics(event.target.files[0]);
                }
            }
        }
    };


    const [selectedPics2, setSelectedPics2] = React.useState('No selected file');
    const changeHandler2 = (event: any) => {
        // console?.log(2)
        const target = event.target
        let size = (target.files[0].size / 1048576.0)
        let targetName = target.name
        if (target.files && target.files[0]) {
            if (size > 1) {
                if (targetName == 'doc_2') {
                    target.value = ''
                    toast.warn('File too large');
                }
            }
            if (size <= 1) {
                if (targetName == 'doc_2') {
                    setSelectedPics2(event.target.files[0]);
                }
            }
        }
    };




    const UpdateUser = React.useCallback(
        (e: any) => {
            e.preventDefault();
            const userData = new FormData();
            userData.append('doc_1', selectedPics2);
            userData.append('doc_2', selectedPics2);

            DashboardApi.uploadBankDoc(userData).then(
                (response: any) => {
                    if (response?.data) {
                        toast.success(response?.data?.message);
                        console?.log(response?.data?.data)
                        // navigate('/wallet');
                        // dispatch(login({ email: userLoginData?.email, token: userLoginData?.token, id: userLoginData?.id, name: response.data.data.name,data:response.data.data}))
                    }
                }
            ).catch((err) => {
                if (err?.response?.status == 422) {
                    toast.error('Form not rightly filled. Kindly Cross check.');
                } else {
                    toast.error('Some error occured.Please try again');
                }


            }).finally(() => {

            });
        },
        [selectedPics, selectedPics2]
    );



    return (
        <>




            <div className="">

                <HeaderTitle title="Upload Forms  " />
                <div className='mt-[22px]'>
                    <h3 className='text-[#6E7093] text-[12px] mb-[60px] font-normal'>Please Upload the forms for processing</h3>
                </div>
                <form onSubmit={UpdateUser}>
                    <div className="relative mb-6">
                        {/* <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white" htmlFor="file_input">Upload file</label> */}
                        <input name='doc_1' onChange={changeHandler} className="block w-full file:py-2.5 file:border-none file:bg-[#DADBD6] text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer  " id="file_input" type="file" />
                    </div>
                    <div className="relative mb-6">
                        {/* <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white" htmlFor="file_input">Upload file</label> */}
                        <input name='doc_2' onChange={changeHandler2} className="block w-full file:py-2.5 file:border-none file:bg-[#DADBD6] text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer  " id="file_input" type="file" />
                    </div>



                    <div>

                        <button
                            type='submit'

                            className=

                            "bg-[#00B07B] text-white mt-3 font-medium rounded-[5px] text-[12px] w-full py-2.5 text-center "


                        >Submit</button>

                    </div>
                </form>







                {/* <div className='w-full flex space-x-5 mt-5'>
                <button
                    type='button'
                    onClick={() => navigate(-1)}
                    className=" bg-[#979797] text-white mt-3 font-medium rounded-[5px] text-[12px] w-full py-2.5 text-center "
                >Back</button>
                <button
                    type='submit'

                    className=

                    "bg-[#00B07B] text-white mt-3 font-medium rounded-[5px] text-[12px] w-full py-2.5 text-center "


                >Continue</button>
            </div> */}
            </div>


            <ToastContainer
                position="bottom-left"
                autoClose={2000}
                hideProgressBar={true}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover />


        </>
    )
}

export default UploadForms