/*eslint-disable*/
import { AxiosResponse } from "axios";
import React from "react";
import { Link } from "react-router-dom";
import { AuthApis } from "../../apis/authApis";
import { useDispatch } from "react-redux";
import { login } from "../../reducer/loginSlice";
import { NavLink, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { MdDashboard } from "react-icons/md";
import { TbReceipt } from "react-icons/tb";
import { AiFillDollarCircle } from "react-icons/ai";
import { FaUser } from "react-icons/fa";
import { SlGraduation } from "react-icons/sl";
import { FaSchool } from "react-icons/fa";


export default function AdminSidebar() {

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userLoginData = useSelector((state: any) => state.data.login.value);

  const logOut = () => {
    AuthApis.logout('').then(
      (response: AxiosResponse<any>) => {
        if (response?.data) {
          dispatch(login([]))
          navigate('/sign-in');

        }
      }
    ).catch(function (error) {
     
      console.log(error.response.data);
      // console.log("new error");
    })

  };


  // React.useEffect(() => {
  //   (userLoginData.token && userLoginData?.data?.role == 'admin') ?
  //     ''
  //     :
  //     navigate('/sign-in');
  // }, []);



  const [collapseShow, setCollapseShow] = React.useState("hidden");
  return (
    <>
      <nav className="bg-[#F8F8F8] md:left-0 md:block md:fixed md:top-0 md:bottom-0 md:overflow-y-auto md:flex-row md:flex-nowrap md:overflow-hidden shadow-xl  flex flex-wrap items-center justify-between relative md:w-64 z-10 py-4 px-6">
        <div className="md:flex-col md:items-stretch md:min-h-full md:flex-nowrap px-0 flex flex-wrap items-center justify-between w-full mx-auto">
          {/* Toggler */}
          <button
            className="cursor-pointer text-white opacity-50 md:hidden px-3 py-1 text-xl leading-none bg-transparent rounded border border-solid border-transparent"
            type="button"
            onClick={() => setCollapseShow("bg-[#000000] m-2 py-3 px-6")}
          >
            <b className="fas fa-bars"> ≡ </b>
          </button>
          {/* Brand */}
          {/* <Link
            className="md:mt-10 md:block text-center md:pb-2 text-blueGray-600 mr-0 inline-block whitespace-nowrap text-sm uppercase font-bold p-4 px-0"
            to="/"
          >
            <img src="/images/Dashboard/logo.svg" alt="hero" />
          </Link> */}
          {/* User */}
          <ul className="md:hidden items-center flex flex-wrap list-none">
            <li className="inline-block relative">
              {/* <NotificationDropdown /> */}
            </li>
            <li className="inline-block relative">
              {/* <UserDropdown /> */}
            </li>
          </ul>
          {/* Collapse */}
          <div
            className={
              "bg-[#F8F8F8] md:flex md:flex-col md:items-stretch md:opacity-100 md:relative md:mt-4 md:shadow-none shadow absolute top-0 left-0 right-0 z-40 overflow-y-auto overflow-x-hidden h-auto items-center flex-1 rounded " +
              collapseShow
            }
          >
            {/* Collapse header */}
            <div className=" md:min-w-full md:hidden block pb-4 mb-4 border-b border-solid border-blueGray-200">
              <div className="flex flex-wrap">

                <div className="w-6/12 flex justify-end">
                  <button
                    type="button"
                    className="cursor-pointer text-white opacity-50 md:hidden px-3 py-1 text-xl leading-none bg-transparent rounded border border-solid border-transparent"
                    onClick={() => setCollapseShow("hidden")}
                  >
                    <b className="fas fa-times"> X </b>
                  </button>
                </div>
              </div>
            </div>
            {/* Form */}



           

            {/* Heading */}

            {/* Navigation */}

            <ul className="md:flex-col md:min-w-full flex flex-col list-none md:mt-16 mt-10">
            <li className="items-center">
                <NavLink
                  style={({ isActive }) =>
                    isActive ? { backgroundColor: '#00B07B', color: '#ffffff', paddingLeft: "12px", borderRadius: "8px" } : { color: '#6E7093' }
                  }
                  className={
                    "text-sm  py-2 pl-3 font-light flex  " +
                    (window.location.href.indexOf("/admin-dashboard") !== -1
                      ? "text-[#6E7093] bg-[#ffffff] hover:text-[#fff] "
                      : "text-[#6E7093] bg-[#ffffff] rounded-[8px]")
                  }
                  to="/admin-dashboard"
                >
                 <MdDashboard className="mr-2" size={24}/>
                 <span className="mt-1">Dashboard</span>
                  
                </NavLink>
              </li>
             
              <li className="items-center mt-[18px]">
                <NavLink
                  style={({ isActive }) =>
                    isActive ? { backgroundColor: '#00B07B',color: '#ffffff', paddingLeft: "12px", borderRadius: "8px" } : { color: '#6E7093' }
                  }
                  className={
                    "text-sm  py-2 pl-3 font-light flex  " +
                    (window.location.href.indexOf("/list-of-students") !== -1
                      ? "text-[#6E7093] bg-[#ffffff] hover:text-lightBlue-600"
                      : "text-[#6E7093] bg-[#ffffff] rounded-[8px] hover:text-blueGray-500")
                  }
                  to="/list-of-students"
                >
                 <FaUser className="mr-2" size={24}/>
                 <span className="mt-1">Students</span>
                </NavLink>
              </li>


              <li className="items-center mt-[18px]">
                <NavLink
                  style={({ isActive }) =>
                    isActive ? { backgroundColor: '#00B07B',color: '#ffffff', paddingLeft: "12px", borderRadius: "8px" } : { color: '#6E7093' }
                  }
                  className={
                    "text-sm  py-2 pl-3 font-light flex  " +
                    (window.location.href.indexOf("/edupremium-form-list") !== -1
                      ? "text-[#6E7093] bg-[#ffffff] hover:text-lightBlue-600"
                      : "text-[#6E7093] bg-[#ffffff] rounded-[8px] hover:text-blueGray-500")
                  }
                  to="/edupremium-form-list"
                >
                 <FaUser className="mr-2" size={24}/>
                 <span className="mt-1">Edupremium Form</span>
                </NavLink>
              </li>



              <li className="items-center mt-[18px]">
                <NavLink
                  style={({ isActive }) =>
                    isActive ? { backgroundColor: '#00B07B',color: '#ffffff', paddingLeft: "12px", borderRadius: "8px" } : { color: '#6E7093' }
                  }
                  className={
                    "text-sm  py-2 pl-3 font-light flex  " +
                    (window.location.href.indexOf("/list-of-students-loan") !== -1
                      ? "text-[#6E7093] bg-[#ffffff] hover:text-lightBlue-600"
                      : "text-[#6E7093] bg-[#ffffff] rounded-[8px] hover:text-blueGray-500")
                  }
                  to="/list-of-students-loan"
                >
                 <AiFillDollarCircle className="mr-2" size={24}/>
                 <span className="mt-1">Student Loan</span>
                </NavLink>
              </li>
              
              <li className="items-center mt-[18px]">
                <NavLink
                  style={({ isActive }) =>
                    isActive ? { backgroundColor: '#00B07B',color: '#ffffff', paddingLeft: "12px", borderRadius: "8px" } : { color: '#6E7093' }
                  }
                  className={
                    "text-sm  py-2 pl-3 font-light flex  " +
                    (window.location.href.indexOf("/list-of-school-form") !== -1
                      ? "text-[#6E7093] bg-[#ffffff] hover:text-lightBlue-600"
                      : "text-[#6E7093] bg-[#ffffff] rounded-[8px] hover:text-blueGray-500")
                  }
                  to="/list-of-school-form"
                >
                 <FaSchool className="mr-2" size={24}/>
                 <span className="mt-1">School Form</span>
                </NavLink>
              </li>
              

              <li className="items-center mt-[18px]">
                <NavLink
                  style={({ isActive }) =>
                    isActive ? { backgroundColor: '#00B07B', paddingLeft: "12px", borderRadius: "8px" } : { color: '#6E7093' }
                  }
                  className={
                    "text-sm  py-2 pl-3 font-light flex  " +
                    (window.location.href.indexOf("/list-of-tuition-payment") !== -1
                      ? "text-white hover:text-lightBlue-600"
                      : "text-[#6E7093] bg-[#ffffff] rounded-[8px] hover:text-blueGray-500")
                  }
                  to="/list-of-tuition-payment"
                >
                  <SlGraduation className="mr-2" size={24}/>
                  <span className="mt-1"> Tuition Payment</span>     
                </NavLink>
              </li>
              {/* <li className="items-center mt-[18px]">
                <NavLink
                  style={({ isActive }) =>
                    isActive ? { backgroundColor: '#00B07B', paddingLeft: "12px", borderRadius: "8px" } : { color: '#6E7093' }
                  }
                  className={
                    "text-sm  py-2 pl-3 font-light flex  " +
                    (window.location.href.indexOf("/list-of-loans") !== -1
                      ? "text-white hover:text-lightBlue-600"
                      : "text-[#6E7093] bg-[#ffffff] rounded-[8px] hover:text-blueGray-500")
                  }
                  to="/list-of-loans"
                >
                  <AiFillDollarCircle className="mr-2" size={24} />
                  <span className="mt-1"> Loans</span>     
                </NavLink>
              </li> */}

              <li className="items-center mt-[18px]">
                <NavLink
                  style={({ isActive }) =>
                    isActive ? { backgroundColor: '#00B07B', paddingLeft: "12px", borderRadius: "8px" } : { color: '#6E7093' }
                  }
                  className={
                    "text-sm  py-2 pl-3 font-light flex  " +
                    (window.location.href.indexOf("/list-of-pof") !== -1
                      ? "text-white hover:text-lightBlue-600"
                      : "text-[#6E7093] bg-[#ffffff] rounded-[8px] hover:text-blueGray-500")
                  }
                  to="/list-of-pof"
                >
                  <TbReceipt className="mr-2" size={24}/>
                  <span className="mt-1">Proof of Funds</span>
                  
                </NavLink>
              </li>

              {/* <li className="items-center mt-[18px]">
                <NavLink
                  style={({ isActive }) =>
                    isActive ? { backgroundColor: '#00B07B', paddingLeft: "12px", borderRadius: "8px" } : { color: '#6E7093' }
                  }
                  className={
                    "text-sm  py-2 pl-3 font-light flex  " +
                    (window.location.href.indexOf("/") !== -1
                      ? "text-white hover:text-[lightBlue-600]"
                      : "text-[#6E7093] bg-[#ffffff] rounded-[8px] hover:text-blueGray-500")
                  }
                  to="/"
                >
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" className="mr-2" viewBox="0 0 24 24"><path fill="#6E7093" d="M22 6h-7a6 6 0 1 0 0 12h7v2a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1h18a1 1 0 0 1 1 1v2zm-7 2h8v8h-8a4 4 0 1 1 0-8zm0 3v2h3v-2h-3z" /></svg>{" "}
                  KYC
                </NavLink>
              </li> */}

              <li className="items-center mt-[18px]">
                <NavLink
                  style={({ isActive }) =>
                    isActive ? { backgroundColor: '#00B07B', paddingLeft: "12px", borderRadius: "8px" } : { color: '#6E7093' }
                  }
                  className={
                    "text-sm  py-2 pl-3 font-light flex  " +
                    (window.location.href.indexOf("/schools") !== -1
                      ? "text-white hover:text-[lightBlue-600]"
                      : "text-[#6E7093] bg-[#ffffff] rounded-[8px] hover:text-blueGray-500")
                  }
                  to="/schools"
                >
                  <FaSchool className="mr-2" size={24}/>
                  <span className="mt-1">Schools</span>
                </NavLink>
              </li>

          


              {/* onClick={logOut} */}
              {/* <li className="flex justify-start py-10" style={{ cursor: 'pointer' }} onClick={logOut}>

                <svg width="20" className="mr-2" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M13.3337 11.3334V9.66675H5.83366V7.16675L1.66699 10.5001L5.83366 13.8334V11.3334H13.3337Z" fill="white" />
                  <path d="M16.6667 3H9.16667C8.2475 3 7.5 3.7475 7.5 4.66667V8H9.16667V4.66667H16.6667V16.3333H9.16667V13H7.5V16.3333C7.5 17.2525 8.2475 18 9.16667 18H16.6667C17.5858 18 18.3333 17.2525 18.3333 16.3333V4.66667C18.3333 3.7475 17.5858 3 16.6667 3Z" fill="white" />
                </svg>

                <span className=" text-white">Logout</span>

              </li> */}
            </ul>











          </div>
        </div>
      </nav>
    </>
  );
}
