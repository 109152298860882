import {createSlice} from '@reduxjs/toolkit';

const initialStateValue = {
    nationality: "",
    admission_letter: "",
    country_of_residence: "",
    country_of_School_accepted_to: "",
    school_accepted_to: ""

}

const loanCreditlice = createSlice({
    name: 'Credit',
    initialState: {
        value: initialStateValue,
    },

    reducers: {
        credit : (state,action) =>{
            state.value = action.payload;
        },
    },
});

export const {credit} = loanCreditlice.actions

export default loanCreditlice.reducer