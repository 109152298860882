import React, {useState} from "react";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from 'react-redux';
import { Dispatch } from "redux";
import { AuthApis } from "../../apis/authApis";
import { login } from "../../reducer/loginSlice";
import { NavLink, useNavigate,Link } from "react-router-dom";
export default function AdminNavbar({ title }) {
  const [toggle, setToggle] = useState(false)

  const dispatch = useDispatch();
  const userLoginData = useSelector((state) => state.data.login.value);

  React.useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  
  const navigate = useNavigate();


  React.useEffect(() => {
    if(!userLoginData?.token){
      dispatch(login([]))
      navigate('/sign-in');
    }
     
  }, []);

  React.useEffect(() => {
    if(!userLoginData.token || (userLoginData.data?.user_data?.role == 'user')){
      dispatch(login([]))
      navigate('/sign-in');
    }
  }, []);

  const logOut = () => {
    AuthApis.logout('').then(
      (response) => {
        if (response?.data) {
          dispatch(login([]))
          navigate('/sign-in');

        }
      }
    ).catch(function (error) {
      dispatch(login([]))
      navigate('/sign-in');
    }).finally(function(){
      dispatch(login([]))
          navigate('/sign-in');
    }
      
    )

  };


  return (
    <>
      <div className="relative bg-[#FAFAFB] md:pt-12 md:pb-6">
        {/* Navbar */}
        <nav className="absolute top-0 left-0 w-full z-10 bg-transparent md:flex-row md:flex-nowrap md:justify-start flex items-center  p-4 ">
          <div className="w-full mx-auto items-center flex justify-between md:flex-nowrap flex-wrap md:px-10 px-4">
            {/* Brand */}
            <a
              className="text-[#000000] text-[20px]  hidden lg:inline-block font-semibold"
              href="#pablo"
              onClick={(e) => e.preventDefault()}
            >
              {title}
            </a>


           
          </div>
          <div className="md:pr-14 md:flex space-x-2 hidden">
         
          <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32"><path fill="none" d="M8.007 24.93A4.996 4.996 0 0 1 13 20h6a4.996 4.996 0 0 1 4.993 4.93a11.94 11.94 0 0 1-15.986 0M20.5 12.5A4.5 4.5 0 1 1 16 8a4.5 4.5 0 0 1 4.5 4.5"/><path fill="#6e7093" d="M26.749 24.93A13.99 13.99 0 1 0 2 16a13.899 13.899 0 0 0 3.251 8.93l-.02.017c.07.084.15.156.222.239c.09.103.187.2.28.3c.28.304.568.596.87.87c.092.084.187.162.28.242c.32.276.649.538.99.782c.044.03.084.069.128.1v-.012a13.901 13.901 0 0 0 16 0v.012c.044-.031.083-.07.128-.1c.34-.245.67-.506.99-.782c.093-.08.188-.159.28-.242c.302-.275.59-.566.87-.87c.093-.1.189-.197.28-.3c.071-.083.152-.155.222-.24ZM16 8a4.5 4.5 0 1 1-4.5 4.5A4.5 4.5 0 0 1 16 8M8.007 24.93A4.996 4.996 0 0 1 13 20h6a4.996 4.996 0 0 1 4.993 4.93a11.94 11.94 0 0 1-15.986 0"/></svg>
         <div onClick={() => setToggle((prev) => (!prev))} className="flex space-x-2 cursor-pointer relative">
         <h3 className="text-[14px] text-[#6E7093] mt-2">{userLoginData?.name}</h3> 
         <span className="mt-1.5">
                      {toggle ?
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="#6E7093" d="m16.9 13.4l-4.2-4.2c-.4-.4-1-.4-1.4 0l-4.2 4.2c-.4.4-.4 1 0 1.4s1 .4 1.4 0l3.5-3.5l3.5 3.5c.2.2.4.3.7.3c.3 0 .5-.1.7-.3c.4-.4.4-1 0-1.4z" /></svg>
                        :
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="#6E7093" d="M12 15.121a.997.997 0 0 1-.707-.293L7.05 10.586a1 1 0 0 1 1.414-1.414L12 12.707l3.536-3.535a1 1 0 0 1 1.414 1.414l-4.243 4.242a.997.997 0 0 1-.707.293Z" /></svg>
                      }
                    </span>
          </div>
       
          <div className={`${toggle ? 'flex' : 'hidden'} absolute -bottom-24 z-10 border border-gray-100 rounded-md bg-white shadow-lg`}>
            <div className="pt-3 pl-4 px-10 pb-4">
              <div className="mt-1 mb-3 text-[16px] cursor-pointer hover:text-[#6E7093]">
              <Link to="/profile" >
              Profile
              </Link>
              </div>
              <div className="mt-1 mb-3 text-[16px] cursor-pointer hover:text-[#6E7093]">
              <div onClick={logOut} >
              Log Out
              </div>
              </div>
             


            </div>
                 
                </div>
          </div>
        </nav>
      </div>
      {/* End Navbar */}
    </>
  );
}

AdminNavbar.defaultProps = {
  title: " ",
};
