import React from 'react'
import Sidebar from "../../Sidebar/AdminSidebar";
import UserNavbar from "../../Navbars/AdminNavbar";
import { SvgElement, icontypesEnum } from '../../assets/svgElement';
function TuitionPaymentList() {
  return (
    <>
    <Sidebar />
    <div className="relative md:ml-64">
        <UserNavbar title="Tuition Payment" />
        <div className='pt-5 lg:px-10 px-6 bg-[#F8F8F8] min-h-screen'>


        <div className='p-4 grid grid-cols-1 md:grid-cols-4 gap-5'>
                        <div className='bg-[#fff] px-5 py-4 flex justify-between rounded-[10px]'>
                            <div className='mt-2'>
                                <h3 className='text-[#A0AEC0] text-[12px]'>Total Disbusted Funds</h3>
                                <h1 className='text-[#2D3748] text-[18px] font-semibold'>$53400</h1>
                            </div>
                            <div>
                            <svg width="57" height="56" viewBox="0 0 57 56" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g filter="url(#filter0_d_577_3702)">
                                        <rect x="6" y="2" width="45" height="45" rx="12" fill="#00B07B" />
                                    </g>
                                    <path d="M21.4464 17.8176H35.5089C35.6732 17.8175 35.8373 17.8279 36.0002 17.8488C35.945 17.4611 35.8118 17.0886 35.6088 16.7537C35.4057 16.4189 35.1369 16.1286 34.8186 15.9005C34.5003 15.6723 34.1391 15.511 33.7568 15.4262C33.3745 15.3414 32.9789 15.3349 32.594 15.4072L21.029 17.3816H21.0158C20.2898 17.5204 19.6443 17.9312 19.2109 18.5299C19.8638 18.0655 20.6453 17.8165 21.4464 17.8176Z" fill="white" />
                                    <path d="M35.5078 18.875H21.4453C20.6996 18.8758 19.9847 19.1724 19.4575 19.6997C18.9302 20.2269 18.6336 20.9418 18.6328 21.6875V30.125C18.6336 30.8707 18.9302 31.5856 19.4575 32.1128C19.9847 32.6401 20.6996 32.9367 21.4453 32.9375H35.5078C36.2535 32.9367 36.9684 32.6401 37.4956 32.1128C38.0229 31.5856 38.3195 30.8707 38.3203 30.125V21.6875C38.3195 20.9418 38.0229 20.2269 37.4956 19.6997C36.9684 19.1724 36.2535 18.8758 35.5078 18.875ZM33.4204 27.3125C33.1423 27.3125 32.8704 27.23 32.6391 27.0755C32.4079 26.921 32.2276 26.7014 32.1212 26.4444C32.0148 26.1874 31.9869 25.9047 32.0412 25.6319C32.0954 25.3591 32.2294 25.1085 32.426 24.9119C32.6227 24.7152 32.8733 24.5813 33.1461 24.527C33.4188 24.4728 33.7016 24.5006 33.9586 24.607C34.2155 24.7135 34.4351 24.8937 34.5897 25.125C34.7442 25.3562 34.8267 25.6281 34.8267 25.9062C34.8267 26.2792 34.6785 26.6369 34.4148 26.9006C34.151 27.1643 33.7934 27.3125 33.4204 27.3125Z" fill="white" />
                                    <path d="M18.6562 24.6523V20.2798C18.6562 19.3275 19.1836 17.731 21.0139 17.3851C22.5674 17.0937 24.1055 17.0938 24.1055 17.0938C24.1055 17.0938 25.1162 17.7969 24.2812 17.7969C23.4463 17.7969 23.4683 18.8735 24.2812 18.8735C25.0942 18.8735 24.2812 19.9062 24.2812 19.9062L21.0073 23.6196L18.6562 24.6523Z" fill="white" />
                                    <defs>
                                        <filter id="filter0_d_577_3702" x="0.5" y="-2.38419e-07" width="56" height="56" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                            <feFlood flood-opacity="0" result="BackgroundImageFix" />
                                            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                                            <feOffset dy="3.5" />
                                            <feGaussianBlur stdDeviation="2.75" />
                                            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.02 0" />
                                            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_577_3702" />
                                            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_577_3702" result="shape" />
                                        </filter>
                                    </defs>
                                </svg>

                            </div>

                        </div>
                        <div className='bg-[#fff] px-5 py-4 flex justify-between rounded-[10px]'>
                            <div className='mt-2'>
                                <h3 className='text-[#A0AEC0] text-[12px]'>Comfirmed Payments</h3>
                                <h1 className='text-[#2D3748] text-[18px] font-semibold'>130</h1>
                            </div>
                            <div>
                                <svg width="57" height="56" viewBox="0 0 57 56" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g opacity="0.1" filter="url(#filter0_d_577_4003)">
                                        <rect x="6" y="2" width="45" height="45" rx="12" fill="#00B07B" />
                                    </g>
                                    <path d="M40.5906 14.5458C40.5831 14.5101 40.5654 14.4773 40.5396 14.4511C40.5138 14.425 40.481 14.4066 40.445 14.3981C38.0427 13.8204 32.492 15.8791 29.4848 18.8358C28.9484 19.3591 28.4594 19.9273 28.0234 20.5342C27.096 20.4535 26.1686 20.5208 25.3783 20.8597C23.1483 21.8251 22.499 24.3441 22.3181 25.4276C22.3078 25.487 22.3117 25.5478 22.3294 25.6054C22.3471 25.663 22.3781 25.7157 22.4201 25.7595C22.462 25.8033 22.5137 25.8369 22.5711 25.8578C22.6285 25.8786 22.69 25.886 22.7508 25.8795L26.3319 25.491C26.3344 25.7565 26.3507 26.0218 26.3807 26.2857C26.3987 26.469 26.4814 26.6403 26.6145 26.7698L28.0012 28.1306C28.133 28.2613 28.3071 28.3426 28.4934 28.3605C28.7602 28.3899 29.0284 28.4059 29.2969 28.4085L28.904 31.9263C28.8974 31.9861 28.905 32.0466 28.9262 32.103C28.9474 32.1594 28.9816 32.2102 29.0261 32.2514C29.0706 32.2927 29.1242 32.3232 29.1827 32.3406C29.2412 32.3581 29.3031 32.3619 29.3634 32.3519C30.463 32.1785 33.0285 31.5399 34.0043 29.3465C34.3488 28.5691 34.4193 27.6614 34.3398 26.7537C34.9582 26.3248 35.5375 25.8437 36.071 25.3159C39.0877 22.3637 41.1689 17.026 40.5906 14.5458ZM33.0613 21.794C32.7859 21.5233 32.5982 21.1782 32.5222 20.8026C32.4461 20.4269 32.485 20.0375 32.634 19.6836C32.7829 19.3297 33.0353 19.0272 33.3591 18.8144C33.6829 18.6015 34.0635 18.4879 34.453 18.4879C34.8424 18.4879 35.2231 18.6015 35.5469 18.8144C35.8707 19.0272 36.123 19.3297 36.272 19.6836C36.421 20.0375 36.4599 20.4269 36.3838 20.8026C36.3077 21.1782 36.1201 21.5233 35.8446 21.794C35.662 21.974 35.445 22.1167 35.2063 22.2141C34.9675 22.3115 34.7115 22.3616 34.453 22.3616C34.1945 22.3616 33.9385 22.3115 33.6997 22.2141C33.4609 22.1167 33.244 21.974 33.0613 21.794Z" fill="#00B07B" />
                                    <path d="M27.907 29.1136C27.6823 29.3351 27.3217 29.4214 26.8878 29.4952C25.9129 29.6586 25.0519 28.83 25.2271 27.8606C25.2939 27.493 25.4916 26.9779 25.6147 26.8569C25.6416 26.8309 25.6595 26.7973 25.6658 26.7608C25.6722 26.7243 25.6667 26.6867 25.6501 26.6534C25.6336 26.6201 25.6067 26.5929 25.5735 26.5754C25.5403 26.558 25.5023 26.5513 25.465 26.5563C24.9198 26.6219 24.4126 26.865 24.0241 27.247C23.0598 28.1962 22.9688 31.7185 22.9688 31.7185C22.9688 31.7185 26.5519 31.6289 27.5162 30.6797C27.9057 30.2979 28.1532 29.7982 28.2187 29.2612C28.2339 29.0926 28.0252 28.9922 27.907 29.1136Z" fill="#00B07B" />
                                    <defs>
                                        <filter id="filter0_d_577_4003" x="0.5" y="-2.38419e-07" width="56" height="56" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                            <feFlood flood-opacity="0" result="BackgroundImageFix" />
                                            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                                            <feOffset dy="3.5" />
                                            <feGaussianBlur stdDeviation="2.75" />
                                            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.02 0" />
                                            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_577_4003" />
                                            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_577_4003" result="shape" />
                                        </filter>
                                    </defs>
                                </svg>




                            </div>

                        </div>
                        <div className='bg-[#fff] px-5 py-4 flex justify-between rounded-[10px]'>
                            <div className='mt-2'>
                                <h3 className='text-[#A0AEC0] text-[12px]'>Pending Payments</h3>
                                <h1 className='text-[#2D3748] text-[18px] font-semibold'>48</h1>
                            </div>
                            <div>
                                <svg width="57" height="56" viewBox="0 0 57 56" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g opacity="0.1" filter="url(#filter0_d_577_3994)">
                                        <rect x="6" y="2" width="45" height="45" rx="12" fill="#F29339" />
                                    </g>
                                    <path d="M23 14H35V20L31 24L35 28V34H23V28L27 24L23 20V14ZM33 28.5L29 24.5L25 28.5V32H33V28.5ZM29 23.5L33 19.5V16H25V19.5L29 23.5ZM27 18H31V18.75L29 20.75L27 18.75V18Z" fill="#F29339" />
                                    <defs>
                                        <filter id="filter0_d_577_3994" x="0.5" y="-2.38419e-07" width="56" height="56" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                            <feFlood flood-opacity="0" result="BackgroundImageFix" />
                                            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                                            <feOffset dy="3.5" />
                                            <feGaussianBlur stdDeviation="2.75" />
                                            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.02 0" />
                                            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_577_3994" />
                                            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_577_3994" result="shape" />
                                        </filter>
                                    </defs>
                                </svg>


                            </div>

                        </div>
                        <div className='bg-[#fff] px-5 py-4 flex justify-between rounded-[10px]'>
                            <div className='mt-2'>
                                <h3 className='text-[#A0AEC0] text-[12px]'>Failed Payments</h3>
                                <h1 className='text-[#2D3748] text-[18px] font-semibold'>20</h1>
                            </div>
                            <div>
                                <svg width="57" height="56" viewBox="0 0 57 56" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g opacity="0.1" filter="url(#filter0_d_577_4003)">
                                        <rect x="6" y="2" width="45" height="45" rx="12" fill="#00B07B" />
                                    </g>
                                    <path d="M40.5906 14.5458C40.5831 14.5101 40.5654 14.4773 40.5396 14.4511C40.5138 14.425 40.481 14.4066 40.445 14.3981C38.0427 13.8204 32.492 15.8791 29.4848 18.8358C28.9484 19.3591 28.4594 19.9273 28.0234 20.5342C27.096 20.4535 26.1686 20.5208 25.3783 20.8597C23.1483 21.8251 22.499 24.3441 22.3181 25.4276C22.3078 25.487 22.3117 25.5478 22.3294 25.6054C22.3471 25.663 22.3781 25.7157 22.4201 25.7595C22.462 25.8033 22.5137 25.8369 22.5711 25.8578C22.6285 25.8786 22.69 25.886 22.7508 25.8795L26.3319 25.491C26.3344 25.7565 26.3507 26.0218 26.3807 26.2857C26.3987 26.469 26.4814 26.6403 26.6145 26.7698L28.0012 28.1306C28.133 28.2613 28.3071 28.3426 28.4934 28.3605C28.7602 28.3899 29.0284 28.4059 29.2969 28.4085L28.904 31.9263C28.8974 31.9861 28.905 32.0466 28.9262 32.103C28.9474 32.1594 28.9816 32.2102 29.0261 32.2514C29.0706 32.2927 29.1242 32.3232 29.1827 32.3406C29.2412 32.3581 29.3031 32.3619 29.3634 32.3519C30.463 32.1785 33.0285 31.5399 34.0043 29.3465C34.3488 28.5691 34.4193 27.6614 34.3398 26.7537C34.9582 26.3248 35.5375 25.8437 36.071 25.3159C39.0877 22.3637 41.1689 17.026 40.5906 14.5458ZM33.0613 21.794C32.7859 21.5233 32.5982 21.1782 32.5222 20.8026C32.4461 20.4269 32.485 20.0375 32.634 19.6836C32.7829 19.3297 33.0353 19.0272 33.3591 18.8144C33.6829 18.6015 34.0635 18.4879 34.453 18.4879C34.8424 18.4879 35.2231 18.6015 35.5469 18.8144C35.8707 19.0272 36.123 19.3297 36.272 19.6836C36.421 20.0375 36.4599 20.4269 36.3838 20.8026C36.3077 21.1782 36.1201 21.5233 35.8446 21.794C35.662 21.974 35.445 22.1167 35.2063 22.2141C34.9675 22.3115 34.7115 22.3616 34.453 22.3616C34.1945 22.3616 33.9385 22.3115 33.6997 22.2141C33.4609 22.1167 33.244 21.974 33.0613 21.794Z" fill="#00B07B" />
                                    <path d="M27.907 29.1136C27.6823 29.3351 27.3217 29.4214 26.8878 29.4952C25.9129 29.6586 25.0519 28.83 25.2271 27.8606C25.2939 27.493 25.4916 26.9779 25.6147 26.8569C25.6416 26.8309 25.6595 26.7973 25.6658 26.7608C25.6722 26.7243 25.6667 26.6867 25.6501 26.6534C25.6336 26.6201 25.6067 26.5929 25.5735 26.5754C25.5403 26.558 25.5023 26.5513 25.465 26.5563C24.9198 26.6219 24.4126 26.865 24.0241 27.247C23.0598 28.1962 22.9688 31.7185 22.9688 31.7185C22.9688 31.7185 26.5519 31.6289 27.5162 30.6797C27.9057 30.2979 28.1532 29.7982 28.2187 29.2612C28.2339 29.0926 28.0252 28.9922 27.907 29.1136Z" fill="#00B07B" />
                                    <defs>
                                        <filter id="filter0_d_577_4003" x="0.5" y="-2.38419e-07" width="56" height="56" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                            <feFlood flood-opacity="0" result="BackgroundImageFix" />
                                            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                                            <feOffset dy="3.5" />
                                            <feGaussianBlur stdDeviation="2.75" />
                                            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.02 0" />
                                            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_577_4003" />
                                            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_577_4003" result="shape" />
                                        </filter>
                                    </defs>
                                </svg>



                            </div>

                        </div>
                    </div>

            <div className='p-4'>


                <div className="relative overflow-x-auto sm:rounded-lg">
                    <table className="w-full text-sm text-left rtl:text-right text-gray-500 ">
                        <thead className="text-xs text-gray-700  border-none">
                            <tr>
                                <th scope="col" className="px-6 py-3">
                                    Reg ID
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Name
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    School
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Amount
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Status
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    <span className="sr-only">Edit</span>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr className="bg-white hover:bg-gray-50 hover:shadow-md rounded-lg mb-3 border-none">
                                <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap ">
                                    #876364
                                </th>
                                <td className="px-6 py-4">
                                    Omolade Samuel
                                </td>
                                <td className="px-6 py-4">
                                    University of England
                                </td>
                                <td className="px-6 py-4">
                                    $56,000.00
                                </td>
                                <td className="px-6 py-4">
                                    <div className='bg-[#EDF6F5] text-[#3A974C] text-[12px] rounded-[22px] items-center text-center py-2 px-3'>Confirmed</div>
                                </td>
                                <td className="px-6 py-4 text-right">
                                    <SvgElement type={icontypesEnum.SEEMORE} />
                                </td>
                            </tr>
                            <tr className="bg-white hover:bg-gray-50 hover:shadow-md rounded-lg mb-3 border-none">
                                <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap ">
                                    #876364
                                </th>
                                <td className="px-6 py-4">
                                    James Lawrance
                                </td>
                                <td className="px-6 py-4">
                                    University of Finland
                                </td>
                                <td className="px-6 py-4">
                                    $45,000.00
                                </td>
                                <td className="px-6 py-4">
                                    <div className='bg-[#FBE8EB] text-[#DB1E36] text-[12px] rounded-[22px] items-center text-center py-2 px-3'>Failed</div>
                                </td>
                                <td className="px-6 py-4 text-right">
                                    <SvgElement type={icontypesEnum.SEEMORE} />
                                </td>
                            </tr>

                        </tbody>
                    </table>
                </div>

            </div>



        </div>
    </div>
</>
  )
}

export default TuitionPaymentList