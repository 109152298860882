import React, { useState } from 'react'
import Sidebar from '../../Sidebar/Sidebar'
import UserNavbar from '../../Navbars/UserNavbar'
import { Link } from 'react-router-dom'
import { Chart as ChartJs, Tooltip, Title, ArcElement, Legend, registerables } from "chart.js";
import { Doughnut, getElementsAtEvent } from "react-chartjs-2";

const LoanPremiumCongrat = () => {
    const [dataArray, setDataArray] = useState<number[]>([75,25]);

    ChartJs.register(...registerables, Tooltip, Title, ArcElement, Legend);
  
      // Custom plugin to draw text in the center
      const centerTextPlugin = {
        id: 'centerText',
        beforeDraw: function (chart:any) {
          const { width, height, ctx } = chart;
          ctx.restore();
          const fontSize = (height / 114).toFixed(2);
          ctx.font = `${fontSize}em sans-serif`;
          ctx.textBaseline = "middle";
    
          const text = `${((dataArray[0] / dataArray.reduce((a, b) => a + b, 0)) * 100)}%`;
          const textX = Math.round((width - ctx.measureText(text).width) / 2);
          const textY = height / 2;
    
          ctx.fillText(text, textX, textY);
          ctx.save();
        }
      };
  
    const data = {
      datasets: [
        {
          data: dataArray,
          backgroundColor: ["#00B07B", "#D9D9D9"],
          cutout: '70%',  // Adjust this value to make the doughnut thinner
        },
      ],
      // These labels appear in the legend and in the tooltips when hovering different arcs
      labels: ["Send", "Receive", ],
    };
  
    const options = {
      plugins: {
        legend: {
          display: false, // Hide legend
        },
        tooltip: {
          enabled: false, // Disable tooltips
        },
      },
    };
  
  
  return (
    <div>
    <Sidebar />
 <div className="relative md:ml-64">
   <UserNavbar />
   <div className="lg:mt-3 lg:px-10 pb-6 pt-2 px-6 bg-[#F8F8F8] min-h-screen">
     {/* <HeaderTitle title="Loan" /> */}
     <div className="flex justify-center">
       <div className="pt-3 pb-7">
         <h3 className="text-[#000000] text-center text-[36px] font-[600]">
           Credit Eligibility
         </h3>
         <h5 className="text-[#5F5D5D] text-center text-[20px] font-[500]">
           Check your credit score to apply for loan
         </h5>
       </div>
     </div>

     <div className=" gap-1">
     <div className=" bg-white pt-2 px-2 rounded-[10px]">
<div className='flex justify-center pt-8 pb-4'>
     <img src="/images/pana.png" alt="hero" />

     </div>
     <h4 className="text-center text-[30px] font-bold">Congratulation you are eligible</h4>
     <h4 className="text-center text-[20px]">You can now proceed to fill loan form</h4>
     <div className="flex justify-center mx-10 pb-10 pt-7">
              <Link to='/loans-premium-plan-form' className="text-white  bg-[#00B07B] flex justify-center items-center py-3 rounded-[10px] w-[500px]">
            Proceed to school form
              </Link>
            </div>

     </div>
     {/* <div className="lg:col-span-4">
       <div className="border rounded-[10px] h-fit  w-full flex justify-center relative">
         <div>
      <h4 className="text-[#000000] text-[20px] text-center pt-4 font-semibold">EduPremium Stage</h4>
       <div className="p-4  md:w-[180px] md:h-[180px]">
                   <Doughnut options={options} data={data} plugins={[centerTextPlugin]}/>
                 </div>
                 <div className="flex justify-center">
                 <h4>On Progress <span className="text-[#00B07B]">75%</span></h4>
                 </div>
                 <div className="flex justify-center mt-3 mb-10">
                   <h4 className=" text-center max-w-[200px]">Complete your eligibility to access the loan form</h4>
                 </div>
                 </div>
       </div>

       <div className="rounded-[10px] mt-8">
<div className="bg-white rounded-t-[10px] py-2 flex justify-center">
<h4 className="text-[#000000] text-[20px]">What to do next</h4>
</div>
<div className="bg-[#00B07B] rounded-b-[10px] flex justify-center pt-3 pb-5">
<h4 className="text-white text-[18px] text-center max-w-[300px]">Wait for your review to be confrim</h4>
</div>
       </div>
       </div> */}
     <div>

     </div>

     </div>

</div>
</div>

</div>
  )
}

export default LoanPremiumCongrat
