import React, { useState } from 'react'
import Sidebar from "../../Sidebar/AdminSidebar";
import UserNavbar from "../../Navbars/AdminNavbar";
import { useParams } from 'react-router-dom';
import { NavLink, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { AdminApis } from '../../../apis/adminApi';
import { AxiosResponse } from 'axios';
import { ToastContainer, toast } from 'react-toastify';


function StudentDetails() {
    const [openTestTab, setOpenTestTab] = useState(1);

    const params: any = useParams();
    let id: string = params?.id;
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [userData, setUserData] = React.useState<any>([]);
    const [status, setStatus] = React.useState<any>('');
    const userLoginData = useSelector((state: any) => state.data.login.value);

    React.useEffect(() => {
        AdminApis.getSingleUser(id).then(
            (response:AxiosResponse) => {
                if (response?.data) {
                    setUserData(response?.data?.data)
                }

                // toast.success(response?.data?.message);
            }
        ).catch(function (error) {
            // handle error
            console.log(error.response.data);
            // toast.error(error.response.data?.message);
        })



        // userLoginData.token ?
        // ''
        // :
        //   navigate('/sign-in');
    }, []);

    console?.log(status)


    const changeStatus = React.useCallback(
        (id: any) => {
            //   setLoader(true);
            const query: any = {
                status: status,
            };

            AdminApis.updateStatus(id, query).then(
                (response: AxiosResponse<any>) => {
                    if (response?.data) {

                        toast.success("Status Updated")

                        AdminApis.getSingleUser(id).then(
                            (response:AxiosResponse) => {
                                if (response?.data) {
                                    setUserData(response?.data?.data)
                                }
                
                                // toast.success(response?.data?.message);
                            }
                        )
                        // setUserList(response?.data?.data)
                    }
                }
            ).catch(function (error) {
                console.log(error.response.data);
            })

        }, [status,userData]);


// console.log(userData)



    return (
        <>
            <Sidebar />
            <div className="relative md:ml-64">
                <UserNavbar title="User" />
                <div className='pt-5 pb-6 lg:px-10 px-6 bg-[#F8F8F8] min-h-screen'>

                    <div className='p-4'>
                        <div className='bg-white rounded-[15px] pt-5 pb-3 px-6 flex justify-between'>
                            <div>
                                <h3 className='text-[18px] font-semibold'>{userData?.first_name + ' ' + userData?.last_name}</h3>
                                <h5 className='text-[14px] text-[#A9A9A9]'>Registration ID : {userData?.user_id}</h5>
                                <h5 className='text-[14px] text-[#A9A9A9]'>Registration Date : {(userData?.created_at)?.slice(0,10)} </h5>
                            </div>
                            <div>
                                <span className='bg-[#EDF6F5] text-[#3A974C] text-[12px] rounded-[22px] items-center text-center py-2 capitalize px-6'>{userData?.status}</span>

                                <div className='flex mt-5 space-x-3'>
                                    <form>
                                        <div className="relative w-full">
                                            <select id="status" name="status" onChange={(e)=>setStatus(e.target.value)}
                                                className=" bg-white border border-[#DADADA] text-[#000] text-sm rounded-[5px] block w-full px-3 py-2.5 placeholder-[#000]  ease-linear transition-all duration-150">
                                                <option selected>Choose a Status</option>
                                                <option value="approved">Approved</option>
                                                <option value="inReview">In Review</option>
                                                <option value="pending">Pending</option>
                                                <option value="cancelled">Cancelled</option>
                                            </select>
                                        </div>
                                    </form>
                                    <div>

                                        <button type='button' onClick={()=>changeStatus(userData?.id)} className='bg-[#00B07B] text-white rounded-[5px] py-[8px] px-[16px]'>Save</button>
                                        {/* <button type='button' className='bg-[#00B07B] text-white rounded-[5px] py-[8px] px-[16px]'>Save</button> */}
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='p-4'>


                        <div className="flex flex-wrap font-medium text-center ">
                            <div className="mr-2">
                                <div
                                    onClick={() => setOpenTestTab(1)}
                                    className={` ${openTestTab === 1 ? "bg-[#00B07B] text-[#ffffff]" : "text-black bg-[#FFFFFF]"}  rounded-[17px] px-[90px] py-3  cursor-pointer`}>KYC</div>
                            </div>
                            <div className="mr-2 md:mt-0 mt-3">
                                <div
                                    onClick={() => setOpenTestTab(2)}
                                    className={` ${openTestTab === 2 ? "bg-[#00B07B] text-[#ffffff]" : "text-black bg-[#FFFFFF]"}  rounded-[17px] px-[50px] py-3 cursor-pointer`}>Proof of Funds</div>
                            </div>
                            <div className="mr-2 md:mt-0 mt-3">
                                <div
                                    onClick={() => setOpenTestTab(3)}
                                    className={` ${openTestTab === 3 ? "bg-[#00B07B] text-[#ffffff]" : "text-black bg-[#FFFFFF]"}  rounded-[17px] px-[50px] py-3 cursor-pointer`}>Loans</div>
                            </div>
                            <div className="mr-2 md:mt-0 mt-3">
                                <div
                                    onClick={() => setOpenTestTab(4)}
                                    className={` ${openTestTab === 4 ? "bg-[#00B07B] text-[#ffffff]" : "text-black bg-[#FFFFFF]"}  rounded-[17px] px-[50px] py-3 cursor-pointer`}>Tuition Payment</div>
                            </div>
                        </div>

                        {/* -- KYC -- */}
                        <div className={openTestTab === 1 ? "block" : "hidden"}>
                            <div className='pt-4 flex flex-col lg:flex-row md:flex-row gap-5'>
                                <div className=' basis-4/12'>
                                    <div className='bg-white px-6 pt-5 pb-10 rounded-[20px]'>
                                        <h3 className='text-[16px] font-semibold'>Personal Information</h3>
                                        <div className='mt-4'>
                                            <h3 className='text-[#A9A9A9] text-[14px]'>First Name</h3>
                                            <h2 className='mt-1 text-[14px] font-semibold'>{userData?.first_name}</h2>
                                        </div>
                                        <div className='mt-4'>
                                            <h3 className='text-[#A9A9A9] text-[14px]'>Last Name</h3>
                                            <h2 className='mt-1 text-[14px] font-semibold'>{userData?.last_name}</h2>
                                        </div>
                                        <div className='mt-4'>
                                            <h3 className='text-[#A9A9A9] text-[14px]'>Date of Birth</h3>
                                            <h2 className='mt-1 text-[14px] font-semibold'>{userData?.date_of_birth}</h2>
                                        </div>
                                        <div className='mt-4'>
                                            <h3 className='text-[#A9A9A9] text-[14px]'>Place of Birth</h3>
                                            <h2 className='mt-1 text-[14px] font-semibold'>{userData?.place_of_birth}</h2>
                                        </div>
                                        <div className='mt-4'>
                                            <h3 className='text-[#A9A9A9] text-[14px]'>State of Origin</h3>
                                            <h2 className='mt-1 text-[14px] font-semibold'>{userData?.state_of_origin}</h2>
                                        </div>
                                        <div className='mt-4'>
                                            <h3 className='text-[#A9A9A9] text-[14px]'>Nationality</h3>
                                            <h2 className='mt-1 text-[14px] font-semibold'>{userData?.nationality}</h2>
                                        </div>


                                    </div>
                                </div>
                                <div className=' basis-8/12'>
                                    <div className='bg-white px-6 pt-5 pb-10 rounded-[20px]'>
                                        <h3 className='text-[16px] font-semibold'>Uploaded Information</h3>
                                        <div className='flex justify-between'>
                                            <div className=''>
                                                <h3 className='mt-4'>Picture</h3>
                                                <img src={userData?.profile_picture} alt="hero" />
                                            </div>

                                            <div className=''>
                                                <h3 className='mt-4'>National ID </h3>
                                                <img src={userData?.nin_picture} alt="hero" />
                                            </div>

                                        </div>
                                        <div>
                                            <div className=''>
                                                <h3 className='mt-4 text-[#A9A9A9] text-[14px]'>Utility Bill Type </h3>

                                                <h2 className='mt-1 text-[14px] font-semibold'>NEPA</h2>
                                                <img src={userData?.utility_bill_picture} alt="hero" />
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div className='pt-5'>
                                <div className='bg-white px-6 pt-5 pb-10 rounded-[20px]'>
                                    <h3 className='text-[16px] font-semibold'>Other Information</h3>
                                    <div className='flex justify-between'>
                                        <div>
                                            <div className='mt-4'>
                                                <h3 className='text-[#A9A9A9] text-[14px]'>Occupation</h3>
                                                <h2 className='mt-1 text-[14px] font-semibold'>{userData?.occupation}</h2>
                                            </div>
                                            <div className='mt-4'>
                                                <h3 className='text-[#A9A9A9] text-[14px]'>Employment Status</h3>
                                                <h2 className='mt-1 text-[14px] font-semibold'>{userData?.employment_status}</h2>
                                            </div>
                                            <div className='mt-4'>
                                                <h3 className='text-[#A9A9A9] text-[14px]'>Highest Level of Education Attained</h3>
                                                <h2 className='mt-1 text-[14px] font-semibold'>{userData?.level_of_education}</h2>
                                            </div>

                                        </div>
                                        <div>
                                            <div className='mt-4'>
                                                <h3 className='text-[#A9A9A9] text-[14px]'>NIN (National Identification Number)</h3>
                                                <h2 className='mt-1 text-[14px] font-semibold'>{userData?.nin_number}</h2>
                                            </div>
                                            <div className='mt-4'>
                                                <h3 className='text-[#A9A9A9] text-[14px]'>Address</h3>
                                                <h2 className='mt-1 text-[14px] font-semibold'>{userData?.address}</h2>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>

                        {/* -- Proof of Funds -- */}
                        <div className={openTestTab === 2 ? "block" : "hidden"}>
                            <div className='pt-5'>
                                <div className='bg-white px-6 pt-5 pb-10 rounded-[20px]'>
                                    <h3 className='text-[16px] font-semibold'>Bank Details </h3>
                                    <div className='flex flex-col md:flex-row md:justify-between'>
                                        <div>
                                            <div className='mt-4'>
                                                <h3 className='text-[#A9A9A9] text-[14px]'>Bank Name </h3>
                                                <h2 className='mt-1 text-[14px] font-semibold'>{userData?.bank_name}</h2>
                                            </div>
                                        </div>
                                        <div>
                                            <div className='mt-4'>
                                                <h3 className='text-[#A9A9A9] text-[14px]'>Account Number</h3>
                                                <h2 className='mt-1 text-[14px] font-semibold'>003479270407</h2>
                                            </div>
                                        </div>
                                        <div>
                                            <div className='mt-4'>
                                                <h3 className='text-[#A9A9A9] text-[14px]'>Account Name </h3>
                                                <h2 className='mt-1 text-[14px] font-semibold'>Samuel Daniel Nathaniel</h2>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='grid grid-cols-1 md:grid-cols-3 gap-5 mt-5'>
                                    <div className='bg-white px-6 pt-5 pb-5 rounded-[20px]'>
                                        <h3 className='text-[16px] font-semibold'>Account Information </h3>
                                        <div className='mt-4'>

                                            <h3 className='text-[#A9A9A9] text-[14px]'>Amount Requested </h3>
                                            <h2 className='mt-1 text-[22px] font-semibold'>NGN 23,000,000.00</h2>
                                        </div>
                                        <div className='mt-4'>
                                            <h3 className='text-[#A9A9A9] text-[14px]'>Amount to pay monthly </h3>
                                            <h2 className='mt-1 text-[22px] font-semibold'>NGN 300,000.00</h2>
                                        </div>
                                        <div className='mt-4'>
                                            <h3 className='text-[#A9A9A9] text-[14px]'>Number of Months </h3>
                                            <h2 className='mt-1 text-[22px] font-semibold'>5</h2>
                                        </div>
                                    </div>
                                    <div className='bg-white px-6 pt-5 pb-10 rounded-[20px]'>
                                        <h3 className='text-[16px] font-semibold'>Month  </h3>
                                        <div className='flex justify-between mt-4'>
                                            <h3 className='text-[#A9A9A9] text-[14px]'> Month 1
                                            </h3>
                                            <label className="relative inline-flex items-center cursor-pointer">
                                                <input type="checkbox" value="" className="sr-only peer" />
                                                <div className="w-11 h-6 bg-[#D9D9D9] peer-focus:outline-none rounded-full peer peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-green-600"></div>
                                            </label>
                                        </div>
                                        <div className='flex justify-between mt-4'>
                                            <h3 className='text-[#A9A9A9] text-[14px]'> Month 2
                                            </h3>
                                            <label className="relative inline-flex items-center cursor-pointer">
                                                <input type="checkbox" value="" className="sr-only peer" />
                                                <div className="w-11 h-6 bg-[#D9D9D9] peer-focus:outline-none rounded-full peer peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-green-600"></div>
                                            </label>
                                        </div>
                                        <div className='flex justify-between mt-4'>
                                            <h3 className='text-[#A9A9A9] text-[14px]'> Month 3
                                            </h3>
                                            <label className="relative inline-flex items-center cursor-pointer">
                                                <input type="checkbox" value="" className="sr-only peer" />
                                                <div className="w-11 h-6 bg-[#D9D9D9] peer-focus:outline-none rounded-full peer peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-green-600"></div>
                                            </label>
                                        </div>
                                        <div className='flex justify-between mt-4'>
                                            <h3 className='text-[#A9A9A9] text-[14px]'> Month 4
                                            </h3>
                                            <label className="relative inline-flex items-center cursor-pointer">
                                                <input type="checkbox" value="" className="sr-only peer" />
                                                <div className="w-11 h-6 bg-[#D9D9D9] peer-focus:outline-none rounded-full peer peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-green-600"></div>
                                            </label>
                                        </div>
                                        <div className='flex justify-between mt-4'>
                                            <h3 className='text-[#A9A9A9] text-[14px]'> Month 5
                                            </h3>
                                            <label className="relative inline-flex items-center cursor-pointer">
                                                <input type="checkbox" value="" className="sr-only peer" />
                                                <div className="w-11 h-6 bg-[#D9D9D9] peer-focus:outline-none rounded-full peer peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-green-600"></div>
                                            </label>
                                        </div>
                                    </div>
                                    <div className='bg-white px-6 pt-5 pb-10 rounded-[20px]'>
                                        <h3 className='text-[16px] font-semibold'>Transaction History</h3>
                                        <div className='flex justify-between mt-4'>
                                            <h3 className='text-[#EE1D52] text-[14px]'> 23,000,000.00
                                            </h3>
                                            <h3 className='text-[#A9A9A9] text-[14px]'>12/11/2023</h3>
                                        </div>
                                        <div className='flex justify-between mt-4'>
                                            <h3 className='text-[#00B07B] text-[14px]'> 23,000,000.00
                                            </h3>
                                            <h3 className='text-[#A9A9A9] text-[14px]'>12/12/2023</h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                        {/* -- Loans -- */}
                        <div className={openTestTab === 3 ? "block" : "hidden"}>
                        <div className='pt-5'>
                                <div className='bg-white px-6 pt-5 pb-10 rounded-[20px]'>
                                    <h3 className='text-[16px] font-semibold'>Bank Details </h3>
                                    <div className='flex flex-col md:flex-row md:justify-between'>
                                        <div>
                                            <div className='mt-4'>
                                                <h3 className='text-[#A9A9A9] text-[14px]'>Bank Name </h3>
                                                <h2 className='mt-1 text-[14px] font-semibold'>Fidelity</h2>
                                            </div>
                                        </div>
                                        <div>
                                            <div className='mt-4'>
                                                <h3 className='text-[#A9A9A9] text-[14px]'>Account Number</h3>
                                                <h2 className='mt-1 text-[14px] font-semibold'>003479270407</h2>
                                            </div>
                                        </div>
                                        <div>
                                            <div className='mt-4'>
                                                <h3 className='text-[#A9A9A9] text-[14px]'>Account Name </h3>
                                                <h2 className='mt-1 text-[14px] font-semibold'>Samuel Daniel Nathaniel</h2>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='grid grid-cols-1 md:grid-cols-3 gap-5 mt-5'>
                                    <div className='bg-white px-6 pt-5 pb-5 rounded-[20px]'>
                                        <h3 className='text-[16px] font-semibold'>Account Information </h3>
                                        <div className='mt-4'>

                                            <h3 className='text-[#A9A9A9] text-[14px]'>Amount Requested </h3>
                                            <h2 className='mt-1 text-[22px] font-semibold'>NGN 23,000,000.00</h2>
                                        </div>
                                        <div className='mt-4'>
                                            <h3 className='text-[#A9A9A9] text-[14px]'>Amount to pay monthly </h3>
                                            <h2 className='mt-1 text-[22px] font-semibold'>NGN 300,000.00</h2>
                                        </div>
                                        <div className='mt-4'>
                                            <h3 className='text-[#A9A9A9] text-[14px]'>Number of Months </h3>
                                            <h2 className='mt-1 text-[22px] font-semibold'>5</h2>
                                        </div>
                                    </div>
                                    
                                    <div className='bg-white px-6 pt-5 pb-10 rounded-[20px]'>
                                        <h3 className='text-[16px] font-semibold'>Transaction History</h3>
                                        <div className='flex justify-between mt-4'>
                                            <h3 className='text-[#EE1D52] text-[14px]'> 23,000,000.00
                                            </h3>
                                            <h3 className='text-[#A9A9A9] text-[14px]'>12/11/2023</h3>
                                        </div>
                                        <div className='flex justify-between mt-4'>
                                            <h3 className='text-[#00B07B] text-[14px]'> 23,000,000.00
                                            </h3>
                                            <h3 className='text-[#A9A9A9] text-[14px]'>12/12/2023</h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* -- Tuition Payment -- */}
                        <div className={openTestTab === 4 ? "block" : "hidden"}>
                            <div className='pt-5'>
                                <div className='bg-white px-6 pt-5 pb-10 rounded-[20px]'>
                                    <h3 className='text-[16px] font-semibold'>Tuition Payment Info </h3>
                                    <div className='flex flex-col md:flex-row md:justify-between'>
                                        <div>
                                            <div className='mt-4'>
                                                <h3 className='text-[#A9A9A9] text-[14px]'>School Name</h3>
                                                <h2 className='mt-1 text-[14px] font-semibold'>University of Vassa</h2>
                                            </div>
                                            <div className='mt-4'>
                                                <h3 className='text-[#A9A9A9] text-[14px]'>Swift Code</h3>
                                                <h2 className='mt-1 text-[14px] font-semibold'>MONZGBLS234</h2>
                                            </div>
                                        </div>
                                        <div>
                                            <div className='mt-4'>
                                                <h3 className='text-[#A9A9A9] text-[14px]'>Account Number</h3>
                                                <h2 className='mt-1 text-[14px] font-semibold'>2233444433</h2>
                                            </div>
                                            <div className='mt-4'>
                                                <h3 className='text-[#A9A9A9] text-[14px]'>Country</h3>
                                                <h2 className='mt-1 text-[14px] font-semibold'>FinLand</h2>
                                            </div>
                                        </div>
                                        <div>
                                            <div className='mt-4'>
                                                <h3 className='text-[#A9A9A9] text-[14px]'>Amount</h3>
                                                <h2 className='mt-1 text-[14px] font-semibold'>53,400</h2>
                                            </div>
                                            <div className='mt-4'>
                                                <h3 className='text-[#A9A9A9] text-[14px]'>Currency</h3>
                                                <h2 className='mt-1 text-[14px] font-semibold'>Euro</h2>
                                            </div>
                                        </div>
                                        <div>
                                            <div className='mt-4'>
                                                <h3 className='text-[#A9A9A9] text-[14px]'>Sort Code </h3>
                                                <h2 className='mt-1 text-[14px] font-semibold'>03-00-04</h2>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

            <ToastContainer
                position="bottom-left"
                autoClose={2000}
                hideProgressBar={true}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover />
        </>
    )
}

export default StudentDetails