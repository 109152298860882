import React, { useState } from "react";
import HeaderTitle from "../../ReuseableComponent/HeaderTitle";
import UserNavbar from "../../Navbars/UserNavbar";
import Sidebar from "../../Sidebar/Sidebar";
import { ToastContainer, toast } from "react-toastify";
import {
  Chart as ChartJs,
  Tooltip,
  Title,
  ArcElement,
  Legend,
  registerables,
} from "chart.js";
import { Doughnut, getElementsAtEvent } from "react-chartjs-2";

const LoanGoldForm = () => {
  const [dataArray, setDataArray] = useState<number[]>([0, 100]);

  ChartJs.register(...registerables, Tooltip, Title, ArcElement, Legend);

  // Custom plugin to draw text in the center
  const centerTextPlugin = {
    id: "centerText",
    beforeDraw: function (chart: any) {
      const { width, height, ctx } = chart;
      ctx.restore();
      const fontSize = (height / 114).toFixed(2);
      ctx.font = `${fontSize}em sans-serif`;
      ctx.textBaseline = "middle";

      const text = `${
        (dataArray[0] / dataArray.reduce((a, b) => a + b, 0)) * 100
      }%`;
      const textX = Math.round((width - ctx.measureText(text).width) / 2);
      const textY = height / 2;

      ctx.fillText(text, textX, textY);
      ctx.save();
    },
  };

  const data = {
    datasets: [
      {
        data: dataArray,
        backgroundColor: ["#00B07B", "#D9D9D9"],
        cutout: "70%", // Adjust this value to make the doughnut thinner
      },
    ],
    // These labels appear in the legend and in the tooltips when hovering different arcs
    labels: ["Send", "Receive"],
  };

  const options = {
    plugins: {
      legend: {
        display: false, // Hide legend
      },
      tooltip: {
        enabled: false, // Disable tooltips
      },
    },
  };

  const [userData, setUserdata] = React.useState({
    bvn: "",
    nin_number: "",
    front_nin_card: "",
    back_nin_card: "",
    id_card_number: "",
    front_id_card: "",
    back_id_card: "",
  });

  // const navigate = useNavigate();
  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    setUserdata({ ...userData, [name]: value });
  };

  const [selectedPics2, setSelectedPics2] =
    React.useState<any>("No selected file");
  const changeHandler2 = (event: any) => {
    // console?.log(2)
    const target = event.target;
    let size = target.files[0].size / 1048576.0;
    let targetName = target.name;
    if (target.files && target.files[0]) {
      if (size > 3) {
        if (targetName == "front_nin_card") {
          target.value = "";
          toast.warn("File too large");
        }
      }
      if (size <= 3) {
        if (targetName == "front_nin_card") {
          setSelectedPics2(event.target.files[0]);
        }
      }
    }
  };
  const handleSubmit = (e: React.ChangeEvent<HTMLFormElement>) => {
    // navigate("/loans-gold-plan")
    // onOpenModal(e);
toast.error("Provide the right information");

  };
  return (
    <div>
      <Sidebar />
      <div className="relative md:ml-64">
        <UserNavbar />
        <div className="lg:mt-3 lg:px-10 pb-6 pt-2 px-6 bg-[#F8F8F8] min-h-screen">
          <HeaderTitle title="Loan" />

          <div className="flex justify-center">
            <div className="pt-3 pb-7">
              <h3 className="text-[#000000] text-center text-[36px] font-[600]">
                Loan Form
              </h3>
              <h5 className="text-[#5F5D5D] text-center text-[20px] font-[500]">
                Apply for loan
              </h5>
            </div>
          </div>

          <div className="grid lg:grid-cols-12 gap-1">
            <div className="lg:col-span-8 bg-white pt-2 px-2 rounded-[10px]">
              <form onSubmit={handleSubmit}>
                <div className="rounded-[10px] py-3 bg-[#EEEEEE] flex justify-center">
                  <h4 className="tex-[#000000] text-[16px] font-[500]">
                    Fill out the Form
                  </h4>
                </div>

                <div className="relative w-full mt-4">
                  <label className="block mb-2 text-[16px] text-[#667085]">
                    Question
                  </label>
                  <input
                    type="text"
                    className="bg-white border border-[#D9D9D9] text-[#333333] text-sm rounded-[8px] block w-full p-3 placeholder:text-[#414143] placeholder:text-[12px]  ease-linear transition-all duration-150"
                    placeholder="Answer"
                    name="bvn"
                    //   defaultValue={userDetails?.first_name}
                    onChange={handleChange}
                    //   disabled
                  />
                </div>
                <div className="pt-6">
                  <label className="block mb-2 text-[16px] text-[#667085]">
                    Question Selection
                  </label>
                  <select
                    id="country_of_residence"
                    name="country_of_residence"
                    //   value={userData?.country_of_residence}
                    onChange={handleChange}
                    className=" border border-[#D9D9D9] mt-3 text-[#333333] text-sm rounded-[8px]  block w-full py-3 px-2.5"
                  >
                    <option selected>select option</option>
                    <option value="usa">USA</option>
                    <option value="canada">Canada</option>
                  </select>
                </div>

                <div className="pt-6">
                  <label className="block mb-2 text-[16px] text-[#667085]">
                    Question Selection
                  </label>
                  <select
                    id="country_of_residence"
                    name="country_of_residence"
                    //   value={userData?.country_of_residence}
                    onChange={handleChange}
                    className=" border border-[#D9D9D9] mt-3 text-[#333333] text-sm rounded-[8px]  block w-full py-3 px-2.5"
                  >
                    <option selected>select option</option>
                    <option value="usa">USA</option>
                    <option value="canada">Canada</option>
                  </select>
                </div>

                <div className="pt-6">
                  <label className="block mb-2 text-[16px] text-[#667085]">
                    Question Selection
                  </label>
                  <select
                    id="country_of_residence"
                    name="country_of_residence"
                    //   value={userData?.country_of_residence}
                    onChange={handleChange}
                    className=" border border-[#D9D9D9] mt-3 text-[#333333] text-sm rounded-[8px]  block w-full py-3 px-2.5"
                  >
                    <option selected>select option</option>
                    <option value="usa">USA</option>
                    <option value="canada">Canada</option>
                  </select>
                </div>

                <div className="relative w-full mt-4">
                  <label className="block mb-2 text-[16px] text-[#667085]">
                    NIN number
                  </label>
                  <input
                    type="text"
                    className="bg-white border border-[#D9D9D9] text-[#333333] text-sm rounded-[8px] block w-full p-3 placeholder:text-[#414143] placeholder:text-[12px]  ease-linear transition-all duration-150"
                    placeholder="Answer"
                    name="nin_number"
                    //   defaultValue={userDetails?.first_name}
                    onChange={handleChange}
                    //   disabled
                  />
                </div>

                <div className="relative w-full mt-4">
                  <label className="block mb-2 text-[16px] text-[#667085]">
                    Document
                  </label>
                  <div className="border-2 border-gray-300 border-dashed rounded-md pt-10 pb-8 h-[160px] text-center">
                    <label htmlFor="front_nin_card">
                      <h3 className="text-[12px] font-medium">
                        {selectedPics2 !== "No selected file"
                          ? selectedPics2?.name
                          : "e.g, Driver's Licence,Nin Slip,passport,e.t.c"}{" "}
                      </h3>
                      <h3 className="text-[14px] pt-4 text-gray-400 font-medium">
                        {selectedPics2 !== "No selected file"
                          ? ""
                          : "Not more than 3MB"}{" "}
                      </h3>
                      <div className="mt-5">
                        <span className="py-2.5 px-5 mb-2 text-[12px] font-medium text-[#979797] focus:outline-none bg-white rounded-full border border-[#979797]">
                          Choose a File
                        </span>
                      </div>
                      <input
                        id="front_nin_card"
                        type="file"
                        accept="image/png, image/jpeg"
                        name="front_nin_card"
                        onChange={changeHandler2}
                        className="hidden"
                      />
                    </label>
                  </div>
                </div>

                <div className="flex justify-center mb-10 pt-7">
                  <button type="submit" className="text-white  bg-[#00B07B] flex justify-center items-center py-3 rounded-[10px] w-full">
                    Submit form
                  </button>
                </div>
              </form>
            </div>

            <div className="lg:col-span-4">
              <div className="border rounded-[10px] h-fit  w-full flex justify-center relative">
                <div>
                  <h4 className="text-[#000000] text-[20px] text-center pt-4 font-semibold">
                    EduGold Stage
                  </h4>
                  <div className="p-4  md:w-[180px] md:h-[180px]">
                    <Doughnut
                      options={options}
                      data={data}
                      plugins={[centerTextPlugin]}
                    />
                  </div>
                  <div className="flex justify-center">
                    <h4>
                      On Progress <span className="text-[#00B07B]">0%</span>
                    </h4>
                  </div>
                  <div className="flex justify-center mt-3 mb-10">
                    <h4 className=" text-center max-w-[200px]">
                      Form to check credit eligibility
                    </h4>
                  </div>
                </div>
              </div>

              <div className="rounded-[10px] mt-8">
                <div className="bg-white rounded-t-[10px] py-2 flex justify-center">
                  <h4 className="text-[#000000] text-[20px]">
                    What to do next
                  </h4>
                </div>
                <div className="bg-[#00B07B] rounded-b-[10px] flex justify-center pt-3 pb-5">
                  <h4 className="text-white text-[18px] text-center max-w-[300px]">
                    Fill credit eligibility form
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer
        position="bottom-left"
        autoClose={2000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover />
    </div>
  );

};

export default LoanGoldForm;
