import React from 'react'
import { NavLink } from 'react-router-dom'

function Footer() {
    return (
        <>
            <section className='pt-10 md:pt-10 pb-14 bg-[#161C28]'>
                <div className='grid grid-cols-2 gap-8 md:grid-cols-3 lg:grid-cols-12 px-8 lg:px-16'>
                    <div className='col-span-5 mb-6'>
                        {/* <h1 className='md:text-[50px] text-[30px] font-medium text-[#209D5A]'>AfriPay</h1> */}
                        <NavLink to="/" >
                        <img src="/images/afripay-logo.png" alt="logo" className="pt-4" />
                        </NavLink>
                        <p className='text-[#A6A6A6] mt-3'>Get started now try our product</p>
                        <div className='mt-[30px]'>

                            {/* <form>

                                <div className="relative">

                                    <input type="email" id="email" className="block w-full p-4 text-sm text-gray-900 border-[2px] border-[#A6A6A6] rounded-[70px] bg-transparent" placeholder="Enter your email here" required />
                                    <button type="submit" className=" absolute right-2 bottom-1">
                                        <svg width="46" height="46" viewBox="0 0 46 46" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <circle cx="23" cy="23" r="23" fill="#209D5A" />
                                            <path d="M16 23H30" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                            <path d="M23 16L30 23L23 30" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                        </svg>


                                    </button>
                                </div>
                            </form> */}

                        </div>
                    </div>
                    <div className=' col-span-1 hidden md:block'></div>
                    <div className=' col-span-2 mt-3'>
                        <h4 className='text-[#ffffff] text-[16px] md:text-[18px] font-medium mb-6'>Powered by</h4>
                        <h3 className='mb-2 text-[16px] md:text-[18px] font-medium text-[#A6A6A6]'>MasterCard Lighthouse</h3>
                        <h3 className='mb-2 text-[16px] md:text-[18px] font-medium text-[#A6A6A6]'>Wema Bank</h3>
                        {/* <h3 className='mb-2 text-[16px] md:text-[18px] font-medium text-[#A6A6A6]'>Interswitch</h3> */}
                        <h3 className='mb-2 text-[16px] md:text-[18px] font-medium text-[#A6A6A6]'>Flutterwave</h3>
                        <h3 className='mb-2 text-[16px] md:text-[18px] font-medium text-[#A6A6A6]'>Verto</h3>
                    </div>
                    <div className=' col-span-2 mt-3'>
                        <h4 className='text-[#ffffff] text-[16px] md:text-[18px] font-medium mb-6'>Support</h4>
                        <NavLink to="/faq">
                        <h3 className='mb-2 text-[16px] md:text-[18px] font-medium text-[#A6A6A6]'>FAQs</h3>
                        </NavLink>
                        <NavLink to="/about-us">
                        <h3 className='mb-2 text-[16px] md:text-[18px] font-medium text-[#A6A6A6]'>About Us</h3>
                        </NavLink>
                       <NavLink to="/contact-us">
                       <h3 className='mb-2 text-[16px] md:text-[18px] font-medium text-[#A6A6A6]'>Contact Us</h3>
                       </NavLink>
                        {/* <NavLink to="/">
                        <h3 className='mb-2 text-[16px] md:text-[18px] font-medium text-[#A6A6A6]'>Blog</h3>
                        </NavLink> */}
                        
                    </div>
                
                    <div className=' col-span-2 mt-3'>
                        <h4 className='text-[#ffffff] text-[16px] md:text-[18px] font-medium mb-6'>Product</h4>
                        <NavLink to="/tuition-payment">
                        <h3 className='mb-2 text-[16px] md:text-[18px] font-medium text-[#A6A6A6]'>Tuition Payment</h3>
                        </NavLink>
                        
                        <NavLink to="/student-loan">
                        <h3 className='mb-2 text-[16px] md:text-[18px] font-medium text-[#A6A6A6]'>Loans</h3>
                        </NavLink>
                        
                        <NavLink to="/savings-with-afripay">
                        <h3 className='mb-2 text-[16px] md:text-[18px] font-medium text-[#A6A6A6]'>Savings</h3>
                        </NavLink>
                        
                        <NavLink to="/afripayshop">
                        <h3 className='mb-2 text-[16px] md:text-[18px] font-medium text-[#A6A6A6]'>Afripay Shop</h3>
                        </NavLink>
                        
                        {/* <h3 className='mb-2 text-[16px] md:text-[18px] font-medium text-[#A6A6A6]'>Proof of Funds</h3> */}
                        
                    </div>
                </div>
                <div className='flex flex-col lg:flex-row lg:justify-between mt-16 px-8 lg:px-16'>
                        <p className='text-white text-[18px] md:text-[20px] font-bold'>All our financial services are provided by our partners</p>
                    </div>
                <div className='flex flex-col lg:flex-row lg:justify-between mt-5 px-8 lg:px-16' >

                    <div>
                        <p className='text-white text-[16px] md:text-[18px]'>©2024 Afripay. Copyright and rights reserved</p>
                    </div>
                    <div className='flex space-x-2 mt-3'>
                    <NavLink to="/terms-of-use">
                    <p className='text-white text-[16px] md:text-[18px]'>Terms of Use</p>
                        </NavLink>
                       
                        <p className='text-white text-[16px] md:text-[18px]'>.</p>
                        <NavLink to="/privacy-policy">
                        <p className='text-white text-[16px] md:text-[18px]'>Privacy Policy</p>
                        </NavLink>
                       
                    </div>
                </div>
            </section>
        </>
    )
}

export default Footer