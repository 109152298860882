import React, { useEffect } from 'react'
import Sidebar from "../../Sidebar/Sidebar";
import UserNavbar from "../../Navbars/UserNavbar";

function TravelStart() {
    // Iframe ID
   const IframeId = 'travelstartIframe-3ddec78d-91ef-43f4-92c9-01fbe09aadeb';
    return (
        <>
         <Sidebar />
         <div className="relative md:ml-64">
         <UserNavbar />
         <div className='lg:mt-5  pb-6 px-6 '>
         <iframe
        src="https://www.travelstart.com.ng"
        sandbox="allow-scripts allow-same-origin"
        id={IframeId}
        width="100%"
        // height="400"
        className='pb-10 '
        >
      </iframe>
         </div>
</div>
            
     
            
        </>
    )
}

export default TravelStart