import React from 'react'
import Sidebar from "../../Sidebar/AdminSidebar";
import UserNavbar from "../../Navbars/AdminNavbar";
import { SvgElement, icontypesEnum } from '../../assets/svgElement';
import { useNavigate, Link, NavLink } from 'react-router-dom';
import { AdminApis } from '../../../apis/adminApi';
import { AxiosResponse } from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
//@ts-ignore
import { CSVLink, CSVDownload } from "react-csv";
function Students() {

    const navigate = useNavigate();
    const [userLists, setUserList] = React.useState<any>([]);
    const [searchText, setSearchText] = React.useState('');
    const [csvData, setCsvData] = React.useState<any>([]);

    React.useEffect(() => {
        const query: any = {
            search: searchText,
        };
        AdminApis.getAllUsers('', query).then(
            (response: AxiosResponse<any>) => {
                if (response?.data) {
                    setUserList(response?.data?.data)
                }
            }
        ).catch(function (error) {
            // handle error

        })

    }, []);

   


    const paginator = React.useCallback(
        (value: any) => {
            //   setLoader(true);
            let value2 = '';
            if (value !== null) {
                value2 = value;
            } else {
                value2 = ''
            }
            const query: any = {
                search: searchText,
            };

            AdminApis.getAllUsers(value2, query).then(
                (response: AxiosResponse<any>) => {
                    if (response?.data) {
                        setUserList(response?.data?.data)
                    }
                }
            ).catch(function (error) {
                console.log(error.response.data);
            })

        }, [userLists, searchText]);




    return (
        <>
            <Sidebar />
            <div className="relative md:ml-64">
                <UserNavbar title="Students" />
                <div className='pt-5 lg:px-10 px-6 bg-[#F8F8F8] min-h-screen'>


                    {/* <div className='p-4 grid grid-cols-1 md:grid-cols-4 gap-5'>
                        <div className='bg-[#fff] px-5 py-4 flex justify-between rounded-[10px]'>
                            <div className='mt-2'>
                                <h3 className='text-[#A0AEC0] text-[12px]'>Total Students</h3>
                                <h1 className='text-[#2D3748] text-[18px] font-semibold'>500</h1>
                            </div>
                            <div> 
                                <svg width="57" height="56" viewBox="0 0 57 56" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g filter="url(#filter0_d_577_3702)">
                                        <rect x="6" y="2" width="45" height="45" rx="12" fill="#00B07B" />
                                    </g>
                                    <path d="M21.4464 17.8176H35.5089C35.6732 17.8175 35.8373 17.8279 36.0002 17.8488C35.945 17.4611 35.8118 17.0886 35.6088 16.7537C35.4057 16.4189 35.1369 16.1286 34.8186 15.9005C34.5003 15.6723 34.1391 15.511 33.7568 15.4262C33.3745 15.3414 32.9789 15.3349 32.594 15.4072L21.029 17.3816H21.0158C20.2898 17.5204 19.6443 17.9312 19.2109 18.5299C19.8638 18.0655 20.6453 17.8165 21.4464 17.8176Z" fill="white" />
                                    <path d="M35.5078 18.875H21.4453C20.6996 18.8758 19.9847 19.1724 19.4575 19.6997C18.9302 20.2269 18.6336 20.9418 18.6328 21.6875V30.125C18.6336 30.8707 18.9302 31.5856 19.4575 32.1128C19.9847 32.6401 20.6996 32.9367 21.4453 32.9375H35.5078C36.2535 32.9367 36.9684 32.6401 37.4956 32.1128C38.0229 31.5856 38.3195 30.8707 38.3203 30.125V21.6875C38.3195 20.9418 38.0229 20.2269 37.4956 19.6997C36.9684 19.1724 36.2535 18.8758 35.5078 18.875ZM33.4204 27.3125C33.1423 27.3125 32.8704 27.23 32.6391 27.0755C32.4079 26.921 32.2276 26.7014 32.1212 26.4444C32.0148 26.1874 31.9869 25.9047 32.0412 25.6319C32.0954 25.3591 32.2294 25.1085 32.426 24.9119C32.6227 24.7152 32.8733 24.5813 33.1461 24.527C33.4188 24.4728 33.7016 24.5006 33.9586 24.607C34.2155 24.7135 34.4351 24.8937 34.5897 25.125C34.7442 25.3562 34.8267 25.6281 34.8267 25.9062C34.8267 26.2792 34.6785 26.6369 34.4148 26.9006C34.151 27.1643 33.7934 27.3125 33.4204 27.3125Z" fill="white" />
                                    <path d="M18.6562 24.6523V20.2798C18.6562 19.3275 19.1836 17.731 21.0139 17.3851C22.5674 17.0937 24.1055 17.0938 24.1055 17.0938C24.1055 17.0938 25.1162 17.7969 24.2812 17.7969C23.4463 17.7969 23.4683 18.8735 24.2812 18.8735C25.0942 18.8735 24.2812 19.9062 24.2812 19.9062L21.0073 23.6196L18.6562 24.6523Z" fill="white" />
                                    <defs>
                                        <filter id="filter0_d_577_3702" x="0.5" y="-2.38419e-07" width="56" height="56" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                            <feFlood flood-opacity="0" result="BackgroundImageFix" />
                                            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                                            <feOffset dy="3.5" />
                                            <feGaussianBlur stdDeviation="2.75" />
                                            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.02 0" />
                                            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_577_3702" />
                                            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_577_3702" result="shape" />
                                        </filter>
                                    </defs>
                                </svg>


                            </div>

                        </div>
                        <div className='bg-[#fff] px-5 py-4 flex justify-between rounded-[10px]'>
                            <div className='mt-2'>
                                <h3 className='text-[#A0AEC0] text-[12px]'>Loans</h3>
                                <h1 className='text-[#2D3748] text-[18px] font-semibold'>130</h1>
                            </div>
                            <div>
                                <svg width="57" height="56" viewBox="0 0 57 56" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g filter="url(#filter0_d_577_3694)">
                                        <rect x="6" y="2" width="45" height="45" rx="12" fill="#00B07B" />
                                    </g>
                                    <path d="M28.5 15.3594C23.452 15.3594 19.3594 19.452 19.3594 24.5C19.3594 29.548 23.452 33.6406 28.5 33.6406C33.548 33.6406 37.6406 29.548 37.6406 24.5C37.6406 19.452 33.548 15.3594 28.5 15.3594Z" fill="white" stroke="#00B07B" stroke-width="0.75" stroke-miterlimit="10" />
                                    <path d="M28.4982 15.3594C25.9463 15.3594 23.5469 19.452 23.5469 24.5C23.5469 29.548 25.9463 33.6406 28.4982 33.6406C31.0501 33.6406 33.4495 29.548 33.4495 24.5C33.4495 19.452 31.0501 15.3594 28.4982 15.3594Z" fill="white" stroke="#00B07B" stroke-width="0.75" stroke-miterlimit="10" />
                                    <path d="M22.4062 18.4062C24.0867 19.5994 26.2022 20.3108 28.5001 20.3108C30.798 20.3108 32.9136 19.5994 34.594 18.4062" fill="white" />
                                    <path d="M22.4062 18.4062C24.0867 19.5994 26.2022 20.3108 28.5001 20.3108C30.798 20.3108 32.9136 19.5994 34.594 18.4062" stroke="#00B07B" stroke-width="0.75" stroke-linecap="round" stroke-linejoin="round" />
                                    <path d="M34.594 30.594C32.9136 29.4009 30.798 28.6895 28.5001 28.6895C26.2022 28.6895 24.0867 29.4009 22.4062 30.594" stroke="#00B07B" stroke-width="0.75" stroke-linecap="round" stroke-linejoin="round" />
                                    <path d="M28.5 15.3594V33.6406" stroke="#00B07B" stroke-width="0.75" stroke-miterlimit="10" />
                                    <path d="M37.6406 24.498H19.3594" stroke="#00B07B" stroke-width="0.75" stroke-miterlimit="10" />
                                    <defs>
                                        <filter id="filter0_d_577_3694" x="0.5" y="-2.38419e-07" width="56" height="56" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                            <feFlood flood-opacity="0" result="BackgroundImageFix" />
                                            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                                            <feOffset dy="3.5" />
                                            <feGaussianBlur stdDeviation="2.75" />
                                            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.02 0" />
                                            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_577_3694" />
                                            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_577_3694" result="shape" />
                                        </filter>
                                    </defs>
                                </svg>




                            </div>

                        </div>
                        <div className='bg-[#fff] px-5 py-4 flex justify-between rounded-[10px]'>
                            <div className='mt-2'>
                                <h3 className='text-[#A0AEC0] text-[12px]'>Tuition Payment</h3>
                                <h1 className='text-[#2D3748] text-[18px] font-semibold'>48</h1>
                            </div>
                            <div>
                                <svg width="57" height="56" viewBox="0 0 57 56" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g filter="url(#filter0_d_577_3686)">
                                        <rect x="6" y="2" width="45" height="45" rx="12" fill="#00B07B" />
                                    </g>
                                    <path d="M29.9062 23.1055H36.0586C36.1021 23.1055 36.1438 23.1228 36.1746 23.1535C36.2054 23.1843 36.2227 23.226 36.2227 23.2695V31.5312C36.2227 32.2741 35.9276 32.9864 35.4023 33.5117C34.8771 34.0369 34.1647 34.332 33.4219 34.332H23.5781C22.8353 34.332 22.1229 34.0369 21.5977 33.5117C21.0724 32.9864 20.7773 32.2741 20.7773 31.5312V17.4687C20.7773 16.7259 21.0724 16.0135 21.5977 15.4883C22.1229 14.9631 22.8353 14.668 23.5781 14.668H27.6211C27.6646 14.668 27.7063 14.6853 27.7371 14.716C27.7679 14.7468 27.7852 14.7885 27.7852 14.832V20.9844C27.7852 21.5469 28.0086 22.0864 28.4064 22.4842C28.8042 22.882 29.3437 23.1055 29.9062 23.1055ZM24.9844 30.1367H32.0156C32.2052 30.1367 32.387 30.0614 32.5211 29.9273C32.6552 29.7933 32.7305 29.6115 32.7305 29.4219C32.7305 29.2323 32.6552 29.0505 32.5211 28.9164C32.387 28.7823 32.2052 28.707 32.0156 28.707H24.9844C24.7948 28.707 24.613 28.7823 24.4789 28.9164C24.3448 29.0505 24.2695 29.2323 24.2695 29.4219C24.2695 29.6115 24.3448 29.7933 24.4789 29.9273C24.613 30.0614 24.7948 30.1367 24.9844 30.1367ZM24.9844 26.6211H32.0156C32.2052 26.6211 32.387 26.5458 32.5211 26.4117C32.6552 26.2777 32.7305 26.0958 32.7305 25.9062C32.7305 25.7167 32.6552 25.5348 32.5211 25.4008C32.387 25.2667 32.2052 25.1914 32.0156 25.1914H24.9844C24.7948 25.1914 24.613 25.2667 24.4789 25.4008C24.3448 25.5348 24.2695 25.7167 24.2695 25.9062C24.2695 26.0958 24.3448 26.2777 24.4789 26.4117C24.613 26.5458 24.7948 26.6211 24.9844 26.6211Z" fill="white" stroke="#00B07B" stroke-width="0.0234375" />
                                    <path d="M29.3447 15.2272L35.6644 21.547L29.3447 15.2272ZM29.3447 15.2272C29.3447 15.2272 29.3447 15.2272 29.3447 15.2272M29.3447 15.2272L29.3447 15.2272M29.3447 15.2272C29.3341 15.2166 29.3205 15.2094 29.3058 15.2065C29.2911 15.2036 29.2758 15.2051 29.2619 15.2109C29.248 15.2166 29.2362 15.2263 29.2278 15.2388C29.2194 15.2512 29.2149 15.2659 29.2148 15.2809V20.9854C29.2148 21.1688 29.2877 21.3447 29.4174 21.4743C29.547 21.604 29.7229 21.6768 29.9063 21.6768H35.6107C35.6258 21.6768 35.6404 21.6723 35.6529 21.6639C35.6654 21.6555 35.6751 21.6436 35.6808 21.6298C35.6866 21.6159 35.6881 21.6006 35.6851 21.5859C35.6822 21.5712 35.675 21.5576 35.6645 21.547L29.3447 15.2272Z" fill="white" stroke="#00B07B" stroke-width="0.0234375" />
                                    <defs>
                                        <filter id="filter0_d_577_3686" x="0.5" y="-2.38419e-07" width="56" height="56" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                            <feFlood flood-opacity="0" result="BackgroundImageFix" />
                                            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                                            <feOffset dy="3.5" />
                                            <feGaussianBlur stdDeviation="2.75" />
                                            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.02 0" />
                                            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_577_3686" />
                                            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_577_3686" result="shape" />
                                        </filter>
                                    </defs>
                                </svg>



                            </div>

                        </div>
                        <div className='bg-[#fff] px-5 py-4 flex justify-between rounded-[10px]'>
                            <div className='mt-2'>
                                <h3 className='text-[#A0AEC0] text-[12px]'>Proof of Funds</h3>
                                <h1 className='text-[#2D3748] text-[18px] font-semibold'>120</h1>
                            </div>
                            <div>
                                <svg width="57" height="56" viewBox="0 0 57 56" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g filter="url(#filter0_d_577_3678)">
                                        <rect x="6" y="2" width="45" height="45" rx="12" fill="#00B07B" />
                                    </g>
                                    <path d="M24.9844 32.9375C25.761 32.9375 26.3906 32.3079 26.3906 31.5312C26.3906 30.7546 25.761 30.125 24.9844 30.125C24.2077 30.125 23.5781 30.7546 23.5781 31.5312C23.5781 32.3079 24.2077 32.9375 24.9844 32.9375Z" fill="white" />
                                    <path d="M34.8281 32.9375C35.6048 32.9375 36.2344 32.3079 36.2344 31.5312C36.2344 30.7546 35.6048 30.125 34.8281 30.125C34.0515 30.125 33.4219 30.7546 33.4219 31.5312C33.4219 32.3079 34.0515 32.9375 34.8281 32.9375Z" fill="white" />
                                    <path d="M37.3242 18.5577C37.2254 18.4369 37.101 18.3396 36.9599 18.2728C36.8188 18.2061 36.6647 18.1716 36.5086 18.1719H23.1338L22.8645 16.6435C22.8357 16.4807 22.7506 16.3332 22.6239 16.2269C22.4972 16.1207 22.3372 16.0625 22.1719 16.0625H19.3594C19.1729 16.0625 18.9941 16.1366 18.8622 16.2684C18.7303 16.4003 18.6562 16.5791 18.6562 16.7656C18.6562 16.9521 18.7303 17.1309 18.8622 17.2628C18.9941 17.3947 19.1729 17.4687 19.3594 17.4687H21.5821L23.5887 28.8409C23.6174 29.0037 23.7026 29.1512 23.8292 29.2574C23.9559 29.3637 24.1159 29.4219 24.2812 29.4219H35.5312C35.7177 29.4219 35.8966 29.3478 36.0284 29.2159C36.1603 29.0841 36.2344 28.9052 36.2344 28.7187C36.2344 28.5323 36.1603 28.3534 36.0284 28.2216C35.8966 28.0897 35.7177 28.0156 35.5312 28.0156H24.871L24.6231 26.6094H35.243C35.4868 26.6091 35.723 26.5245 35.9117 26.37C36.1004 26.2156 36.2299 26.0007 36.2783 25.7617L37.5439 19.4335C37.5744 19.2804 37.5705 19.1224 37.5325 18.9709C37.4945 18.8195 37.4234 18.6783 37.3242 18.5577Z" fill="white" />
                                    <defs>
                                        <filter id="filter0_d_577_3678" x="0.5" y="-2.38419e-07" width="56" height="56" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                            <feFlood flood-opacity="0" result="BackgroundImageFix" />
                                            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                                            <feOffset dy="3.5" />
                                            <feGaussianBlur stdDeviation="2.75" />
                                            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.02 0" />
                                            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_577_3678" />
                                            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_577_3678" result="shape" />
                                        </filter>
                                    </defs>
                                </svg>



                            </div>

                        </div>
                    </div> */}


                    <div className='flex justify-end'>
                        <div className=" lg:ml-auto mr-3 flex justify-end">
                            <div className="relative flex w-full flex-wrap items-stretch">
                                <div className="absolute inset-y-0 right-2 flex items-center pl-3 pointer-events-none">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"><path fill="#9da4aa" d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0a5.5 5.5 0 0 1 11 0z" /></svg>
                                </div>
                                <input type="text" onClick={() => paginator('')} onChange={e => setSearchText(e.target.value)} placeholder='search...' id="simple-search" className=" border border-gray-300 text-gray-500 text-sm rounded-md block w-full pl-4 p-1  " required />
                            </div>

                            <div className='mt-0.5 ml-2'><button type='button' onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => paginator('')} className={"font-normal text-white bg-[#00B07B] px-3 py-0.5 rounded-md"}>Search</button> </div>

                            <div className='ml-4 mt-1 cursor-pointer' onClick={() => { }}>
                                <CSVLink data={csvData} filename={"AgentLists.csv"} target="_blank">

                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M18 7H6V3H18V7ZM18 12.5C18.2833 12.5 18.5207 12.404 18.712 12.212C18.904 12.0207 19 11.7833 19 11.5C19 11.2167 18.904 10.979 18.712 10.787C18.5207 10.5957 18.2833 10.5 18 10.5C17.7167 10.5 17.4793 10.5957 17.288 10.787C17.096 10.979 17 11.2167 17 11.5C17 11.7833 17.096 12.0207 17.288 12.212C17.4793 12.404 17.7167 12.5 18 12.5ZM16 19V15H8V19H16ZM18 21H6V17H2V11C2 10.15 2.29167 9.43767 2.875 8.863C3.45833 8.28767 4.16667 8 5 8H19C19.85 8 20.5627 8.28767 21.138 8.863C21.7127 9.43767 22 10.15 22 11V17H18V21Z" fill="#9DA4AA" />
                                    </svg>

                                </CSVLink>

                            </div>

                        </div>

                        {/* <div className='mt-1'>Filter </div> */}
                    </div>

                    <div className='p-4'>


                        <div className="relative overflow-x-auto sm:rounded-lg">
                            <table className="w-full text-sm text-left rtl:text-right text-gray-500 ">
                                <thead className="text-xs text-gray-700  border-none">
                                    <tr>
                                        <th scope="col" className="px-6 py-3">
                                            Reg ID
                                        </th>
                                        <th scope="col" className="px-6 py-3">
                                            Name
                                        </th>
                                        <th scope="col" className="px-6 py-3">
                                            Email
                                        </th>
                                        <th scope="col" className="px-6 py-3">
                                            Date
                                        </th>
                                        <th scope="col" className="px-6 py-3">
                                            Status
                                        </th>
                                        <th scope="col" className="px-6 py-3">
                                            <span className="sr-only">Edit</span>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>

                                    {
                                        userLists?.data?.map(
                                            (datas: any, index: any) => (
                                                <tr className="bg-white hover:bg-gray-50 hover:shadow-md rounded-lg mb-3 border-none">
                                                    <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap ">
                                                        {datas?.id}
                                                    </th>
                                                    <td className="px-6 py-4">
                                                        {datas?.first_name + ' ' + datas?.last_name}
                                                    </td>
                                                    <td className="px-6 py-4">
                                                        {datas?.email}
                                                    </td>
                                                    <td className="px-6 py-4">
                                                        {(datas?.created_at).slice(0, 10)}
                                                    </td>
                                                    <td className="px-6 py-4">
                                                        <div className='bg-[#EDF6F5] text-[#3A974C] text-[12px] rounded-[22px] items-center text-center py-2 px-3'> {datas?.status}</div>
                                                    </td>
                                                    <NavLink to={`/student-details/${datas?.id}`}>
                                                    <td className="px-6 py-4 text-right">
                                                        <SvgElement type={icontypesEnum.SEEMORE} />
                                                    </td>
                                                    </NavLink>
                                                </tr>
                                            )
                                        )
                                    }



                                </tbody>
                            </table>
                        </div>

                    </div>

                    <div className='m-4'>
                        {
                            userLists?.links?.filter(((item: any, idx: any) => idx < 1000)).map(
                                (datas: any, index: any) => (
                                    <button onClick={() => paginator(datas?.label == 'Next &raquo;' ? datas?.url.charAt(datas?.url.length - 1) : (datas?.label === '&laquo; Previous') ? datas?.url.charAt(datas?.url.length - 1) : datas?.label)} disabled={datas?.active} className={'mx-1 py-1 px-2 ' + (datas?.active == false ? 'bg-gray-300 text-black ' : 'bg-[#00B07B] text-white')}>
                                        {datas?.label == '&laquo; Previous' ? '< Previous' : (datas?.label === 'Next &raquo;') ? 'Next  >' : datas?.label}
                                    </button>
                                )
                            )
                        }

                    </div>



                </div>
            </div>
        </>
    )
}

export default Students