import React from 'react'
import Sidebar from "../../Sidebar/AdminSidebar";
import UserNavbar from "../../Navbars/AdminNavbar";
import { SvgElement, icontypesEnum } from '../../assets/svgElement';
import { useNavigate, Link, NavLink } from 'react-router-dom';
import { AdminApis } from '../../../apis/adminApi';
import { AxiosResponse } from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
//@ts-ignore
import { CSVLink, CSVDownload } from "react-csv";
function StudentLoanList() {

    const navigate = useNavigate();
    const [newuserLists, setNewUserList] = React.useState<any>([]);
    const [searchText, setSearchText] = React.useState('');
    const [csvData, setCsvData] = React.useState<any>([]);
    let [showScreen, setShowScreen] = React.useState<any>("");

    React.useEffect(() => {
        const query: any = {
            search: searchText,
        };
        AdminApis.getAllCreditEligibility('', query).then(
            (response: AxiosResponse<any>) => {
                if (response?.data) {
                    // console.log(response?.data?.data)
                    setNewUserList(response?.data?.data)
                }
            }
        ).catch(function (error) {
            // handle error

        })

    }, []);

    console.log(newuserLists)

    




    const paginator = React.useCallback(
        (value: any) => {
            //   setLoader(true);
            let value2 = '';
            if (value !== null) {
                value2 = value;
            } else {
                value2 = ''
            }
            const query: any = {
                search: searchText,
            };

            AdminApis.getAllCreditEligibility(value2, query).then(
                (response: AxiosResponse<any>) => {
                    if (response?.data) {
                        setNewUserList(response?.data?.data)
                    }
                }
            ).catch(function (error) {
                console.log(error.response.data);
            })

        }, [newuserLists, searchText]);




    return (
        <>
            <Sidebar />
            <div className="relative md:ml-64">
                <UserNavbar title="Loans" />
                <div className='pt-5 lg:px-10 px-6 bg-[#F8F8F8] min-h-screen'>


                    {/* <div className='flex justify-end'>
                        <div className=" lg:ml-auto mr-3 flex justify-end">
                            <div className="relative flex w-full flex-wrap items-stretch">
                                <div className="absolute inset-y-0 right-2 flex items-center pl-3 pointer-events-none">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"><path fill="#9da4aa" d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0a5.5 5.5 0 0 1 11 0z" /></svg>
                                </div>
                                <input type="text" onClick={() => paginator('')} onChange={e => setSearchText(e.target.value)} placeholder='search...' id="simple-search" className=" border border-gray-300 text-gray-500 text-sm rounded-md block w-full pl-4 p-1  " required />
                            </div>

                            <div className='mt-0.5 ml-2'><button type='button' onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => paginator('')} className={"font-normal text-white bg-[#00B07B] px-3 py-0.5 rounded-md"}>Search</button> </div>

                            <div className='ml-4 mt-1 cursor-pointer' onClick={() => { }}>
                                <CSVLink data={csvData} filename={"StudentLoanLists.csv"} target="_blank">

                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M18 7H6V3H18V7ZM18 12.5C18.2833 12.5 18.5207 12.404 18.712 12.212C18.904 12.0207 19 11.7833 19 11.5C19 11.2167 18.904 10.979 18.712 10.787C18.5207 10.5957 18.2833 10.5 18 10.5C17.7167 10.5 17.4793 10.5957 17.288 10.787C17.096 10.979 17 11.2167 17 11.5C17 11.7833 17.096 12.0207 17.288 12.212C17.4793 12.404 17.7167 12.5 18 12.5ZM16 19V15H8V19H16ZM18 21H6V17H2V11C2 10.15 2.29167 9.43767 2.875 8.863C3.45833 8.28767 4.16667 8 5 8H19C19.85 8 20.5627 8.28767 21.138 8.863C21.7127 9.43767 22 10.15 22 11V17H18V21Z" fill="#9DA4AA" />
                                    </svg>

                                </CSVLink>

                            </div>

                        </div>
                    </div> */}

                    <div className='flex space-x-5 p-4'>
                    {/* <div className={showScreen === "EduGold" ? "block " : "hidden"}></div> */}
                    <button type='button'
                    onClick={()=>setShowScreen('')}
                    className={` ${showScreen === '' ? "bg-[#14B266] text-[#ffffff] " : "text-black"}     font-medium rounded-lg text-sm px-3 py-2 cursor-pointer`}
                    >All
                    </button>
                    <button type='button'
                    onClick={()=>setShowScreen("EduGold")}
                    className={` ${showScreen === 'EduGold' ? "bg-[#14B266] text-[#ffffff] " : "text-black"}     font-medium rounded-lg text-sm px-3 py-2 cursor-pointer`}
                    >EduGold
                    </button>
                    <button type='button'
                    onClick={()=>setShowScreen("EduPremium")}
                    className={` ${showScreen === 'EduPremium' ? "bg-[#14B266] text-[#ffffff] " : "text-black"}     font-medium rounded-lg text-sm px-3 py-2 cursor-pointer`}
                    >EduPremium
                    </button>
                    <button type='button'
                    onClick={()=>setShowScreen("creditCheckOnly")}
                    className={` ${showScreen === 'creditCheckOnly' ? "bg-[#14B266] text-[#ffffff] " : "text-black"}     font-medium rounded-lg text-sm px-3 py-2 cursor-pointer`}
                    >Credit Check Only
                    </button>
                    </div>


                    <div className='p-4'>
                        <div className="relative overflow-x-auto sm:rounded-lg">
                            <table className="w-full text-sm text-left rtl:text-right text-gray-500 ">
                                <thead className="text-xs text-gray-700  border-none">
                                    <tr>
                                        <th scope="col" className="px-6 py-3">
                                            S/N
                                        </th>
                                        <th scope="col" className="px-6 py-3">
                                            Name
                                        </th>
                                        <th scope="col" className="px-6 py-3">
                                            Email
                                        </th>
                                        <th scope="col" className="px-6 py-3">
                                            Reg Type
                                        </th>
                                        <th scope="col" className="px-6 py-3">
                                            Credit Score
                                        </th>
                                        <th scope="col" className="px-6 py-3">
                                           Payment Status
                                        </th>
                                        <th scope="col" className="px-6 py-3">
                                            Date
                                        </th>
                                        <th scope="col" className="px-6 py-3">
                                            <span className="">Action</span>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>

                                    {
                                        newuserLists?.data?.length > 0 ?
                                            newuserLists?.data?.filter(((item: any, idx: any) => showScreen !==''? item?.reg_type == showScreen :item?.reg_type !== ''))?.map(
                                                (datas: any, index: any) => (
                                                    <tr className="bg-white hover:bg-gray-50 hover:shadow-md rounded-lg mb-3 border-none">
                                                        <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap ">
                                                            {index + 1}
                                                        </th>
                                                        <td className="px-6 py-4">
                                                            {datas?.first_name + ' ' + datas?.last_name}
                                                        </td>
                                                        <td className="px-6 py-4">
                                                            {datas?.email}
                                                        </td>
                                                        <td className="px-6 py-4 capitalize">
                                                            {datas?.reg_type}
                                                        </td>
                                                        <td className="px-6 py-4 capitalize">
                                                            {datas?.credit_score ? datas?.credit_score : "no data"}
                                                        </td>
                                                        <td className="px-6 py-4 capitalize">
                                                            {datas?.payment_status === "pending" ?  <span className='bg-[#FFDDBD] text-[#FF922B] text-[12px] px-[8px] py-[2px] rounded-sm'>{datas?.payment_status}</span> : "" }
                                                            {datas?.payment_status === "successful" ?<span className='bg-[#C9F0D0] text-[#51CF66] text-[12px] px-[8px] py-[2px] rounded-sm'>{datas?.payment_status}</span>: "" }
                                                            {datas?.payment_status === "failed" ? <span className='bg-[#F5BFBF] text-[#E03130] text-[12px] px-[8px] py-[2px] rounded-sm'>{datas?.payment_status}</span> : ""}
                                                            
                                                        </td>
                                                        <td className="px-6 py-4">
                                                            {(datas?.created_at).slice(0, 10)}
                                                        </td>
                                                       
                                                        <NavLink to={`/student-loan-details/${datas?.user_id}`} className={"mt-4"}>
                                                            <td className="px-6 py-4 mt-4 text-right">
                                                                <SvgElement type={icontypesEnum.EYE} />
                                                            </td>
                                                        </NavLink>
                                                    </tr>
                                                )
                                            )
                                            :
                                            "No Record Found "
                                    }



                                </tbody>
                            </table>
                        </div>
                    </div>

                    {/* <div className='p-4'>


                        <div className="relative overflow-x-auto sm:rounded-lg">
                            <table className="w-full text-sm text-left rtl:text-right text-gray-500 ">
                                <thead className="text-xs text-gray-700  border-none">
                                    <tr>
                                        <th scope="col" className="px-6 py-3">
                                            S/N
                                        </th>
                                        <th scope="col" className="px-6 py-3">
                                            Name
                                        </th>
                                        <th scope="col" className="px-6 py-3">
                                            Email
                                        </th>
                                        <th scope="col" className="px-6 py-3">
                                            Program Type
                                        </th>
                                        <th scope="col" className="px-6 py-3">
                                            Country Of Study
                                        </th>
                                        <th scope="col" className="px-6 py-3">
                                            Date
                                        </th>
                                        <th scope="col" className="px-6 py-3">
                                            Status
                                        </th>
                                        <th scope="col" className="px-6 py-3">
                                            <span className="">Action</span>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>

                                    {
                                        userLists?.data?.map(
                                            (datas: any, index: any) => (
                                                <tr className="bg-white hover:bg-gray-50 hover:shadow-md rounded-lg mb-3 border-none">
                                                    <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap ">
                                                        {index + 1}
                                                    </th>
                                                    <td className="px-6 py-4">
                                                        {datas?.f_name + ' ' + datas?.l_name}
                                                    </td>
                                                    <td className="px-6 py-4">
                                                        {datas?.email}
                                                    </td>
                                                    <td className="px-6 py-4 capitalize">
                                                        {datas?.program_of_application}
                                                    </td>
                                                    <td className="px-6 py-4 capitalize">
                                                        {datas?.where_are_you_studing}
                                                    </td>
                                                    <td className="px-6 py-4">
                                                        {(datas?.created_at).slice(0, 10)}
                                                    </td>
                                                    <td className="px-6 py-4">
                                                        <div className='bg-[#EDF6F5] text-[#3A974C] text-[12px] rounded-[22px] items-center text-center py-2 px-3'> {datas?.status}</div>
                                                    </td>
                                                    <NavLink to={`/student-loan-details/${datas?.id}`}>
                                                        <td className="px-6 py-4 mt-4 text-right">
                                                            <SvgElement type={icontypesEnum.SEEMORE} />
                                                        </td>
                                                    </NavLink>
                                                </tr>
                                            )
                                        )
                                    }



                                </tbody>
                            </table>
                        </div>

                    </div> */}

                    <div className='m-4'>
                        {
                            newuserLists?.links?.filter(((item: any, idx: any) => idx < 1000)).map(
                                (datas: any, index: any) => (
                                    <button onClick={() => paginator(datas?.label == 'Next &raquo;' ? datas?.url.charAt(datas?.url.length - 1) : (datas?.label === '&laquo; Previous') ? datas?.url.charAt(datas?.url.length - 1) : datas?.label)} disabled={datas?.active} className={'mx-1 py-1 px-2 ' + (datas?.active == false ? 'bg-gray-300 text-black ' : 'bg-[#00B07B] text-white')}>
                                        {datas?.label == '&laquo; Previous' ? '< Previous' : (datas?.label === 'Next &raquo;') ? 'Next  >' : datas?.label}
                                    </button>
                                )
                            )
                        }

                    </div>



                </div>
            </div>
        </>
    )
}

export default StudentLoanList;