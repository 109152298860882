import React from 'react'
import Navbar from '../../Navbar'
import Footer from '../../Footer';
import BlogBg from "../../../img/blog.png"
import { NavLink } from 'react-router-dom';

function SpendingHabits() {
  return (
    <>
    <Navbar />
    <div className='pt-16'>
        <div
            style={{
                backgroundImage: `url(${BlogBg})`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                backgroundPosition: "center center",
            }}
            className="md:bg-cover bg-contain lg:px-14 px-6 md:pb-24 pb-10 mx-auto  flex flex-col lg:justify-center lg:space-x-14 relative"
        >
            <div className="place-self-center text-center md:pt-32 md:pb-10 pt-8">
                <h1 className="md:text-5xl text-2xl text-[#191A15] font-bold">
                These Spending Habits Will Get You Broke Quicker, Try It!
                </h1>

                <p className="md:mt-5 mt-2 md:text-base text-[#999A93] text-sm">
                    Get News with AfriPay
                </p>
            </div>
        </div>
    </div>

    <section className="bg-[#FFFFFF] body-font font-poppins py-10">
        <div className='lg:px-16 mx-auto px-8 pt-3'>
            <div className='grid grid-cols-1 gap-8 md:grid-cols-3 lg:grid-cols-12'>
                <div className='col-span-8'>
                    <div>
                        <img src="/images/blog11.jpg" alt="blog" className='rounded-[10px]' />


                        <p className='mt-3'>We all know those spending traps that leave us scratching our heads at the end of the month wondering, where did all my money go? It's time to talk about the sneaky, often invisible habits that fast-track you to broke town. This blog will take a light-hearted, entertaining approach to help you recognize those habits and avoid them, saving your hard-earned money for the future!</p>
                    </div>

                </div>
            </div>

            <div className='mt-5'>
                <h3 className='mt-3 text-[20px] font-semibold'>1. Impulse Buying: What’s the Rush?</h3>

                <div className='mt-5'>
                    <img src="/images/spending1.jpg" alt="blog" className='' />
                </div>

                <p className='mt-3'>The thrill of grabbing a cute shirt you saw online or treating yourself to an expensive dinner because "you deserve it" is real—but it’s also one of the easiest ways to lose track of your finances. Impulse buying is when you purchase something without planning for it. You’re not thinking about your budget, your future, or your savings—just the "now." And guess what? That ‘now’ feeling disappears as soon as the purchase loses its shine, and you’re left with a thinner wallet.</p>

                <p className='mt-3 text-[18px]'><span className=' font-semibold'>Why it happens:</span>   Impulse buys give instant gratification. A little dopamine boost!                   </p>
                <p className='mt-3 text-[18px]'><span className=' font-semibold'>Who does it affect:</span> Practically everyone who’s not careful!</p>
                <p className='mt-3 text-[18px]'><span className=' font-semibold'>Where it hurts: </span>Your bank account, long-term savings, and mental peace.</p>
                <p className='mt-4 text-[18px]'><span className=' font-semibold'>Recommendation: </span>Before purchasing, use the "30-minute rule." Wait 30 minutes and ask yourself: Do I really need this? Chances are, you'll skip that purchase!</p>

      

                <p className='mt-2 text-[18px]'><NavLink to={"https://www.invespcro.com/blog/impulse-buying/"}><span className=' underline underline-offset-1 cursor-pointer text-[#14B266]'>Impulse Buying Statistics</span></NavLink></p>




                <h3 className='mt-5 text-[20px] font-semibold'>2 . Subscription Traps: Do You Really Need 4 Streaming Services?</h3>
                <div className='mt-5'>
                    <img src="/images/spending2.jpg" alt="blog" className='' />
                </div>

                <p className='mt-3 text-[18px]'>In today's digital world, subscriptions are everywhere. Netflix, Spotify, Hulu, Amazon Prime—before you know it, they add up! Each might seem affordable, but when combined, these small recurring charges can drain your account. Even worse, we often forget we’re paying for them until it’s too late!</p>
               
                <p className='mt-3 text-[18px]'><span className=' font-semibold'>What it does:</span>Eats up your paycheck quietly over time.</p>
                <p className='mt-3 text-[18px]'><span className=' font-semibold'>Why it happens:</span>With companies making subscriptions as easy as one click, it’s super convenient to accumulate them. </p>
                <p className='mt-3 text-[18px]'><span className=' font-semibold'>Who gets trapped:</span> Anyone who doesn't actively track their subscriptions.</p>
                <p className='mt-3 text-[18px]'><span className=' font-semibold'>Where to find them: </span>Check your app store, bank statements, or account settings.</p>
                <p className='mt-4 text-[18px]'><span className=' font-semibold'>Recommendation: </span> Do a subscription cleanse every few months. If you’re not using a service frequently, cancel it!</p>


                <p className='mt-2 text-[18px]'>AfriPay can help with so many transactions with ease, check it out <NavLink to={"http://goafripay.com"}><span className=' underline underline-offset-1 cursor-pointer text-[#14B266]'>AfriPay</span></NavLink></p>

                <h3 className='mt-5 text-[18px] font-semibold'>3. Lifestyle Inflation: Because You Got a Raise Doesn’t Mean You Have to Spend More</h3>

                <div className='mt-5'>
                    <img src="/images/spending3.jpg" alt="blog" className='' />

                </div>
                <p className='mt-3 text-[18px]'>One common pitfall is "lifestyle inflation," where as your income grows, so do your expenses. Got a raise? Suddenly you want that new car or a bigger apartment. You start dining out more often or buying luxury items, thinking you’ve "earned it."</p>

                <p className='mt-3 text-[18px]'><span className=' font-semibold'>What it is:</span>Adjusting your lifestyle in line with a new paycheck instead of saving the extra income.</p>
                <p className='mt-3 text-[18px]'><span className=' font-semibold'>Why it happens:</span>The desire to reward yourself for hard work!</p>
                <p className='mt-3 text-[18px]'><span className=' font-semibold'>Who falls for it:</span> Anyone who’s just received a raise or new job.</p>
                <p className='mt-3 text-[18px]'><span className=' font-semibold'>Where it shows: </span>Your bank balance and growing bills.</p>
                <p className='mt-4 text-[18px]'><span className=' font-semibold'>Recommendation: </span> Don’t let your spending grow as fast as your income. Try to maintain the same lifestyle for a few years after a raise, and stash the extra cash into savings.</p>


                <p className='mt-2 text-[18px]'><NavLink to={"https://www.marketwatch.com/guides/banking/lifestyle-creep-how-to-avoid-the-trap-and-save-more/#:~:text=Also%20known%20as%20“lifestyle%20inflation,position%2C%20despite%20earning%20more%20money."}><span className=' underline underline-offset-1 cursor-pointer text-[#14B266]'>Lifestyle Inflation Trap</span></NavLink></p>


                <h3 className='mt-5 text-[18px] font-semibold'>4.  Not Tracking Expenses: Ignorance is NOT Bliss</h3>
                <p className='mt-3 text-[18px]'>If you don’t know where your money is going, you're setting yourself up for financial trouble. Many people have no clue how much they’re spending on coffee, dining out, or online shopping. This lack of awareness makes it easy to overspend and rack up debt.</p>

                <p className='mt-3 text-[18px]'><span className=' font-semibold'>What’s wrong with it:</span> Without clear visibility, you’re bound to overspend. </p>
                <p className='mt-3 text-[18px]'><span className=' font-semibold'>How it happens:</span> It’s easy to avoid tracking your spending when everything feels automated and manageable.</p>
                <p className='mt-3 text-[18px]'><span className=' font-semibold'>Who struggles:</span> Anyone who relies on guesswork when budgeting. </p>
                <p className='mt-3 text-[18px]'><span className=' font-semibold'>Where it impacts: </span> Your monthly savings and ability to achieve financial goals.</p>
                <p className='mt-4 text-[18px]'><span className=' font-semibold'>Recommendation: </span> Use apps like Mint or YNAB (You Need A Budget) to track every penny. Knowing where your money is going is the first step to controlling it.</p>


                <p className='mt-2 text-[18px]'><NavLink to={"https://www.bankofthejames.bank/articles/3-reasons-why-you-should-start-tracking-your-expenses/"}><span className=' underline underline-offset-1 cursor-pointer text-[#14B266]'>The Importance of Tracking Expenses</span></NavLink></p>



                <h3 className='mt-5 text-[18px] font-semibold'>5. Ignoring Emergency Savings: Playing with Financial Fire</h3>
                <p className='mt-3 text-[18px]'> One of the biggest mistakes people make is not having an emergency fund. It might seem fine when life is smooth sailing, but when unexpected costs like medical bills, car repairs, or job loss hit, they can send you into a financial tailspin.</p>
               
                <p className='mt-3 text-[18px]'><span className=' font-semibold'>What happens:</span> You end up in debt, taking out high-interest loans, or digging into credit cards when emergencies arise. </p>
                <p className='mt-3 text-[18px]'><span className=' font-semibold'>Why it’s a problem:</span> Without a safety net, even minor emergencies can lead to massive financial setbacks.</p>
                <p className='mt-3 text-[18px]'><span className=' font-semibold'>Who faces this risk:</span>People who live paycheck to paycheck or who don’t prioritize savings. </p>
                <p className='mt-3 text-[18px]'><span className=' font-semibold'>Where it hurts: </span>  Long-term financial stability.</p>
                <p className='mt-4 text-[18px]'><span className=' font-semibold'>Recommendation: </span> Build an emergency fund with 3-6 months' worth of expenses. Start small but stay consistent.</p>


                <p className='mt-2 text-[18px]'><NavLink to={"https://www.bankrate.com/banking/savings/starting-an-emergency-fund/"}><span className=' underline underline-offset-1 cursor-pointer text-[#14B266]'>Emergency Fund Tips</span></NavLink></p>


                <h3 className='mt-5 text-[18px] font-semibold'>So, What Can You Do to Break These Bad Habits?</h3>

                <div className='mt-5'>
                    <img src="/images/spending4.jpg" alt="blog" className='' />

                </div>

                <p className='mt-3 text-[18px]'>Here’s a quick recap of how to avoid these common pitfalls:</p>

                <ul className='space-y-4 list-decimal list-inside mt-4'>
                            <li><span className=' font-semibold'> Plan your purchases:</span> Avoid impulse buys by waiting before committing. </li>
                            <li><span className=' font-semibold'> Keep track of your subscriptions: </span>  Don’t let small recurring charges eat into your savings.</li>
                            <li><span className=' font-semibold'> Don’t let lifestyle inflation happen: </span>  Live below your means and save the surplus.</li>
                            <li><span className=' font-semibold'> Track your spending: </span>  Use apps to gain full visibility of your finances.</li>
                            <li><span className=' font-semibold'> Prioritize an emergency fund: </span> Be prepared for the unexpected! </li>

                        </ul>


                <h3 className='mt-5 text-[20px] font-semibold'>Ready to Save Smarter?</h3>

                <div className='mt-5'>
                    <img src="/images/spending5.jpg" alt="blog" className='' />

                </div>
                <p className='mt-3 text-[18px]'>If you want to stay ahead and avoid these common financial mistakes, AfriPay is launching a <span className=' font-semibold'>savings feature</span> soon! With <span className=' font-semibold'>AfriPay,</span> you can easily save, manage expenses, and stay financially prepared for the future. Say goodbye to bad spending habits and hello to smarter money management!</p>

                <p className='mt-2 text-[18px]'>Sign up today to be the first to know when our savings feature goes live at <NavLink to={"http://goafripay.com"}><span className=' underline underline-offset-1 cursor-pointer text-[#14B266]'>AfriPay</span></NavLink></p>



                <hr className='my-4' />
                {/* <-- Tags --> */}
                <div className='mt-3'>
                    <h3 className='mt-5 text-[20px] font-semibold'>References:</h3>
                    <h3 className='mt-3'><NavLink to="https://www.invespcro.com/blog/impulse-buying/"><span className=' underline underline-offset-1 cursor-pointer text-[#14B266]'>1. Impulse Buying Statistics
                    </span></NavLink></h3>
                    <h3 className='mt-3'><NavLink to="https://britepayments.com/resources/article/subscription-economy/#:~:text=The%20subscription%20economy%20is%20the,to%20a%20product%20or%20service."><span className=' underline underline-offset-1 cursor-pointer text-[#14B266]'>2. Subscription Economy
                    </span></NavLink></h3>
                    <h3 className='mt-3' ><NavLink to="https://www.marketwatch.com/guides/banking/lifestyle-creep-how-to-avoid-the-trap-and-save-more/#:~:text=Also%20known%20as%20“lifestyle%20inflation,position%2C%20despite%20earning%20more%20money."><span className=' underline underline-offset-1 cursor-pointer text-[#14B266]'>3. Lifestyle Inflation Trap
                    </span></NavLink></h3>
                    <h3 className='mt-3'><NavLink to="https://www.bankofthejames.bank/articles/3-reasons-why-you-should-start-tracking-your-expenses/"><span className=' underline underline-offset-1 cursor-pointer text-[#14B266]'>4. The Importance of Tracking Expenses
                    </span></NavLink></h3>
                    <h3 className='mt-3'><NavLink to="https://www.bankrate.com/banking/savings/starting-an-emergency-fund/"><span className=' underline underline-offset-1 cursor-pointer text-[#14B266]'>5. Emergency Fund Tips
                    </span></NavLink></h3>
                </div>
            </div>
        </div>
    </section>



    {/* <-- Footer --> */}
    <Footer />
</>
  )
}

export default SpendingHabits