import React, { useState } from "react";
import Sidebar from "../../Sidebar/Sidebar";
import UserNavbar from "../../Navbars/UserNavbar";
import HeaderTitle from '../../ReuseableComponent/HeaderTitle';
import { useNavigate, Link } from 'react-router-dom';
import DownloadForms from "./DownloadForms";
import UploadForms from "./UploadForms";

function ProofOfFunds() {

    let [screen, setScreen] = React.useState<any>(1);
    return (
        <>
            <Sidebar />
            <div className="relative md:ml-64">
                <UserNavbar />
                <div className='lg:mt-5 lg:px-10 px-6 bg-[#Ffffff] min-h-screen'>


                    <div className='mt-[20px] flex'>


                        <div className={"w-full lg:w-6/12 " + (screen == 1 ? '' : 'hidden')}>
                            <HeaderTitle title="Proof of Funds " />
                            <div className='mt-[22px]'>
                                <h3 className='text-[#6E7093] text-[12px] mb-[60px] font-normal'>Apply for your proof of funds here . </h3>
                            </div>
                            <div className='border-[0.5px] border-[#C4C4C4] bg-white rounded-[10px] mb-3 py-6 px-5'>
                                <div className='flex flex-col md:flex-row md:justify-between'>
                                    <div>
                                        <img src="/images/Dashbord/keystone-bank.png" alt="logo" />
                                    </div>
                                    <div>
                                        <h3 className=' text-[12px]  text-[#979797]'>Minimum Amount</h3>
                                        <h3 className='text-[18px] font-semibold text-[#000] '>NGN 3,000,000.00 </h3>
                                    </div>
                                    <div>
                                        <h3 className=' text-[12px]  text-[#979797]'>Interest Rate </h3>
                                        <h3 className='text-[18px] font-semibold text-[#000] '>4.5%</h3>
                                    </div>

                                </div>

                            </div>
                            <div className='border-[0.5px] border-[#C4C4C4] bg-white rounded-[10px] mb-3 py-6 px-5'>
                                <div className='flex flex-col md:flex-row md:justify-between'>
                                    <div>
                                        <img src="/images/Dashbord/fidelity-bank.png" alt="logo" />
                                    </div>
                                    <div>
                                        <h3 className=' text-[12px]  text-[#979797]'>Minimum Amount</h3>
                                        <h3 className='text-[18px] font-semibold text-[#000] '>NGN 5,000,000.00</h3>
                                    </div>
                                    <div>
                                        <h3 className=' text-[12px]  text-[#979797]'>Interest Rate </h3>
                                        <h3 className='text-[18px] font-semibold text-[#000] '>3.5%</h3>
                                    </div>

                                </div>

                            </div>
                            <div className="flex items-center mb-4">
                                <input id="default-checkbox" type="checkbox" value="" className="w-[24px] h-[24px] accent-green-600 border border-gray-300 rounded" />
                                <label htmlFor="default-checkbox" className="ml-2 mt-2 text-[12px] font-medium text-[#6E7093]">I agree that I have read and accepted the <span className="text-[#00B07B]">Terms and conditions</span> <br />and wish to proceed </label>
                            </div>



                            {/* <div className='w-full flex space-x-5 mt-5'>
                                <button
                                    type='button'
                                    onClick={() => navigate(-1)}
                                    className=" bg-[#979797] text-white mt-3 font-medium rounded-[5px] text-[12px] w-full py-2.5 text-center "
                                >Back</button>
                                <button
                                    type='submit'

                                    className=

                                    "bg-[#00B07B] text-white mt-3 font-medium rounded-[5px] text-[12px] w-full py-2.5 text-center "


                                >Continue</button>
                            </div> */}
                        </div>


                        <div className={"w-full lg:w-6/12 " + (screen == 2 ? '' : 'hidden')}>
                            {/* <DownloadForms/> */}
                            <div className=" ">
                                <HeaderTitle title="Download Forms  " />
                                <div className='mt-[22px]'>
                                    <h3 className='text-[#6E7093] text-[12px] mb-[60px] font-normal'>Please download the following forms</h3>
                                </div>
                                <div className='border-[0.5px] border-[#C4C4C4] bg-white rounded-[5px] mb-3 py-5 px-5'>
                                    <a download="Example-PDF-document" target="_blank" rel="noreferrer">
                                    <Link to="/files/doc_2.pdf" target="_blank" download>
                                        <div className='flex flex-col md:flex-row md:justify-between'>
                                            <div >
                                                <h3 className=' text-[12px]  text-[#979797]'>1. First Document </h3>

                                                </div>
                                            <div className=" cursor-pointer">

                                                <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M12 16.5L7 11.5L8.4 10.05L11 12.65V4.5H13V12.65L15.6 10.05L17 11.5L12 16.5ZM6 20.5C5.45 20.5 4.97933 20.3043 4.588 19.913C4.19667 19.5217 4.00067 19.0507 4 18.5V15.5H6V18.5H18V15.5H20V18.5C20 19.05 19.8043 19.521 19.413 19.913C19.0217 20.305 18.5507 20.5007 18 20.5H6Z" fill="#6E7093" />
                                                </svg>
                                            </div>
                                           

                                        </div>
                                        </Link>
                                    </a>


                                </div>
                                <div className='border-[0.5px] border-[#C4C4C4] bg-white rounded-[5px] mb-3 py-5 px-5'>
                                    <a download="Example-PDF-document" target="_blank" rel="noreferrer">
                                    <Link to="/files/doc_1.pdf" target="_blank" download>
                                        <div className='flex flex-col md:flex-row md:justify-between'>
                                            <div >
                                                <h3 className=' text-[12px]  text-[#979797]'>2. Second Document </h3>

                                                </div>
                                            <div className=" cursor-pointer">

                                                <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M12 16.5L7 11.5L8.4 10.05L11 12.65V4.5H13V12.65L15.6 10.05L17 11.5L12 16.5ZM6 20.5C5.45 20.5 4.97933 20.3043 4.588 19.913C4.19667 19.5217 4.00067 19.0507 4 18.5V15.5H6V18.5H18V15.5H20V18.5C20 19.05 19.8043 19.521 19.413 19.913C19.0217 20.305 18.5507 20.5007 18 20.5H6Z" fill="#6E7093" />
                                                </svg>
                                            </div>
                                           

                                        </div>
                                        </Link>
                                    </a>


                                </div>
                                {/* <div className='border-[0.5px] border-[#C4C4C4] bg-white rounded-[5px] mb-3 py-5 px-5'>
                                    <a download="Example-PDF-document" target="_blank" rel="noreferrer">
                                        <div className='flex flex-col md:flex-row md:justify-between'>

                                            <div>
                                                <h3 className=' text-[12px]  text-[#979797]'>3. Name of File to be downloaded </h3>

                                            </div>
                                            <div className=" cursor-pointer">

                                                <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M12 16.5L7 11.5L8.4 10.05L11 12.65V4.5H13V12.65L15.6 10.05L17 11.5L12 16.5ZM6 20.5C5.45 20.5 4.97933 20.3043 4.588 19.913C4.19667 19.5217 4.00067 19.0507 4 18.5V15.5H6V18.5H18V15.5H20V18.5C20 19.05 19.8043 19.521 19.413 19.913C19.0217 20.305 18.5507 20.5007 18 20.5H6Z" fill="#6E7093" />
                                                </svg>
                                            </div>

                                        </div>
                                    </a>


                                </div> */}
                                <div>

                                    <button
                                        type='button'
                                        onClick={() => setScreen(3)}
                                        className=

                                        "bg-[#00B07B] text-white mt-3 font-medium rounded-[5px] text-[12px] w-full py-2.5 text-center "


                                    >Next</button>

                                </div>







                                {/* <div className='w-full flex space-x-5 mt-5'>
<button
type='button'
onClick={() => navigate(-1)}
className=" bg-[#979797] text-white mt-3 font-medium rounded-[5px] text-[12px] w-full py-2.5 text-center "
>Back</button>
<button
type='submit'

className=

"bg-[#00B07B] text-white mt-3 font-medium rounded-[5px] text-[12px] w-full py-2.5 text-center "


>Continue</button>
</div> */}
                            </div>
                        </div>

                        <div className={"w-full lg:w-6/12 " + (screen == 3 ? '' : 'hidden')}>
                            <UploadForms />
                        </div>



                        <div className="w-full lg:w-2/12 hidden md:block"></div>
                        <div className="w-full lg:w-4/12 hidden md:block pb-6 mt-48">
                            <div className=' border border-[#979797] px-[22px] pt-4 pb-5 rounded-[15px]'>
                                <h3 className="text-[#00B07B] text-[18px] font-semibold">Proof of Funds Calculator</h3>
                                <form>
                                    <div className="mt-3">
                                        <div className="relative w-full mb-6">
                                            <label
                                                className="block text-[#6E7093] text-[12px] font-medium mb-2"
                                                htmlFor="account_number"
                                            >
                                                How much do you need?
                                            </label>
                                            <input
                                                type="number"
                                                //   onChange={handleChange}
                                                name="account_number"
                                                className="bg-white border border-[#DADADA] text-[#000] text-sm rounded-[5px] block w-full p-3 placeholder-[#000]  ease-linear transition-all duration-150"
                                                placeholder="NGN 0.00"

                                            />
                                        </div>
                                        <div className="relative w-full mb-6">
                                            <label
                                                className="block text-[#6E7093] text-[12px] font-medium mb-2"
                                                htmlFor="account_number"
                                            >
                                                Duration
                                            </label>
                                            <input
                                                type="number"
                                                //   onChange={handleChange}
                                                name="account_number"
                                                className="bg-white border border-[#DADADA] text-[#000] text-sm rounded-[5px] block w-full p-3 placeholder-[#000]  ease-linear transition-all duration-150"
                                                placeholder="No of Months"

                                            />
                                        </div>
                                        <div className="relative w-full mb-6">
                                            <label
                                                className="block text-[#6E7093] text-[12px] font-medium mb-2"
                                                htmlFor="account_number"
                                            >
                                                Interest rate
                                            </label>
                                            <input
                                                type="number"
                                                //   onChange={handleChange}
                                                disabled
                                                name="account_number"
                                                className="bg-white border border-[#DADADA] text-[#000] text-sm rounded-[5px] block w-full p-3 placeholder-[#000]  ease-linear transition-all duration-150"
                                                placeholder="3.5%"

                                            />
                                        </div>
                                        <div className="relative w-full mb-6">
                                            <label
                                                className="block text-[#6E7093] text-[12px] font-medium mb-2"
                                                htmlFor="account_number"
                                            >
                                                First Month Payment
                                            </label>
                                            <input
                                                type="number"
                                                //   onChange={handleChange}
                                                disabled
                                                name="account_number"
                                                className="bg-white border border-[#DADADA] text-[#000] text-sm rounded-[5px] block w-full p-3 placeholder-[#000]  ease-linear transition-all duration-150"
                                                placeholder=""

                                            />
                                        </div>
                                        <div className="relative w-full mb-6">
                                            <label
                                                className="block text-[#6E7093] text-[12px] font-medium mb-2"
                                                htmlFor="account_number"
                                            >
                                                Initiation Payment
                                            </label>
                                            <input
                                                type="number"
                                                //   onChange={handleChange}
                                                disabled
                                                name="account_number"
                                                className="bg-white border border-[#DADADA] text-[#000] text-sm rounded-[5px] block w-full p-3 placeholder-[#000]  ease-linear transition-all duration-150"
                                                placeholder=""

                                            />
                                        </div>

                                        <div>
                                            <div onClick={() => setScreen(2)} className="w-full">
                                                <button
                                                    type='button'

                                                    className=

                                                    "bg-[#00B07B] text-white mt-3 font-medium rounded-[38px] text-[12px] w-full py-2.5 text-center "


                                                >Proceed</button>
                                            </div>

                                        </div>
                                    </div>

                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ProofOfFunds