import React from 'react'
import { NavLink } from "react-router-dom";
import Footer from '../Footer';

function PrivacyPolicy() {
  return (
    <>
    <div className='py-3 lg:pt-2 lg:px-20 px-6'>
                <NavLink to="/" >
                    <img src="/images/afripay-logo.png" alt="logo" className="pt-4" />

                </NavLink>
            </div>

            <hr className='mt-3' />
            <div className='lg:px-20 px-6 pt-10 pb-5'>
                <div>
                    {/* <h1 className='text-[36px] font-bold'> Afripay Terms of Use</h1> */}
                    <h1 className='text-[36px] font-bold'>Privacy Policy</h1>

                    <h3 className='text-[20px] mt-2 text-[#6E7093]'>Last Updated: April 1, 2024</h3>
                </div>
                <p className='text-[16px] mt-3'>AfriPay is committed to protecting the privacy of our users. This Privacy Policy outlines how we collect, use, disclose, and safeguard your personal information when you use our services.</p>
                <div className='mt-5'>
                    <h3 className='text-[25px] font-semibold'>Information We Collect</h3>
                    <ul className='mt-2 list-disc list-outside space-y-2 text-black text-[16px] pl-4'>
                        <li>Personal Information: We may collect personal information such as your name, email address, phone number, and billing information when you register an account or make transactions through our platform.</li>
                        <li>Usage Information: We automatically collect information about your interactions with our platform, including your IP address, device information, and browsing activities.</li>
                        <li>Cookies: We use cookies and similar tracking technologies to enhance your experience and analyze usage patterns on our platform.</li>
                    </ul>
                </div>

                <div className='mt-5'>
                    <h3 className='text-[25px] font-semibold'>How We Use Your Information</h3>
                    <ul className='mt-2 list-disc list-outside space-y-2 text-black text-[16px] pl-4'>
                        <li>Provide Services: We use your information to deliver the services you request, such as processing transactions and managing your account.</li>
                        <li>Improve Our Platform: We analyze usage data to enhance and optimize our platform's performance and user experience.</li>
                        <li>Communicate with You: We may send you updates, promotional offers, and important notifications related to your account or our services.</li>
                        <li>Legal Compliance: We may use your information to comply with legal obligations or enforce our terms and policies.</li>
                    </ul>
                </div>
                <div className='mt-5'>
                    <h3 className='text-[25px] font-semibold'>Information Sharing</h3>
                    <ul className='mt-2 list-disc list-outside space-y-2 text-black text-[16px] pl-4'>
                        <li>Third-Party Service Providers: We may share your information with trusted third-party service providers to facilitate transactions and improve our services.</li>
                        <li>Legal Requirements: We may disclose your information in response to legal requests or to protect our rights, property, or safety, or that of others.</li>
                    </ul>
                </div>
                <div className='mt-5'>
                    <h3 className='text-[25px] font-semibold'>Data Security</h3>
                    <ul className='mt-2 list-disc list-outside space-y-2 text-black text-[16px] pl-4'>
                        <li>We implement appropriate security measures to protect your personal information from unauthorized access, disclosure, alteration, or destruction.</li>
                    </ul>
                </div>
                <div className='mt-5'>
                    <h3 className='text-[25px] font-semibold'>Your Choices</h3>
                    <ul className='mt-2 list-disc list-outside space-y-2 text-black text-[16px] pl-4'>
                        <li>You can update your account settings and preferences to manage communication preferences and opt-out of promotional emails.</li>
                        <li>You may also disable cookies in your browser settings, although this may affect your experience on our platform.</li>
                    </ul>
                </div>
                <div className='mt-5'>
                    <h3 className='text-[25px] font-semibold'>Changes to this Policy</h3>
                    <ul className='mt-2 list-disc list-outside space-y-2 text-black text-[16px] pl-4'>
                        <li>We reserve the right to update or modify this Privacy Policy at any time. We will notify you of any changes by posting the revised policy on our website.</li>
                    </ul>
                </div>
                <div className='mt-5'>
                    <h3 className='text-[25px] font-semibold'>Contact Us</h3>
                    <ul className='mt-2 list-disc list-outside space-y-2 text-black text-[16px] pl-4'>
                        <li>If you have any questions or concerns about our Privacy Policy, please contact us at <span className='text-[#209D5A]'>hello@goafripay.com</span>.</li>
                        
                    </ul>
                </div>
                

            </div>

            <Footer/>
    </>
  )
}

export default PrivacyPolicy