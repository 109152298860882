import React, { useState } from 'react'
import Sidebar from "../../Sidebar/Sidebar";
import UserNavbar from "../../Navbars/UserNavbar";
import HeaderTitle from '../../ReuseableComponent/HeaderTitle';
import { useNavigate, Link, useSearchParams } from 'react-router-dom';
import "react-responsive-modal/styles.css";
import { Modal } from 'react-responsive-modal'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { DashboardApi } from '../../../apis/DashboardApi';
import { MutatingDots, Oval } from 'react-loader-spinner'
import { AxiosResponse } from 'axios';
import { useSelector, useDispatch } from 'react-redux';
import { Dispatch } from "redux";


function PaymentLists() {

    const userLoginData = useSelector((state: any) => state.data.login.value);
    const navigate = useNavigate();
    const [open, setOpen] = useState(false);
    const [loader, setLoader] = useState<any>(false);
    const onOpenModal = () => setOpen(true);
    const onCloseModal = () => setOpen(false);
    let [screen, setScreen] = React.useState<any>(1);
    const [searchText, setSearchText] = React.useState('');
    const [transactionList, setTransactionList] = React.useState<any>([]);
    const [schoolList, setSchoolList] = React.useState<any>([]);
    const [searchParams, setSearchParams] = useSearchParams();

    const [openAddInstitution, setOpenAddInstitution] = useState(false);
    const onOpenAddInstitutionModal = () => setOpenAddInstitution(true);
    const onCloseAddInstitutionModal = () => setOpenAddInstitution(false);


    // console?.log(userLoginData?.email)

    React.useEffect(() => {
        const query: any = {
            search: searchText,
        };
        DashboardApi.getPaymentHistory('', query).then(
            (response: AxiosResponse<any>) => {
                if (response?.data) {
                    setTransactionList(response?.data?.data)
                }
            }
        ).catch(function (error) {
            // handle error

        })


    }, []);


    React.useEffect(() => {
        DashboardApi.getSchoolList().then(
            (response: AxiosResponse<any>) => {
                if (response?.data) {
                    setSchoolList(response?.data?.data)
                    // console?.log(response?.data?.data)
                }
            }
        ).catch(function (error) {
            // handle error

        })


    }, []);

    let [schoolData, setSchoolData] = React.useState({
        school_name: "", location: "",
        email_address: "empty", phone_number: "",student_email:"empty",
        account_number: "", swift_code: "0",
        sort_code: "0", accepted_currency: "",
        other_information: "",
    });

    const handleChange2 = (e: any) => {
        setSchoolData({ ...schoolData, [e.target.name]: e.target.value });
    }

    const addSchool = React.useCallback(
        (e: any) => {
            e.preventDefault();
            // setLoader(true);
            const userData = new FormData();
            userData.append('school_name', schoolData?.school_name);
            userData.append('location', schoolData?.location);
            userData.append('email_address', schoolData?.email_address);
            userData.append('phone_number', schoolData?.phone_number);
            userData.append('account_number', schoolData?.account_number);
            userData.append('swift_code', schoolData?.swift_code);
            userData.append('sort_code', schoolData?.sort_code);
            userData.append('accepted_currency', schoolData?.accepted_currency);
            userData.append('other_information', schoolData?.other_information);

            DashboardApi.addSchool(userData).then(
                (response: any) => {
                    if (response?.data) {
                        toast.success('School Added Successfully');
                        onCloseAddInstitutionModal()
                        toast.success('School Added Successfully');


                        DashboardApi.getSchoolList().then(
                            (response: AxiosResponse<any>) => {
                                if (response?.data) {
                                    setSchoolList(response?.data?.data)
                                    console?.log(response?.data?.data)
                                }
                            }
                        )
                    }
                }
            ).catch((err) => {
                if (err?.response?.status == 422) {
                    toast.error('Form not rightly filled. Kindly Cross check.');
                } else {
                    toast.error('Some error occured.Please try again');
                }


            }).finally(() => {

            });
        },
        [schoolData, loader]
    );

    const [nairaValue, setNairaValue] = React.useState<any>('');

    let [userDatas, setUserDatas] = React.useState<any>({
        country: "", institution: "",
        institution_type: "null", institution_email: "",
        institution_address: "", payment_by: "",
        payment_for: "", account_number: "",
        sort_code: "", swift_code: "",
        sch_currency: "", school_amount: '',
        program_type: 'masters',
        payment_instruction: "", student_first_name: '',
        student_last_name: "", student_email: '',
        means_of_identification: "", additional_info: '',
        payment_status: "", country_from: "",
    });


    React.useEffect(() => {
        {
            if (userDatas?.sch_currency == 'eur') {
                setNairaValue((parseInt(userDatas?.school_amount) + ((userDatas?.program_type == 'masters' || userDatas?.program_type =='')?100:200) ));
                // setNairaValue((parseInt(userDatas?.school_amount) + 100) * 1800);
            } else {
                setNairaValue((parseInt(userDatas?.school_amount) + ((userDatas?.program_type == 'masters' || userDatas?.program_type =='')?100:200)));
                // setNairaValue((parseInt(userDatas?.school_amount) + 100) * 1500);
            }
        }
    }, [userDatas?.school_amount, userDatas?.sch_currency,userDatas?.program_type]);


    const [instInfo, setInstInfo] = React.useState<any>('');
    React.useEffect(() => {
        {
            schoolList?.filter((data: any) => data?.school_name == userDatas?.institution)?.map(
                (datas: any, index: any) => (
                    // setUserDatas({ ...userDatas, institution_address: datas?.location })
                    setInstInfo(datas)
                )
            )
            setUserDatas({ ...userDatas, institution_address: instInfo?.location })
        }
    }, [userDatas?.institution, instInfo]);

    if (searchParams.get('tx_ref')) {
        DashboardApi.getCallback(searchParams.get('tx_ref')).then(
            (response: any) => {
                if (response?.data) {
                    // console?.log(response?.data)
                    if (response?.data?.success === true) {
                        toast.success(response?.data?.data);
                        console?.log(response?.data)
                        navigate('/payments');
                        window.location.reload();
                    }
                } else {
                    toast.error("payment failed");
                    navigate('/payments');
                }
            }
        ).catch(function (error) {
            // handle error
            console.log(error.response.data);
        }).finally(() => {
            // toast.error("No Internet Connection");

        });
    }

    if (searchParams.get('response')) {
        toast.success(searchParams.get('response'));
        navigate('/payments')
    }

    const handleChange = (e: any) => {
        setUserDatas({ ...userDatas, [e.target.name]: e.target.value });
    }


    const [selectedPics, setSelectedPics] = React.useState<any>('No selected file');
    const changeHandler = (event: any) => {
        // console?.log(2)
        const target = event.target
        let size = (target.files[0].size / 1048576.0)
        let targetName = target.name
        if (target.files && target.files[0]) {
            if (size > 1) {
                if (targetName == 'payment_instruction') {
                    target.value = ''
                    toast.warn('File too large');
                }
            }
            if (size <= 1) {
                if (targetName == 'payment_instruction') {
                    setSelectedPics(event.target.files[0]);
                }
            }
        }
    };

    const [selectedPics2, setSelectedPics2] = React.useState<any>('No selected file');
    const changeHandler2 = (event: any) => {
        console?.log(2)
        const target = event.target
        let size = (target.files[0].size / 1048576.0)
        let targetName = target.name
        if (target.files && target.files[0]) {
            if (size > 1) {
                if (targetName == 'means_of_identification') {
                    target.value = ''
                    toast.warn('File too large');
                }
            }
            if (size <= 1) {
                if (targetName == 'means_of_identification') {
                    setSelectedPics2(event.target.files[0]);
                }
            }
        }
    };

    const makePayments = React.useCallback(
        (e: any) => {
            e.preventDefault();
            setLoader(true);
            const userData = new FormData();
            userData.append('country', userDatas?.country);
            userData.append('country_from', userDatas?.country_from);
            userData.append('institution', userDatas?.institution);
            userData.append('institution_type', 'School');
            userData.append('institution_email', instInfo?.email_address);
            userData.append('institution_address', userDatas?.institution_address);
            userData.append('payment_by', userDatas?.payment_by);
            userData.append('payment_for', userDatas?.payment_for);
            userData.append('account_number', userDatas?.account_number);
            userData.append('sort_code', userDatas?.sort_code);
            userData.append('swift_code', userDatas?.swift_code);
            userData.append('sch_currency', 'USD');
            userData.append('school_amount', userDatas?.school_amount);
            userData.append('student_first_name', userDatas?.student_first_name);
            userData.append('student_last_name', userDatas?.student_last_name);
            userData.append('student_email', userDatas?.student_email);
            userData.append('additional_info', userDatas?.additional_info);
            userData.append('payment_status', 'pending');
            userData.append('means_of_identification', selectedPics2);
            userData.append('payment_instruction', selectedPics);

            DashboardApi.makeSchoolPayment(userData).then(
                (response: any) => {
                    if (response?.data) {
                        toast.success('Information Submitted Successfully');
                        console?.log(response?.data?.data)
                        setLoader(false)
                        setScreen(6)
                        // navigate('/wallet');
                        // dispatch(login({ email: userLoginData?.email, token: userLoginData?.token, id: userLoginData?.id, name: response.data.data.name,data:response.data.data}))
                    }
                }
            ).catch((err) => {
                if (err?.response?.status == 422) {
                    toast.error('Form not rightly filled. Kindly Cross check.');
                } else {
                    toast.error('Some error occured.Please try again');
                }


            }).finally(() => {

            });
        },
        [selectedPics, selectedPics2, userDatas, screen, loader]
    );


    const handleSubmit = (e: any) => {
        setLoader(true)
        const formData = new FormData()
        formData.append('amount', (parseFloat(nairaValue) * 1.015).toFixed(2) )
        formData.append('payment_id', userDatas?.id)
        formData.append('customer_id', userLoginData?.email)
        DashboardApi.makePayment(formData).then(
            (response: any) => {
                if (response?.data) {
                    if (response?.data?.success) {
                        console?.log(response?.data)
                        window.location.replace(response?.data?.data?.data?.link);

                    }
                } else {
                    setLoader(false)
                    toast.warn('Something went wrong');
                }
            }
        ).catch(function (error) {
            setLoader(false)
            // handle error
        }).finally(() => {
            setLoader(false)
            // toast.error("No Internet Connection");

        });
    }


    const handlePaymentWithWallet = (e: any) => {
        setLoader(true)
        const formData = new FormData()
        formData.append('amount', nairaValue)
        formData.append('payment_id', userDatas?.id)
        formData.append('customer_id', userLoginData?.email)
        DashboardApi.makePaymentFromWallet(formData).then(
            (response: any) => {
                if (response?.data) {
                    if (response?.data?.success) {
                        console?.log(response?.data?.data)
                        if (response?.data?.data == 'Insufficient Wallet Balance') {
                            toast.error('Insufficient Wallet Balance');
                            setLoader(false)
                        } else {
                            toast.success(response?.data?.data);
                            window.location.reload();
                        }
                        // window.location.replace(response?.data?.data?.data?.link);
                    }
                } else {
                    setLoader(false)
                    toast.warn('Something went wrong');
                }
            }
        ).catch(function (error) {
            setLoader(false)
            // handle error
        }).finally(() => {
            // toast.error("No Internet Connection");

        });
    }



    const getDetails = (id: any) => {
        DashboardApi.getPaymentDetail(id).then(
            (response: any) => {
                if (response?.data) {
                    if (response?.data?.success === true) {
                        setUserDatas(response?.data?.data)
                        setScreen(6)
                    }
                } else {
                    // toast.warn('Invalid Login Credentials');
                }
            }
        ).catch(function (error) {
            // handle error
        }).finally(() => {
            // toast.error("No Internet Connection");

        });
    }


    return (
        <>
            <Sidebar />
            <div className="relative md:ml-64 bg-blueGray-100">
                <UserNavbar />
                <div className='lg:mt-5 lg:px-10 px-6 bg-[#Ffffff] min-h-screen'>
                    <div className='pt-10 flex space-x-3 cursor-pointer'>
                        <div className=' place-content-center mt-8' onClick={() => setScreen(screen == 1 ? screen : screen -= 1)}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="none" stroke="#6e7093" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M20 12H4m0 0l6-6m-6 6l6 6" /></svg>
                        </div>
                        <div className='flex justify-start'>
                            <div>
                                <h1 className='font-bold md:text-[32px] text-[25px]'>Payments</h1>
                                <hr className='w-[266px] h-1 bg-[#00B07B]' />
                            </div>

                            <div>
                                <Oval
                                    visible={loader}
                                    height="60"
                                    width="60"
                                    color="#4fa94d"
                                    secondaryColor="#E6F1FC"
                                    ariaLabel="oval-loading"
                                    wrapperStyle={{}}
                                    wrapperClass=""
                                />

                                {/* <MutatingDots
                                    height="100"
                                    width="100"
                                    color="#4fa94d"
                                    secondaryColor='#4fa94d'
                                    radius='10.5'
                                    ariaLabel="mutating-dots-loading"
                                    wrapperStyle={{}}
                                    wrapperClass=""
                                    visible={loader}
                                /> */}
                            </div>
                        </div>

                    </div>
                    <form onSubmit={makePayments}>
                        {/*-- Payment List --*/}
                        <div className={screen === 1 ? "block " : "hidden"}>

                            <div className='flex mt-[22px]'>
                                <div className=' md:basis-7/12 basis-full md:px-0'>
                                    <div className='mt-[30px] flex justify-end'>
                                        <button type='button'
                                            onClick={() => setScreen(2)}
                                            className='bg-[#00B07B] text-white rounded-[5px] text-[12px] py-[17px] px-[31px]'>Make New Payment</button>
                                    </div>

                                    <h3 className='text-[#6E7093] text-[12px] mb-[83px] font-normal'>All the payments you have made are here </h3>
                                    <div>
                                        <h3 className='md:text-[16px] text-[14px] font-semibold'> Payment(s)</h3>
                                    </div>


                                    {transactionList?.data?.length > 0 ?
                                        transactionList?.data?.filter(((item: any, idx: any) => item?.payment_status == 'pending'))?.map(
                                            (datas: any, index: any) => (
                                                <div onClick={() => getDetails(datas?.id)} className='border-[0.5px] border-[#C4C4C4] bg-white rounded-[10px] mt-2 py-6 px-5'>
                                                    <div className='flex flex-col md:flex-row md:justify-between'>
                                                        {/* <h3 className='mt-1 font-medium'> {(datas?.sch_currency) + ' ' + datas?.school_amount}</h3> */}
                                                        <h3 className='mt-1 font-medium'> {"USD" + ' ' + datas?.school_amount}</h3>
                                                        <div>
                                                            <h3 className='mt-2 text-[14px] text-[#979797]'>{datas?.institution}</h3>
                                                            <h3 className='text-[14px] text-[#979797]'> {(datas?.created_at).slice(0, 10)} </h3>
                                                        </div>
                                                        <div className='mt-3'>
                                                            <button type="button" className='bg-[#979797] text-white px-5 md:px-8 py-2 rounded-[30px] text-[12px]'> {datas?.payment_status}</button>
                                                        </div>
                                                    </div>

                                                </div>
                                            )
                                        ) :
                                        <div className='border-[0.5px] border-[#C4C4C4] bg-white rounded-[10px] mt-2 py-6 px-5'>
                                            <div className='flex flex-col md:flex-row md:justify-center'>
                                                <h3 className='mt-1 text-[#6E7093] font-normal'>No Payment yet</h3>
                                            </div>

                                        </div>
                                    }







                                </div>
                            </div>
                        </div>


                        {/*-- New Payment --*/} {/*-- School Information --*/}
                        <div className={screen === 2 ? "block " : "hidden"}>
                            {/* <HeaderTitle title="Enter School Information " /> */}
                            <div className='mt-[22px]'>
                                <h3 className='text-[#6E7093] text-[12px] mb-[83px] font-normal'>Enter your schools’s details to begin your payment process </h3>
                            </div>

                            <div className='mt-[57px] flex'>
                                <div className="w-full lg:w-6/12 ">

                                    <div className="relative flex flex-col min-w-0 break-words w-full  ">
                                        <div className="flex-auto  py-10 pt-0">
                                            <div className="flex flex-wrap">
                                                <div className="relative w-full mb-6">
                                                {/* <span className='text-[#EE1D52] font-bold'> *</span> */}
                                                    <label htmlFor="country" className="text-[#000] text-sm font-medium mb-2 "> Country  </label>
                                                    <select id="country" name="country"
                                                        onChange={handleChange}
                                                        className=" bg-white border border-[#DADADA] text-[#000] text-sm rounded-[5px] block w-full p-3 placeholder-[#000]  ease-linear transition-all duration-150">
                                                        <option selected>{userDatas?.country !=='' ? userDatas?.country:'Choose a Country'}  </option>
                                                        <option value="finland">FinLand</option>
                                                        <option value="nigeria">Nigeria</option>
                                                        {/* <option value="other">I prefer not to say</option> */}
                                                    </select>
                                                </div>
                                                <div className="relative w-full mb-6">
                                                    <label
                                                        className="block text-[#000] text-sm font-medium mb-2"
                                                        htmlFor="institution"
                                                    >
                                                        Institution Name
                                                    </label>
                                                    {/* <input
                                                        type="text"
                                                        onChange={handleChange}
                                                        name="institution"
                                                        className="bg-white border border-[#DADADA] text-[#000] text-sm rounded-[5px] block w-full p-3 placeholder-[#000]  ease-linear transition-all duration-150"
                                                        placeholder=""

                                                    /> */}
                                                    <select id="country" name="institution"
                                                        onChange={handleChange}
                                                        className=" bg-white border border-[#DADADA] text-[#000] text-sm rounded-[5px] block w-full p-3 placeholder-[#000]  ease-linear transition-all duration-150">
                                                        <option selected>{userDatas?.institution !=='' ? userDatas?.institution:'Search Institution'}</option>

                                                        {
                                                            schoolList?.map(
                                                                (datas: any, index: any) => (
                                                                    <option value={datas?.school_name}>{datas?.school_name}</option>
                                                                )
                                                            )
                                                        }

                                                    </select>
                                                    <div
                                                        onClick={() => onOpenAddInstitutionModal()}
                                                        className='flex justify-end mt-2 text-[11px] font-medium cursor-pointer'><span className='h-[15px] w-[15px] bg-[#00B07B] text-center text-white rounded-full'>+ </span>&nbsp;Add institution</div>
                                                </div>


                                                <div className="relative w-full mb-6">
                                                    <label
                                                        className="block text-[#000] text-sm font-medium mb-2"
                                                        htmlFor="institution_address"
                                                    >
                                                        Institution Address
                                                    </label>
                                                    <input
                                                        type="text"
                                                        onChange={handleChange}
                                                        name="institution_address"
                                                        disabled
                                                        defaultValue={userDatas?.institution_address}
                                                        className="bg-white border border-[#DADADA] text-[#000] text-sm rounded-[5px] block w-full p-3 placeholder-[#000]  ease-linear transition-all duration-150"
                                                        placeholder=""

                                                    />
                                                </div>





                                                {/* <div className='w-full'>
                                                <button
                                                    type='submit'

                                                    className={
                                                        "text-white " +
                                                        (userData?.institution_address === ""
                                                            ? "bg-[#00B07B] opacity-50"
                                                            : "bg-[#00B07B] ") +
                                                        " mt-3 font-medium rounded-[5px] text-[12px] w-full py-2.5 text-center "
                                                    }
                                                    disabled={userData?.institution_address === "" ? true : false}
                                              
                                                >Continue</button>
                                            </div> */}
                                                <div className="w-full">

                                                    <button
                                                        type='button'
                                                        onClick={() => setScreen(3)}
                                                        className=

                                                        "bg-[#00B07B] text-white mt-3 font-medium rounded-[5px] text-[12px] w-full py-2.5 text-center "


                                                    >Continue</button>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="w-full lg:w-2/12 hidden md:block"></div>
                                <div className="w-full lg:w-4/12 hidden md:block">
                                    <div className='0 border border-[#979797] pr-5 pl-14 pt-5 pb-10 rounded-[15px]'>

                                        <ol className="relative border-l-2 border-[#D9D9D9]">
                                            <li className="mb-5 ml-6">
                                                <div className="absolute  bg-[#00B07B] text-white text-[10px] rounded-[30px]  -left-[31px] py-1 px-5 ">Step 1 </div>
                                                <p className="mt-2 mb-4   ml-8 text-[12px]  text-[#6E7093] ">Enter your schools details to begin your payment Process</p>
                                            </li>
                                            <li className="mb-5 ml-6">
                                                <div className="absolute  bg-[#979797] text-white text-[10px] rounded-[30px]  -left-[31px] py-1 px-5 ">Step 2</div>
                                                <p className="mt-2 mb-4   ml-8 text-[12px]  text-[#6E7093] ">Confirm the amount you are sending before proceeding</p>
                                            </li>
                                            <li className="mb-5 ml-6">
                                                <div className="absolute  bg-[#979797] text-white text-[10px] rounded-[30px]  -left-[31px] py-1 px-5 ">Step 3</div>
                                                <p className="mt-2 mb-4   ml-8 text-[12px]  text-[#6E7093]">Enter Student details to be sent to the <br />school </p>
                                            </li>
                                            <li className="mb-5 ml-6">
                                                <div className="absolute  bg-[#979797] text-white text-[10px] rounded-[30px]  -left-[31px] py-1 px-5 ">Step 4</div>
                                                <p className="mt-2 mb-4   ml-8 text-[12px]  text-[#6E7093]">Verify all the information you have provided before proceeding </p>
                                            </li>
                                            <li className="mb-5 ml-6">
                                                <div className="absolute  bg-[#979797] text-white text-[10px] rounded-[30px]  -left-[31px] py-1 px-5 ">Step 5</div>
                                                <p className="mt-2 mb-4   ml-8 text-[12px]  text-[#6E7093] ">Make Payment </p>
                                            </li>


                                        </ol>
                                    </div>
                                </div>
                            </div>
                        </div>


                        {/*-- New Payment --*/} {/*-- Payment Information --*/}
                        <div className={screen === 3 ? "block " : "hidden"}>
                            <div>
                                <h2 className=' font-[600] text-[20px] mt-10'>Payment Information</h2>
                            </div>
                            <div className='mt-[22px]'>
                                <h3 className='text-[#6E7093] text-[12px] mb-[20px] font-normal'>Confirm the amount you are sending before proceeding </h3>
                            </div>

                            <div className='mt-[57px] flex'>
                                <div className="w-full lg:w-6/12 ">

                                    <div className="relative flex flex-col min-w-0 break-words w-full  ">
                                        <div className="flex-auto  py-10 pt-0">
                                            <div className="flex flex-wrap">
                                                <div className="relative w-full mb-6">
                                                    <label htmlFor="payment_by" className="text-[#000] text-sm font-medium mb-2 ">Payment by</label>
                                                    <select id="payment_by" name="payment_by"
                                                        onChange={handleChange}
                                                        className=" bg-white border border-[#DADADA] text-[#000] text-sm rounded-[5px] block w-full p-3 placeholder-[#000]  ease-linear transition-all duration-150">
                                                        <option selected>{userDatas?.payment_by !=='' ? userDatas?.payment_by:'Choose an option'}</option>
                                                        <option value="student">Student</option>
                                                        <option value="parent">Parent</option>
                                                        <option value="guardian">Guardian</option>
                                                    </select>
                                                </div>
                                                <div className="relative w-full mb-6">
                                                    <label htmlFor="payment_for" className="text-[#000] text-sm font-medium mb-2 ">Payment for</label>
                                                    <select id="payment_for" name="payment_for"
                                                        onChange={handleChange}
                                                        className=" bg-white border border-[#DADADA] text-[#000] text-sm rounded-[5px] block w-full p-3 placeholder-[#000]  ease-linear transition-all duration-150">
                                                        <option selected> {userDatas?.payment_for !=='' ? userDatas?.payment_for:'Choose a Option'}</option>
                                                        <option value="Tuition">Tuition</option>
                                                        {/* <option value="other">I prefer not to say</option> */}
                                                    </select>
                                                </div>
                                                <div className="relative w-full mb-6">
                                                    <label htmlFor="country_from" className="text-[#000] text-sm font-medium mb-2 ">Country you’re paying from</label>
                                                    <select id="country_from" name="country_from"
                                                        onChange={handleChange}
                                                        className=" bg-white border border-[#DADADA] text-[#000] text-sm rounded-[5px] block w-full p-3 placeholder-[#000]  ease-linear transition-all duration-150">
                                                        <option selected>{userDatas?.country_from !=='' ? userDatas?.country_from:'Choose a Country'}</option>
                                                        <option value="Nigeria">Nigeria(NGN)</option>
                                                        <option value="Finland">Finland</option>
                                                    </select>
                                                </div>

                                                <div className="relative w-full mb-6">
                                                    <label htmlFor="program_type" className="text-[#000] text-sm font-medium mb-2 ">Program Type</label>
                                                    <select id="program_type" name="program_type"
                                                        onChange={handleChange}
                                                        className=" bg-white border border-[#DADADA] text-[#000] text-sm rounded-[5px] block w-full p-3 placeholder-[#000]  ease-linear transition-all duration-150">
                                                        <option value="masters">Masters</option>
                                                        <option value="others">others</option>
                                                    </select>
                                                </div>


                                                <div className="relative w-full mb-6">
                                                    <label
                                                        className="block text-[#000] text-sm font-medium mb-2"
                                                        htmlFor="account_number"
                                                    >
                                                        Account Number
                                                    </label>
                                                    <input
                                                        type="number"
                                                        onChange={handleChange}
                                                        name="account_number"
                                                        className="bg-white border border-[#DADADA] text-[#000] text-sm rounded-[5px] block w-full p-3 placeholder-[#000]  ease-linear transition-all duration-150"
                                                        placeholder=""
                                                        defaultValue={userDatas?.account_number}

                                                    />
                                                </div>
                                                <div className="relative w-full mb-6">
                                                    <label
                                                        className="block text-[#000] text-sm font-medium mb-2"
                                                        htmlFor="sort_code"
                                                    >
                                                        Sort Code
                                                    </label>
                                                    <input
                                                        type="text"
                                                        onChange={handleChange}
                                                        name="sort_code"
                                                        defaultValue={userDatas?.sort_code}
                                                        className="bg-white border border-[#DADADA] text-[#000] text-sm rounded-[5px] block w-full p-3 placeholder-[#000]  ease-linear transition-all duration-150"
                                                        placeholder=""

                                                    />
                                                </div>
                                                <div className="relative w-full mb-6">
                                                    <label
                                                        className="block text-[#000] text-sm font-medium mb-2"
                                                        htmlFor="swift_code"
                                                    >
                                                        Swift Code
                                                    </label>
                                                    <input
                                                        type="text"
                                                        onChange={handleChange}
                                                        name="swift_code"
                                                        defaultValue={userDatas?.swift_code}
                                                        className="bg-white border border-[#DADADA] text-[#000] text-sm rounded-[5px] block w-full p-3 placeholder-[#000]  ease-linear transition-all duration-150"
                                                        placeholder=""

                                                    />
                                                </div>
                                                <div className="relative w-full mb-6">
                                                    <label htmlFor="sch_currency" className="text-[#000] text-sm font-medium mb-2 ">Your school will receive</label>
                                                    <div className="flex">
                                                        <select id="sch_currency" name="sch_currency"
                                                            onChange={handleChange}
                                                            className=" bg-white border border-[#DADADA] text-[#000] text-sm rounded-[5px] block w-[20%] p-3 placeholder-[#000]  ease-linear transition-all duration-150">
                                                            <option selected>USD</option>
                                                            {/* <option value="eur">EUR</option> */}
                                                            <option value="usd" >USD</option>
                                                        </select>
                                                        <input
                                                            type="number"
                                                            onChange={handleChange}
                                                            name="school_amount"
                                                            defaultValue={userDatas?.school_amount}
                                                            className="bg-white border border-[#DADADA] text-[#000] text-sm rounded-[5px] block w-[80%] p-3 placeholder-[#000]  ease-linear transition-all duration-150"
                                                            placeholder=""

                                                        />
                                                    </div>
                                                </div>
                                                {/* */}
                                                <div className={"w-full " + (userDatas?.school_amount === "" ? "hidden" : "block")}>
                                                    <div className="relative w-full mb-6">
                                                        <label htmlFor="sch_currency" className="text-[#000] text-sm font-medium mb-2 ">Your Total Payment (Plus Fees )</label>
                                                        <div className="flex">
                                                            <div
                                                                className=" bg-white border border-[#DADADA] text-[#000] text-sm rounded-[5px] block w-[20%] p-3 placeholder-[#000]  ease-linear transition-all duration-150">
                                                               USD
                                                            </div>
                                                            <input
                                                                type="number"
                                                                onChange={handleChange}
                                                                name="school_amount_ngn"
                                                                disabled
                                                                defaultValue={(nairaValue)}
                                                                className="bg-white border border-[#DADADA] text-[#000] text-sm rounded-[5px] block w-[80%] p-3 placeholder-[#000]  ease-linear transition-all duration-150"
                                                                placeholder=""

                                                            />
                                                        </div>
                                                        {/* <span className="text-[10px] text-[#979797]">Total payment in EUR = €11,000.00</span> */}
                                                    </div>
                                                    <div className="w-full mb-6">
                                                        <div className="border border-[#DADADA] py-6 px-4 rounded-[15px] bg-[#E7F2EC]">
                                                            <div className="flex justify-between mb-3">
                                                                <h3 className="text-[#00B07B] text-[12px]">Delivery Time</h3>
                                                                <h3 className="text-[#00B07B] text-[12px]">Approx. 48 Hours </h3>
                                                            </div>
                                                            <div className="flex justify-between">
                                                                <h3 className="text-[#00B07B] text-[12px]">Service Charge</h3>
                                                                <h3 className="text-[#00B07B] text-[12px]"> {(userDatas?.program_type == 'masters' || !userDatas?.program_type )?'$100':'$200'}</h3>
                                                            </div>
                                                            {/* <div className="flex justify-between">
                                                                <h3 className="text-[#00B07B] text-[12px]">Exchange Rate</h3>
                                                                <h3 className="text-[#00B07B] text-[12px]">{userDatas?.sch_currency == 'eur' ? "€" : "$"} 1 = NGN {userDatas?.sch_currency == 'eur' ? "1800" : "1500"} </h3>
                                                            </div> */}
                                                        </div>
                                                    </div>
                                                </div>


                                                <div className=" mb-6">
                                                    <label htmlFor="sch_currency" className="text-[#000] text-sm font-medium mb-2 ">Upload an invoice or payment instruction that states  schools, account information</label>
                                                    <div className='flex flex-col border border-[#d9d9d9] w-full rounded-[4px] p-3'>
                                                        <div>
                                                            <label >
                                                                <div className='flex space-x-2 justify-center cursor-pointer'>

                                                                    {selectedPics !== 'No selected file' ? selectedPics?.name :
                                                                        <span className='flex justify-center'>
                                                                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24"><path fill="currentColor" d="M11 16V7.85l-2.6 2.6L7 9l5-5l5 5l-1.4 1.45l-2.6-2.6V16h-2Zm-5 4q-.825 0-1.413-.588T4 18v-3h2v3h12v-3h2v3q0 .825-.588 1.413T18 20H6Z" /></svg>
                                                                            <p className='text-[14px]'> Add file</p>
                                                                        </span>
                                                                    }

                                                                </div>
                                                                <input type="file" name="payment_instruction" onChange={changeHandler} accept=".jpeg,.png,.jpg,.gif,.svg" className="hidden" />
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>


                                                <div className='w-full flex space-x-5'>
                                                    <button
                                                        type='button'
                                                        onClick={() => setScreen(screen == 1 ? screen : screen -= 1)}
                                                        className=" bg-[#979797] text-white mt-3 font-medium rounded-[5px] text-[12px] w-full py-2.5 text-center "
                                                    >Back</button>
                                                    {/* <button
                                                    type='submit'

                                                    className={
                                                        "text-white " +
                                                        (userData?.sch_currency === ""
                                                            ? "bg-[#00B07B] opacity-50"
                                                            : "bg-[#00B07B] ") +
                                                        " mt-3 font-medium rounded-[5px] text-[12px] w-full py-2.5 text-center "
                                                    }
                                                    disabled={userData?.sch_currency === "" ? true : false}
                                                >Continue</button> */}
                                                    <div className="w-full">
                                                        <button
                                                            type='button'
                                                            onClick={() => setScreen(4)}
                                                            className=

                                                            "bg-[#00B07B] text-white mt-3 font-medium rounded-[5px] text-[12px] w-full py-2.5 text-center "


                                                        >Continue</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="w-full lg:w-2/12 hidden md:block"></div>
                                <div className="w-full lg:w-4/12 hidden md:block">
                                    <div className='0 border border-[#979797] pr-5 pl-14 pt-5 pb-10 rounded-[15px]'>

                                        <ol className="relative border-l-2 border-[#D9D9D9]">
                                            <li className="mb-5 ml-6">
                                                <div className="absolute  bg-[#00B07B] text-white text-[10px] rounded-[30px]  -left-[31px] py-1 px-5 ">Step 1 </div>
                                                <p className="mt-2 mb-4   ml-8 text-[12px]  text-[#6E7093] ">Enter your schools details to begin your payment Process</p>
                                            </li>
                                            <li className="mb-5 ml-6">
                                                <div className="absolute  bg-[#00B07B] text-white text-[10px] rounded-[30px]  -left-[31px] py-1 px-5 ">Step 2</div>
                                                <p className="mt-2 mb-4   ml-8 text-[12px]  text-[#6E7093] ">Confirm the amount you are sending before proceeding</p>
                                            </li>
                                            <li className="mb-5 ml-6">
                                                <div className="absolute  bg-[#979797] text-white text-[10px] rounded-[30px]  -left-[31px] py-1 px-5 ">Step 3</div>
                                                <p className="mt-2 mb-4   ml-8 text-[12px]  text-[#6E7093]">Enter Student details to be sent to the <br />school </p>
                                            </li>
                                            <li className="mb-5 ml-6">
                                                <div className="absolute  bg-[#979797] text-white text-[10px] rounded-[30px]  -left-[31px] py-1 px-5 ">Step 4</div>
                                                <p className="mt-2 mb-4   ml-8 text-[12px]  text-[#6E7093]">Verify all the information you have provided before proceeding </p>
                                            </li>
                                            <li className="mb-5 ml-6">
                                                <div className="absolute  bg-[#979797] text-white text-[10px] rounded-[30px]  -left-[31px] py-1 px-5 ">Step 5</div>
                                                <p className="mt-2 mb-4   ml-8 text-[12px]  text-[#6E7093] ">Make Payment </p>
                                            </li>


                                        </ol>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/*-- New Payment --*/} {/*-- Student Information --*/}
                        <div className={screen === 4 ? "block " : "hidden"}>
                            <div>
                                <h2 className=' font-[600] text-[20px] mt-10'>Enter Student Information</h2>
                            </div>

                            <div className='mt-[22px]'>
                                <h3 className='text-[#6E7093] text-[12px] mb-[20px] font-normal'>Enter the student details to be sent to the school  </h3>
                            </div>

                            <div className='mt-[57px] flex'>
                                <div className="w-full lg:w-6/12 ">
                                    <div className="relative flex flex-col min-w-0 break-words w-full  ">
                                        <div className="flex-auto  py-10 pt-0">
                                            <div className="flex flex-wrap">

                                                <div className="relative w-full mb-6">
                                                    <label
                                                        className="block text-[#000] text-sm font-medium mb-2"
                                                        htmlFor="account_number"
                                                    >
                                                        Student First Name
                                                    </label>
                                                    <input
                                                        type="text"
                                                        onChange={handleChange}
                                                        name="student_first_name"
                                                        className="bg-white border border-[#DADADA] text-[#000] text-sm rounded-[5px] block w-full p-3 placeholder-[#000]  ease-linear transition-all duration-150"
                                                        placeholder=""
                                                        defaultValue={userDatas?.student_first_name}
                                                    />
                                                </div>
                                                <div className="relative w-full mb-6">
                                                    <label
                                                        className="block text-[#000] text-sm font-medium mb-2"
                                                        htmlFor="sort_code"
                                                    >
                                                        Student Last Name
                                                    </label>
                                                    <input
                                                        type="text"
                                                        onChange={handleChange}
                                                        name="student_last_name"
                                                        defaultValue={userDatas?.student_last_name}
                                                        className="bg-white border border-[#DADADA] text-[#000] text-sm rounded-[5px] block w-full p-3 placeholder-[#000]  ease-linear transition-all duration-150"
                                                        placeholder=""
                                                    />
                                                </div>
                                                <div className="relative w-full mb-6">
                                                    <label
                                                        className="block text-[#000] text-sm font-medium mb-2"
                                                        htmlFor="swift_code"
                                                    >
                                                        Student Email Address
                                                    </label>
                                                    <input
                                                        type="email"
                                                        required
                                                        onChange={handleChange}
                                                        name="student_email"
                                                        defaultValue={userDatas?.student_email}
                                                        className="bg-white border border-[#DADADA] text-[#000] text-sm rounded-[5px] block w-full p-3 placeholder-[#000]  ease-linear transition-all duration-150"
                                                        placeholder=""
                                                    />
                                                </div>

                                                <div className="w-full mb-6">
                                                    <label htmlFor="sch_currency" className="text-[#000] text-sm font-medium mb-2 ">Upload a means of identification (Student ID or Admission Letter)</label>
                                                    <div className='flex flex-col border border-[#DADADA] w-full rounded-[5px] p-3'>
                                                        <div>
                                                            <label className=' cursor-pointer'>
                                                                <div className='flex space-x-2 justify-center '>
                                                                    {selectedPics2 !== 'No selected file' ? selectedPics2?.name :
                                                                        <span className='flex justify-center'>
                                                                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24"><path fill="currentColor" d="M11 16V7.85l-2.6 2.6L7 9l5-5l5 5l-1.4 1.45l-2.6-2.6V16h-2Zm-5 4q-.825 0-1.413-.588T4 18v-3h2v3h12v-3h2v3q0 .825-.588 1.413T18 20H6Z" /></svg>
                                                                            <p className='text-[14px]'> Add file</p>
                                                                        </span>
                                                                    }
                                                                </div>

                                                                <input type="file" name="means_of_identification" onChange={(e: any) => changeHandler2(e)} className="hidden" />
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="relative w-full mb-6">

                                                    <label htmlFor="message" className="text-[#000] text-sm font-medium mb-2 ">Additional Information</label>
                                                    <textarea id="message" rows={4} className="block p-2.5 w-full text-sm text-gray-900 rounded-[5px] border border-[#DADADA]" placeholder="Write here..." name="additional_info" defaultValue={userDatas?.additional_info} onChange={handleChange}></textarea>

                                                </div>
                                                <div className='w-full flex space-x-5'>
                                                    <button
                                                        type='button'
                                                        onClick={() => setScreen(screen == 1 ? screen : screen -= 1)}
                                                        className=" bg-[#979797] text-white mt-3 font-medium rounded-[5px] text-[12px] w-full py-2.5 text-center "
                                                    >Back</button>

                                                    <div className="w-full">
                                                        <button
                                                            type='button'
                                                            onClick={() => setScreen(5)}
                                                            className=

                                                            "bg-[#00B07B] text-white mt-3 font-medium rounded-[5px] text-[12px] w-full py-2.5 text-center "


                                                        >Continue</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="w-full lg:w-2/12 hidden md:block"></div>
                                <div className="w-full lg:w-4/12 hidden md:block">
                                    <div className='0 border border-[#979797] pr-5 pl-14 pt-5 pb-10 rounded-[15px]'>

                                        <ol className="relative border-l-2 border-[#D9D9D9]">
                                            <li className="mb-5 ml-6">
                                                <div className="absolute  bg-[#00B07B] text-white text-[10px] rounded-[30px]  -left-[31px] py-1 px-5 ">Step 1 </div>
                                                <p className="mt-2 mb-4   ml-8 text-[12px]  text-[#6E7093] ">Enter your schools details to begin your payment Process</p>
                                            </li>
                                            <li className="mb-5 ml-6">
                                                <div className="absolute  bg-[#00B07B] text-white text-[10px] rounded-[30px]  -left-[31px] py-1 px-5 ">Step 2</div>
                                                <p className="mt-2 mb-4   ml-8 text-[12px]  text-[#6E7093] ">Confirm the amount you are sending before proceeding</p>
                                            </li>
                                            <li className="mb-5 ml-6">
                                                <div className="absolute  bg-[#00B07B] text-white text-[10px] rounded-[30px]  -left-[31px] py-1 px-5 ">Step 3</div>
                                                <p className="mt-2 mb-4   ml-8 text-[12px]  text-[#6E7093]">Enter Student details to be sent to the <br />school </p>
                                            </li>
                                            <li className="mb-5 ml-6">
                                                <div className="absolute  bg-[#979797] text-white text-[10px] rounded-[30px]  -left-[31px] py-1 px-5 ">Step 4</div>
                                                <p className="mt-2 mb-4   ml-8 text-[12px]  text-[#6E7093]">Verify all the information you have provided before proceeding </p>
                                            </li>
                                            <li className="mb-5 ml-6">
                                                <div className="absolute  bg-[#979797] text-white text-[10px] rounded-[30px]  -left-[31px] py-1 px-5 ">Step 5</div>
                                                <p className="mt-2 mb-4   ml-8 text-[12px]  text-[#6E7093] ">Make Payment </p>
                                            </li>


                                        </ol>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/*-- New Payment --*/} {/*-- Verify Information --*/}
                        <div className={screen === 5 ? "block pb-10" : "hidden"}>
                            <div>
                                <h2 className=' font-[600] text-[20px] mt-10'>Verify Information</h2>
                            </div>
                            {/* <HeaderTitle title=" " /> */}
                            <div className='mt-[22px]'>
                                <h3 className='text-[#6E7093] text-[12px] mb-[83px] font-normal'>Enter the student details to be sent to the school  </h3>
                            </div>

                            <div className=' flex'>
                                <div className="w-full lg:w-6/12 ">
                                    <div>
                                        <div className="flex justify-between">
                                            <h2 className=" font-medium md:text-[20px] text-[16px]">School  Information</h2>
                                            {/* <div>Edit</div> */}
                                        </div>
                                        <hr className="my-1" />
                                        <div className='flex justify-between pt-2 mt-1 mb-2 px-2 pb-1'>
                                            <h3 className='text-[12px] font-medium'>School Name : </h3>
                                            <h3 className='text-[12px]'>{userDatas?.institution} </h3>
                                        </div>
                                        <div className='flex justify-between pt-2 mt-1 mb-2 px-2 pb-1'>
                                            <h3 className='text-[12px] font-medium'>School Address : </h3>
                                            <h3 className='text-[12px]'>{userDatas?.institution_address} </h3>
                                        </div>
                                        <div className='flex justify-between pt-2 mt-1 mb-2 px-2 pb-1'>
                                            <h3 className='text-[12px] font-medium'>Country: </h3>
                                            <h3 className='text-[12px]'>{userDatas?.country} </h3>
                                        </div>

                                    </div>
                                    <div className="mt-10">
                                        <div className="flex justify-between">
                                            <h2 className=" font-medium md:text-[20px] text-[16px]">Payment Information</h2>
                                            {/* <div>Edit</div> */}
                                        </div>
                                        <hr className="my-1" />
                                        <div className='flex justify-between pt-2 mt-1 mb-2 px-2 pb-1'>
                                            <h3 className='text-[12px] font-medium'>Payment for : </h3>
                                            <h3 className='text-[12px]'>{userDatas?.payment_for} </h3>
                                        </div>
                                        <div className='flex justify-between pt-2 mt-1 mb-2 px-2 pb-1'>
                                            <h3 className='text-[12px] font-medium'>Country you are paying from : </h3>
                                            <h3 className='text-[12px]'> </h3>
                                        </div>
                                        <div className='flex justify-between pt-2 mt-1 mb-2 px-2 pb-1'>
                                            <h3 className='text-[12px] font-medium'>Country: </h3>
                                            <h3 className='text-[12px]'>{userDatas?.country_from}</h3>
                                        </div>

                                    </div>
                                    <div className="mt-10">
                                        <div className="flex justify-between">
                                            <h2 className=" font-medium md:text-[20px] text-[16px]">Student Information</h2>
                                            {/* <div>Edit</div> */}
                                        </div>
                                        <hr className="my-1" />
                                        <div className='flex justify-between pt-2 mt-1 mb-2 px-2 pb-1'>
                                            <h3 className='text-[12px] font-medium'>Full Name : </h3>
                                            <h3 className='text-[12px]'>{userDatas?.student_first_name + " " + userDatas?.student_last_name}</h3>
                                        </div>
                                        <div className='flex justify-between pt-2 mt-1 mb-2 px-2 pb-1'>
                                            <h3 className='text-[12px] font-medium'>Student Email Address : </h3>
                                            <h3 className='text-[12px]'>{userDatas?.student_email}</h3>
                                        </div>
                                        <div className='flex justify-between pt-2 mt-1 mb-2 px-2 pb-1'>
                                            <h3 className='text-[12px] font-medium'>Extra Info : </h3>
                                            <h3 className='text-[12px]'>{userDatas?.additional_info}</h3>
                                        </div>

                                    </div>

                                    <div className='w-full flex space-x-5'>
                                        <div className="w-full">
                                            <button
                                                type={userDatas?.id && userDatas?.id !=='' ? 'button':'submit'} 
                                                onClick={()=> (userDatas?.id && userDatas?.id !=='' ?setScreen(6):'')} 
                                                className=
                                                "bg-[#00B07B] text-white  mt-3 font-medium rounded-[5px] text-[12px] w-full py-2.5 text-center "
                                            >Proceed to Payment
                                           
                                             </button>
                                        </div>

                                    </div>
                                </div>
                                <div className="w-full lg:w-2/12 hidden md:block"></div>
                                <div className="w-full lg:w-4/12 hidden md:block">
                                    <div className='0 border border-[#979797] pr-5 pl-14 pt-5 pb-10 rounded-[15px]'>

                                        <ol className="relative border-l-2 border-[#D9D9D9]">
                                            <li className="mb-5 ml-6">
                                                <div className="absolute  bg-[#00B07B] text-white text-[10px] rounded-[30px]  -left-[31px] py-1 px-5 ">Step 1 </div>
                                                <p className="mt-2 mb-4   ml-8 text-[12px]  text-[#6E7093] ">Enter your schools details to begin your payment Process</p>
                                            </li>
                                            <li className="mb-5 ml-6">
                                                <div className="absolute  bg-[#00B07B] text-white text-[10px] rounded-[30px]  -left-[31px] py-1 px-5 ">Step 2</div>
                                                <p className="mt-2 mb-4   ml-8 text-[12px]  text-[#6E7093] ">Confirm the amount you are sending before proceeding</p>
                                            </li>
                                            <li className="mb-5 ml-6">
                                                <div className="absolute  bg-[#00B07B] text-white text-[10px] rounded-[30px]  -left-[31px] py-1 px-5 ">Step 3</div>
                                                <p className="mt-2 mb-4   ml-8 text-[12px]  text-[#6E7093]">Enter Student details to be sent to the <br />school </p>
                                            </li>
                                            <li className="mb-5 ml-6">
                                                <div className="absolute  bg-[#00B07B] text-white text-[10px] rounded-[30px]  -left-[31px] py-1 px-5 ">Step 4</div>
                                                <p className="mt-2 mb-4   ml-8 text-[12px]  text-[#6E7093]">Verify all the information you have provided before proceeding </p>
                                            </li>
                                            <li className="mb-5 ml-6">
                                                <div className="absolute  bg-[#979797] text-white text-[10px] rounded-[30px]  -left-[31px] py-1 px-5 ">Step 5</div>
                                                <p className="mt-2 mb-4   ml-8 text-[12px]  text-[#6E7093] ">Make Payment </p>
                                            </li>


                                        </ol>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                    {/*-- New Payment --*/} {/*-- Make Payment --*/}
                    <div className={screen === 6 ? "block " : "hidden"}>
                        {/* <HeaderTitle title=" " /> */}
                        <div>
                            <h2 className=' font-[600] text-[20px] mt-10'>Make Payment</h2>
                        </div>
                        <div className='mt-[22px]'>
                            <h3 className='text-[#6E7093] text-[12px] mb-[20px] font-normal'>Confirm your transaction limits with your bank and choose a preffered payment method</h3>
                        </div>

                        <div className='mt-[57px] flex'>
                            <div className="w-full lg:w-6/12 ">

                                <button
                                    // onClick={() => onOpenModal()}
                                    className='w-full border-[0.5px] border-[#C4C4C4] bg-white rounded-[10px] mb-3 py-6 px-5'>
                                    <div className='flex flex-col md:flex-row space-x-3'>
                                        <div>
                                            <svg width="39" height="39" viewBox="0 0 39 39" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <circle cx="19.5" cy="19.5" r="19.5" fill="#00B050" fill-opacity="0.2" />
                                                <g clip-path="url(#clip0_80_203)">
                                                    <path d="M11.9444 16.5333L12.7778 16.0444V17.8889H27.2222V16.0611L28.0444 16.5333C28.1714 16.5998 28.3191 16.6145 28.4567 16.5746C28.5943 16.5347 28.7112 16.4431 28.7829 16.3191C28.8546 16.195 28.8756 16.0481 28.8415 15.9089C28.8074 15.7697 28.7209 15.6491 28.6 15.5722L20 10.6222L11.3889 15.5722C11.3225 15.607 11.2638 15.6548 11.2164 15.7128C11.1689 15.7707 11.1337 15.8377 11.1128 15.9097C11.0919 15.9817 11.0858 16.0571 11.0949 16.1315C11.1039 16.2059 11.1279 16.2776 11.1654 16.3425C11.2029 16.4074 11.2531 16.464 11.3131 16.509C11.373 16.5539 11.4414 16.5863 11.5142 16.6041C11.587 16.6219 11.6626 16.6248 11.7365 16.6127C11.8105 16.6005 11.8812 16.5735 11.9444 16.5333ZM19.9166 12.95C19.9842 12.911 20.0608 12.8905 20.1389 12.8905C20.2169 12.8905 20.2935 12.911 20.3611 12.95L24.0944 15.1111H22.3055L20.1389 13.8667L17.9722 15.1111H16.1889L19.9166 12.95Z" fill="#00B07B" />
                                                    <path d="M28.2502 24H27.778V23.5278C27.778 23.3583 27.7107 23.1958 27.5909 23.076C27.4711 22.9562 27.3085 22.8889 27.1391 22.8889H25.5558V18.7944H23.3336V22.8889H21.1113V18.7944H18.8891V22.8889H16.6669V18.7944H14.4447V22.8889H12.8613C12.6919 22.8889 12.5294 22.9562 12.4096 23.076C12.2898 23.1958 12.2224 23.3583 12.2224 23.5278V24H11.7502C11.5808 24 11.4183 24.0673 11.2985 24.1871C11.1786 24.3069 11.1113 24.4694 11.1113 24.6389V26.2222H28.8891V24.6389C28.8891 24.4694 28.8218 24.3069 28.702 24.1871C28.5822 24.0673 28.4197 24 28.2502 24Z" fill="#00B07B" />
                                                </g>
                                                <defs>
                                                    <clipPath id="clip0_80_203">
                                                        <rect width="20" height="20" fill="white" transform="translate(10 9)" />
                                                    </clipPath>
                                                </defs>
                                            </svg>
                                        </div>
                                        <div>
                                            <h3 className=' text-[14px] text-[#000] font-semibold text-left'>Bank Transfer (Coming Soon)</h3>
                                            <h3 className='text-[14px] text-[#979797]'>Send funds directly from your bank account </h3>
                                        </div>

                                    </div>

                                </button>
                                <div className='border-[0.5px] border-[#C4C4C4] bg-white rounded-[10px] mb-3 py-6 px-5'>
                                    <div className='flex flex-col md:flex-row space-x-3 cursor-pointer' onClick={(e) => (loader ? '':handleSubmit(e)) }>
                                        <div>
                                            <svg width="39" height="39" viewBox="0 0 39 39" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <circle cx="19.5" cy="19.5" r="19.5" fill="#00B050" fill-opacity="0.2" />
                                                <g clip-path="url(#clip0_80_273)">
                                                    <path d="M28.3332 17.3333V23.1667C28.3332 23.8297 28.0698 24.4656 27.6009 24.9344C27.1321 25.4033 26.4962 25.6667 25.8332 25.6667H14.1665C13.5035 25.6667 12.8676 25.4033 12.3987 24.9344C11.9299 24.4656 11.6665 23.8297 11.6665 23.1667V17.3333H28.3332ZM24.9998 20.6667H22.4998C22.2788 20.6667 22.0669 20.7545 21.9106 20.9107C21.7543 21.067 21.6665 21.279 21.6665 21.5C21.6665 21.721 21.7543 21.933 21.9106 22.0893C22.0669 22.2455 22.2788 22.3333 22.4998 22.3333H24.9998C25.2209 22.3333 25.4328 22.2455 25.5891 22.0893C25.7454 21.933 25.8332 21.721 25.8332 21.5C25.8332 21.279 25.7454 21.067 25.5891 20.9107C25.4328 20.7545 25.2209 20.6667 24.9998 20.6667ZM25.8332 12.3333C26.4962 12.3333 27.1321 12.5967 27.6009 13.0656C28.0698 13.5344 28.3332 14.1703 28.3332 14.8333V15.6667H11.6665V14.8333C11.6665 14.1703 11.9299 13.5344 12.3987 13.0656C12.8676 12.5967 13.5035 12.3333 14.1665 12.3333H25.8332Z" fill="#00B07B" />
                                                </g>
                                                <defs>
                                                    <clipPath id="clip0_80_273">
                                                        <rect width="20" height="20" fill="white" transform="translate(10 9)" />
                                                    </clipPath>
                                                </defs>
                                            </svg>

                                        </div>
                                        <div>
                                            <h3 className=' text-[14px] text-[#000] font-semibold'>Debit Card</h3>
                                            <h3 className='text-[14px] text-[#979797]'>Send funds securely to your school using your card details  </h3>
                                        </div>

                                    </div>

                                </div>
                                <div className='border-[0.5px] border-[#C4C4C4] bg-white rounded-[10px] mb-3 py-6 px-5'>
                                    <div className=' cursor-pointer flex flex-col md:flex-row space-x-3' onClick={(e: any) => (loader?'':handlePaymentWithWallet(e))}>
                                        <div>
                                            <svg width="39" height="39" viewBox="0 0 39 39" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <circle cx="19.5" cy="19.5" r="19.5" fill="#00B050" fill-opacity="0.2" />
                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M27.583 15.67C27.5372 15.6667 27.4863 15.6667 27.433 15.6667H25.3288C23.6055 15.6667 22.1313 17.0233 22.1313 18.7917C22.1313 20.56 23.6063 21.9167 25.3288 21.9167H27.433C27.4863 21.9167 27.5372 21.9167 27.5847 21.9133C27.9389 21.892 28.2732 21.7426 28.5254 21.493C28.7776 21.2434 28.9305 20.9106 28.9555 20.5567C28.9588 20.5067 28.9588 20.4525 28.9588 20.4025V17.1808C28.9588 17.1308 28.9588 17.0767 28.9555 17.0267C28.9305 16.6727 28.7776 16.3399 28.5254 16.0903C28.2732 15.8407 27.9389 15.6914 27.5847 15.67H27.583ZM25.143 19.625C25.5863 19.625 25.9455 19.2517 25.9455 18.7917C25.9455 18.3317 25.5863 17.9583 25.143 17.9583C24.6988 17.9583 24.3397 18.3317 24.3397 18.7917C24.3397 19.2517 24.6988 19.625 25.143 19.625Z" fill="#00B07B" />
                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M27.4315 23.1667C27.4603 23.1655 27.489 23.1712 27.5152 23.1832C27.5414 23.1952 27.5644 23.2132 27.5824 23.2358C27.6003 23.2583 27.6127 23.2848 27.6185 23.3131C27.6243 23.3413 27.6233 23.3705 27.6157 23.3983C27.449 23.9917 27.1832 24.4983 26.7573 24.9233C26.1332 25.5483 25.3423 25.8242 24.3657 25.9558C23.4157 26.0833 22.2032 26.0833 20.6715 26.0833H18.9115C17.3798 26.0833 16.1665 26.0833 15.2173 25.9558C14.2407 25.8242 13.4498 25.5475 12.8257 24.9242C12.2023 24.3 11.9257 23.5092 11.794 22.5325C11.6665 21.5825 11.6665 20.37 11.6665 18.8383V18.745C11.6665 17.2133 11.6665 16 11.794 15.05C11.9257 14.0733 12.2023 13.2825 12.8257 12.6583C13.4498 12.035 14.2407 11.7583 15.2173 11.6267C16.1673 11.5 17.3798 11.5 18.9115 11.5H20.6715C22.2032 11.5 23.4165 11.5 24.3657 11.6275C25.3423 11.7592 26.1332 12.0358 26.7573 12.6592C27.1832 13.0858 27.449 13.5917 27.6157 14.185C27.6233 14.2128 27.6243 14.242 27.6185 14.2703C27.6127 14.2985 27.6003 14.325 27.5824 14.3476C27.5644 14.3701 27.5414 14.3882 27.5152 14.4002C27.489 14.4122 27.4603 14.4178 27.4315 14.4167H25.3282C22.964 14.4167 20.8807 16.2833 20.8807 18.7917C20.8807 21.3 22.964 23.1667 25.3282 23.1667H27.4315ZM14.7915 14.8333C14.6257 14.8333 14.4668 14.8992 14.3496 15.0164C14.2324 15.1336 14.1665 15.2926 14.1665 15.4583C14.1665 15.6241 14.2324 15.7831 14.3496 15.9003C14.4668 16.0175 14.6257 16.0833 14.7915 16.0833H18.1248C18.2906 16.0833 18.4496 16.0175 18.5668 15.9003C18.684 15.7831 18.7498 15.6241 18.7498 15.4583C18.7498 15.2926 18.684 15.1336 18.5668 15.0164C18.4496 14.8992 18.2906 14.8333 18.1248 14.8333H14.7915Z" fill="#00B07B" />
                                            </svg>

                                        </div>
                                        <div>
                                            <h3 className=' text-[14px] text-[#000] font-semibold'>Afripay Wallet</h3>
                                            <h3 className='text-[14px] text-[#979797]'>Send funds directly from Afripay Wallet </h3>
                                        </div>

                                    </div>

                                </div>


                                <div className='w-full flex space-x-5 mt-5'>
                                    <button
                                        type='button'
                                        onClick={() => setScreen(screen == 1 ? screen : screen -= 1)}
                                        className=" bg-[#979797] text-white mt-3 font-medium rounded-[5px] text-[12px] w-full py-2.5 text-center "
                                    >Back</button>
                                    <button
                                        type='button'

                                        className="bg-[#00B07B] text-white mt-3 font-medium rounded-[5px] text-[12px] w-full py-2.5 text-center "


                                    >Continue</button>
                                </div>
                            </div>
                            <div className="w-full lg:w-2/12 hidden md:block"></div>
                            <div className="w-full lg:w-4/12 hidden md:block">
                                <div className='0 border border-[#979797] pr-5 pl-14 pt-5 pb-10 rounded-[15px]'>

                                    <ol className="relative border-l-2 border-[#D9D9D9]">
                                        <li className="mb-5 ml-6">
                                            <div className="absolute  bg-[#00B07B] text-white text-[10px] rounded-[30px]  -left-[31px] py-1 px-5 ">Step 1 </div>
                                            <p className="mt-2 mb-4   ml-8 text-[12px]  text-[#6E7093] ">Enter your schools details to begin your payment Process</p>
                                        </li>
                                        <li className="mb-5 ml-6">
                                            <div className="absolute  bg-[#00B07B] text-white text-[10px] rounded-[30px]  -left-[31px] py-1 px-5 ">Step 2</div>
                                            <p className="mt-2 mb-4   ml-8 text-[12px]  text-[#6E7093] ">Confirm the amount you are sending before proceeding</p>
                                        </li>
                                        <li className="mb-5 ml-6">
                                            <div className="absolute  bg-[#00B07B] text-white text-[10px] rounded-[30px]  -left-[31px] py-1 px-5 ">Step 3</div>
                                            <p className="mt-2 mb-4   ml-8 text-[12px]  text-[#6E7093]">Enter Student details to be sent to the <br />school </p>
                                        </li>
                                        <li className="mb-5 ml-6">
                                            <div className="absolute  bg-[#00B07B] text-white text-[10px] rounded-[30px]  -left-[31px] py-1 px-5 ">Step 4</div>
                                            <p className="mt-2 mb-4   ml-8 text-[12px]  text-[#6E7093]">Verify all the information you have provided before proceeding </p>
                                        </li>
                                        <li className="mb-5 ml-6">
                                            <div className="absolute  bg-[#00B07B] text-white text-[10px] rounded-[30px]  -left-[31px] py-1 px-5 ">Step 5</div>
                                            <p className="mt-2 mb-4   ml-8 text-[12px]  text-[#6E7093] ">Make Payment </p>
                                        </li>


                                    </ol>
                                </div>
                            </div>
                        </div>


                        <Modal open={open} onClose={onCloseModal} center>
                            <div className='md:max-w-md  body-font font-poppins'>
                                <div className="flex flex-col mt-4">
                                    <h3 className="text-[20px] font-semibold">Make Payment</h3>
                                    <p className="text-[12px] text-[#9DA4AA] mt-2 mb-4">Please make a payment to this account and wait for <br /> approval from us. </p>
                                    <hr className="bg-[#E2E8F0]" />
                                    <div className="mt-5">
                                        <img src="/images/Dashbord/logo.png" alt="logo" className="max-w-[100px]" />
                                    </div>
                                    <div className="mt-4">
                                        <h5 className="text-[12px] text-[#979797]">Amount to send</h5>
                                        <h2 className="text-[20px] font-semibold">NGN 13,090,749.55</h2>
                                    </div>
                                    <div className="mt-4">
                                        <div>
                                            <h5 className="text-[12px] text-[#979797]">Account to send to</h5>
                                        </div>
                                        <div className="flex justify-between">
                                            <h2 className="text-[20px] font-semibold">00347928748</h2>
                                            <div className="inline-flex bg-[#00B07B] text-white py-1 px-3 text-center rounded-[5px] mt-1 cursor-pointer">
                                                <span><svg width="12" height="14" className="mr-2" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M11.3439 4.12821L7.41935 0.157949C7.31963 0.0569387 7.18434 0.000125678 7.04323 0H5.29032C4.77272 0 4.27632 0.208012 3.91032 0.578276C3.54433 0.948541 3.33871 1.45073 3.33871 1.97436V2.87179H2.45161C1.93401 2.87179 1.43761 3.07981 1.07161 3.45007C0.705616 3.82034 0.5 4.32252 0.5 4.84615V12.0256C0.5 12.5493 0.705616 13.0515 1.07161 13.4217C1.43761 13.792 1.93401 14 2.45161 14H7.41935C7.93695 14 8.43335 13.792 8.79935 13.4217C9.16535 13.0515 9.37097 12.5493 9.37097 12.0256V11.1282H9.54839C10.066 11.1282 10.5624 10.9202 10.9284 10.5499C11.2944 10.1797 11.5 9.67748 11.5 9.15385V4.48718C11.4944 4.35192 11.4387 4.22375 11.3439 4.12821ZM7.59677 1.83795L9.68323 3.94872H7.59677V1.83795ZM8.30645 12.0256C8.30645 12.2637 8.21299 12.4919 8.04663 12.6602C7.88026 12.8285 7.65463 12.9231 7.41935 12.9231H2.45161C2.21634 12.9231 1.9907 12.8285 1.82434 12.6602C1.65798 12.4919 1.56452 12.2637 1.56452 12.0256V4.84615C1.56452 4.60814 1.65798 4.37987 1.82434 4.21157C1.9907 4.04327 2.21634 3.94872 2.45161 3.94872H3.33871V9.15385C3.33871 9.67748 3.54433 10.1797 3.91032 10.5499C4.27632 10.9202 4.77272 11.1282 5.29032 11.1282H8.30645V12.0256ZM9.54839 10.0513H5.29032C5.05505 10.0513 4.82941 9.95673 4.66305 9.78843C4.49669 9.62013 4.40323 9.39186 4.40323 9.15385V1.97436C4.40323 1.73634 4.49669 1.50808 4.66305 1.33978C4.82941 1.17147 5.05505 1.07692 5.29032 1.07692H6.53226V4.48718C6.5341 4.62941 6.59076 4.76529 6.69019 4.86587C6.78961 4.96645 6.92392 5.02378 7.06452 5.02564H10.4355V9.15385C10.4355 9.39186 10.342 9.62013 10.1757 9.78843C10.0093 9.95673 9.78366 10.0513 9.54839 10.0513Z" fill="white" />
                                                </svg>
                                                </span>
                                                <span className="text-[12px]">Copy</span>
                                            </div>
                                        </div>

                                    </div>
                                    <div className="mt-4">
                                        <h5 className="text-[12px] text-[#979797]">Bank Name</h5>
                                        <img src="/images/Dashbord/access.png" alt="logo" />
                                    </div>
                                    <p className="text-[12px] text-[#9DA4AA] mt-2 mb-4">This account is only valid for 24 hours and is for this specific transaction alone.</p>
                                    <div>

                                        <button
                                            type='button'

                                            className=

                                            "bg-[#00B07B] text-white mt-3 font-medium rounded-[5px] text-[12px] w-full py-2.5 text-center "


                                        >I have sent the money</button>

                                    </div>
                                </div>
                            </div>
                        </Modal>

                        <Modal open={openAddInstitution} onClose={onCloseAddInstitutionModal} center>
                            <div className='md:max-w-md  body-font font-poppins'>
                                <div className="flex flex-col mt-4">
                                    <h3 className="text-[30px] font-semibold text-[#00B07B]">Institution Information</h3>
                                    <p className="text-[12px] text-[#9DA4AA] mt-2 mb-4">Kindly provide the information of your institution so we can reach out to them and get back to you to continue your payment process.</p>

                                    <form onSubmit={addSchool}>
                                        <div className='mt-4'>
                                            <div className="relative w-full mb-3">
                                                <label
                                                    className="block text-[#000] text-sm font-medium mb-2"
                                                    htmlFor="institution_name"
                                                >
                                                    Institution Name
                                                </label>
                                                <input
                                                    type="text"
                                                    onChange={handleChange2}
                                                    name="school_name"

                                                    className="bg-white border border-[#DADADA] text-[#000] text-sm rounded-[5px] block w-full p-3 placeholder-[#000]  ease-linear transition-all duration-150"
                                                    placeholder=""

                                                />
                                            </div>
                                            <div className="relative w-full mb-3">
                                                <label
                                                    className="block text-[#000] text-sm font-medium mb-2"
                                                    htmlFor="institution_type"
                                                >
                                                    Type Institution
                                                </label>
                                                <input
                                                    type="text"
                                                    onChange={handleChange2}
                                                    name="other_information"

                                                    className="bg-white border border-[#DADADA] text-[#000] text-sm rounded-[5px] block w-full p-3 placeholder-[#000]  ease-linear transition-all duration-150"
                                                    placeholder=""

                                                />
                                            </div>
                                            <div className="relative w-full mb-3">
                                                <label
                                                    className="block text-[#000] text-sm font-medium mb-2"
                                                    htmlFor="institution_email"
                                                >
                                                    Email Address Institution
                                                </label>
                                                <input
                                                    type="email"
                                                    onChange={handleChange2}
                                                    name="email_address"

                                                    className="bg-white border border-[#DADADA] text-[#000] text-sm rounded-[5px] block w-full p-3 placeholder-[#000]  ease-linear transition-all duration-150"
                                                    placeholder=""

                                                />
                                            </div>
                                            <div className="relative w-full mb-6">
                                                <label
                                                    className="block text-[#000] text-sm font-medium mb-2"
                                                    htmlFor="institution_address"
                                                >
                                                    Institution Address
                                                </label>
                                                <input
                                                    type="text"
                                                    onChange={handleChange2}
                                                    name="location"

                                                    className="bg-white border border-[#DADADA] text-[#000] text-sm rounded-[5px] block w-full p-3 placeholder-[#000]  ease-linear transition-all duration-150"
                                                    placeholder=""

                                                />
                                            </div>
                                        </div>
                                        <div>
                                            <button
                                                type='submit'
                                                className="bg-[#00B07B] text-white mt-3 font-medium rounded-[5px] text-[12px] w-full py-2.5 text-center "
                                            >Submit</button>

                                        </div>
                                    </form>


                                </div>
                            </div>
                        </Modal>

                    </div>

                </div>
            </div>

            <ToastContainer
                position="top-right"
                autoClose={2000}
                hideProgressBar={true}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover />
        </>
    )
}

export default PaymentLists;