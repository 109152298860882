import React from 'react'
import Navbar from '../../Navbar'
import Footer from '../../Footer';
import BlogBg from "../../../img/blog.png"
import { NavLink } from 'react-router-dom';

function StudyAbroad() {
    return (
        <>
            <Navbar />
            <div className='pt-16'>
                <div
                    style={{
                        backgroundImage: `url(${BlogBg})`,
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "cover",
                        backgroundPosition: "center center",
                    }}
                    className="md:bg-cover bg-contain lg:px-14 px-6 md:pb-24 pb-10 mx-auto  flex flex-col lg:justify-center lg:space-x-14 relative"
                >
                    <div className="place-self-center text-center md:pt-32 md:pb-10 pt-8">
                        <h1 className="md:text-5xl text-2xl text-[#191A15] font-bold">
                        How Can I Study Abroad Without the Finances?
                        </h1>

                        <p className="md:mt-5 mt-2 md:text-base text-[#999A93] text-sm">
                            Get News with AfriPay
                        </p>
                    </div>
                </div>
            </div>

            <section className="bg-[#FFFFFF] body-font font-poppins py-10">
                <div className='lg:px-16 mx-auto px-8 pt-3'>
                    <div className='grid grid-cols-1 gap-8 md:grid-cols-3 lg:grid-cols-12'>
                        <div className='col-span-8'>
                            <div>
                                <img src="/images/blog8.jpg" alt="blog" className='rounded-[10px]' />

                                <p className='mt-3'>
                                Dreaming of studying abroad but worried about the financial hurdles? You're not alone, and the good news is, there are options available to help international students like you turn those dreams into reality.
                                </p>
                                <p className='mt-3'>At AfriPay, we understand that financing your education overseas can seem daunting. That's why we offer tailored educational loans designed to support your aspirations, regardless of your current financial situation.</p>
                            </div>

                        </div>
                    </div>

                    <div className='mt-5'>
                        <h3 className='mt-3 text-[20px] font-semibold'>Accessible Education Loans with AfriPay</h3>
<p className='mt-3'>Many international students think that securing an education loan requires collateral, such as a family home or business. However, with AfriPay, you can obtain a loan based on your potential rather than your present assets. Here’s how we make it possible:</p>
                       
                        <p className='mt-3 text-[18px]'><span className='font-semibold'>● No Collateral Needed:</span>  AfriPay loans are uniquely structured to not require any collateral. Whether your family owns property or not, you can apply for a loan independently. Your parents don’t need to be involved in the process at all! </p>
                        <p className='mt-3 text-[18px]'><span className='font-semibold'>● Future Earning Potential:</span>  We believe in investing in your future. AfriPay evaluates your loan application based on your future earning potential instead of your current wealth. This means that even if you’re just starting out, your academic and career prospects can help secure your loan. </p>
                        <p className='mt-3 text-[18px]'><span className='font-semibold'>● Support Beyond Finances:</span>  AfriPay isn’t just about providing loans. We offer comprehensive support to ensure your success abroad, including guidance on immigration, career development, and financial empowerment both before and after graduation. </p>

                        <h3 className='mt-3 text-[20px] font-semibold'>Special Consideration for STEM Students</h3>
                        <p className='mt-3'>Are you pursuing a degree in Science, Technology, Engineering, or Mathematics (STEM)? Great news! AfriPay recognizes the high demand and promising career paths in STEM fields. Students in these disciplines often have higher chances of loan approval due to the strong employment opportunities and earning potential associated with STEM careers.</p>

                        <h3 className='mt-3 text-[20px] font-semibold'>Loan Amounts and Uses</h3>
                        <p className='mt-3'>AfriPay offers loans up to US$100,000 to cover a wide array of educational expenses, including:</p>
              
                        <p className='mt-3 text-[18px]'> ●  Tuition fees</p>
                        <p className='mt-3 text-[18px]'> ● Books and supplies</p>
                        <p className='mt-3 text-[18px]'> ● Accommodation and rent</p>
                        <p className='mt-3 text-[18px]'> ● Food and daily necessities</p>
                        <p className='mt-3 text-[18px]'> ● Transportation and other living expenses</p>
                 

                        <p className='mt-3'>With an AfriPay loan, you can focus on your studies and embrace the international experience without the constant worry of financial strain.</p>

                        <h3 className='mt-3 text-[20px] font-semibold'>Take the First Step Now</h3>
                        <p className='mt-3'>Don’t let financial barriers hold you back from achieving your educational goals. With AfriPay, you can secure the funding you need to study abroad without the stress of collateral requirements.</p>


                        



                        <p className='mt-5 font-semibold'>Ready to make your dreams a reality? <NavLink to="/sign-in"><span className=' underline underline-offset-1 cursor-pointer'>Get your study loan now</span></NavLink>  and embark on your journey to success!
                        </p>



                        <hr className='my-4'/>
                        <p className='mt-6'>By choosing AfriPay, you’re not just getting a loan; you’re gaining a partner dedicated to your educational and professional success. Let us help you navigate the path to studying abroad with confidence and financial peace of mind.</p>

                    </div>
                </div>
            </section>



            {/* <-- Footer --> */}
            <Footer />
        </>
    )
}

export default StudyAbroad;