import React from 'react'
import Navbar from '../../Navbar'
import Footer from '../../Footer';
import BlogBg from "../../../img/blog.png"

function PasswordLeaked() {
    return (
        <>
            <Navbar />
            <div className='pt-16'>
                <div
                    style={{
                        backgroundImage: `url(${BlogBg})`,
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "cover",
                        backgroundPosition: "center center",
                    }}
                    className="md:bg-cover bg-contain lg:px-14 px-6 md:pb-24 pb-10 mx-auto  flex flex-col lg:justify-center lg:space-x-14 relative"
                >
                    <div className="place-self-center text-center md:pt-32 md:pb-10 pt-8">
                        <h1 className="md:text-5xl text-2xl text-[#191A15] font-bold">
                        Has Your Password Leaked Before? This Is How It Happened
                        </h1>

                        <p className="md:mt-5 mt-2 md:text-base text-[#999A93] text-sm">
                            Get News with AfriPay
                        </p>
                    </div>
                </div>
            </div>

            <section className="bg-[#FFFFFF] body-font font-poppins py-10">
                <div className='lg:px-16 mx-auto px-8 pt-3'>
                    <div className='grid grid-cols-1 gap-8 md:grid-cols-3 lg:grid-cols-12'>
                        <div className='col-span-8'>
                            <div>
                                <img src="/images/blog7.jpg" alt="blog" className='rounded-[10px]' />

                                <p className='mt-3'>
                                    In today's digital age, passwords are the keys to our online kingdoms. Yet, despite our best efforts to keep them secure, password leaks still happen all too often. If you've ever wondered how your password might have leaked, you're in the right place. Let's dive into the common ways passwords get compromised and, more importantly, how you can protect yourself.
                                </p>
                            </div>

                        </div>
                    </div>

                    <div className='mt-5'>
                        <h3 className='mt-3 text-[20px] font-semibold'>This is how your password got leaked:</h3>

                        <h3 className='mt-3 text-[18px] font-semibold'>1. Phishing Attacks</h3>
                        <p className='mt-3'>Phishing is one of the oldest tricks in the book. It involves scammers sending emails that appear to be from legitimate sources, such as your bank or a popular online service, tricking you into entering your login details on a fake website. Once you do, they have your password.</p>
                        <p className='mt-3'><span className='font-semibold'>Protection Tip:</span>  Always check the URL of the website you’re visiting and be wary of unsolicited emails asking for your personal information. Look for spelling mistakes and suspicious links.</p>


                        <h3 className='mt-3 text-[18px] font-semibold'>2. Data Breaches</h3>
                        <p className='mt-3'>Large companies and websites sometimes get hacked, resulting in massive data breaches where user credentials are stolen. These breaches are often reported in the news, and the stolen data is sometimes sold on the dark web.</p>
                        <p className='mt-3'><span className='font-semibold'>Protection Tip:</span> Use a password manager to create unique, strong passwords for each of your accounts. This way, if one account is compromised, the others remain secure.</p>


                        <h3 className='mt-3 text-[18px] font-semibold'>3. Weak Passwords</h3>
                        <p className='mt-3'>Using easily guessable passwords like "123456" or "password" makes it simple for hackers to break into your accounts using brute force attacks, where they try numerous password combinations until they find the right one.</p>
                        <p className='mt-3'><span className='font-semibold'>Protection Tip:</span>  Create complex passwords using a mix of letters, numbers, and special characters. Avoid using personal information like your birthdate or pet’s name.</p>

                        <h3 className='mt-3 text-[18px] font-semibold'>4. Malware</h3>
                        <p className='mt-3'>Malware, or malicious software, can infect your computer and steal your passwords. Keyloggers, a type of malware, record everything you type and send it back to the hacker.</p>
                        <p className='mt-3'><span className='font-semibold'>Protection Tip:</span>  Keep your antivirus software up-to-date and run regular scans. Be cautious about downloading software from untrusted sources.</p>


                        <h3 className='mt-3 text-[18px] font-semibold'>5. Public Wi-Fi</h3>
                        <p className='mt-3'>Using public Wi-Fi networks without protection can expose your data to hackers. They can intercept data transmitted over these networks, including your passwords.</p>
                        <p className='mt-3'><span className='font-semibold'>Protection Tip:</span>  Use a Virtual Private Network (VPN) when connecting to public Wi-Fi. This encrypts your data, making it difficult for hackers to intercept.</p>


                        <h3 className='mt-6 text-[18px] font-semibold'>How to Protect Yourself</h3>
                        <ul className='space-y-4 list-decimal list-inside mt-4'>
                            <li><span className='font-semibold'>Use Two-Factor Authentication (2FA)</span> Enable 2FA on your accounts. This adds an extra layer of security by requiring a second form of verification, such as a code sent to your phone. </li>
                            <li><span className='font-semibold'>Regularly Update Passwords</span>  Change your passwords regularly and avoid reusing old passwords.</li>
                            <li><span className='font-semibold'>Monitor for Breaches</span>  Use services like Have I Been Pwned to check if your email or passwords have been involved in a data breach.</li>
                            <li><span className='font-semibold'>Stay Informed</span>  Keep yourself updated on the latest cybersecurity threats and best practices. Knowledge is your best defense against password leaks.</li>
                        </ul>


                        <p className='mt-8'>Password leaks are a serious threat in our increasingly digital world, but by understanding how they happen and taking proactive steps to protect yourself, you can significantly reduce your risk. Stay vigilant, stay informed, and stay secure.</p>

                        <p className='mt-5'>Kindly note:
                            AfriPay will never request your bank card number or pin either via call/text or email address.
                        </p>
                        <p className='mt-6'>Protect your digital life with AfriPay. For more tips and services to keep your online transactions secure, visit goafripay.com or email us at hello@goafripay.com.</p>










                        <hr className='my-4' />
                        {/* <-- Tags --> */}
                        <div className='mt-3'>
                            <h3>
                            #CyberSecurity #PasswordProtection #DataBreach #OnlineSafety #AfriPay #StaySecure #DigitalSafety #Phishing #MalwareProtection #TwoFactorAuthentication #VPN #PublicWiFiSafety

                            </h3>
                        </div>
                    </div>
                </div>
            </section>



            {/* <-- Footer --> */}
            <Footer />
        </>
    )
}

export default PasswordLeaked