import React, { useState } from "react";
import { Await, NavLink, useNavigate } from "react-router-dom";
import { FaEyeSlash, FaEye, FaLessThanEqual } from "react-icons/fa";
import { AuthApis } from "../../../apis/authApis";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useDispatch, useSelector } from "react-redux";
import { login } from "../../../reducer/loginSlice";
import { Oval } from "react-loader-spinner";

function Login() {


  // React.useEffect(() => {
  //   window.location.reload()
  // }, []);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [show, setShow] = useState(false);
  const userLoginData = useSelector((state: any) => state.data.login.value);

  const [userData, setUserdata] = useState({
    'email': "",
    'password': "",
  });

  const [loader, setLoader] = useState<boolean>(false);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    setUserdata({ ...userData, [name]: value });
  };


  React.useEffect(() => {
    userLoginData?.token ?
      navigate("/new-dashboard")
      :
      navigate("/sign-in");
  }, []);

  const handleSubmit = async (e: React.ChangeEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoader(true);
  
    const formData = new FormData();
    formData.append('email', userData?.email);
    formData.append('password', userData?.password);
  
    try {
      const response = await AuthApis.login(formData);
  
      if (response?.data) {
        if (response?.data?.success === true) {
          await new Promise<void>((resolve) => {
            dispatch(login({
              email: userData?.email,
              isVerified: response.data.data?.isVerified,
              token: response?.data?.data?.token,
              id: response?.data?.data?.account_id,
              name: response?.data?.data?.name,
              data: response?.data?.data
            }));
            resolve();
          });

          console?.log()
  
          if (response?.data?.data?.role === 'admin') {
            navigate('/admin-dashboard');
            window.location.reload();
          } else {
            if (response?.data?.data?.user_data?.status !== 'approved') {
              navigate('/kyc');
              window.location.reload();
            } else {
              navigate('/new-dashboard');
              window.location.reload();
            }
          }
        }
      } else {
        // toast.warn('Invalid Login Credentials');
      }
    } catch (error:any) {
      console.log(error.response.data);
      toast.error(error.response.data?.message);
    } finally {
      setLoader(false);
    }
  };
  


  return (
    <>
      {/* <!-- Start block --> */}
      <section className="bg-[#F8F8F8] h-screen body-font font-poppins pb-20">
        <div className="grid  max-w-screen-xl  lg:gap-8 xl:gap-0 lg:grid-cols-12">
          <div className="hidden lg:col-span-6 lg:flex w-full bg-[#00B07B] min-h-screen">
            <div className="relative pt-10">
              <div className="lg:pl-[40px] pr-[5px] lg:pt-[260px]">
                <h3 className="text-[44px] text-white font-bold">
                  Start your remarkable Academic journey<br /> with us!
                </h3>
                <p className="mt-6 text-[16px] text-white pr-[15px]">
                  AfriPay aims to revolutionize the EdTech payment landscape by providing an integrated payment solution for students and their families, simplifying international tuition fee payments.
                </p>
              </div>
            </div>
          </div>

          <div className="lg:pl-20 lg:pr-6 mx-auto lg:col-span-6 lg:py-4 mt-8 md:mt-[80px]  px-2 py-10">
            <div className="flex justify-center">
              <NavLink to="/" >
                <img src="/images/afripay-logo.png" alt="logo" className="pt-4" />

              </NavLink>
            </div>
            <div className="mt-6">
              <h1 className=" text-2xl text-[#0A0A0C] font-semibold leading-10 md:text-[32px] xl:text-4xl text-center ">
                Login with AfriPay
              </h1>
              <p className="text-xs mt-3 mb-6 text-[##414143] text-center">
                Continue from where you stopped
              </p>
            </div>

            <div className=" mt-6 mx-4 md:mx-6 lg:mx-0">
              <form onSubmit={handleSubmit}>
                <div className="flex flex-col lg:flex-row lg:justify-between max-w-screen-xl mx-auto  mt-5">
                  <div className="relative flex flex-col min-w-0 break-words w-full  ">
                    <div className="flex-auto    pt-0">
                      <div className="flex flex-wrap ">

                        <div className="w-full lg:w-12/12 ">
                          <div className="relative w-full mb-6">
                            <label className="block mb-2 text-sm font-semibold text-[#414143]">
                              Email Address*
                            </label>
                            <input
                              type="email"
                              className="bg-white border border-[#ffffff] text-[#333333] text-sm rounded-[8px] block w-full p-3 placeholder:text-[#414143] placeholder:text-[12px]  ease-linear transition-all duration-150"
                              placeholder="Enter email"
                              name="email"
                              required
                              onChange={handleChange}

                            />
                          </div>
                        </div>



                        <div className="w-full lg:w-12/12">
                          <div className="relative w-full">
                            <label className="block mb-2 text-sm font-semibold text-[#414143]">
                              Password*
                            </label>
                            <input
                              type={show ? "text" : "password"}
                              className="bg-white border border-[#ffffff] text-[#333333] text-sm rounded-[8px] block w-full p-3 placeholder:text-[#414143] placeholder:text-[12px]  ease-linear transition-all duration-150"
                              placeholder="Enter password"
                              onChange={handleChange}
                              name="password"
                              required
                            />
                            <button
                              type="button"
                              className=" absolute right-2.5 bottom-3.5"
                              onClick={() => setShow((prev) => !prev)}
                            >
                              {!show ? <FaEyeSlash /> : <FaEye />}
                            </button>
                          </div>
                        </div>





                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex justify-start">
                  <NavLink to={"/forgot-password"} className="mt-2 ">
                    <p className="text-[#00B07B] text-left text-sm font-medium">Forgot Password?</p>
                  </NavLink>
                </div>
                <div className="pt-10">
                  <button
                    type="submit"
                    disabled={loader}
                    className="w-full flex justify-center gap-3 text-white bg-[#00B07B] hover:bg-[#05401C]  font-medium rounded-full text-sm px-5 py-2.5 mr-2 mb-2"
                  >
                    <span>Login</span>
                    {loader?
                    <span><Oval
                      visible={true}
                      height="20"
                      width="20"
                      color="#14B266"
                      secondaryColor="#E6F1FC"
                      ariaLabel="oval-loading"
                      wrapperStyle={{}}
                      wrapperClass=""
                    /></span>
                    :''
                  }



                  </button>
                </div>


                <p className="text-center text-[#0A0A0C] mt-2 text-[15px] font-semibold">
                  Don't have an account?{" "}
                  <NavLink to="/sign-up">
                    <span className="text-[#00B07B] cursor-pointer font-bold">Sign Up</span>
                  </NavLink>
                </p>
              </form>


            </div>
          </div>
        </div>
      </section>

      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover />

      {/* <!-- End block --> */}
    </>
  );
}

export default Login;
