import { AxiosGlobal } from "../shared/axios";
import { AxiosPromise } from "axios";
import configs from "../../configs";
import { store } from "../../Components/store/store";



export class AuthLiveApis extends AxiosGlobal {

    loginUser(data: any): AxiosPromise<any> {
        return this.axios.post(`${configs.context}/${configs.apiList.LOGIN}`, data);
    }


    registerUser(data: any): AxiosPromise<any> {
        return this.axios.post(`${configs.context}/${configs.apiList.REGISTER}`, data);
    }

    createSurvey(data: any): AxiosPromise<any> {
        return this.axios.post(`${configs.context}/survey/create-survey`, data);
    }

    forgotPassword(data: any): AxiosPromise<any> {
        return this.axios.post(`${configs.context}/forgot`, data);
    }


    verifyMail(data: any): AxiosPromise<any> {
        return this.axios.post(`${configs.context}/verify-mail`, data,{
            headers: { "Content-Type": "aplication/json", "Accept": "aplication/json", "Authorization": `Bearer ${store.getState().data.login.value.token}`, "Access-Control-Allow-Origin": "*" },
        });
    }

    resetPassword(data: any): AxiosPromise<any> {
        return this.axios.post(`${configs.context}/reset`, data,{
            headers: { "Content-Type": "aplication/json", "Accept": "aplication/json", "Authorization": `Bearer ${store.getState().data.login.value.token}`, "Access-Control-Allow-Origin": "*" },
        });
    }

    logout(data: any): AxiosPromise<any> {
        return this.axios.post(`${configs.context}/logout`, data, {
            headers: { "Content-Type": "aplication/json", "Accept": "aplication/json", "Authorization": `Bearer ${store.getState().data.login.value.token}`, "Access-Control-Allow-Origin": "*" },
        });
    }
    getSinglePartnerSchoolByName(schoolName:any): AxiosPromise<Array<any>> {
        return this.axios.get(`${configs.context}/get-single-partners-school/${schoolName}`,{
            headers: { "Content-Type": "aplication/json","Accept":"aplication/json","Authorization":`Bearer ${store.getState().data.login.value.token}`,"Access-Control-Allow-Origin":"*" },
          });
    } 

    getSinglePartnerSchoolById(id:any): AxiosPromise<Array<any>> {
        return this.axios.get(`${configs.context}/get-single-partners-school-by-id/${id}`,{
            headers: { "Content-Type": "aplication/json","Accept":"aplication/json","Authorization":`Bearer ${store.getState().data.login.value.token}`,"Access-Control-Allow-Origin":"*" },
          });
    } 

    sendMail(data: any): AxiosPromise<any> {
        return this.axios.post(`${configs.context}/send-mail`, data, {
            headers: { "Content-Type": "aplication/json", "Accept": "aplication/json", "Authorization": `Bearer ${store.getState().data.login.value.token}`, "Access-Control-Allow-Origin": "*" },
        });
    }

    createAgent(data:any): AxiosPromise<Array<any>> {
        return this.axios.post(`${configs.context}/create-agent`,data,{
            headers: { "Content-Type": "aplication/json","Accept":"aplication/json","Authorization":`Bearer ${store.getState().data.login.value.token}`,"Access-Control-Allow-Origin":"*" },
          });
    }

    createMasterCourse(data: any): AxiosPromise<Array<any>> {
        return this.axios.post(`${configs.context}/create-master-application`,data, {
            headers: { "Content-Type": "aplication/json", "Accept": "aplication/json", "Authorization": `Bearer ${store.getState().data.login.value.token}`, "Access-Control-Allow-Origin": "*" },
        });
    }  

    getFilteredCourse(pageNo:any,query:any): AxiosPromise<Array<any>> {
        return this.axios.get(`${configs.context}/get-filtered-course?page=${pageNo}&title=${query?.title}&category=${query?.category}`,{
            headers: { "Content-Type": "aplication/json","Accept":"aplication/json","Authorization":`Bearer ${store.getState().data.login.value.token}`,"Access-Control-Allow-Origin":"*" },
          });
    } 

    makeOutsudePayment(data:any): AxiosPromise<Array<any>> {
        return this.axios.post(`${configs.context}/make-outside-payment`,data,{
            headers: { "Content-Type": "aplication/json","Accept":"aplication/json","Authorization":`Bearer ${store.getState().data.login.value.token}`,"Access-Control-Allow-Origin":"*" },
          });
    }

    getSinglePartnerCourse(id: any): AxiosPromise<Array<any>> {
        return this.axios.get(`${configs.context}/get-single-partner-course/${id}`, {
            headers: { "Content-Type": "aplication/json", "Accept": "aplication/json", "Authorization": `Bearer ${store.getState().data.login.value.token}`, "Access-Control-Allow-Origin": "*" },
        });
    }

  

    createCookProgram(data: any): AxiosPromise<Array<any>> {
        return this.axios.post(`${configs.context}/create-cook-program`, data, {
            headers: { "Content-Type": "aplication/json", "Accept": "aplication/json", "Authorization": `Bearer ${store.getState().data.login.value.token}`, "Access-Control-Allow-Origin": "*" },
        });
    }


    contactInfo(data: any): AxiosPromise<Array<any>> {
        return this.axios.post(`${configs.context}/get-contact-data`, data, {
            headers: { "Content-Type": "aplication/json", "Accept": "aplication/json", "Authorization": `Bearer ${store.getState().data.login.value.token}`, "Access-Control-Allow-Origin": "*" },
        });
    }  


    makeOutsideNairaPayment(data: any): AxiosPromise<Array<any>> {
        return this.axios.post(`${configs.context}/make-outside-naira-payment`, data, {
            headers: { "Content-Type": "aplication/json", "Accept": "aplication/json", "Authorization": `Bearer ${store.getState().data.login.value.token}`, "Access-Control-Allow-Origin": "*" },
        });
    }

    makeCoursePayment(data: any): AxiosPromise<Array<any>> {
        return this.axios.post(`${configs.context}/make-course-payment`, data, {
            headers: { "Content-Type": "aplication/json", "Accept": "aplication/json", "Authorization": `Bearer ${store.getState().data.login.value.token}`, "Access-Control-Allow-Origin": "*" },
        });
    }

    outsidePaymentCallback(reference: any, status: any): AxiosPromise<Array<any>> {
        return this.axios.get(`${configs.context}/outside-payment-callback?reference=${reference}&status=${status}`, {
            headers: { "Content-Type": "aplication/json", "Accept": "aplication/json", "Authorization": `Bearer ${store.getState().data.login.value.token}`, "Access-Control-Allow-Origin": "*" },
        });
    }

    getAllBlogs(pageNo: any): AxiosPromise<Array<any>> {
        return this.axios.get(`${configs.context}/get-all-blogs?page=${pageNo}`, {
            headers: { "Content-Type": "aplication/json", "Accept": "aplication/json", "Authorization": `Bearer ${store.getState().data.login.value.token}`, "Access-Control-Allow-Origin": "*" },
        });
    }

    getSIngleBlog(id: any): AxiosPromise<Array<any>> {
        return this.axios.get(`${configs.context}/get-single-blog/${id}`, {
            headers: { "Content-Type": "aplication/json", "Accept": "aplication/json", "Authorization": `Bearer ${store.getState().data.login.value.token}`, "Access-Control-Allow-Origin": "*" },
        });
    }


    // getAllUsers(cursor:any): AxiosPromise<Array<any>> {
    //     return this.axios.get(`${configs.context}/${configs.apiList.GET_ALL_USERS}?cursor=${cursor}`,{
    //         headers: { "Content-Type": "aplication/json","Accept":"aplication/json","Authorization":`Bearer ${store.getState().data.login.value.token}`,"Access-Control-Allow-Origin":"*" },
    //       });
    // }



    //  loginUser(data:any): AxiosPromise<any> {
    //      return this.axios.post(`${configs.context}/login`, data,{
    //         headers: { "Content-Type": "aplication/json","Accept":"aplication/json","Authorization":'Bearer 2|X4dvppS3EugstZmfvKwPbf4jBF7Y70OYvKGjdFnQ',"Access-Control-Allow-Origin":"*" },
    //       });
    // }


}