import React, { useState } from "react";
import Sidebar from "../../Sidebar/Sidebar";
import UserNavbar from "../../Navbars/UserNavbar";
import HeaderTitle from '../../ReuseableComponent/HeaderTitle';
import { useNavigate, Link } from 'react-router-dom';

function SchoolInfo() {
    const navigate = useNavigate();
    const [userData, setUserdata] = useState({
        'country': "",
        'institution_address': "",
        'institution': "",

    });
    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const { name, value } = e.target;
        setUserdata({ ...userData, [name]: value });
    };
    const handleSubmit = (e: React.ChangeEvent<HTMLFormElement>) => {
        e.preventDefault();
        navigate('/payment-information');
        // const formData = new FormData()
        // formData.append('country', userData?.country)
        // formData.append('institution_address', userData?.institution_address)
        // formData.append('institution', userData?.institution)



        // AuthApis.register(formData).then(
        //   (response) => {
        //     if (response?.data) {
        //       console.log(response.data)
        //       toast.error(response?.data?.message);
        //       if (response?.data?.success === true) {
        //         dispatch(login({ email: userData?.email, isVerified: response.data.data?.isVerified, token: response.data.data.token, id: response.data.data.account_id, name: response.data.data.name, data: response.data.data }))
        //         navigate('/email-verify');
        //       } else {
        //         toast.success(response?.data?.message);
        //       }
        //     } else {

        //     }

        //     // toast.success(response?.data?.message);
        //   }
        // ).catch(function (error) {
        //   // handle error
        //   console.log(error.response.data);
        //   toast.error(error.response.data?.message);
        // }).finally(() => {
        //   // toast.error("No Internet Connection");

        // });
    }

    return (
        <>
            <Sidebar />
            <div className="relative md:ml-64">
                <UserNavbar />
                <div className='lg:mt-5 lg:px-10 px-6 bg-[#Ffffff] min-h-screen'>
                    <HeaderTitle title="Enter School Information " />
                    <div className='mt-[22px]'>
                        <h3 className='text-[#6E7093] text-[12px] mb-[83px] font-normal'>Enter your schools’s details to begin your payment process </h3>
                    </div>

                    <div className='mt-[57px] flex'>
                        <div className="w-full lg:w-6/12 ">
                            <form onSubmit={handleSubmit}>
                                <div className="relative flex flex-col min-w-0 break-words w-full  ">
                                    <div className="flex-auto  py-10 pt-0">
                                        <div className="flex flex-wrap">
                                            <div className="relative w-full mb-6">
                                                <label htmlFor="country" className="text-[#000] text-sm font-medium mb-2 ">Country</label>
                                                <select id="country" name="country"
                                                    onChange={handleChange}
                                                    className=" bg-white border border-[#DADADA] text-[#000] text-sm rounded-[5px] block w-full p-3 placeholder-[#000]  ease-linear transition-all duration-150">
                                                    <option selected>Choose a Country</option>
                                                    <option value="finland">FinLand</option>
                                                    <option value="nigeria">Nigeria</option>
                                                    <option value="other">I prefer not to say</option>
                                                </select>
                                            </div>
                                            <div className="relative w-full mb-6">
                                                <label
                                                    className="block text-[#000] text-sm font-medium mb-2"
                                                    htmlFor="institution"
                                                >
                                                    Institution Name
                                                </label>
                                                <input
                                                    type="text"
                                                    onChange={handleChange}
                                                    name="institution"
                                                    className="bg-white border border-[#DADADA] text-[#000] text-sm rounded-[5px] block w-full p-3 placeholder-[#000]  ease-linear transition-all duration-150"
                                                    placeholder=""

                                                />
                                            </div>
                                            <div className="relative w-full mb-6">
                                                <label htmlFor="institution_type" className="text-[#000] text-sm font-medium mb-2 ">Type of Institution </label>
                                                <select id="institution_type" name="institution_type"
                                                    onChange={handleChange}
                                                    className=" bg-white border border-[#DADADA] text-[#000] text-sm rounded-[5px] block w-full p-3 placeholder-[#000]  ease-linear transition-all duration-150">
                                                    <option selected>Choose Institution Type</option>
                                                    <option value="finland">FinLand</option>
                                                    <option value="nigeria">Nigeria</option>
                                                    <option value="other">I prefer not to say</option>
                                                </select>
                                            </div>
                                            <div className="relative w-full mb-6">
                                                <label
                                                    className="block text-[#000] text-sm font-medium mb-2"
                                                    htmlFor="institution_email"
                                                >
                                                    Email Address of Institution
                                                </label>
                                                <input
                                                    type="text"
                                                    onChange={handleChange}
                                                    name="institution_email"
                                                    className="bg-white border border-[#DADADA] text-[#000] text-sm rounded-[5px] block w-full p-3 placeholder-[#000]  ease-linear transition-all duration-150"
                                                    placeholder=""

                                                />
                                            </div>
                                            <div className="relative w-full mb-6">
                                                <label
                                                    className="block text-[#000] text-sm font-medium mb-2"
                                                    htmlFor="institution_address"
                                                >
                                                    Institution Address
                                                </label>
                                                <input
                                                    type="text"
                                                    onChange={handleChange}
                                                    name="institution_address"

                                                    className="bg-white border border-[#DADADA] text-[#000] text-sm rounded-[5px] block w-full p-3 placeholder-[#000]  ease-linear transition-all duration-150"
                                                    placeholder=""

                                                />
                                            </div>





                                            {/* <div className='w-full'>
                                                <button
                                                    type='submit'

                                                    className={
                                                        "text-white " +
                                                        (userData?.institution_address === ""
                                                            ? "bg-[#00B07B] opacity-50"
                                                            : "bg-[#00B07B] ") +
                                                        " mt-3 font-medium rounded-[5px] text-[12px] w-full py-2.5 text-center "
                                                    }
                                                    disabled={userData?.institution_address === "" ? true : false}
                                              
                                                >Continue</button>
                                            </div> */}
                                            <div className="w-full">
                                                <Link to="/payment-information">
                                                    <button
                                                        type='submit'

                                                        className=

                                                        "bg-[#00B07B] text-white mt-3 font-medium rounded-[5px] text-[12px] w-full py-2.5 text-center "


                                                    >Continue</button>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div className="w-full lg:w-2/12 hidden md:block"></div>
                        <div className="w-full lg:w-4/12 hidden md:block">
                            <div className='0 border border-[#979797] pr-5 pl-14 pt-5 pb-10 rounded-[15px]'>

                                <ol className="relative border-l-2 border-[#D9D9D9]">
                                    <li className="mb-5 ml-6">
                                        <div className="absolute  bg-[#00B07B] text-white text-[10px] rounded-[30px]  -left-[31px] py-1 px-5 ">Step 1 </div>
                                        <p className="mt-2 mb-4   ml-8 text-[12px]  text-[#6E7093] ">Enter your schools details to begin your payment Process</p>
                                    </li>
                                    <li className="mb-5 ml-6">
                                        <div className="absolute  bg-[#979797] text-white text-[10px] rounded-[30px]  -left-[31px] py-1 px-5 ">Step 2</div>
                                        <p className="mt-2 mb-4   ml-8 text-[12px]  text-[#6E7093] ">Confirm the amount you are sending before proceeding</p>
                                    </li>
                                    <li className="mb-5 ml-6">
                                        <div className="absolute  bg-[#979797] text-white text-[10px] rounded-[30px]  -left-[31px] py-1 px-5 ">Step 3</div>
                                        <p className="mt-2 mb-4   ml-8 text-[12px]  text-[#6E7093]">Enter Student details to be sent to the <br />school </p>
                                    </li>
                                    <li className="mb-5 ml-6">
                                        <div className="absolute  bg-[#979797] text-white text-[10px] rounded-[30px]  -left-[31px] py-1 px-5 ">Step 4</div>
                                        <p className="mt-2 mb-4   ml-8 text-[12px]  text-[#6E7093]">Verify all the information you have provided before proceeding </p>
                                    </li>
                                    <li className="mb-5 ml-6">
                                        <div className="absolute  bg-[#979797] text-white text-[10px] rounded-[30px]  -left-[31px] py-1 px-5 ">Step 5</div>
                                        <p className="mt-2 mb-4   ml-8 text-[12px]  text-[#6E7093] ">Make Payment </p>
                                    </li>


                                </ol>
                            </div>
                        </div>
                    </div>


                </div>
            </div>
        </>
    )
}

export default SchoolInfo