import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { FaEyeSlash, FaEye } from "react-icons/fa";
import { useNavigate, useSearchParams } from 'react-router-dom';
import { AuthApis } from "../../../apis/authApis";
import { Dispatch } from "redux";
import { ToastContainer, toast } from 'react-toastify';
import { useSelector, useDispatch } from 'react-redux';
import { login } from '../../reducer/loginSlice';
import 'react-toastify/dist/ReactToastify.css';

// @ts-ignore
import { PhoneInput } from "react-contact-number-input";
import LoadingSpinner from "../../UI/LoadingSpinner";

function Register() {
  const [show, setShow] = useState(false);


  const [phone, setPhone] = useState<any>('');
  const [searchParams, setSearchParams] = useSearchParams();
  // console?.log(searchParams.get('ref'));
  const [refId, setRefId] = useState<any>(searchParams.get('ref'));
  const [loading, setLoading] = useState(false);
  const [emailError, setEmailError] = useState('');
  const [passwordError, setPassordError] = useState('');

  // Show password
  const [showPassword, setShowPassword] = useState(false);
  const [showOldPassword, setShowOldPassword] = useState(false);

  const userLoginData = useSelector((state: any) => state.data.login.value);


  const dispatch = useDispatch();
  const [userData, setUserdata] = useState({
    'email': "",
    'f_name': "",
    'l_name': "",
    'phone_number': "",
    'password': "",
    'confirm_password': ""
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    setUserdata({ ...userData, [name]: value });


    // Password strength validation
    if (name === 'password') {
      // const strongPasswordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/;
      const strongPasswordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+])[a-zA-Z\d!@#$%^&*()_+]{8,}$/;

      const isValidPassword = strongPasswordRegex.test(value);
      if (!isValidPassword) {
        setPassordError('Password must be at least 8 characters long and contain at least one uppercase letter, one lowercase letter, one number, and one special character.');
      } else {
        setPassordError('');
      }
    }

    // Confirm password match validation
    if (name === 'confirm_password') {
      if (value !== userData.password) {
        setPassordError("Password and Confirm Password don't match.");
      } else {
        setPassordError('');
      }
    }

    if (name === 'email') {
      // Regular expression for email validation
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      const isValidEmail = emailRegex.test(value);

      // Update the email error state based on validation result
      setEmailError(isValidEmail ? '' : 'Please enter a valid email address.');
    }
  };
  const handleInput = (event: any) => {
    const value = event.target.value;
    const regex = /^[a-zA-Z]*$/; // Regular expression to match alphabetic characters only

    if (!regex.test(value)) {
      event.target.value = value.replace(/[^a-zA-Z]/g, ''); // Remove any non-alphabetic characters
    }
  };

  const navigate = useNavigate();

  // React.useEffect(() => {
  //   userLoginData?.token ?
  //   navigate("/new-dashboard")
  //     :
  //   ''
  // }, []);


  const handleSubmit = (e: React.ChangeEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);

    // Check if password and confirm password match
    if (userData.password !== userData.confirm_password) {
      toast.error("Password and Confirm Password must match.");
      return; // Prevent form submission if passwords don't match
    }
    const formData = new FormData()
    formData.append('email', userData?.email)
    formData.append('first_name', userData?.f_name)
    formData.append('last_name', userData?.l_name)
    formData.append('phone_number', phone?.validData?.phoneNumber)
    formData.append('confirm_password', userData?.confirm_password)
    formData.append('password', userData?.password)


    AuthApis.register(formData).then(
      (response) => {
        if (response?.data) {
          // console.log(response.data)
          toast.success(response?.data?.message);
          if (response?.data?.success === true) {
            dispatch(login({ email: userData?.email, isVerified: response.data.data?.isVerified, token: response.data.data.token, id: response.data.data.account_id, name: response.data.data.name, data: response.data.data }))
            setLoading(false);
            navigate('/email-verify');
          } else {
            toast.error(response?.data?.message);
            setLoading(false);

          }
        } else {
          setLoading(false);

        }

        // toast.success(response?.data?.message);
      }
    ).catch(function (error) {
      // handle error
      console.log(error.response.data);
      toast.error(error.response.data?.message);
      setLoading(false);

    }).finally(() => {
      // toast.error("No Internet Connection");
      setLoading(false);

    });
  }



  return (
    <>
      {/* <!-- Start block --> */}


      <section className="bg-[#F8F8F8]  body-font font-poppins ">
        <div className="grid  max-w-screen-xl  lg:gap-8 xl:gap-0 lg:grid-cols-12">
          <div className="hidden lg:col-span-6 lg:flex w-full bg-[#00B07B]">
            <div className="relative pt-10">
              <div className="lg:pl-[40px] pr-[5px] lg:pt-[260px]">
                <h3 className="text-[44px] text-white font-bold">
                  Start your remarkable Academic journey<br /> with us!
                </h3>
                <p className="mt-6 text-[16px] text-white pr-[15px]">
                  AfriPay aims to revolutionize the EdTech payment landscape by providing an integrated payment solution for students and their families, simplifying international tuition fee payments.
                </p>
              </div>
            </div>
          </div>

          <div className="lg:pl-20 lg:pr-6 mx-auto lg:col-span-6 lg:py-4 mt-8 md:mt-[100px] px-2 py-10">
            <div className="flex justify-center">
              <NavLink to="/" >
                <img src="/images/afripay-logo.png" alt="logo" className="pt-4" />

              </NavLink>
            </div>
            <div className="mt-6">
              <h1 className=" text-2xl text-[#0A0A0C] font-semibold leading-10 md:text-[32px] xl:text-4xl text-center ">
                Sign up with AfriPay
              </h1>
              <p className="text-xs mt-3 mb-6 text-[##414143] text-center">
                Empower your experience, sign up for a free account today
              </p>
            </div>

            <div className=" mt-6 mx-4 md:mx-6 lg:mx-0">
              <form onSubmit={handleSubmit}>
                <div className="flex flex-col lg:flex-row lg:justify-between max-w-screen-xl mx-auto  mt-5">
                  <div className="relative flex flex-col min-w-0 break-words w-full  ">
                    <div className="flex-auto   py-10 pt-0">
                      <div className="flex flex-wrap ">

                        <div className="w-full lg:w-6/12 lg:pr-4">
                          <div className="relative w-full mb-6 mt-4">
                            <label className="block mb-2 text-sm font-semibold text-[#414143]">
                              First Name*
                            </label>
                            <input
                              type="text"
                              className="bg-white border border-[#ffffff] text-[#333333] text-sm rounded-[8px] block w-full p-3 placeholder:text-[#414143] placeholder:text-[12px]  ease-linear transition-all duration-150"
                              placeholder="Enter First Name"
                              name="f_name"
                              onChange={handleChange}
                              onInput={handleInput}

                              required
                            />
                          </div>
                        </div>

                        <div className="w-full lg:w-6/12 ">
                          <div className="relative w-full mb-6 md:mt-4 mt-0">
                            <label className="block mb-2 text-sm font-semibold text-[#414143]">
                              Last Name*
                            </label>
                            <input
                              type="text"
                              className="bg-white border border-[#ffffff] text-[#333333] text-sm rounded-[8px] block w-full p-3 placeholder:text-[#414143] placeholder:text-[12px]  ease-linear transition-all duration-150"
                              placeholder="Enter Last Name"
                              name="l_name"
                              required
                              onChange={handleChange}
                              onInput={handleInput}

                            />
                          </div>
                        </div>

                        <div className="w-full lg:w-12/12 ">
                          <div className="relative w-full mb-6">
                            <label className="block mb-2 text-sm font-semibold text-[#414143]">
                              Email Address*
                            </label>
                            <input
                              type="email"
                              className="bg-white border border-[#ffffff] text-[#333333] text-sm rounded-[8px] block w-full p-3 placeholder:text-[#414143] placeholder:text-[12px]  ease-linear transition-all duration-150"
                              placeholder="Enter email"
                              name="email"
                              required
                              onChange={handleChange}
                              value={userData.email}

                            />
                            {emailError && <div className="text-red-500 text-sm mt-1">{emailError}</div>}

                          </div>
                        </div>

                        <div className="w-full lg:w-12/12">
                          <div className="relative mb-6">
                            <label className="block mb-2 text-sm font-semibold text-[#414143]">
                              Phone No*
                            </label>
                            <PhoneInput
                              style={{ backgroundColor: '#F5F5F5', width: "80%" }}
                              disabled={false}
                              className={"p-3 mr-4"}
                              containerClass={"bg-white border border-[#ffffff] text-[#333333] text-sm rounded-[8px] block w-full p-1 "}
                              countryCode={'ng'}
                              onChange={setPhone}

                            />
                            <div className="pl-2 mt-1 text-xs text-red-500">{phone?.validData?.phoneNumber === null ? 'Enter valid phone number *' : ''}</div>

                          </div>
                        </div>

                        <div className="w-full lg:w-12/12">
                          <div className="relative w-full mb-6">
                            <label className="block mb-2 text-sm font-semibold text-[#414143]">
                              Password*
                            </label>
                            <input
                              type={show ? "text" : "password"}
                              className="bg-white border border-[#ffffff] text-[#333333] text-sm rounded-[8px] block w-full p-3 placeholder:text-[#414143] placeholder:text-[12px]  ease-linear transition-all duration-150"
                              placeholder="Enter password"
                              onChange={handleChange}
                              name="password"
                              required
                            />
                            <button
                              type="button"
                              className=" absolute right-2.5 bottom-3.5"
                              onClick={() => setShow((prev) => !prev)}
                            >
                              {!show ? <FaEyeSlash /> : <FaEye />}
                            </button>
                          </div>
                          {passwordError && (
                            <div className="text-red-500 text-xs mt-1">{passwordError}</div>
                          )}
                        </div>

                        <div className="w-full lg:w-12/12">
                          <div className="relative w-full mt-6 md:mt-0">
                            <label className="block mb-2 text-sm font-semibold text-[#414143]">
                              Confirm Password*
                            </label>
                            <input
                              type={!showPassword ? "password" : "text"}
                              className="bg-white border border-[#ffffff] text-[#333333] text-sm rounded-[8px] block w-full p-3 placeholder:text-[#414143] placeholder:text-[12px]  ease-linear transition-all duration-150"
                              placeholder="Enter password"
                              onChange={handleChange}
                              name="confirm_password"
                              required

                            />
                            <button
                              type="button"
                              // onClick={() => setShow((prev) => !prev)}
                              onClick={() => setShowPassword((prev) => !prev)}
                              className={`absolute right-4 top-[43px]`}
                            >
                              {!showPassword ? <FaEyeSlash /> : <FaEye />}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mb-4">
                  <p className="text-[13px] text-[#414143]">By registering for an account, you are consenting to our <NavLink to="/privacy-policy"><span className="text-[#00B07B] underline underline-offset-1">Terms of Service</span></NavLink> and confirming that you have reviewed and accepted the   <NavLink to="/privacy-policy"><span className="text-[#00B07B]">Global Privacy Statement.</span></NavLink></p>
                </div>

                <button
                  type="submit"
                  disabled={loading || emailError || passwordError || phone?.validData?.phoneNumber === null ? true : false}
                  className="w-full text-white bg-[#00B07B] hover:bg-[#05401C]  font-medium rounded-full text-sm px-5 py-2.5 mr-2 mb-2"
                >
                  {loading ? <LoadingSpinner /> : "Get started free"}


                </button>
                <p className="text-center text-[#0A0A0C] mt-1 text-[15px] font-semibold">
                  Already have an account?{" "}
                  <NavLink to="/sign-in">
                    <span className="text-[#00B07B] cursor-pointer font-bold">Login</span>
                  </NavLink>
                </p>
              </form>
            </div>
          </div>
        </div>
      </section>


      <ToastContainer
        position="bottom-left"
        autoClose={2000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover />

      {/* <!-- End block --> */}
    </>
  )
}

export default Register