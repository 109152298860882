import React, { useState } from "react";
import Sidebar from "../../Sidebar/Sidebar";
import UserNavbar from "../../Navbars/UserNavbar";
import HeaderTitle from '../../ReuseableComponent/HeaderTitle';
import { useNavigate, Link } from 'react-router-dom';

function StudentInfo() {
  const navigate = useNavigate();
  const [userData, setUserdata] = useState({
    'payment_by': "",
    'payment_for': "",
    'country_from': "",
    'account_number': "",
    'sort_code': "",
    'swift_code': "",
    'sch_currency': "",

  });
  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    setUserdata({ ...userData, [name]: value });
  };
  const handleSubmit = (e: React.ChangeEvent<HTMLFormElement>) => {
    e.preventDefault();
    navigate('/verify-information');
    // const formData = new FormData()
    // formData.append('payment_by', userData?.payment_by)
    // formData.append('institution_address', userData?.institution_address)
    // formData.append('institution', userData?.institution)



    // AuthApis.register(formData).then(
    //   (response) => {
    //     if (response?.data) {
    //       console.log(response.data)
    //       toast.error(response?.data?.message);
    //       if (response?.data?.success === true) {
    //         dispatch(login({ email: userData?.email, isVerified: response.data.data?.isVerified, token: response.data.data.token, id: response.data.data.account_id, name: response.data.data.name, data: response.data.data }))
    //         navigate('/email-verify');
    //       } else {
    //         toast.success(response?.data?.message);
    //       }
    //     } else {

    //     }

    //     // toast.success(response?.data?.message);
    //   }
    // ).catch(function (error) {
    //   // handle error
    //   console.log(error.response.data);
    //   toast.error(error.response.data?.message);
    // }).finally(() => {
    //   // toast.error("No Internet Connection");

    // });
  }
  return (
    <>
      <Sidebar />
      <div className="relative md:ml-64">
        <UserNavbar />
        <div className='lg:mt-5 lg:px-10 px-6 bg-[#Ffffff] min-h-screen'>
          <HeaderTitle title="Enter Student Information " />
          <div className='mt-[22px]'>
            <h3 className='text-[#6E7093] text-[12px] mb-[83px] font-normal'>Enter the student details to be sent to the school  </h3>
          </div>

          <div className='mt-[57px] flex'>
            <div className="w-full lg:w-6/12 ">
              <form onSubmit={handleSubmit}>
                <div className="relative flex flex-col min-w-0 break-words w-full  ">
                  <div className="flex-auto  py-10 pt-0">
                    <div className="flex flex-wrap">



                      <div className="relative w-full mb-6">
                        <label
                          className="block text-[#000] text-sm font-medium mb-2"
                          htmlFor="account_number"
                        >
                          Student First Name
                        </label>
                        <input
                          type="text"
                          onChange={handleChange}
                          name="account_number"
                          className="bg-white border border-[#DADADA] text-[#000] text-sm rounded-[5px] block w-full p-3 placeholder-[#000]  ease-linear transition-all duration-150"
                          placeholder=""

                        />
                      </div>
                      <div className="relative w-full mb-6">
                        <label
                          className="block text-[#000] text-sm font-medium mb-2"
                          htmlFor="sort_code"
                        >
                          Student Last Name
                        </label>
                        <input
                          type="text"
                          onChange={handleChange}
                          name="sort_code"

                          className="bg-white border border-[#DADADA] text-[#000] text-sm rounded-[5px] block w-full p-3 placeholder-[#000]  ease-linear transition-all duration-150"
                          placeholder=""

                        />
                      </div>
                      <div className="relative w-full mb-6">
                        <label
                          className="block text-[#000] text-sm font-medium mb-2"
                          htmlFor="swift_code"
                        >
                          Student Email Address
                        </label>
                        <input
                          type="email"
                          onChange={handleChange}
                          name="swift_code"

                          className="bg-white border border-[#DADADA] text-[#000] text-sm rounded-[5px] block w-full p-3 placeholder-[#000]  ease-linear transition-all duration-150"
                          placeholder=""

                        />
                      </div>

                      <div className="w-full mb-6">
                        <label htmlFor="sch_currency" className="text-[#000] text-sm font-medium mb-2 ">Upload a means of identification (Student ID or Admission Letter)</label>
                        <div className='flex flex-col border border-[#DADADA] w-full rounded-[5px] p-3'>
                          <div>
                            <label htmlFor="dropzone-file">
                              <div className='flex space-x-2 justify-center '>
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24"><path fill="currentColor" d="M11 16V7.85l-2.6 2.6L7 9l5-5l5 5l-1.4 1.45l-2.6-2.6V16h-2Zm-5 4q-.825 0-1.413-.588T4 18v-3h2v3h12v-3h2v3q0 .825-.588 1.413T18 20H6Z" /></svg>
                                <p className='text-[14px]'>Add file</p>
                              </div>
                              <input id="dropzone-file" type="file" className="hidden" />
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="relative w-full mb-6">

                        <label htmlFor="message" className="text-[#000] text-sm font-medium mb-2 ">Additional Information</label>
                        <textarea id="message" rows={4} className="block p-2.5 w-full text-sm text-gray-900 rounded-[5px] border border-[#DADADA]" placeholder="Write here..."></textarea>

                      </div>






                      <div className='w-full flex space-x-5'>
                        <button
                          type='button'
                          onClick={() => navigate(-1)}
                          className=" bg-[#979797] text-white mt-3 font-medium rounded-[5px] text-[12px] w-full py-2.5 text-center "
                        >Back</button>
                        {/* <button
                          type='submit'

                          className={
                            "text-white " +
                            (userData?.sch_currency === ""
                              ? "bg-[#00B07B] opacity-50"
                              : "bg-[#00B07B] ") +
                            " mt-3 font-medium rounded-[5px] text-[12px] w-full py-2.5 text-center "
                          }
                          disabled={userData?.sch_currency === "" ? true : false}
                        >Continue</button> */}
                         <Link to="/verify-information" className="w-full">
                                                    <button
                                                        type='submit'

                                                        className=

                                                        "bg-[#00B07B] text-white mt-3 font-medium rounded-[5px] text-[12px] w-full py-2.5 text-center "


                                                    >Continue</button>
                                                </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <div className="w-full lg:w-2/12 hidden md:block"></div>
            <div className="w-full lg:w-4/12 hidden md:block">
              <div className='0 border border-[#979797] pr-5 pl-14 pt-5 pb-10 rounded-[15px]'>

                <ol className="relative border-l-2 border-[#D9D9D9]">
                  <li className="mb-5 ml-6">
                    <div className="absolute  bg-[#00B07B] text-white text-[10px] rounded-[30px]  -left-[31px] py-1 px-5 ">Step 1 </div>
                    <p className="mt-2 mb-4   ml-8 text-[12px]  text-[#6E7093] ">Enter your schools details to begin your payment Process</p>
                  </li>
                  <li className="mb-5 ml-6">
                    <div className="absolute  bg-[#00B07B] text-white text-[10px] rounded-[30px]  -left-[31px] py-1 px-5 ">Step 2</div>
                    <p className="mt-2 mb-4   ml-8 text-[12px]  text-[#6E7093] ">Confirm the amount you are sending before proceeding</p>
                  </li>
                  <li className="mb-5 ml-6">
                    <div className="absolute  bg-[#00B07B] text-white text-[10px] rounded-[30px]  -left-[31px] py-1 px-5 ">Step 3</div>
                    <p className="mt-2 mb-4   ml-8 text-[12px]  text-[#6E7093]">Enter Student details to be sent to the <br />school </p>
                  </li>
                  <li className="mb-5 ml-6">
                    <div className="absolute  bg-[#979797] text-white text-[10px] rounded-[30px]  -left-[31px] py-1 px-5 ">Step 4</div>
                    <p className="mt-2 mb-4   ml-8 text-[12px]  text-[#6E7093]">Verify all the information you have provided before proceeding </p>
                  </li>
                  <li className="mb-5 ml-6">
                    <div className="absolute  bg-[#979797] text-white text-[10px] rounded-[30px]  -left-[31px] py-1 px-5 ">Step 5</div>
                    <p className="mt-2 mb-4   ml-8 text-[12px]  text-[#6E7093] ">Make Payment </p>
                  </li>


                </ol>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default StudentInfo