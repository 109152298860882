import React from 'react'
import Sidebar from "../../Sidebar/AdminSidebar";
import UserNavbar from "../../Navbars/AdminNavbar";
import { SvgElement, icontypesEnum } from '../../assets/svgElement';
import { Link, NavLink } from 'react-router-dom';
import { DashboardApi } from '../../../apis/DashboardApi';
import { AxiosResponse } from 'axios';
function Schools() {


    const [userLists, setUserList] = React.useState<any>([]);
    const [searchText, setSearchText] = React.useState('');
    const [csvData, setCsvData] = React.useState<any>([]);

    React.useEffect(() => {
        const query: any = {
            search: searchText,
        };
        DashboardApi.getAllSchool('', query).then(
            (response: AxiosResponse<any>) => {
                if (response?.data) {
                    setUserList(response?.data?.data)
                    console?.log(response?.data?.data)
                }
            }
        ).catch(function (error) {
            // handle error

        })

    }, []);




    const paginator = React.useCallback(
        (value: any) => {
            //   setLoader(true);
            let value2 = '';
            if (value !== null) {
                value2 = value;
            } else {
                value2 = ''
            }
            const query: any = {
                search: searchText,
            };

            DashboardApi.getAllSchool(value2, query).then(
                (response: AxiosResponse<any>) => {
                    if (response?.data) {
                        setUserList(response?.data?.data)
                    }
                }
            ).catch(function (error) {
                console.log(error.response.data);
            })

        }, [userLists, searchText]);


    return (
        <>
            <Sidebar />
            <div className="relative md:ml-64">
                <UserNavbar title="Schools" />
                <div className='pt-5 lg:px-10 px-6 bg-[#F8F8F8] min-h-screen'>
                    <div className='p-4 flex justify-end'>
                        <Link to="/add-school">
                            <div>
                                <button type='button' className='bg-[#00B07B] text-white rounded-[5px] py-[12.5px] px-[30px]'>+ Add School</button>
                            </div></Link>

                    </div>
                    {/* <div className='grid grid-cols-4 items-center'>
                        <div className='text-center'>Location/Country</div>
                        <div className='text-center' >Status</div>
                        <div></div>
                        <div >Date</div>
                    </div> */}
                    {/* <div className='p-4'>
                        <div className='bg-white grid grid-cols-4 py-4 pl-5 rounded-[15px] mb-3'>
                            <div>
                                Finland
                            </div>
                            <div className='text-center'>
                                <span className='bg-[#EDF6F5] text-[#3A974C] text-[12px] rounded-[22px] items-center text-center py-2 px-[40px]'>Active</span>
                            </div>
                            <div></div>
                            <div className='text-[14px] text-[#979797]'>12/05/2023</div>

                        </div>
                        <div className='bg-white grid grid-cols-4 py-4 pl-5 rounded-[15px]'>
                            <div>
                                Finland
                            </div>
                            <div className='text-center'>
                                <span className='bg-[#EDF6F5] text-[#3A974C] text-[12px] rounded-[22px] items-center text-center py-2 px-[40px]'>Active</span>
                            </div>
                            <div></div>
                            <div className='text-[14px] text-[#979797]'>12/05/2023</div>

                        </div>
                    </div> */}
                    <div className='p-4'>
                        <div className="relative overflow-x-auto sm:rounded-lg">
                            <table className="w-full text-sm text-left rtl:text-right text-gray-500 ">
                                <thead className="text-xs text-gray-700  border-none">
                                    <tr>
                                        <th scope="col" className="px-6 py-3">
                                            S/N
                                        </th>
                                        <th scope="col" className="px-6 py-3">
                                            Name
                                        </th>
                                        <th scope="col" className="px-6 py-3">
                                            Location/Country
                                        </th>
                                        <th scope="col" className="px-6 py-3">
                                            Email
                                        </th>
                                        <th scope="col" className="px-6 py-3">
                                            Accepted Currency
                                        </th>
                                        <th scope="col" className="px-6 py-3">
                                            Account Number
                                        </th>
                                        <th scope="col" className="px-6 py-3">
                                            Phone Number
                                        </th>


                                        <th scope="col" className="px-6 py-3">
                                            Date Added
                                        </th>

                                        <th scope="col" className="px-6 py-3">
                                            <span className="sr-only">Edit</span>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        userLists?.data?.map(
                                            (datas: any, index: any) => (
                                                <tr className="bg-white hover:bg-gray-50 hover:shadow-md rounded-lg mb-3 border-none">
                                                    <td className="px-6 py-4">
                                                        {index + 1}
                                                    </td>
                                                    <td className="px-6 py-4">
                                                        {datas?.school_name}
                                                    </td>
                                                    <td className="px-6 py-4">
                                                        {datas?.location}
                                                    </td>
                                                    <td className="px-6 py-4">
                                                        {datas?.email_address}
                                                    </td>
                                                    <td className="px-6 py-4">
                                                        {datas?.accepted_currency}
                                                    </td>
                                                    <td className="px-6 py-4">
                                                        {datas?.account_number}
                                                    </td>
                                                    <td className="px-6 py-4">
                                                        {datas?.phone_number}
                                                    </td>

                                                    <td className="px-6 py-4">
                                                        {(datas?.created_at).slice(0, 10)}
                                                    </td>
                                                    {/* <td className="px-6 py-4">
                                                        <div className='bg-[#EDF6F5] text-[#3A974C] text-[12px] rounded-[22px] items-center text-center py-2 px-3'> {datas?.status}</div>
                                                    </td> */}
                                                    {/* <NavLink to={`/student-details/${datas?.id}`}>
                                                    <td className="px-6 py-4 text-right">
                                                        <SvgElement type={icontypesEnum.SEEMORE} />
                                                    </td>
                                                    </NavLink> */}
                                                </tr>
                                            )
                                        )
                                    }


                                </tbody>
                            </table>
                        </div>

                    </div>
                    <div className='m-4'>
                        {
                            userLists?.links?.filter(((item: any, idx: any) => idx < 1000)).map(
                                (datas: any, index: any) => (
                                    <button onClick={() => paginator(datas?.label == 'Next &raquo;' ? datas?.url.charAt(datas?.url.length - 1) : (datas?.label === '&laquo; Previous') ? datas?.url.charAt(datas?.url.length - 1) : datas?.label)} disabled={datas?.active} className={'mx-1 py-1 px-2 ' + (datas?.active == false ? 'bg-gray-300 text-black ' : 'bg-green-500 text-white')}>
                                        {datas?.label == '&laquo; Previous' ? '< Previous' : (datas?.label === 'Next &raquo;') ? 'Next  >' : datas?.label}
                                    </button>
                                )
                            )
                        }

                    </div>
                </div>
            </div>
        </>
    )
}

export default Schools