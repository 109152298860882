import React from 'react'
import Sidebar from "../../Sidebar/AdminSidebar";
import UserNavbar from "../../Navbars/AdminNavbar";
import { SvgElement, icontypesEnum } from '../../assets/svgElement';
import { useNavigate, Link, NavLink } from 'react-router-dom';
import { AdminApis } from '../../../apis/adminApi';
import { AxiosResponse } from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function SchoolFormList() {

    const [schoolLists, setSchoolList] = React.useState<any>([]);
    const [searchText, setSearchText] = React.useState('');

    React.useEffect(() => {
        const query: any = {
            search: searchText,
        };
        AdminApis.getAllSchoolForm('', query).then(
            (response: AxiosResponse<any>) => {
                if (response?.data) {
                    // console.log(response?.data?.data)
                    setSchoolList(response?.data?.data)
                }
            }
        ).catch(function (error) {
            // handle error

        })

    }, []);

    console.log(schoolLists)
    const paginator = React.useCallback(
        (value: any) => {
            //   setLoader(true);
            let value2 = '';
            if (value !== null) {
                value2 = value;
            } else {
                value2 = ''
            }
            const query: any = {
                search: searchText,
            };

            AdminApis.getAllSchoolForm(value2, query).then(
                (response: AxiosResponse<any>) => {
                    if (response?.data) {
                        setSchoolList(response?.data?.data)
                    }
                }
            ).catch(function (error) {
                console.log(error.response.data);
            })

        }, [schoolLists, searchText]);
  return (
    <>
    <Sidebar />
    <div className="relative md:ml-64">
    <UserNavbar title="School Form" />
    <div className='pt-5 lg:px-10 px-6 bg-[#F8F8F8] min-h-screen'>
         <div className='p-4'>


                        <div className="relative overflow-x-auto sm:rounded-lg">
                            <table className="w-full text-sm text-left rtl:text-right text-gray-500 ">
                                <thead className="text-xs text-gray-700  border-none">
                                    <tr>
                                        <th scope="col" className="px-6 py-3">
                                            S/N
                                        </th>
                                        <th scope="col" className="px-6 py-3">
                                          Full  Name
                                        </th>
                                        <th scope="col" className="px-6 py-3">
                                            Email
                                        </th>
                                        <th scope="col" className="px-6 py-3">
                                           Amount Paid
                                        </th>
                                        <th scope="col" className="px-6 py-3">
                                            Payment Status
                                        </th>
                                        
                                        <th scope="col" className="px-6 py-3">
                                            Date
                                        </th>
                                       
                                        <th scope="col" className="px-6 py-3">
                                            <span className="">Action</span>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>

                                    {
                                        schoolLists?.data?.map(
                                            (datas: any, index: any) => (
                                                <tr className="bg-white hover:bg-gray-50 hover:shadow-md rounded-lg mb-3 border-none">
                                                    <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap ">
                                                        {index + 1}
                                                    </th>
                                                    <td className="px-6 py-4">
                                                        {datas?.full_name}
                                                    </td>
                                                    <td className="px-6 py-4">
                                                        {datas?.email}
                                                    </td>
                                                    <td className="px-6 py-4 capitalize">
                                                        {datas?.amount ? datas?.amount : "0"}
                                                    </td>
                                                    <td className="px-6 py-4 capitalize">
                                                            {datas?.payment_status === "pending" ?  <span className='bg-[#FFDDBD] text-[#FF922B] text-[12px] px-[8px] py-[2px] rounded-sm'>{datas?.payment_status}</span> : "" }
                                                            {datas?.payment_status === "successful" ?<span className='bg-[#C9F0D0] text-[#51CF66] text-[12px] px-[8px] py-[2px] rounded-sm'>{datas?.payment_status}</span>: "" }
                                                            {datas?.payment_status === "failed" ? <span className='bg-[#F5BFBF] text-[#E03130] text-[12px] px-[8px] py-[2px] rounded-sm'>{datas?.payment_status}</span> : ""}
                                                            
                                                        </td>
                                                    
                                                    <td className="px-6 py-4">
                                                        {(datas?.created_at).slice(0, 10)}
                                                    </td>
                                                    
                                                    <NavLink to={`/school-form-details/${datas?.user_id}`}>
                                                        <td className="px-6 py-4 mt-4 text-right">
                                                            <SvgElement type={icontypesEnum.EYE} />
                                                        </td>
                                                    </NavLink>
                                                </tr>
                                            )
                                        )
                                    }



                                </tbody>
                            </table>
                        </div>

                    </div>

<div className='m-4'>
                        {
                            schoolLists?.links?.filter(((item: any, idx: any) => idx < 1000)).map(
                                (datas: any, index: any) => (
                                    <button onClick={() => paginator(datas?.label == 'Next &raquo;' ? datas?.url.charAt(datas?.url.length - 1) : (datas?.label === '&laquo; Previous') ? datas?.url.charAt(datas?.url.length - 1) : datas?.label)} disabled={datas?.active} className={'mx-1 py-1 px-2 ' + (datas?.active == false ? 'bg-gray-300 text-black ' : 'bg-[#00B07B] text-white')}>
                                        {datas?.label == '&laquo; Previous' ? '< Previous' : (datas?.label === 'Next &raquo;') ? 'Next  >' : datas?.label}
                                    </button>
                                )
                            )
                        }

                    </div>
    </div>
    </div>
    </>
  )
}

export default SchoolFormList