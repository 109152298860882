import React from 'react'
import Navbar from '../../Navbar'
import Footer from '../../Footer';
import BlogBg from "../../../img/blog.png"

function CreditReport() {
    return (
        <>
            <Navbar />
            <div className='pt-16'>
                <div
                    style={{
                        backgroundImage: `url(${BlogBg})`,
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "cover",
                        backgroundPosition: "center center",
                    }}
                    className="md:bg-cover bg-contain lg:px-14 px-6 md:pb-24 pb-10 mx-auto  flex flex-col lg:justify-center lg:space-x-14 relative"
                >
                    <div className="place-self-center text-center md:pt-32 md:pb-10 pt-8">
                        <h1 className="md:text-5xl text-2xl text-[#191A15] font-bold">
                            Understanding Credit Reports and Credit Scores with AfriPay
                        </h1>

                        <p className="md:mt-5 mt-2 md:text-base text-[#999A93] text-sm">
                            Get News with AfriPay
                        </p>
                    </div>
                </div>
            </div>

            <section className="bg-[#FFFFFF] body-font font-poppins py-10">
                <div className='lg:px-16 mx-auto px-8 pt-3'>
                    <div className='grid grid-cols-1 gap-8 md:grid-cols-3 lg:grid-cols-12'>
                        <div className='col-span-8'>
                            <div>
                                <img src="/images/blog5.jpg" alt="blog" className='rounded-[10px]' />

                                <p className='mt-3'>
                                    In many places worldwide, being creditworthy is a significant asset. Suppose you’ve ever wondered how to assess your financial standing and gain valuable insights into your creditworthiness as a Nigerian. In that case, this blog will guide you on how to check your credit score in Nigeria and get a free credit report from trusted credit bureaus. Understanding your credit score is essential for making informed financial decisions, and AfriProEdu is here to help you navigate this crucial aspect of financial health.
                                </p>
                            </div>

                        </div>
                    </div>

                    <div className='mt-5'>
                        <h3 className='mt-3 text-[20px] font-semibold'>Why Your Credit Score Matters</h3>
                        <p className='mt-3'>An economy grows when economic transactions can take place smoothly. These transactions are possible when people and businesses can borrow money, which is why a credit score in Nigeria is important. Credit allows people to obtain things they can’t pay for all at once, such as cars or houses, which are too expensive for most people. They turn to credit to help them purchase the essential products and services they need.</p>
                        <p className='mt-3'>Creditors and lenders usually report your behavior to credit bureaus when you borrow money from them. This report includes information such as whether you fail to make loan payments or make payments late. This information is aggregated and assessed by credit bureaus to create credit reports and generate credit scores.</p>
                        <p className='mt-3'>These credit bureaus also keep track of how you use credit, including credit cards, lines of credit, and mortgages, and whether you pay your bills on time. Together, this information helps create your personal credit report and credit score.            </p>


                        <h3 className='mt-8 text-[20px] font-semibold'>What is a Credit Bureau?</h3>
                        <p className='mt-3'>A credit bureau is an agency that gathers and maintains information on people’s loan/credit performance and sells it to lenders, creditors, and other permissible parties in the form of a credit report. The information that the credit bureau gathers includes previous loans that have already been paid, new loans that have just been collected, how the loans have/are being serviced, and if there are any outstanding balances. This information also includes some personal information.</p>
                        <p className='mt-3'>Credit Bureaus keep all this information on individuals and business entities, which can be both positive and negative. This information is strung into a database that allows lenders and other parties to access the credit history of a person or entity. Individuals can also see their credit report by applying to a Credit Bureau for access.</p>


                        <h3 className='mt-8 text-[20px] font-semibold'>Renowned Credit Bureaus in Nigeria</h3>
                        <p className='mt-3'>Here’s a list of renowned credit bureaus in Nigeria that can help you with your credit score and credit reports:</p>
                        <h3 className='mt-3 font-semibold'>● CRC Credit Bureau</h3>
                        <h3 className='mt-3 font-semibold'>● Credit Registry Bureau</h3>
                        <h3 className='mt-3 font-semibold'>● FirstCentral Credit Bureau / XDS Credit Bureau</h3>

                        <h3 className='mt-8 text-[20px] font-semibold'>Understanding Credit Scores and Credit Reports</h3>
                        <p className='mt-3'><span className='font-semibold'>Credit Score:</span> A credit score is a three-digit number that ranges from 300 to 850, indicating the quality of your credit status. A high score shows that an individual poses little risk to lenders. Companies use this score to estimate the risk of lending an individual money or providing a service.</p>
                        <p className='mt-3'><span className='font-semibold'>Credit Report:</span>  A credit report includes information about your past and existing credit contracts, such as loans, credit cards, debt collections, and mortgages. It details how much you owe creditors, how long each account has been open, and how consistently you make on-time payments.</p>


                        <h3 className='mt-8 text-[20px] font-semibold'>How to Get a Free Credit Score and Free Credit Report in Nigeria</h3>
                        <p className='mt-3'>You are entitled to one free Credit report every year from any registered Nigerian Credit Bureau. Here are the steps to get your free credit report:</p>
                        <ul className='space-y-4 list-decimal list-inside mt-4'>
                            <li><span className='font-semibold'>CRC Credit Bureau:</span> Apply for your free report from CRC Credit Bureau. CRC Credit Bureau also offers a USSD code for instant Credit reports. Dial 5658# on your mobile phone to get your credit report in an instant (available to MTN subscribers only).                </li>
                            <li><span className='font-semibold'>Credit Registry:</span> Get a free credit report from Credit Registry. They offer an annual free credit report.</li>
                            <li><span className='font-semibold'>Carbon (Paylater):</span> Carbon, formerly Paylater, also offers free credit reports to Nigerians. You can access the report by registering on their platform.</li>
                        </ul>

                        <h3 className='mt-8 text-[20px] font-semibold'>What Kind of Information is Included in a Credit Report?</h3>
                        <p className='mt-3'>The information collected by credit bureaus and included in credit reports falls into four categories:</p>
                        <ul className='space-y-4 list-disc list-inside mt-4'>
                            <li><span className='font-semibold'>Demographic Information:</span> Name, address, means of identification, registration numbers (for commercial enterprises), etc.</li>
                            <li><span className='font-semibold'>Credit History Information:</span> Details about credit accounts, including account opening dates, credit limits, payment terms, balances, and payment history.</li>
                            <li><span className='font-semibold'>Inquiry Information:</span> Records of credit checks made by lenders, service providers, landlords, or insurers.</li>
                            <li><span className='font-semibold'>Public Records:</span> Information on collateral, securities, lawsuits, litigation, returned cheques, default in taxes, and court orders.</li>
                        </ul>

                        <h3 className='mt-8 text-[20px] font-semibold'>Who Can See Your Credit Report?</h3>
                        <p className='mt-3'>Fortunately, only a short list of people or entities can access your credit report. Generally, this includes:</p>
                        <h3 className='mt-3 font-semibold'>● Banks, specialized banks, and other financial institutions</h3>
                        <h3 className='mt-3 font-semibold'>● Leasing companies</h3>
                        <h3 className='mt-3 font-semibold'>● Insurance companies</h3>
                        <h3 className='mt-3 font-semibold'>● Cooperative societies and institutions offering credit to SMEs</h3>
                        <h3 className='mt-3 font-semibold'>● Utility companies</h3>
                        <h3 className='mt-3 font-semibold'>● Asset management companies</h3>
                        <h3 className='mt-3 font-semibold'>● Suppliers of goods and services on a post-paid, deferred, or installment payment basis</h3>
                        <h3 className='mt-3 font-semibold'>● Entities that in their ordinary course of business have relevant information that complies with Permissible Purposes</h3>
                        <h3 className='mt-3 font-semibold'>● CBN’s CRMS</h3>
                        

                        <h3 className='mt-8 text-[20px] font-semibold'>Why Check Your Credit Score or Credit Report?</h3>
                        <p className='mt-3'>Different entities and individuals use credit reports to make decisions about people. These entities range from credit card companies, mortgage loan companies, auto loans, business loans, and insurance companies, to landlords and employers. They check credit reports to determine if you were financially responsible in the past, helping them ascertain if you will be responsible in the future.</p>
                        
                        <h3 className='mt-8 text-[20px] font-semibold'>What to Do if There are Mistakes on Your Credit Report</h3>
                        <p className='mt-3'>If there are any mistakes in your report, you need to dispute them immediately by contacting a Credit Bureau. Errors on your credit report may lower your credit score and prevent you from obtaining credit when you need it. You can dispute mistakes by checking your credit reports every year to ensure they are correct.</p>
                        <p className='mt-3'>Additionally, check your credit report for accounts you didn’t open, charges you didn’t make, and defaults you didn’t cause. If you see evidence of fraud, contact Nigeria Credit Reports immediately, explain the situation, and ask for a fraud alert to be placed in your file</p>
                        <p className='mt-5'>Your credit report and credit score follow you throughout your life and can help you financially or hurt you. Always check and review them carefully. At AfriProEdu, we believe in empowering individuals with financial literacy and providing the necessary tools to succeed. Start understanding your credit today, and take control of your financial future!</p>
                        <p className='mt-6'>Have questions? Reach out to us at hello@afriproedu.com.</p>





                       





                        <hr className='my-4' />
                        {/* <-- Tags --> */}
                        <div className='mt-3'>
                            <h3>
                            #CreditScore
#CreditReport
#FinancialLiteracy
#NigerianFinance
#AfriProEdu
#FinancialEmpowerment
#KnowYourCredit

                                </h3>
                        </div>
                    </div>
                </div>
            </section>



            {/* <-- Footer --> */}
            <Footer />
        </>
    )
}

export default CreditReport