import  React, { useState }  from "react";
import { NavLink,useNavigate } from "react-router-dom";
import { AuthApis } from "../../../apis/authApis";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Oval } from 'react-loader-spinner'

function ForgotPword() {
  const navigate = useNavigate();
  const [loader, setLoader] = React.useState<any>(false);

  const [userData, setUserdata] = useState({
    'email': "",
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    setUserdata({ ...userData, [name]: value });
  };

  const handleSubmit = (e: React.ChangeEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoader(true)
    const formData = new FormData()
    formData.append('email', userData?.email)
    AuthApis.forgotPassword(formData).then(
      (response) => {
        if (response?.data) {
         if(response?.data?.success){
          navigate('/otp-verify');
          setLoader(false)
         }
        } else {
          toast.warn('Invalid Login Credentials');
          setLoader(false)
        }

        // toast.success(response?.data?.message);
      }
    ).catch(function (error) {
      setLoader(false)
      toast.warn('Invalid Credentials');
      // console.log(error.response.data);
      // toast.error("Offfline");
    }).finally(() => {
      // toast.error("No Internet Connection");

    });
  }

  return (
    <>
      {/* <!-- Start block --> */}
      <section className="bg-[#FBFBFB]  body-font font-poppins ">
        <div className="flex justify-center     ">
         
          

          <div className="lg:px-24 pb-32  mt-36">
            <div className="flex justify-center">
            <NavLink to="/" >
            <img src="/images/logo.png" alt="sign-in" className="max-w-[200px]"/>
            </NavLink>
              
            </div>
            <div className="flex justify-center">
            <h1 className="max-w-2xl mt-6 text-3xl text-[#000000] font-semibold leading-10 md:text-3xl xl:text-4xl md:text-center ">
              Forgot Password?
            </h1>
            </div>
            
            <p className="text-xs mt-3 text-gray-500 text-center">
              No worries, we’ll send you reset instructions.
            </p>

            <div className="mt-10">
              <form onSubmit={handleSubmit}>
                <div className="mb-6">
                  <label className="block mb-2 text-sm font-semibold text-[#414143]">
                    Email address
                  </label>
                  <input
                    type="email"
                    className=" border border-[#D9D9D9] text-[#333333] text-sm rounded-lg block w-full p-3"
                    placeholder="Enter email"
                    name="email"
                    required
                    onChange={handleChange}
                  />
                </div>
                

                
                  <button
                    type="submit"
                    className="w-full text-white bg-[#00B07B] hover:bg-[#05401C] font-medium rounded-full text-sm px-5 py-2.5 mr-2 mb-2"
                  >
                      <span className='flex justify-center'>
                                        <span>  Reset Password</span>
                                        <span>
                                    <Oval
                                        visible={loader}
                                        height="20"
                                        width="80"
                                        color="#00B07B"
                                        secondaryColor="#E6F1FC"
                                        ariaLabel="oval-loading"
                                        wrapperStyle={{}}
                                        wrapperClass=""
                                      />
                                    </span>

                                      </span>
                   
                  </button>
              
                <NavLink to={"/sign-in"} className="flex justify-center">
               
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" className=" mr-2" viewBox="0 0 24 24"><g id="evaArrowBackOutline0"><g id="evaArrowBackOutline1"><path id="evaArrowBackOutline2" fill="currentColor" d="M19 11H7.14l3.63-4.36a1 1 0 1 0-1.54-1.28l-5 6a1.19 1.19 0 0 0-.09.15c0 .05 0 .08-.07.13A1 1 0 0 0 4 12a1 1 0 0 0 .07.36c0 .05 0 .08.07.13a1.19 1.19 0 0 0 .09.15l5 6A1 1 0 0 0 10 19a1 1 0 0 0 .64-.23a1 1 0 0 0 .13-1.41L7.14 13H19a1 1 0 0 0 0-2Z"/></g></g></svg>
                <p className="text-center text-xs font-semibold text-[#414143] mt-1">
                
                  Back to login
                  
                </p>
                </NavLink>
                
              </form>
            </div>
          </div>
        </div>
      </section>

      {/* <!-- End block --> */}

      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover />
    </>
  );
}

export default ForgotPword;
