import React, { useState } from 'react'
import { NavLink,useNavigate } from "react-router-dom";
import { FaEyeSlash, FaEye } from "react-icons/fa";
import { AuthApis } from '../../../apis/authApis';

function OTPVerify() {
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [userData, setUserdata] = useState({
    'one': "",
    'two': "",
    'three': "",
    'four': "",
    'email':"",
    'password':"",
    'confirm_password':""
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    setUserdata({ ...userData, [name]: value });
  };

  const handleSubmit = (e: React.ChangeEvent<HTMLFormElement>) => {
    e.preventDefault();
    const formData = new FormData()
    formData.append('token', userData?.one + userData?.two + userData?.three + userData?.four)
    formData.append('email', userData?.email)
    formData.append('password', userData?.password)
    formData.append('password_confirmation', userData?.confirm_password)

    AuthApis.resetPassword(formData).then(
      (response) => {
        if (response?.data) {
          if(response?.data?.success){
            navigate('/reset-success');
           }
        } else {
          // toast.warn('Invalid Login Credentials');
        }

        // toast.success(response?.data?.message);
      }
    ).catch(function (error) {
      // handle error
      console.log(error.response.data);
      // toast.error("Offfline");
    }).finally(() => {
      // toast.error("No Internet Connection");

    });
  }
  return (
    <>
      {/* <!-- Start block --> */}
      <section className="bg-[#FBFBFB]  body-font font-poppins ">
        <div className="flex justify-center py-24    ">



          <div className="lg:px-24  mt-30">
            <div className="flex justify-center">
            <img src="/images/logo.png" alt="sign-in" className="max-w-[200px]"/>
            </div>
            <h1 className="max-w-2xl mt-6 text-3xl text-[#000000] font-semibold leading-10 md:text-3xl xl:text-4xl text-center ">
              Password Reset
            </h1>
            <p className="text-xs mt-3 text-gray-500 text-center">
              We sent a code to Phone Numnber
              
            </p>
            <form onSubmit={handleSubmit}>
              <div id="otp" className="flex flex-row justify-center text-center px-2 mt-5 mb-5">
                <input className="m-2 border h-14 w-12 text-center form-control focus:border-[#48B774] focus:ring-[#48B774] font-semibold text-3xl" type="text" id="first" name="one" onChange={handleChange} maxLength={1} />
                <input className="m-2 border h-14 w-12 text-center form-control focus:border-[#48B774] focus:ring-[#48B774] font-semibold text-3xl" type="text" id="second" name="two" onChange={handleChange} maxLength={1} />
                <input className="m-2 border h-14 w-12 text-center form-control focus:border-[#48B774] focus:ring-[#48B774] font-semibold text-3xl" type="text" id="third" name="three" onChange={handleChange} maxLength={1} />
                <input className="m-2 border h-14 w-12 text-center form-control focus:border-[#48B774] focus:ring-[#48B774] font-semibold text-3xl" type="text" id="fourth" name="four" onChange={handleChange} maxLength={1} />
              </div>

              <div className="mb-5">
                  <label className="block mb-2 text-sm font-semibold text-[#414143]">
                    Email
                  </label>
                  <input
                    type="email"
                    className=" border border-[#D9D9D9] text-[#333333] text-sm rounded-lg block w-full p-3"
                    placeholder="*****"
                    name="email"
                    required
                    onChange={handleChange}
                  />
                </div>

              <div className="relative mb-5">
                  <label className="block mb-2 text-sm font-semibold text-[#414143]">
                    Password
                  </label>
                  <input
                   type={show ? "text" : "password"}
                    className=" border border-[#D9D9D9] text-[#333333] text-sm rounded-lg block w-full p-3"
                    placeholder="Enter a new password"
                    name="password"
                    required
                    onChange={handleChange}
                  />
                  <button
                              type="button"
                              className=" absolute right-2.5 bottom-3.5"
                              onClick={() => setShow((prev) => !prev)}
                            >
                              {!show ? <FaEyeSlash /> : <FaEye />}
                            </button>
                </div>

                <div className="mb-5">
                  <label className="block mb-2 text-sm font-semibold text-[#414143]">
                    Confirm Password
                  </label>
                  <input
                    type="password"
                    className=" border border-[#D9D9D9] text-[#333333] text-sm rounded-lg block w-full p-3"
                    placeholder="*****"
                    name="confirm_password"
                    required
                    onChange={handleChange}
                  />
                </div>

             
                <button
                  type="submit"
                  className="w-full text-white bg-[#00B07B] hover:bg-[#05401C] focus:ring-4 font-medium rounded-full text-sm px-5 py-2.5 mr-2 mb-2"
                >
                  Continue
                </button>
             
            </form>

            <p className="text-center text-xs font-semibold mt-4 text-gray-500">
              Don't don't receive any email?{" "}
              <NavLink to={'/forgot-password'}>
                <span className="text-[#000] cursor-pointer">click here to resend</span>
              </NavLink>
            </p>

            <NavLink to={"/sign-in"} className="flex justify-center mt-4">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" className=" mr-2" viewBox="0 0 24 24"><g id="evaArrowBackOutline0"><g id="evaArrowBackOutline1"><path id="evaArrowBackOutline2" fill="currentColor" d="M19 11H7.14l3.63-4.36a1 1 0 1 0-1.54-1.28l-5 6a1.19 1.19 0 0 0-.09.15c0 .05 0 .08-.07.13A1 1 0 0 0 4 12a1 1 0 0 0 .07.36c0 .05 0 .08.07.13a1.19 1.19 0 0 0 .09.15l5 6A1 1 0 0 0 10 19a1 1 0 0 0 .64-.23a1 1 0 0 0 .13-1.41L7.14 13H19a1 1 0 0 0 0-2Z"/></g></g></svg>
                <p className="text-center text-xs font-semibold text-[#414143] mt-1">
                
                  Back to login
                  
                </p>
            </NavLink>


          </div>
        </div>
      </section>

      {/* <!-- End block --> */}
    </>
  )
}

export default OTPVerify