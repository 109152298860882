import React from 'react'
import { NavLink } from "react-router-dom";
import Footer from '../Footer';
function TermsOfUse() {
    return (
        <>
            <div className='py-3 lg:pt-2 lg:px-20 px-6'>
                <NavLink to="/" >
                    <img src="/images/afripay-logo.png" alt="logo" className="pt-4" />

                </NavLink>
            </div>
            
            <hr className='mt-3' />
            <div className='lg:px-20 px-6 pt-5 pb-10'>
            <div>
                    {/* <h1 className='text-[36px] font-bold'> Afripay Terms of Use</h1> */}
                    <h1 className='text-[36px] font-bold'>Terms of Use</h1>

                    <h3 className='text-[20px] mt-2 text-[#6E7093]'>Last Updated: April 1, 2024</h3>
                </div>
               
                <p className='text-[16px] mt-3'>By accessing or using AfriPay's services, you agree to comply with these Terms of Use. Please read these terms carefully before using our platform.</p>
                <div className='mt-5'>
                    <h3 className='text-[25px] font-semibold'>Acceptance of Terms</h3>
                    <ul className='mt-2 list-disc list-outside space-y-2 text-black text-[16px] pl-4'>
                        <li>By accessing or using our platform, you agree to be bound by these Terms of Use and our Privacy Policy.</li>
                       
                    </ul>
                </div>
                <div className='mt-5'>
                    <h3 className='text-[25px] font-semibold'>Use of Services</h3>
                    <ul className='mt-2 list-disc list-outside space-y-2 text-black text-[16px] pl-4'>
                        <li>You must be at least 18 years old and legally capable of entering into contracts to use our services.</li>
                        <li>You agree to provide accurate and complete information when registering an account and using our platform.</li>
                        <li>You are responsible for maintaining the confidentiality of your account credentials and for all activities that occur under your account.</li>
                        
                    </ul>
                </div>
                <div className='mt-5'>
                    <h3 className='text-[25px] font-semibold'>Intellectual Property</h3>
                    <ul className='mt-2 list-disc list-outside space-y-2 text-black text-[16px] pl-4'>
                        <li>All content and materials on our platform, including logos, trademarks, and software, are owned or licensed by AfriPay and protected by intellectual property laws.</li>
                        
                    </ul>
                </div>
                <div className='mt-5'>
                    <h3 className='text-[25px] font-semibold'>Prohibited Activities</h3>
                    <ul className='mt-2 list-disc list-outside space-y-2 text-black text-[16px] pl-4'>
                        <li>You agree not to engage in any illegal, fraudulent, or unauthorized activities when using our platform.</li>
                        <li>You may not modify, adapt, reverse engineer, or decompile any part of our platform.</li>
                       
                    </ul>
                </div>
                <div className='mt-5'>
                    <h3 className='text-[25px] font-semibold'>Limitation of Liability</h3>
                    <ul className='mt-2 list-disc list-outside space-y-2 text-black text-[16px] pl-4'>
                        <li>AfriPay is not liable for any damages, losses, or liabilities arising from your use of our platform or services.</li>
                        
                    </ul>
                </div>
                <div className='mt-5'>
                    <h3 className='text-[25px] font-semibold'>Governing Law</h3>
                    <ul className='mt-2 list-disc list-outside space-y-2 text-black text-[16px] pl-4'>
                        <li>These Terms of Use are governed by the laws of [Your Country], without regard to its conflict of law provisions.</li>
                        
                    </ul>
                </div>
                <div className='mt-5'>
                    <h3 className='text-[25px] font-semibold'>Changes to this Agreement</h3>
                    <ul className='mt-2 list-disc list-outside space-y-2 text-black text-[16px] pl-4'>
                        <li>We reserve the right to update or modify these Terms of Use at any time. Continued use of our platform after any changes constitutes acceptance of the revised terms.</li>
                        
                    </ul>
                </div>
            </div>
            <Footer/>
        </>
    )
}

export default TermsOfUse