import React from 'react'
import Navbar from '../../Navbar'
import Footer from '../../Footer';
import BlogBg from "../../../img/blog.png"
import { NavLink } from 'react-router-dom';
function BadCredit() {
    return (
        <>
            <Navbar />
            <div className='pt-16'>
                <div
                    style={{
                        backgroundImage: `url(${BlogBg})`,
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "cover",
                        backgroundPosition: "center center",
                    }}
                    className="md:bg-cover bg-contain lg:px-14 px-6 md:pb-24 pb-10 mx-auto  flex flex-col lg:justify-center lg:space-x-14 relative"
                >
                    <div className="place-self-center text-center md:pt-32 md:pb-10 pt-8">
                        <h1 className="md:text-5xl text-2xl text-[#191A15] font-bold">
                            How to Go from Bad Credit Score to Good
                        </h1>

                        <p className="md:mt-5 mt-2 md:text-base text-[#999A93] text-sm">
                            Get News with AfriPay
                        </p>
                    </div>
                </div>
            </div>

            <section className="bg-[#FFFFFF] body-font font-poppins py-10">
                <div className='lg:px-16 mx-auto px-8 pt-3'>
                    <div className='grid grid-cols-1 gap-8 md:grid-cols-3 lg:grid-cols-12'>
                        <div className='col-span-8'>
                            <div>
                                <img src="/images/blog10.jpg" alt="blog" className='rounded-[10px]' />


                                <p className='mt-3'>Your credit score has a powerful influence over your financial health. Whether you’re applying for a loan, renting an apartment, or seeking better interest rates, a bad credit score can hold you back. If you're currently facing credit challenges, don’t worry—this blog will guide you through the steps to rebuild your credit and improve your financial standing.</p>
                            </div>

                        </div>
                    </div>

                    <div className='mt-5'>
                        <h3 className='mt-3 text-[20px] font-semibold'>What is a Credit Score, and Why Does It Matter?</h3>
                        <p className='mt-3'>Your credit score is a three-digit number that reflects your creditworthiness based on your credit history. It ranges from 300 to 850, with higher scores indicating better credit. Lenders, landlords, and even employers may use this score to assess how likely you are to repay debts.</p>

                        <p className='mt-3 text-[18px]'><span className=' font-semibold'>● Good Credit Score:</span>  700–850                        </p>
                        <p className='mt-3 text-[18px]'><span className=' font-semibold'>● Fair Credit Score:</span>  650–699</p>
                        <p className='mt-3 text-[18px]'><span className=' font-semibold'>● Poor Credit Score: </span> Below 650</p>

                        <p className='mt-3 text-[18px]'> A good credit score leads to:</p>
                        <ul className='space-y-4 list-decimal list-inside mt-4'>
                            <li>Lower interest rates on loans and credit cards.</li>
                            <li>Easier approval for rental housing</li>
                            <li>Higher chances of loan approval</li>
                        </ul>
                        <p className='mt-3 text-[18px]'> But how can you bounce back from a bad score? Let us analyze together.</p>


                        <h3 className='mt-3 text-[20px] font-semibold'>How to Improve Your Credit Score</h3>
                        <div className='mt-5'>
                            <img src="/images/credit4.jpg" alt="blog" className='' />
                        </div>

                        <h3 className='mt-3 text-[18px] font-semibold'>1. Check Your Credit Report for Errors</h3>
                        <p className='mt-3 text-[18px]'>Start by obtaining a copy of your credit report from a reputable agency (AfriPay and our partners can help you generate your credit report). Errors like incorrect personal information, outdated accounts, or inaccurate payment history could be dragging your score down. Correcting these errors can give your score an immediate boost.</p>

                        <p className='mt-2 text-[18px]'><NavLink to={"https://www.goafripay.com/understanding-credit-reports-and-credits-scores-with-afripay"}><span className=' underline underline-offset-1 cursor-pointer text-[#14B266]'>Learn more about checking credit reports</span></NavLink></p>

                        <h3 className='mt-3 text-[18px] font-semibold'>2. Pay Your Bills on Time</h3>

                        <div className='mt-5'>
                            <img src="/images/credit3.jpg" alt="blog" className='' />

                        </div>
                        <p className='mt-3 text-[18px]'>Payment history accounts for 35% of your credit score. Consistently making on-time payments will gradually raise your score. Set up automatic payments or reminders to ensure you never miss a payment.</p>

                        <h3 className='mt-3 text-[18px] font-semibold'>3. Reduce Your Credit Utilization Ratio</h3>
                        <p className='mt-3 text-[18px]'>The amount of credit you're using compared to your credit limit is called the <span className=' font-semibold'>credit utilization ratio.</span> Ideally, you should keep your credit usage below 30% of your total available credit. If you're over this limit, focus on paying down high balances.</p>

                        <h3 className='mt-3 text-[18px] font-semibold'>4. Settle Outstanding Debts</h3>
                        <p className='mt-3 text-[18px]'>Unpaid debts, especially those that have gone into collections, can devastate your score. Contact creditors to negotiate payment plans or settlements. Clearing these debts shows responsibility and can positively affect your score over time.</p>
                        <p className='mt-3 text-[18px] font-semibold'>more about debt repayment strategies (Coming soon)</p>

                        <h3 className='mt-3 text-[18px] font-semibold'>5. Limit New Credit Applications</h3>
                        <div className='mt-5'>
                            <img src="/images/credit1.jpg" alt="blog" className='' />

                        </div>
                        <p className='mt-3 text-[18px]'>Each time you apply for new credit, a hard inquiry is performed, which can temporarily lower your score. Limit your credit applications to only when absolutely necessary.</p>


                        <h3 className='mt-5 text-[20px] font-semibold'>Why Does Your Credit Score Drop in the First Place?</h3>
                        <p className='mt-3 text-[18px]'>Common reasons for a bad credit score include:</p>

                        <p className='mt-3 text-[18px]'>● Missed or late payments</p>
                        <p className='mt-3 text-[18px]'>● High credit card balances</p>
                        <p className='mt-3 text-[18px]'>● Too many credit inquiries</p>
                        <p className='mt-3 text-[18px]'>● Defaulting on loans</p>

                        <p className='mt-3 text-[18px]'>Even life events, like losing a job, can lead to a decline in your score due to financial stress.</p>

                        <h3 className='mt-5 text-[20px] font-semibold'>Who Can Help You Improve Your Credit?</h3>
                        <p className='mt-3 text-[18px]'>Financial advisors, credit counselors, and online resources like AfriPay’s blog provide valuable guidance on credit recovery. Seek help from certified professionals rather than falling for “credit repair” scams that promise quick fixes.</p>

                        <h3 className='mt-5 text-[20px] font-semibold'>Where Can You Track Your Progress?</h3>
                        <div className='mt-5'>
                            <img src="/images/credit2.jpg" alt="blog" className='' />

                        </div>
                        <p className='mt-3 text-[18px]'>Use reliable apps or online banking platforms to keep an eye on your score as it improves. Monitoring your credit is essential for catching any dips or errors as soon as they occur. You can check your credit score through various platforms like AfriPay or directly through your bank’s app.</p>

                        <h3 className='mt-5 text-[20px] font-semibold'>Reasons to Fix Your Credit</h3>

                       
                        <p className='mt-3 text-[18px]'>Improving your credit score isn’t just about numbers—it’s about peace of mind. Imagine being able to buy your first home, get approved for an education loan, or simply not having to worry about being denied for basic financial services.
                            Fixing your credit score means <span className=' font-semibold'>taking back control of your financial future.</span> That sense of empowerment can reduce stress and create new opportunities for personal growth and stability.</p>

                        <h3 className='mt-5 text-[20px] font-semibold'>Recommendations for Building a Better Financial Future</h3>
                        <p className='mt-3 text-[18px]'><span className=' font-semibold'>● Start Early:</span>  It can take several months or years to rebuild a credit score, so begin as soon as possible.</p>
                        <p className='mt-3 text-[18px]'><span className=' font-semibold'>● Avoid Quick Fixes:</span>  Be wary of companies promising to improve your credit overnight. The truth is that building good credit takes time and responsible financial behavior.</p>
                        <p className='mt-3 text-[18px]'><span className=' font-semibold'>● Stay Consistent:</span>  Once you’ve improved your credit, continue practicing good financial habits to maintain it.</p>




                        <p className='mt-6 text-[18px]'>Whether you’re planning to finance an international education, buy a home, or simply enjoy peace of mind, a good credit score opens doors to countless opportunities. Don’t let a bad score define your future—take the steps outlined above to rebuild your credit today.
                            Remember, AfriPay is committed to supporting you on your financial journey. With the upcoming <span className=' font-semibold'>AfriPay savings feature,</span> you’ll have even more tools to manage your money wisely.</p>


                        <p className='mt-5 text-[18px] font-semibold'> <NavLink to="https://www.goafripay.com/" target='_blank'><span className=' underline underline-offset-1 cursor-pointer text-[#14B266]'>Sign up for AfriPay</span> </NavLink>
                            and be the first to know when our savings feature launches!
                        </p>

                        <p className='mt-5 text-[18px]'> <span className=' font-semibold'>Ready to fix your finances?</span> Visit <NavLink to="/sign-in" target='_blank'><span className=' underline underline-offset-1 cursor-pointer text-[#14B266]'>AfriPay</span></NavLink>
                            today and explore how our financial tools can help you achieve your dreams.
                        </p>


                        <hr className='my-4' />
                        {/* <-- Tags --> */}
                        <div className='mt-3'>
                            <h3 className='mt-5 text-[20px] font-semibold'>References:</h3>
                            <h3 className='mt-3'><NavLink to="https://www.consumerfinance.gov/"><span className=' underline underline-offset-1 cursor-pointer text-[#14B266]'>1. Consumer Financial Protection Bureau
                            </span></NavLink></h3>
                            <h3 className='mt-3'><NavLink to="https://www.equifax.com/"><span className=' underline underline-offset-1 cursor-pointer text-[#14B266]'>2. Equifax
                            </span></NavLink></h3>
                            <h3 className='mt-3' ><NavLink to="https://www.debt.org/"><span className=' underline underline-offset-1 cursor-pointer text-[#14B266]'>3. Debt.org
                            </span></NavLink></h3>
                            <h3 className='mt-3'><NavLink to="https://www.creditkarma.com/"><span className=' underline underline-offset-1 cursor-pointer text-[#14B266]'>4. Credit Karma
                            </span></NavLink></h3>
                            <h3 className='mt-3'><NavLink to="https://www.experian.com/"><span className=' underline underline-offset-1 cursor-pointer text-[#14B266]'>5. Experian
                            </span></NavLink></h3>
                        </div>
                    </div>
                </div>
            </section>



            {/* <-- Footer --> */}
            <Footer />
        </>
    )
}

export default BadCredit