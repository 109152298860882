import React from 'react'
import Navbar from '../../Navbar'
import Footer from '../../Footer';
import BlogBg from "../../../img/blog.png"

function SafetyTips() {
  return (
    <>
    <Navbar />
    <div className='pt-16'>
        <div
            style={{
                backgroundImage: `url(${BlogBg})`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                backgroundPosition: "center center",
            }}
            className="md:bg-cover bg-contain lg:px-14 px-6 md:pb-24 pb-10 mx-auto  flex flex-col lg:justify-center lg:space-x-14 relative"
        >
            <div className="place-self-center text-center md:pt-32 md:pb-10 pt-8">
                <h1 className="md:text-5xl text-2xl text-[#191A15] font-bold">
                Safety Tips for Transacting Online with AfriPay
                </h1>

                <p className="md:mt-5 mt-2 md:text-base text-[#999A93] text-sm">
                    Get News with AfriPay
                </p>
            </div>
        </div>
    </div>

    <section className="bg-[#FFFFFF] body-font font-poppins py-10">
        <div className='lg:px-16 mx-auto px-8 pt-3'>
            <div className='grid grid-cols-1 gap-8 md:grid-cols-3 lg:grid-cols-12'>
                <div className='col-span-8'>
                    <div>
                        <img src="/images/blog4.jpg" alt="blog" className='rounded-[10px]' />
                        
                        <p className='mt-3'>In today's digital age, online transactions have become an integral part of our daily lives. Whether you're paying tuition fees, booking flights, or applying for study loans, ensuring your online transactions are secure is paramount. At AfriPay, we prioritize your safety and have compiled essential tips to keep your transactions secure.</p>
                    </div>

                </div>
            </div>

            <div className='mt-5'>
                
                <div className='mt-3'>
                    <ul className='space-y-4 list-decimal list-inside'>
                        <li><span className='font-semibold'>Use Strong and Unique Passwords</span> </li>
                       <h3>● Create complex passwords with a mix of letters, numbers, and symbols.</h3>
                       <h3>● Avoid using easily guessable information like birthdays or common words.</h3>
                       <h3>● Change your passwords regularly and do not reuse passwords across different sites.</h3>
                        
                        <li><span className='font-semibold'>Enable Two-Factor Authentication (2FA)</span> </li>
                        <h3>● Add an extra layer of security by enabling 2FA on your AfriPay account.</h3>
                        <h3>● This typically involves a secondary verification step, such as a code sent to your mobile device.</h3>
                       
                        <li><span className='font-semibold'>Keep Your Devices Secure</span> </li>
                        <h3>● Install reputable antivirus and anti-malware software on your devices.</h3>
                        <h3>● Keep your operating system and all applications updated to protect against security vulnerabilities.</h3>
                        <h3>● Avoid using public Wi-Fi for financial transactions. If necessary, use a trusted VPN service to encrypt your internet connection.</h3>

                        <li><span className='font-semibold'>Be Cautious of Phishing Scams</span> </li>
                        <h3>● Beware of emails, messages, or calls that ask for your personal information or direct you to unfamiliar websites.</h3>
                        <h3>● AfriPay will never ask for your password or personal information via email or SMS.</h3>
                        <h3>● Always verify the sender's information and look for signs of phishing, such as spelling errors or suspicious links.</h3>

                        <li><span className='font-semibold'> Monitor Your Accounts Regularly</span></li>
                        <h3>● Frequently check your AfriPay account for any unauthorized transactions.</h3>
                        <h3>● Report any suspicious activity immediately to AfriPay's customer service.</h3>

                        <li><span className='font-semibold'> Use Secure Websites</span></li>
                        <h3>● Ensure the website URL begins with "https://" before entering your payment information.</h3>
                        <h3>● Look for the padlock icon in the browser's address bar, indicating a secure connection.</h3>

                        <li><span className='font-semibold'>Avoid Sharing Sensitive Information </span></li>
                        <h3>● Never share your AfriPay login details or personal information over email or social media.</h3>
                        <h3>● Be cautious when sharing information over the phone and verify the identity of the caller.</h3>

                        <li><span className='font-semibold'>Log Out After Transactions </span></li>
                        <h3>● Always log out of your AfriPay account after completing transactions, especially on shared or public devices.</h3>

                        <li><span className='font-semibold'> Keep Your Mobile App Updated </span></li>
                        <h3>● Regularly update the AfriPay mobile app to benefit from the latest security features and improvements.</h3>
                        <h3>● Download the app only from official app stores (Google Play Store or Apple App Store).</h3>

                        <li><span className='font-semibold'> Contact AfriPay for Assistance   </span></li>
                        <h3>● If you suspect any security issues or need help, contact AfriPay's customer support immediately. hello@goafripay.com</h3>
                        
                    </ul>
                   
                </div>
                
               
                <div className='mt-5'>
                    <h3 className='mt-3 text-[18px] font-semibold'>Ready to make secure transactions? Sign up on AfriPay today and explore our range of financial services designed for your convenience.</h3>
                   
                </div>
                
                

               

                <hr className='my-4'/>
{/* <-- Tags --> */}
                <div className='mt-3'>
                    <h3>#OnlineSafety
#SecureTransactions
#AfriPay
#DigitalSecurity
#StaySafeOnline
#FinancialSecurity
#CyberSecurity
#OnlineBanking
#ProtectYourData
#SecurePayments</h3>
                </div>
            </div>
        </div>
    </section>



    {/* <-- Footer --> */}
    <Footer />
</>
  )
}

export default SafetyTips